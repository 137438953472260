import React, { StrictMode } from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import {
  StatusManager,
  createOnErrorLink,
  traceLink,
  StatusManagerProvider,
  StatusCache,
  StatusLink,
} from "@naviothera/apollo-link-error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { defaultTheme } from "@naviothera/navio-ui";
import { createUploadLink } from "apollo-upload-client";

import MainLayout from "./components/MainLayout";
import { IS_ADMIN } from "./helpers/isAdmin";
import PageTitle, { getPageTitle } from "./components/common/PageTitle";
import {
  NativeBaseProvider,
  defaultTheme as nativeDefaultTheme,
} from "@naviothera/react-native-navio-ui";
import * as Sentry from "@sentry/react";

// generated by Fragment Matcher plugin
import introspectionResult from "./__generated__/fragments";

import "react-intl-tel-input/dist/main.css";

// change the title of the app if admin
document.title = getPageTitle();

// register super property
// this will always include this property in every tracking event
mixpanel.register({
  app: IS_ADMIN ? "admin" : "provider",
});

// which aws pool are we using
const ENV =
  IS_ADMIN || process.env.REACT_APP_ENV === "cypress" ? "ADMIN" : "PROVIDER";

const AWS_CONFIG = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env[`REACT_APP_${ENV}_AWS_USERPOOL_ID`],
  aws_user_pools_web_client_id:
    process.env[`REACT_APP_${ENV}_AWS_USERPOOL_CLIENT_ID`],
  oauth: {},
};

if (process.env.REACT_APP_NO_AUTH !== "true") {
  Amplify.configure(AWS_CONFIG);
}

const getHeaders = async (headers: any = {}) => {
  if (process.env.REACT_APP_NO_AUTH === "true") {
    return { headers };
  }

  try {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "X-ID-Token": session.getIdToken().getJwtToken(),
      },
    };
  } catch (error) {
    console.error(error);
    return { headers };
  }
};

const planningLink = new HttpLink({
  uri: process.env.REACT_APP_PLANNING_GRAPHQL,
});

const providerLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

const providerAdminLink = createUploadLink({
  uri: process.env.REACT_APP_ADMIN_GRAPHQL,
});

const patientIndexLink = new HttpLink({
  uri: process.env.REACT_APP_PATIENT_GRAPHQL,
});

const adminOperations = [
  "AddPatientsToStaticCohort",
  "CreateMatchRule",
  "CreateStaticPatientCohort",
  "DeletePatientDemographics",
  "DisableMatchRule",
  "NotificationTemplates",
  "CrmPerson",
  "ProviderSettings",
  "RemovePatientsFromStaticCohort",
  "RunScenario",
  "Scenarios",
  "SendTemplatizedMessage",
  "StaticCohorts",
  "AddPatientIdentifier",
  "RemovePatientIdentifier",
];

const planningOperations = [
  "EditNodes",
  "GetNodes",
  "RenderCalendarPlanning",
  "TreatmentPlanPreview",
  "UploadMedia",
  "ListAvailableSurveys",
  "UploadPreview",
];

const patientIndexOperations = ["Patients", "Suggest", "SuggestElements"];

const providerPlanningLink = ApolloLink.split(
  (operation) => planningOperations.includes(operation.operationName),
  planningLink,
  providerLink as any
);

const providerSplitLink = ApolloLink.split(
  (operation) => adminOperations.includes(operation.operationName),
  providerAdminLink as any,
  providerPlanningLink
);

const httpLink = ApolloLink.split(
  (operation) => patientIndexOperations.includes(operation.operationName),
  patientIndexLink,
  providerSplitLink
);

// Create WebSocket link if we are enabling it on this build
const mainLink = process.env.REACT_APP_PLANNING_GRAPHQL_WS
  ? ApolloLink.split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      new WebSocketLink({
        uri: process.env.REACT_APP_PLANNING_GRAPHQL_WS,
        options: {
          reconnect: true,
          lazy: true,
          connectionParams: getHeaders,
        },
      }),
      httpLink
    )
  : httpLink;

// attempt auth on every request
const authLink = setContext(async (request, { headers }) =>
  getHeaders(headers)
);
const statusCache = new StatusCache();
const statusManager = new StatusManager(statusCache);
const onErrorLink = createOnErrorLink(statusManager);
const statusLink = new StatusLink(statusManager);

export const generateCache = () =>
  new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      UserInfo: {
        keyFields: ["username"],
      },
      PatientDemographics: {
        fields: {
          patientIdentifiers: {
            merge(
              existing: any[],
              incoming: any[],
              { readField, mergeObjects }
            ) {
              return incoming?.length > 0 ? incoming : existing;
            },
          },
        },
      },
      Query: {
        fields: {
          ...statusCache.getCacheQueryFields(),
          treatmentPlan: (_, { args, toReference }) => {
            return toReference({
              __typename: "TreatmentPlan",
              id: args?.treatmentPlanId,
            });
          },
        },
      },
    },
  });

const cache = generateCache();

const client = new ApolloClient({
  link: ApolloLink.from([
    statusLink,
    authLink,
    traceLink,
    onErrorLink,
    mainLink,
  ]),
  cache,
  resolvers: {},
});

statusManager.initErrorClient(client, {
  skip: (error) => !!error.operation.getContext().skipErrorManager,
});

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <StrictMode>
      <PageTitle />
      <ThemeProvider theme={defaultTheme}>
        <NativeBaseProvider theme={nativeDefaultTheme as any}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ApolloProvider client={client}>
              <StatusManagerProvider statusManager={statusManager}>
                <Sentry.ErrorBoundary>
                  <MainLayout />
                </Sentry.ErrorBoundary>
              </StatusManagerProvider>
            </ApolloProvider>
          </MuiPickersUtilsProvider>
        </NativeBaseProvider>
      </ThemeProvider>
    </StrictMode>
  );
};

export default App;
