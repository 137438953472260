export const defaultProviderPasswordPolicy = {
  withNumber: true,
  withSpecialChar: false,
  withUppercaseChar: true,
  minLength: 10,
  helperText: "PasswordPolicy.DefaultProviderHelperText",
};

export const defaultAdminPasswordPolicy = {
  withNumber: true,
  withSpecialChar: true,
  withUppercaseChar: true,
  minLength: 12,
  helperText: "PasswordPolicy.DefaultAdminHelperText",
};
