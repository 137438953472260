import uniq from "lodash/uniq";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { useApolloClient } from "@apollo/client";
import {
  AuthenticatorRouter,
  AuthProps,
  AuthState,
  useAuthenticator,
} from "@naviothera/navio-amplify-ui";
import { useGlobalStatus } from "@naviothera/apollo-link-error";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Auth from "@aws-amplify/auth";
import { makeStyles } from "@material-ui/core";
import { useIdleTimer } from "react-idle-timer";
import { useTimeout } from "@naviothera/navio-hooks";

import SignInAd from "./SignInAd/SignInAd";
import {
  CONTENT_BUILDER_ROUTE,
  LIBRARY_ROUTE,
  PATIENTS_ROUTE,
  PERSON_SETTINGS_ROUTE,
  PROVIDER_SETTINGS_ROUTE,
  SCENARIOS_ROUTE,
  TREATMENT_PLANS_ROUTE,
} from "../helpers/routes";
import { IS_ADMIN } from "../helpers/isAdmin";
import IdleDialog from "./common/IdleDialog";
import { useWhoamiLazyQuery } from "../__generated__/graphql";
import FixedLinearProgress from "./FixedLinearProgress";
import {
  TreatmentPlansRoute,
  Home,
  Library,
  NotFound,
  PatientsRoute,
  PersonSettings,
  ProviderSettings,
  Scenarios,
  ContentBuilder,
} from "./LazyComponents";
import { getPageTitle } from "./common/PageTitle";
import {
  defaultAdminPasswordPolicy,
  defaultProviderPasswordPolicy,
} from "../helpers/passwordPolicy";

export const IDLE_WARNING_TIMEOUT_MS = 1000 * 60; // 1 minute warning before logout
export const IDLE_TIMEOUT_MS = 1000 * 60 * 60 - IDLE_WARNING_TIMEOUT_MS; // 59 minutes idle till we warn for logout
export const ACTION_DEBOUNCE_MS = 500; // debounce actions on the page for tracking idle

const AUTH_PAGE_TITLE_MAP: Record<AuthState, string | undefined> = {
  [AuthState.confirmingSignInCustomFlow]: "Confirm Sign In",
  [AuthState.confirmingSignUpCustomFlow]: "Confirm Sign Up",
  [AuthState.ConfirmSignIn]: "Confirm Sign In",
  [AuthState.ConfirmSignUp]: "Confirm Sign Up",
  [AuthState.CustomConfirmSignIn]: "Confirm Sign In",
  [AuthState.ForgotPassword]: "Forgot Password",
  [AuthState.Loading]: undefined,
  [AuthState.ResetPassword]: "Reset Password",
  [AuthState.SettingMFA]: "Set MFA",
  [AuthState.SignedIn]: undefined,
  [AuthState.SignedOut]: undefined,
  [AuthState.SignIn]: "Sign In",
  [AuthState.SigningUp]: "Signing Up",
  [AuthState.SignOut]: undefined,
  [AuthState.SignUp]: "Sign Up",
  [AuthState.TOTPSetup]: "TOTP Setup",
  [AuthState.VerifyContact]: "Verify Contact",
  [AuthState.VerifyingAttributes]: "Verifying Attributes",
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

export const AuthErrorHandler = ({ children }: any) => {
  const { isLoggedIn, signOut } = useAuthenticator();
  const { hasAuthError, lastError } = useGlobalStatus();
  const client = useApolloClient();
  const [isIdle, setIsIdle] = useState(false);
  const { t } = useTranslation();

  const handleOnIdle = () => {
    setIsIdle(true);
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT_MS,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: ACTION_DEBOUNCE_MS,
    eventsThrottle: 100,
    crossTab: true,
  });

  const IDLE_TIMEOUT_MESSAGE = t(
    "To protect patient information, you’ve been signed out due to inactivity."
  );
  const AUTH_ERROR_MESSAGE = t(
    "To protect patient information, you’ve been signed out."
  );

  const signOutTimeout = useTimeout(() => {
    signOut({
      info: { message: IDLE_TIMEOUT_MESSAGE },
    });
  }, IDLE_WARNING_TIMEOUT_MS);

  useEffect(() => {
    if (isIdle && !!signOutTimeout) {
      signOutTimeout.start();
    }
    return () => signOutTimeout?.stop();
  }, [signOutTimeout, isIdle]);

  // logout users when we receive a 401/403 network error
  useEffect(() => {
    if (hasAuthError && isLoggedIn && !!lastError?.networkError) {
      signOut({ error: { message: AUTH_ERROR_MESSAGE } });
      client.clearStore();
    }
  }, [
    AUTH_ERROR_MESSAGE,
    hasAuthError,
    isLoggedIn,
    client,
    signOut,
    lastError,
  ]);

  // clear cache whenever we leave authenticated context
  useEffect(() => {
    return () => {
      client.clearStore();
    };
  }, [client]);

  return (
    <React.Fragment>
      {children}
      <IdleDialog open={isIdle} />
    </React.Fragment>
  );
};

export const AutheticatedRoutes = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [whoamiQuery, { data }] = useWhoamiLazyQuery({
    variables: { withProvider: true },
  });

  const passwordPolicyConfig = IS_ADMIN
    ? defaultAdminPasswordPolicy
    : defaultProviderPasswordPolicy;

  let passwordSchema = Yup.string()
    .required(t("PasswordPolicy.PasswordRequired"))
    .min(
      passwordPolicyConfig.minLength,
      t("PasswordPolicy.MinLength", {
        minLength: passwordPolicyConfig.minLength,
      })
    );

  if (passwordPolicyConfig.withNumber) {
    passwordSchema = passwordSchema.matches(
      /\d/,
      t("PasswordPolicy.NumberRequired")
    );
  }

  if (passwordPolicyConfig.withUppercaseChar) {
    passwordSchema = passwordSchema.matches(
      /[A-Z]/,
      t("PasswordPolicy.UpppercaseRequired")
    );
  }

  if (passwordPolicyConfig.withSpecialChar) {
    passwordSchema = passwordSchema.matches(
      /[\\\][^/$*.{}()?"!@#%&/,><':;|_~`]/,
      t("PasswordPolicy.SpecialCharRequired")
    );
  }

  const passwordPolicy = {
    helperText: t(passwordPolicyConfig.helperText),
    schema: passwordSchema,
  };

  const onAuthChange = async (auth: AuthProps) => {
    if (auth.info?.passwordChanged) {
      enqueueSnackbar(t("PasswordPolicy.PasswordUpdated"), {
        variant: "success",
      });
    }

    // register the providerId for all mixpanel tracking events
    if (auth.authState === AuthState.SignedIn) {
      const userInfo = await Auth.currentUserInfo();
      const providerId = userInfo?.attributes?.["custom:providerId"];
      if (providerId) {
        mixpanel.identify(providerId);
        whoamiQuery();
      }
    } else {
      document.title = getPageTitle(AUTH_PAGE_TITLE_MAP[auth.authState]);
      mixpanel.reset();
      mixpanel.register({ app: IS_ADMIN ? "admin" : "provider" });
    }
  };

  useEffect(() => {
    if (data?.whoami?.provider) {
      const { provider } = data.whoami;
      mixpanel.people.set({
        $id: provider.id,
        $name: provider.name,
        $practiceIds: uniq(
          provider.locations.map((location) => location.practice.id)
        ),
        $practices: uniq(
          provider.locations.map((location) => location.practice.name)
        ),
        $organizationIds: uniq(
          provider.locations
            .map((location) => location.practice.organization?.id)
            .filter((id) => !!id)
        ),
        $organizations: uniq(
          provider.locations
            .map((location) => location.practice.organization?.name)
            .filter((name) => !!name)
        ),
      });
    }
  }, [data]);

  const children = [
    <TreatmentPlansRoute
      key="treatments"
      path={`${TREATMENT_PLANS_ROUTE}/*`}
      admin={IS_ADMIN}
    />,
    <PatientsRoute
      key="patients"
      path={`${PATIENTS_ROUTE}/*`}
      admin={IS_ADMIN}
    />,
    <Home admin={IS_ADMIN} key="home" path="/" />,
    <NotFound key="404" default />,
  ];

  IS_ADMIN &&
    children.push(
      <ProviderSettings
        key="provider"
        path={`${PROVIDER_SETTINGS_ROUTE}/:providerId`}
      />,
      <PersonSettings
        key="person"
        path={`${PERSON_SETTINGS_ROUTE}/:copperId`}
      />,
      <Scenarios key="scenarios" path={SCENARIOS_ROUTE} />,
      <Library key="library" path={`${LIBRARY_ROUTE}/*`} />,
      <ContentBuilder key="content" path={`${CONTENT_BUILDER_ROUTE}/*`} />
    );

  if (process.env.REACT_APP_NO_AUTH === "true") {
    return (
      <Suspense fallback={<FixedLinearProgress />}>
        <Router>{children}</Router>;
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<FixedLinearProgress />}>
      <AuthenticatorRouter
        onAuthChange={onAuthChange}
        passwordPolicy={passwordPolicy}
        redirectTo={TREATMENT_PLANS_ROUTE}
        signInAd={<SignInAd />}
        usernameAlias="email"
        termsOfUse={
          <Fragment>
            {t("By signing in, you agree to Navio’s ")}
            <a
              href="https://navio.com/terms-of-use/"
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Terms of Use.")}
            </a>
          </Fragment>
        }
      >
        <AuthErrorHandler path="/">{children}</AuthErrorHandler>
      </AuthenticatorRouter>
    </Suspense>
  );
};

export default AutheticatedRoutes;
