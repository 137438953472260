import { useCallback } from "react";
import { useGlobalStatus } from "@naviothera/apollo-link-error";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

import ErrorSnackbar from "./ErrorSnackbar";
import analyticsEvents from "../helpers/analyticsEvents";
import * as Sentry from "@sentry/react";

const GlobalLoadingIndicator = () => {
  const { t } = useTranslation();
  const { hasError, hasAuthError, errorTraceId, resetError, lastError } =
    useGlobalStatus();

  const renderErrorSnackbar = useCallback(
    ({
      closeCallback,
      traceId,
    }: {
      closeCallback?: () => void;
      traceId?: string | null | undefined;
    }) => {
      return (
        <ErrorSnackbar closeCallback={closeCallback}>
          {hasAuthError
            ? t("You have been logged out.")
            : t(
                "It looks like something went wrong. Please try again in a few minutes. If you continue to have this issue, send us a message at support@navio.com"
              )}
          {!!traceId && (
            <Typography variant="overline" color="textSecondary">
              {`${t("ERROR TRACE ID")}: ${traceId}`}
            </Typography>
          )}
        </ErrorSnackbar>
      );
    },
    [t, hasAuthError]
  );

  if (hasError) {
    mixpanel.track(analyticsEvents.showErrorMessage, {
      error: lastError,
      traceId: errorTraceId,
    });
    Sentry.captureException({
      error: lastError,
      traceId: errorTraceId,
    });
  }

  if (hasError) {
    return renderErrorSnackbar({
      traceId: errorTraceId,
      closeCallback: resetError,
    });
  }
  return null;
};

export default GlobalLoadingIndicator;
