export const analyticsEvents = {
  retriggerSMS: "Retrigger SMS",
  enrollPatient: "Enroll Patient",
  clickTreatmentPlanTab: (name: string) => `Click ${name} Tab`,
  submitPatientDetailsError: "Submit Patient Details Error",
  submitTreatmentPlanEditError: "Submit Treatment Plan Edit Error",
  submitMissingPatientDetailsError: "Submit Missing Patient Details Error",
  submitTreatmentPlanSettingsError: "Submit Treatment Settings Error",
  completeTreatmentPlanSettings: "Complete Treatment Settings",
  completeAssignTreatmentPlan: "Complete Assign Treatment Plan",
  completeMissingPatientDetails: "Complete Missing Patient Details",
  submitAssignTreatmentPlanError: "Assign Treatment Plan Error",
  toggleAssignDrawer: (open: boolean) =>
    `${open ? "Open" : "Close"} Assign Drawer`,
  togglePatientDetailsDrawer: (open: boolean) =>
    `${open ? "Open" : "Close"} Patient Details Drawer`,
  toggleTreatmentPlanSettingsDrawer: (open: boolean) =>
    `${open ? "Open" : "Close"} Treatment Settings Drawer`,
  downloadTreatmentPDF: "Download Treatment Timeline PDF",
  downloadCalendarICS: "Click Download ICS",
  printCalendar: "Print Calendar",
  closePrintCalendar: "Close Print Calendar",
  printTreatmentTimelinePDF: "Print Treatment Timeline PDF",
  closePrintTreatmentTimelinePDF: "Close Print Treatment PDF",
  selectDiagnosisTreatmentPlans: "Select Diagnosis for Treatment Plans",
  clickLogout: "Click Logout",
  clickSignInAd: (val: string) => `Click Sign In Ad for ${val}`,
  removeTreatmentPlan: "Remove Treatment Plan",
  removeTreatmentPlanError: "Remove Treatment Plan Error",
  showErrorMessage: "Show Error Message",
  togglePatientChangesLog: (id: string, open: boolean) =>
    `${open ? "Open" : "Close"} Patient (${id}) changes log`,
};

export default analyticsEvents;
