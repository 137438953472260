import React, { useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  Theme,
  IconButton,
  Box,
  Snackbar,
  SnackbarContent,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface IProps {
  className?: string;
  children?: React.ReactNode;
  autoHide?: boolean;
  closeCallback?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  icon: {
    fontSize: theme.spacing(2),
  },
  message: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ErrorSnackbar = (props: IProps) => {
  const styles = useStyles(props);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const { closeCallback } = props;
  const DEFAULT_AUTO_HIDE_DURATION = 3000;

  const onClose = useCallback(() => {
    setOpen(false);
    closeCallback && closeCallback();
  }, [setOpen, closeCallback]);

  return (
    <Snackbar
      className={clsx(styles.root, props.className)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={props.autoHide ? DEFAULT_AUTO_HIDE_DURATION : null}
    >
      <SnackbarContent
        className={styles.content}
        aria-describedby="error-snackbar"
        message={
          <Box id="error-snackbar" className={styles.message}>
            {props.children}
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label={t("Close")}
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={styles.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default ErrorSnackbar;
