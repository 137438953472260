import { LinearProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface IProps {
  className?: string;
}

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
});

const FixedLinearProgress = (props: IProps) => {
  const styles = useStyles(props);

  return <LinearProgress className={clsx(styles.root, props.className)} />;
};

export default FixedLinearProgress;
