
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IPlanItemOccurrence": [
      "MeasureOccurrence"
    ],
    "IPlanItemTarget": [
      "Measure"
    ],
    "Language": [
      "ContentDoc"
    ],
    "PresentationItem": [
      "ContentDoc",
      "Notification",
      "Survey"
    ],
    "TreatmentPlanNode": [
      "Brand",
      "CalendarEventTemplate",
      "Confirmation",
      "ContentDoc",
      "ContentDocContext",
      "ContentMedia",
      "Diagnosis",
      "EditableParameter",
      "Flow",
      "FlowStep",
      "Layer",
      "LayerGroup",
      "Measure",
      "Module",
      "Notification",
      "NotificationContent",
      "NotificationPreference",
      "NotificationTemplate",
      "Parameter",
      "ParameterMapping",
      "Phase",
      "PlanItem",
      "Question",
      "Resource",
      "ResourceCategory",
      "ResourceReference",
      "Survey",
      "Task",
      "Treatment",
      "TreatmentList",
      "TreatmentPlan"
    ]
  }
};
      export default result;
    