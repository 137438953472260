import { useEffect } from "react";
import { usePrevious } from "@naviothera/navio-hooks";

export const useAnalytics = (pathname?: string) => {
  const trimmedPathname = pathname?.replace(/^\/|\/$/g, "");
  const previousPathname = usePrevious(trimmedPathname);
  useEffect(() => {
    if (trimmedPathname !== previousPathname) {
      mixpanel?.track("pageview", {
        path: trimmedPathname?.split("/")?.[0],
        pathname: trimmedPathname,
      });
    }
  }, [previousPathname, trimmedPathname]);

  return mixpanel;
};

export default useAnalytics;
