import { useState } from "react";
import { View } from "@naviothera/navio-ui";
import { makeStyles, IconButton, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import SwipeableViews from "react-swipeable-views";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import ad1Url from "../../images/ad1.svg";
import ad2Url from "../../images/ad2.svg";
import analyticsEvents from "../../helpers/analyticsEvents";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.white}`,
    padding: 0,
    transition: "opacity 300ms ease",
  },
  controls: {
    position: "absolute",
    width: "100%",
    bottom: theme.spacing(4),
  },
  counter: {
    color: theme.palette.common.white,
    margin: theme.spacing(0, 1.5),
  },
  slide: {
    height: "100vh",
  },
  svgWrapper: {
    maxWidth: "100%",
    padding: theme.spacing(0, 6),
    maxHeight: theme.spacing(50),
  },
  disabled: {
    cursor: "default",
    opacity: 0.3,
  },
  headline: {
    color: theme.palette.common.white,
  },
  description: {
    color: theme.palette.common.white,
    margin: theme.spacing(2, 0, 3, 0),
    maxWidth: theme.spacing(45),
  },
  button: {
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
  },
  img: {
    maxHeight: "100%",
  },
}));

export const SignInAd = () => {
  const [index, setIndex] = useState(0);
  const styles = useStyles();
  const { t } = useTranslation();

  const slides = [
    <View f={1} pt={6} pb={5}>
      <View justifyContent="center" alignItems="center">
        <Typography variant="body1" className={styles.headline}>
          {t("SignInAd.Title1")}
        </Typography>
        <Typography variant="subtitle2" className={styles.description}>
          {t("SignInAd.Caption1")}
        </Typography>
        <a
          href="https://www.navio.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="outlined"
            className={styles.button}
            onClick={() => analyticsEvents.clickSignInAd("Contact Us")}
          >
            {t("SignInAd.ContactUs")}
          </Button>
        </a>
      </View>
      <View className={styles.svgWrapper} mt={3}>
        <img src={ad1Url} alt="mobile ad" className={styles.img} />
      </View>
    </View>,
    <View f={1} pt={6} pb={5}>
      <View justifyContent="center" alignItems="center">
        <Typography variant="body1" className={styles.headline}>
          {t("SignInAd.Title2")}
        </Typography>
        <Typography variant="subtitle2" className={styles.description}>
          {t("SignInAd.Caption2")}
        </Typography>
        <a
          href="https://www.navio.com/treatments/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="outlined"
            className={styles.button}
            onClick={() => analyticsEvents.clickSignInAd("Treatment Plans")}
          >
            {t("SignInAd.ViewPlans")}
          </Button>
        </a>
      </View>
      <View className={styles.svgWrapper} mt={3}>
        <img src={ad2Url} alt="calendar ad" className={styles.img} />
      </View>
    </View>,
  ];
  return (
    <View>
      <SwipeableViews index={index} onChangeIndex={(index) => setIndex(index)}>
        {slides.map((slide, index) => (
          <View key={index} className={styles.slide}>
            {slide}
          </View>
        ))}
      </SwipeableViews>
      <View
        className={styles.controls}
        row
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          onClick={() => index > 0 && setIndex(index - 1)}
          className={clsx(styles.arrow, index === 0 && styles.disabled)}
        >
          <KeyboardArrowLeft style={{ height: 30, width: 30 }} />
        </IconButton>
        <Typography variant="subtitle2" className={styles.counter}>
          {`${index + 1}/${slides.length}`}
        </Typography>
        <IconButton
          onClick={() => index < slides.length - 1 && setIndex(index + 1)}
          className={clsx(
            styles.arrow,
            index >= slides.length - 1 && styles.disabled
          )}
        >
          <KeyboardArrowRight style={{ height: 30, width: 30 }} />
        </IconButton>
      </View>
    </View>
  );
};

export default SignInAd;
