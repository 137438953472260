import React, { useState } from "react";
import { View, Button } from "@naviothera/navio-ui";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { NavioTheme } from "@naviothera/navio-ui/dist/styles/defaultTheme";
import DOMPurify from "dompurify";

const COOKIES_KEY = "navio-cookies-accepted";
const ACCEPTED_COOKIES_VALUE = "accepted";
const COOKIE_DOMAIN =
  process.env.NODE_ENV === "development" ? "" : ".navio.com";

const useStyles = makeStyles((theme: NavioTheme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    height: theme.spacing(7.5),
    backgroundColor: theme.palette.primary[50],
    width: "100%",
    padding: theme.spacing(0.75, 1.25),
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  button: {
    padding: theme.spacing(0.75, 2),
    color: theme.palette.common.white,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body2,
      marginLeft: 0,
    },
  },
  title: {
    color: theme.palette.primary[500],
    ...theme.typography.body2,
    [theme.breakpoints.down("sm")]: {
      flex: 0.9,
      ...theme.typography.caption,
    },
    "& a": {
      textDecoration: "underline",
    },
  },
}));

export const CookieBanner = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [cookiesAccepted, setCookiesAccepted] = useState(
    Cookies.get(COOKIES_KEY) === ACCEPTED_COOKIES_VALUE
  );

  const handleAccept = () => {
    Cookies.set(COOKIES_KEY, ACCEPTED_COOKIES_VALUE, { domain: COOKIE_DOMAIN });
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) {
    return null;
  }

  return (
    <View className={styles.root} row>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Navio uses cookies to ensure you get the best experience. <a href="https://www.navio.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Learn more</a>'
          ),
        }}
        className={styles.title}
      />
      <Button
        data-testid="cookies-accept-button"
        color="secondary"
        className={styles.button}
        onClick={handleAccept}
      >
        {t("Got it!")}
      </Button>
    </View>
  );
};
