import { gql } from "graphql.macro";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Base64String: any;
  Color: any;
  CountryCode: any;
  DataUrl: any;
  Date: any;
  DateTime: any;
  DayOfWeek: any;
  Duration: any;
  ExternalId: any;
  Hash: any;
  JSON: any;
  LanguageTag: any;
  OffsetDateTime: any;
  Period: any;
  Time: any;
  TimeZone: any;
  URL: any;
  UUID: any;
  Upload: any;
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  country: Scalars["CountryCode"];
  id: Scalars["UUID"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
  street1: Scalars["String"];
  street2?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  city: Scalars["String"];
  country: Scalars["CountryCode"];
  id: Scalars["UUID"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
  street1: Scalars["String"];
  street2?: InputMaybe<Scalars["String"]>;
};

export enum AdministrativeSex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum AlternativeName {
  ABBREVIATION = "ABBREVIATION",
  AUTHORING_HINT = "AUTHORING_HINT",
  BRAND = "BRAND",
  GENERIC = "GENERIC",
  PROTOCOL = "PROTOCOL",
  SLUG = "SLUG",
}

export type AlternativeNameEntry = {
  __typename?: "AlternativeNameEntry";
  key: AlternativeName;
  value: Scalars["String"];
};

export type Anything = {
  __typename?: "Anything";
  active: Scalars["Boolean"];
  alternativeNames: Array<AlternativeNameEntry>;
  id: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  name: Scalars["String"];
  relatedItems: Array<Anything>;
  type: AnythingType;
  visible: Scalars["Boolean"];
};

export type AnythingAlternativeNamesArgs = {
  ofTypes?: InputMaybe<Array<AlternativeName>>;
};

export type AnythingRelatedItemsArgs = {
  ofTypes?: InputMaybe<Array<AnythingType>>;
};

export type AnythingConnection = {
  __typename?: "AnythingConnection";
  edges: Array<AnythingEdge>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AnythingEdge = {
  __typename?: "AnythingEdge";
  node: Anything;
  score: Scalars["Float"];
};

export type AnythingSearchSort = {
  field: AnythingSortField;
  order?: InputMaybe<OrderDirection>;
};

export enum AnythingSortField {
  LASTUPDATED = "LASTUPDATED",
  NAME = "NAME",
}

export enum AnythingType {
  Brand = "Brand",
  CalendarEventTemplate = "CalendarEventTemplate",
  Cohort = "Cohort",
  Confirmation = "Confirmation",
  ContentDoc = "ContentDoc",
  ContentDocContext = "ContentDocContext",
  ContentMedia = "ContentMedia",
  Diagnosis = "Diagnosis",
  EditableParameter = "EditableParameter",
  Flow = "Flow",
  FlowStep = "FlowStep",
  Layer = "Layer",
  LayerGroup = "LayerGroup",
  Measure = "Measure",
  Module = "Module",
  Notification = "Notification",
  NotificationPreference = "NotificationPreference",
  NotificationResponse = "NotificationResponse",
  NotificationTemplate = "NotificationTemplate",
  Organization = "Organization",
  Parameter = "Parameter",
  ParameterMapping = "ParameterMapping",
  ParsingTable = "ParsingTable",
  PatientIdentifier = "PatientIdentifier",
  PatientType = "PatientType",
  Phase = "Phase",
  PlanItem = "PlanItem",
  Practice = "Practice",
  Provider = "Provider",
  ProviderLocation = "ProviderLocation",
  ProviderRole = "ProviderRole",
  Question = "Question",
  Resource = "Resource",
  ResourceCategory = "ResourceCategory",
  ResourceReference = "ResourceReference",
  Survey = "Survey",
  Task = "Task",
  Treatment = "Treatment",
  TreatmentList = "TreatmentList",
  TreatmentPlan = "TreatmentPlan",
}

export enum AuditLogAction {
  DELETE = "DELETE",
  INSERT = "INSERT",
  UNKNOWN = "UNKNOWN",
  UPDATE = "UPDATE",
}

export type AuditLogFilter = {
  from?: InputMaybe<Scalars["DateTime"]>;
  patientId: Scalars["UUID"];
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type AuditLogRecord = {
  __typename?: "AuditLogRecord";
  action: AuditLogAction;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<Scalars["UUID"]>;
  createdByName: Scalars["String"];
  delta: Array<PatientAuditDelta>;
  revision: Scalars["Int"];
};

export type AuthEvent = {
  __typename?: "AuthEvent";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  creationDate: Scalars["DateTime"];
  deviceName?: Maybe<Scalars["String"]>;
  eventType: Scalars["String"];
  id: Scalars["String"];
};

export type AuthEventResponse = {
  __typename?: "AuthEventResponse";
  events: Array<AuthEvent>;
  nextPage?: Maybe<Scalars["String"]>;
};

export type AvailableLanguage = {
  __typename?: "AvailableLanguage";
  name: Scalars["String"];
  tag: Scalars["LanguageTag"];
};

export type Brand = TreatmentPlanNode & {
  __typename?: "Brand";
  alternativeNames: Array<AlternativeNameEntry>;
  contentMedia: Array<ContentMedia>;
  designTokens?: Maybe<Scalars["JSON"]>;
  domainPrefix?: Maybe<Scalars["String"]>;
  emailFromAddress?: Maybe<Scalars["String"]>;
  emailFromName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  smsSenderId?: Maybe<Scalars["UUID"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type BrandAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type Calendar = {
  __typename?: "Calendar";
  activePlanItemOccurrences: Array<PlanItemOccurrence>;
  blob: Scalars["String"];
  calendarEventTemplates: Array<CalendarEventTemplate>;
  events: Array<CalendarEvent>;
  parameters: Array<TreatmentPlanParameterValue>;
  timezone?: Maybe<Scalars["TimeZone"]>;
};

export type CalendarEventsArgs = {
  from?: InputMaybe<Scalars["OffsetDateTime"]>;
  to?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type CalendarEvent = {
  __typename?: "CalendarEvent";
  calendarEventTemplate?: Maybe<CalendarEventTemplate>;
  /** @deprecated Use calendarEventTemplate.id */
  calendarEventTemplateId?: Maybe<Scalars["UUID"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  occurrences: Array<CalendarEventOccurrence>;
};

export enum CalendarEventCategory {
  APPOINTMENT = "APPOINTMENT",
  LIFESTYLE = "LIFESTYLE",
  MEDICINE = "MEDICINE",
  MILESTONE = "MILESTONE",
}

export type CalendarEventDuration = {
  __typename?: "CalendarEventDuration";
  allDay?: Maybe<Scalars["Boolean"]>;
  length?: Maybe<Scalars["Duration"]>;
};

export type CalendarEventOccurrence = {
  __typename?: "CalendarEventOccurrence";
  description?: Maybe<Scalars["String"]>;
  editableParameterValues: Array<EditableParameterValue>;
  externalId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  scope: CalendarEventScope;
  timeDescriptionOverride?: Maybe<Scalars["String"]>;
  timing: CalendarEventTiming;
};

export type CalendarEventParameterValueInput = {
  editableParameterId: Scalars["UUID"];
  id: Scalars["UUID"];
  parameterId: Scalars["UUID"];
  scope: CalendarEventScopeInput;
  value?: InputMaybe<Scalars["String"]>;
};

export type CalendarEventScope = {
  __typename?: "CalendarEventScope";
  layerOccurrenceId?: Maybe<Scalars["UUID"]>;
  path: Array<PathElement>;
  string: Scalars["String"];
};

export type CalendarEventScopeInput = {
  layerOccurrenceId?: InputMaybe<Scalars["UUID"]>;
  path?: InputMaybe<Array<PathElementInput>>;
  string?: InputMaybe<Scalars["String"]>;
};

export type CalendarEventStart = {
  __typename?: "CalendarEventStart";
  absoluteTime?: Maybe<Scalars["Time"]>;
  allowedDays?: Maybe<Array<Scalars["DayOfWeek"]>>;
  allowedDaysParameter?: Maybe<Parameter>;
  dateTimeParameter?: Maybe<Parameter>;
  offsetDuration?: Maybe<Scalars["Duration"]>;
  offsetPeriod?: Maybe<Scalars["Period"]>;
  projectionDirection?: Maybe<ProjectionDirection>;
  relativeToEvent?: Maybe<CalendarEventTemplate>;
  relativeToOccurrence?: Maybe<RelativeToOccurrence>;
  timeParameter?: Maybe<Parameter>;
};

export type CalendarEventTemplate = TreatmentPlanNode & {
  __typename?: "CalendarEventTemplate";
  alternativeNames: Array<AlternativeNameEntry>;
  blocking: Scalars["Boolean"];
  category?: Maybe<CalendarEventCategory>;
  color?: Maybe<Scalars["Color"]>;
  confirmations: Array<Confirmation>;
  contentDocs: Array<ContentDoc>;
  contentMedia: Array<ContentMedia>;
  dependentEvents: Array<CalendarEventTemplate>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<CalendarEventDuration>;
  editableParameters: Array<EditableParameter>;
  exceptHolidays: Scalars["Boolean"];
  flows: Array<Flow>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  notifications: Array<Notification>;
  patientNameOverride?: Maybe<Scalars["String"]>;
  recurrenceStrategy?: Maybe<CalendarRecurrenceStrategy>;
  referenceInfo: NodeReferenceInfo;
  rescheduleStrategy?: Maybe<RescheduleStrategy>;
  start?: Maybe<CalendarEventStart>;
  surveys: Array<Survey>;
  tasks: Array<Task>;
  timeDescriptionOverride?: Maybe<Scalars["String"]>;
  visible?: Maybe<Scalars["Boolean"]>;
  visibleTo?: Maybe<VisibleTo>;
};

export type CalendarEventTemplateAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type CalendarEventTiming = {
  __typename?: "CalendarEventTiming";
  allDay: Scalars["Boolean"];
  end: Scalars["OffsetDateTime"];
  start: Scalars["OffsetDateTime"];
};

export type CalendarInput = {
  eventParameterValues?: InputMaybe<Array<CalendarEventParameterValueInput>>;
  includeScheduledEvents?: InputMaybe<Scalars["Boolean"]>;
  layerOccurrences?: InputMaybe<Array<CalendarLayerOccurrence>>;
  parameterValues?: InputMaybe<Array<TreatmentPlanParameterValueInput>>;
  timezone?: InputMaybe<Scalars["TimeZone"]>;
  /** @deprecated Use layerOccurrences */
  treatmentPlanId?: InputMaybe<Scalars["UUID"]>;
  /** @deprecated Use layerOccurrences */
  treatmentPlanIds?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type CalendarLayerOccurrence = {
  layerId: Scalars["UUID"];
  layerOccurrenceId: Scalars["UUID"];
};

export type CalendarPdf = {
  __typename?: "CalendarPDF";
  blob?: Maybe<Scalars["Base64String"]>;
};

export type CalendarPdfRequest = {
  from?: InputMaybe<Scalars["OffsetDateTime"]>;
  language?: InputMaybe<Scalars["LanguageTag"]>;
  orientation?: InputMaybe<PdfOrientation>;
  size?: InputMaybe<PdfSize>;
  to?: InputMaybe<Scalars["OffsetDateTime"]>;
};

export type CalendarRecurrenceStrategy = {
  __typename?: "CalendarRecurrenceStrategy";
  countUntil?: Maybe<CountUntil>;
  inheritReschedules?: Maybe<Scalars["Boolean"]>;
  recurrenceFrequency?: Maybe<Frequency>;
  recurrenceInterval?: Maybe<Scalars["Int"]>;
  /** @deprecated Use repeatUntilEventOccurrence instead. */
  recurrenceRelation?: Maybe<RecurrenceRelation>;
  repeatCount?: Maybe<Scalars["Int"]>;
  repeatCountParameter?: Maybe<Parameter>;
  repeatOnAllAllowedDays?: Maybe<Scalars["Boolean"]>;
  repeatUntilDelta?: Maybe<Scalars["Period"]>;
  repeatUntilEvent?: Maybe<CalendarEventTemplate>;
  repeatUntilEventOccurrence?: Maybe<UntilOccurrence>;
  repeatUntilParameter?: Maybe<Parameter>;
};

export enum CalendarSerializationFormat {
  ICAL = "ICAL",
}

export type Confirmation = TreatmentPlanNode & {
  __typename?: "Confirmation";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDocs: Array<ContentDoc>;
  duration: Scalars["Duration"];
  eventDelta: Scalars["Duration"];
  id: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  occurrenceSpecifier: OccurrenceSpecifier;
  parameters: Array<Parameter>;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ConfirmationAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type ContentDoc = Language &
  PresentationItem &
  TreatmentPlanNode & {
    __typename?: "ContentDoc";
    alternativeNames: Array<AlternativeNameEntry>;
    /** @deprecated Use contentJson instead. */
    contentHtml?: Maybe<Scalars["String"]>;
    contentJson?: Maybe<Scalars["JSON"]>;
    /** @deprecated Use docContext instead. */
    context: Scalars["String"];
    docContext: ContentDocContext;
    externalId?: Maybe<Scalars["UUID"]>;
    id: Scalars["UUID"];
    language: Scalars["LanguageTag"];
    name?: Maybe<Scalars["String"]>;
    nodeHash: Scalars["Hash"];
    nodeProvenance: NodeProvenance;
    nodeType: NodeType;
    previewImageUrl?: Maybe<Scalars["URL"]>;
    referenceInfo: NodeReferenceInfo;
    referencesDocs: Array<ContentDoc>;
    referencesMedia: Array<ContentMedia>;
    referencesQuestions: Array<Question>;
    /** @deprecated Use contentJson instead. */
    slug?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    /** @deprecated Use contentJson instead. */
    url?: Maybe<Scalars["URL"]>;
    useContentJson: Scalars["Boolean"];
    visible?: Maybe<Scalars["Boolean"]>;
  };

export type ContentDocAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type ContentDocContainerSizes = {
  __typename?: "ContentDocContainerSizes";
  maxWidthPx?: Maybe<Scalars["Int"]>;
  minWidthPx?: Maybe<Scalars["Int"]>;
  widthPx?: Maybe<Scalars["Int"]>;
};

export type ContentDocContext = TreatmentPlanNode & {
  __typename?: "ContentDocContext";
  alternativeNames: Array<AlternativeNameEntry>;
  breakpointBase?: Maybe<ContentDocContainerSizes>;
  breakpointExtraLarge?: Maybe<ContentDocContainerSizes>;
  breakpointLarge?: Maybe<ContentDocContainerSizes>;
  breakpointMedium?: Maybe<ContentDocContainerSizes>;
  breakpointSmall?: Maybe<ContentDocContainerSizes>;
  description?: Maybe<Scalars["String"]>;
  exampleImageUrl?: Maybe<Scalars["URL"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  slug?: Maybe<Scalars["String"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ContentDocContextAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type ContentMedia = TreatmentPlanNode & {
  __typename?: "ContentMedia";
  alternativeNames: Array<AlternativeNameEntry>;
  context?: Maybe<ContentMediaContext>;
  id: Scalars["UUID"];
  language: Scalars["LanguageTag"];
  mimeType: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  title: Scalars["String"];
  url?: Maybe<Scalars["URL"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ContentMediaAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum ContentMediaContext {
  CALENDAR_EVENT = "CALENDAR_EVENT",
  CALENDAR_EVENT_ICON = "CALENDAR_EVENT_ICON",
  CONTENT_DOC_IMAGE = "CONTENT_DOC_IMAGE",
  FONT = "FONT",
  LOGO_DARK = "LOGO_DARK",
  LOGO_LIGHT = "LOGO_LIGHT",
  MESSAGE_ATTACHMENT = "MESSAGE_ATTACHMENT",
  MODULE_ICON_DARK = "MODULE_ICON_DARK",
  MODULE_ICON_LIGHT = "MODULE_ICON_LIGHT",
  TREATMENT_PLAN_LIST_DIAGRAM = "TREATMENT_PLAN_LIST_DIAGRAM",
  TREATMENT_PLAN_OVERVIEW = "TREATMENT_PLAN_OVERVIEW",
}

export enum CountUntil {
  MAX = "MAX",
  MIN = "MIN",
}

export type Course = {
  __typename?: "Course";
  activeTreatmentPlanOccurrences: Array<TreatmentPlanOccurrence>;
  calendar: Calendar;
  calendarPDF: CalendarPdf;
  createdAt: Scalars["DateTime"];
  diagnoses: Array<Diagnosis>;
  id: Scalars["UUID"];
  status: TreatmentStatus;
  treatmentPlanOccurrences: Array<TreatmentPlanOccurrence>;
};

export type CourseCalendarArgs = {
  format: CalendarSerializationFormat;
  timezone: Scalars["TimeZone"];
};

export type CourseCalendarPdfArgs = {
  correlationId: Scalars["UUID"];
  pdfRequest?: InputMaybe<CalendarPdfRequest>;
};

export type CourseProviderLocation = {
  __typename?: "CourseProviderLocation";
  courseProviderType: CourseProviderType;
  providerLocation: ProviderLocation;
};

export enum CourseProviderType {
  CONSULTING = "CONSULTING",
  ENTERING = "ENTERING",
  OTHER = "OTHER",
  PRIMARY = "PRIMARY",
  SPONSORING = "SPONSORING",
}

export type CreateAndEnrollOutput = {
  __typename?: "CreateAndEnrollOutput";
  diagnosis?: Maybe<DiagnosisOccurrence>;
  enrollment?: Maybe<Enrollment>;
  matching?: Maybe<MatchResults>;
  patient: PatientDemographics;
  plan?: Maybe<TreatmentPlanOccurrence>;
};

export type CreateAndEnrollPatient = {
  correlationId: Scalars["UUID"];
  demographics?: InputMaybe<PatientDemographicsInput>;
  diagnosis?: InputMaybe<DiagnosisInput>;
  enrollment?: InputMaybe<EnrollmentInput>;
  matching?: InputMaybe<MatchingInput>;
  patientId: Scalars["UUID"];
  person?: InputMaybe<PersonInput>;
  personId?: InputMaybe<Scalars["UUID"]>;
  plan?: InputMaybe<TreatmentPlanInput>;
  /** @deprecated Use treatingProviderLocationId */
  treatingProviderLocation?: InputMaybe<Scalars["ExternalId"]>;
  treatingProviderLocationId?: InputMaybe<Scalars["UUID"]>;
};

export type CreateMatchRule = {
  correlationId?: InputMaybe<Scalars["UUID"]>;
  diagnosisId: Scalars["UUID"];
  id: Scalars["UUID"];
  providerLocationId: Scalars["UUID"];
  treatmentPlanId: Scalars["UUID"];
};

export type CrmCompany = {
  __typename?: "CrmCompany";
  id?: Maybe<Scalars["String"]>;
  logs: Array<CrmForeignSyncLog>;
  organization?: Maybe<Organization>;
};

export type CrmForeignSyncLog = {
  __typename?: "CrmForeignSyncLog";
  field?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  message: Scalars["String"];
};

export type CrmPerson = {
  __typename?: "CrmPerson";
  id: Scalars["String"];
  logs: Array<CrmForeignSyncLog>;
  provider?: Maybe<Provider>;
  relatedUsers: Array<User>;
};

export type CsvFieldMapping = {
  __typename?: "CsvFieldMapping";
  error?: Maybe<Scalars["String"]>;
  incomingColumn?: Maybe<Scalars["String"]>;
  incomingValue?: Maybe<Scalars["String"]>;
  mappedField?: Maybe<Scalars["String"]>;
  mappedValue?: Maybe<Scalars["String"]>;
};

export type CsvHeaderMapping = {
  __typename?: "CsvHeaderMapping";
  columnIndex?: Maybe<Scalars["Int"]>;
  incomingColumn?: Maybe<Scalars["String"]>;
  mappedFields: Array<Scalars["String"]>;
};

export type CsvMappingPreview = {
  __typename?: "CsvMappingPreview";
  headerMappings: Array<CsvHeaderMapping>;
  mappingError?: Maybe<Scalars["String"]>;
  rowMappings: Array<CsvRowMapping>;
};

export type CsvRowMapping = {
  __typename?: "CsvRowMapping";
  importResult?: Maybe<CsvRowMappingOutcome>;
  mappedFields: Array<CsvFieldMapping>;
  rowNumber?: Maybe<Scalars["Int"]>;
};

export type CsvRowMappingOutcome = {
  __typename?: "CsvRowMappingOutcome";
  error?: Maybe<Scalars["String"]>;
  includesDiagnosis?: Maybe<Scalars["Boolean"]>;
  includesTreatmentPlan?: Maybe<Scalars["Boolean"]>;
  patientStatus?: Maybe<CsvRowPatientStatus>;
  willEnroll?: Maybe<Scalars["Boolean"]>;
};

export enum CsvRowPatientStatus {
  MATCHES_EXISTING = "MATCHES_EXISTING",
  NEW = "NEW",
  UPDATED = "UPDATED",
}

export enum DeliveryPreference {
  ALL = "ALL",
  ANY = "ANY",
}

export type Diagnosis = TreatmentPlanNode & {
  __typename?: "Diagnosis";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDocs: Array<ContentDoc>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  resourceReferences: Array<ResourceReference>;
  slug?: Maybe<Scalars["String"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type DiagnosisAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type DiagnosisInput = {
  courseId: Scalars["UUID"];
  dateOfDiagnosis?: InputMaybe<Scalars["Date"]>;
  diagnosisId: Scalars["UUID"];
  id: Scalars["UUID"];
  previousTreatments: Array<PreviousTreatment>;
};

export type DiagnosisOccurrence = {
  __typename?: "DiagnosisOccurrence";
  courseId: Scalars["UUID"];
  dateOfDiagnosis?: Maybe<Scalars["Date"]>;
  diagnosis: Diagnosis;
  id: Scalars["UUID"];
  patientId: Scalars["UUID"];
  status: DiagnosisStatus;
};

export enum DiagnosisStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type EditableParameter = TreatmentPlanNode & {
  __typename?: "EditableParameter";
  alternativeNames: Array<AlternativeNameEntry>;
  andFollowing: Scalars["Boolean"];
  contentDocs: Array<ContentDoc>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  parameter: Parameter;
  referenceInfo: NodeReferenceInfo;
  scopeSelector: ParameterScopeSelector;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type EditableParameterAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type EditableParameterValue = {
  __typename?: "EditableParameterValue";
  coordinate: Scalars["String"];
  editableParameter: EditableParameter;
  editableParameterId: Scalars["UUID"];
  value: TreatmentPlanParameterValue;
};

export type Enrollment = {
  __typename?: "Enrollment";
  courseId: Scalars["UUID"];
  enrollmentId: Scalars["UUID"];
  enrollmentType: EnrollmentType;
  patientFirstName: Scalars["String"];
  patientLastName: Scalars["String"];
};

export type EnrollmentInput = {
  enrollmentType: EnrollmentType;
  id: Scalars["UUID"];
};

export enum EnrollmentType {
  PATIENT_ONBOARDING = "PATIENT_ONBOARDING",
  PATIENT_REINFORCEMENT = "PATIENT_REINFORCEMENT",
}

export enum EnteredBy {
  PATIENT = "PATIENT",
  PROVIDER = "PROVIDER",
}

export enum FilterField {
  COHORT = "COHORT",
  CURRENT_TREATMENT_PLAN_PARAMETER = "CURRENT_TREATMENT_PLAN_PARAMETER",
  DIAGNOSIS = "DIAGNOSIS",
  PATIENT_TYPE = "PATIENT_TYPE",
  SPONSORING_ORGANIZATION = "SPONSORING_ORGANIZATION",
  SPONSORING_PROVIDER = "SPONSORING_PROVIDER",
  TREATING_ORGANIZATION = "TREATING_ORGANIZATION",
  TREATING_PROVIDER = "TREATING_PROVIDER",
  TREATMENT_PLAN = "TREATMENT_PLAN",
}

export type Flow = TreatmentPlanNode & {
  __typename?: "Flow";
  alternativeNames: Array<AlternativeNameEntry>;
  duration?: Maybe<Scalars["Duration"]>;
  eventDelta: Scalars["Duration"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  occurrenceSpecifier: OccurrenceSpecifier;
  referenceInfo: NodeReferenceInfo;
  restartAutomatically: Scalars["Boolean"];
  steps: Array<FlowStep>;
  task?: Maybe<Task>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type FlowAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type FlowOccurrence = {
  __typename?: "FlowOccurrence";
  flowId: Scalars["UUID"];
  occurrenceCoordinate: Scalars["String"];
};

export type FlowStep = TreatmentPlanNode & {
  __typename?: "FlowStep";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDoc?: Maybe<ContentDoc>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nextStep?: Maybe<FlowStep>;
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  notification?: Maybe<Notification>;
  presentation: PresentationItem;
  referenceInfo: NodeReferenceInfo;
  uxData?: Maybe<Scalars["JSON"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type FlowStepAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum Frequency {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export type IPlanItemOccurrence = {
  id: Scalars["UUID"];
};

export type IPlanItemTarget = {
  /** @deprecated Use contentDocsByContext() */
  contentDocs: Array<ContentDoc>;
  contentDocsByContext: Array<ContentDoc>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type IPlanItemTargetContentDocsArgs = {
  contexts?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["LanguageTag"]>;
};

export type IPlanItemTargetContentDocsByContextArgs = {
  docContexts?: InputMaybe<Array<Scalars["UUID"]>>;
  language?: InputMaybe<Scalars["LanguageTag"]>;
};

export type Language = {
  language: Scalars["LanguageTag"];
};

export type Layer = TreatmentPlanNode & {
  __typename?: "Layer";
  alternativeNames: Array<AlternativeNameEntry>;
  description?: Maybe<Scalars["String"]>;
  enabledParameter?: Maybe<Parameter>;
  id: Scalars["UUID"];
  layerGroups: Array<LayerGroup>;
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  parameterMappings: Array<ParameterMapping>;
  parameters: Array<Parameter>;
  phases: Array<Phase>;
  planItems: Array<PlanItem>;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type LayerAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type LayerGroup = TreatmentPlanNode & {
  __typename?: "LayerGroup";
  alternativeNames: Array<AlternativeNameEntry>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  layers: Array<Layer>;
  multiple: Scalars["Boolean"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  required: Scalars["Boolean"];
  visible?: Maybe<Scalars["Boolean"]>;
};

export type LayerGroupAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum MatchKind {
  EXTRACT_VALUE = "EXTRACT_VALUE",
  STANDARD = "STANDARD",
}

export type MatchOccurrence = {
  __typename?: "MatchOccurrence";
  courseId: Scalars["UUID"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  score: Scalars["Float"];
  treatmentPlanId: Scalars["UUID"];
};

export type MatchResults = {
  __typename?: "MatchResults";
  courseId: Scalars["UUID"];
  matches: Array<MatchOccurrence>;
};

export type MatchRule = {
  __typename?: "MatchRule";
  diagnosis: Diagnosis;
  id: Scalars["UUID"];
  providerLocation: ProviderLocation;
  treatmentPlan: TreatmentPlan;
};

export type MatchingInput = {
  id: Scalars["UUID"];
};

export type Measure = IPlanItemTarget &
  TreatmentPlanNode & {
    __typename?: "Measure";
    abbreviation?: Maybe<Scalars["String"]>;
    alternativeNames: Array<AlternativeNameEntry>;
    choices?: Maybe<Array<MeasureChoice>>;
    /** @deprecated Use contentDocsByContext() */
    contentDocs: Array<ContentDoc>;
    contentDocsByContext: Array<ContentDoc>;
    description?: Maybe<Scalars["String"]>;
    id: Scalars["UUID"];
    measureType?: Maybe<MeasureType>;
    name: Scalars["String"];
    nodeHash: Scalars["Hash"];
    nodeProvenance: NodeProvenance;
    nodeType: NodeType;
    parentMeasureId?: Maybe<Scalars["UUID"]>;
    prototype: Scalars["Boolean"];
    referenceInfo: NodeReferenceInfo;
    units?: Maybe<Scalars["String"]>;
    validation?: Maybe<MeasureValidation>;
    visible?: Maybe<Scalars["Boolean"]>;
  };

export type MeasureAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type MeasureContentDocsArgs = {
  contexts?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["LanguageTag"]>;
};

export type MeasureContentDocsByContextArgs = {
  docContexts?: InputMaybe<Array<Scalars["UUID"]>>;
  language?: InputMaybe<Scalars["LanguageTag"]>;
};

export type MeasureChoice = {
  __typename?: "MeasureChoice";
  id: Scalars["UUID"];
  value: Scalars["String"];
};

export type MeasureOccurrence = IPlanItemOccurrence & {
  __typename?: "MeasureOccurrence";
  createdAt: Scalars["OffsetDateTime"];
  enteredBy: EnteredBy;
  id: Scalars["UUID"];
  measure?: Maybe<Measure>;
  measureOccurrenceGroupId?: Maybe<Scalars["UUID"]>;
  measures: Array<Measure>;
  values: Array<Scalars["String"]>;
};

export enum MeasureType {
  ENUMERATED = "ENUMERATED",
  NUMERIC = "NUMERIC",
  QUESTION = "QUESTION",
  TEMPORAL = "TEMPORAL",
  TEXT = "TEXT",
}

export type MeasureValidation = {
  __typename?: "MeasureValidation";
  maxValue?: Maybe<Scalars["Float"]>;
  minValue?: Maybe<Scalars["Float"]>;
};

export type MediaUpload = {
  __typename?: "MediaUpload";
  contentMedia: ContentMedia;
  expiresAt: Scalars["DateTime"];
  uploadUrl: Scalars["URL"];
};

export type MediaUploadRequest = {
  context: ContentMediaContext;
  id: Scalars["UUID"];
  language: Scalars["LanguageTag"];
  mimeType: Scalars["String"];
  name: Scalars["String"];
};

export enum Modality {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export type Module = TreatmentPlanNode & {
  __typename?: "Module";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDoc?: Maybe<ContentDoc>;
  contentMedia: Array<ContentMedia>;
  description: Scalars["String"];
  id: Scalars["UUID"];
  jumbo: Scalars["Boolean"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  ordering: Scalars["Int"];
  referenceInfo: NodeReferenceInfo;
  shortName: Scalars["String"];
  slug: Scalars["String"];
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ModuleAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type Mutation = {
  __typename?: "Mutation";
  acknowledgeOverdueSurvey: PatientDemographics;
  addPatientIdentifier: ProviderLocation;
  addPatientsToStaticCohort: StaticCohort;
  createAndEnrollPatient: CreateAndEnrollOutput;
  createMatchRule: MatchRule;
  createStaticPatientCohort: StaticCohort;
  deletePatientDemographics: Scalars["UUID"];
  deleteTreatmentPlanParameterValue: Course;
  disableMatchRule: Scalars["UUID"];
  editEventParameters: Course;
  editNodes: Array<TreatmentPlanNode>;
  importPatientCsv: Array<CreateAndEnrollOutput>;
  ping?: Maybe<Scalars["String"]>;
  previewCsvImport?: Maybe<CsvMappingPreview>;
  removePatientIdentifier: ProviderLocation;
  removePatientsFromStaticCohort: StaticCohort;
  removeTreatmentPlanOccurrence: Course;
  resendInvite: User;
  resendNotificationForOccurrence: PatientDemographics;
  retriggerEnrollmentSms: PatientDemographics;
  runScenario: ScenarioRunOutput;
  sendTemplatizedMessage: Array<PatientDemographics>;
  setPatientLanguages: ProviderLocation;
  setProviderLanguage: ProviderLocation;
  updateParameterValues: TreatmentPlanOccurrence;
  updatePatientDemographics: PatientDemographics;
  updatePerson?: Maybe<Person>;
  uploadMedia: MediaUpload;
  uploadPreview: NodePreviewUpload;
};

export type MutationAcknowledgeOverdueSurveyArgs = {
  acknowledge: Scalars["Boolean"];
  correlationId: Scalars["UUID"];
  courseId: Scalars["UUID"];
  id: Scalars["UUID"];
  patientId: Scalars["UUID"];
};

export type MutationAddPatientIdentifierArgs = {
  correlationId: Scalars["UUID"];
  patientIdentifierId: Scalars["UUID"];
  providerLocationId: Scalars["UUID"];
};

export type MutationAddPatientsToStaticCohortArgs = {
  correlationId: Scalars["UUID"];
  patientIds: Array<Scalars["UUID"]>;
  staticCohortId: Scalars["UUID"];
};

export type MutationCreateAndEnrollPatientArgs = {
  request: CreateAndEnrollPatient;
};

export type MutationCreateMatchRuleArgs = {
  matchRule: CreateMatchRule;
};

export type MutationCreateStaticPatientCohortArgs = {
  correlationId: Scalars["UUID"];
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
};

export type MutationDeletePatientDemographicsArgs = {
  correlationId: Scalars["UUID"];
  patientId: Scalars["UUID"];
};

export type MutationDeleteTreatmentPlanParameterValueArgs = {
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
};

export type MutationDisableMatchRuleArgs = {
  correlationId?: InputMaybe<Scalars["UUID"]>;
  matchRuleId: Scalars["UUID"];
};

export type MutationEditEventParametersArgs = {
  correlationId: Scalars["UUID"];
  courseId: Scalars["UUID"];
  parameterValues: Array<CalendarEventParameterValueInput>;
};

export type MutationEditNodesArgs = {
  correlationId?: InputMaybe<Scalars["UUID"]>;
  deltas: Array<NodeEditDelta>;
};

export type MutationImportPatientCsvArgs = {
  correlationId: Scalars["UUID"];
  importRows?: InputMaybe<Array<Scalars["Int"]>>;
  mapping?: InputMaybe<Scalars["String"]>;
  upload: Scalars["Upload"];
};

export type MutationPreviewCsvImportArgs = {
  correlationId: Scalars["UUID"];
  mapping?: InputMaybe<Scalars["String"]>;
  upload: Scalars["Upload"];
};

export type MutationRemovePatientIdentifierArgs = {
  correlationId: Scalars["UUID"];
  patientIdentifierId: Scalars["UUID"];
  providerLocationId: Scalars["UUID"];
};

export type MutationRemovePatientsFromStaticCohortArgs = {
  correlationId: Scalars["UUID"];
  patientIds: Array<Scalars["UUID"]>;
  staticCohortId: Scalars["UUID"];
};

export type MutationRemoveTreatmentPlanOccurrenceArgs = {
  correlationId: Scalars["UUID"];
  treatmentPlanOccurrenceId: Scalars["UUID"];
};

export type MutationResendInviteArgs = {
  userId: Scalars["UUID"];
};

export type MutationResendNotificationForOccurrenceArgs = {
  correlationId: Scalars["UUID"];
  courseId: Scalars["UUID"];
  id: Scalars["UUID"];
  patientId: Scalars["UUID"];
  type: NotificationResendRelation;
};

export type MutationRetriggerEnrollmentSmsArgs = {
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
};

export type MutationRunScenarioArgs = {
  scenario: ScenarioRunInput;
};

export type MutationSendTemplatizedMessageArgs = {
  correlationId: Scalars["UUID"];
  deliverTo?: InputMaybe<DeliveryPreference>;
  modalities?: InputMaybe<Array<Modality>>;
  notificationTemplateId: Scalars["UUID"];
  patientIds: Array<Scalars["UUID"]>;
};

export type MutationSetPatientLanguagesArgs = {
  correlationId: Scalars["UUID"];
  languages: Array<Scalars["LanguageTag"]>;
  providerLocation: Scalars["UUID"];
};

export type MutationSetProviderLanguageArgs = {
  correlationId: Scalars["UUID"];
  language: Scalars["LanguageTag"];
  providerLocation: Scalars["UUID"];
};

export type MutationUpdateParameterValuesArgs = {
  correlationId: Scalars["UUID"];
  parameterValues: Array<TreatmentPlanParameterValueInput>;
  treatmentPlanOccurrenceId: Scalars["UUID"];
};

export type MutationUpdatePatientDemographicsArgs = {
  correlationId: Scalars["UUID"];
  demographics: PatientDemographicsInput;
  id: Scalars["UUID"];
  treatingProviderLocationId?: InputMaybe<Scalars["UUID"]>;
};

export type MutationUpdatePersonArgs = {
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
  person: PersonInput;
};

export type MutationUploadMediaArgs = {
  correlationId: Scalars["UUID"];
  request: MediaUploadRequest;
};

export type MutationUploadPreviewArgs = {
  correlationId: Scalars["UUID"];
  nodeId: Scalars["UUID"];
};

export type NodeEditDelta = {
  cloneFrom?: InputMaybe<Scalars["UUID"]>;
  fieldDeltas?: InputMaybe<Array<NodeFieldDelta>>;
  id: Scalars["UUID"];
  nodeType: NodeType;
  referencedByHash?: InputMaybe<Scalars["Hash"]>;
  visible?: InputMaybe<Scalars["Boolean"]>;
};

export type NodeFieldDelta = {
  fieldName?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type NodePreviewUpload = {
  __typename?: "NodePreviewUpload";
  expiresAt: Scalars["DateTime"];
  uploadUrl: Scalars["URL"];
};

export type NodeProvenance = {
  __typename?: "NodeProvenance";
  copiedFrom?: Maybe<Scalars["UUID"]>;
  copiedFromName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  updatedBy: User;
};

export type NodeReference = {
  __typename?: "NodeReference";
  from: TreatmentPlanNode;
  id: Scalars["String"];
  to: TreatmentPlanNode;
};

export type NodeReferenceContainer = {
  __typename?: "NodeReferenceContainer";
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeType: NodeType;
};

export type NodeReferenceInfo = {
  __typename?: "NodeReferenceInfo";
  containers: Array<NodeReferenceContainer>;
  id: Scalars["UUID"];
  referencedBy: Array<Maybe<NodeReference>>;
  referencedByHash: Scalars["Hash"];
  references: Array<Maybe<NodeReference>>;
  referencesHash: Scalars["Hash"];
};

export enum NodeType {
  Brand = "Brand",
  CalendarEventTemplate = "CalendarEventTemplate",
  Confirmation = "Confirmation",
  ContentDoc = "ContentDoc",
  ContentDocContext = "ContentDocContext",
  ContentMedia = "ContentMedia",
  Diagnosis = "Diagnosis",
  EditableParameter = "EditableParameter",
  Flow = "Flow",
  FlowStep = "FlowStep",
  Layer = "Layer",
  LayerGroup = "LayerGroup",
  Measure = "Measure",
  Module = "Module",
  Notification = "Notification",
  NotificationContent = "NotificationContent",
  NotificationPreference = "NotificationPreference",
  NotificationTemplate = "NotificationTemplate",
  Parameter = "Parameter",
  ParameterMapping = "ParameterMapping",
  Phase = "Phase",
  PlanItem = "PlanItem",
  Question = "Question",
  Resource = "Resource",
  ResourceCategory = "ResourceCategory",
  ResourceReference = "ResourceReference",
  Survey = "Survey",
  Task = "Task",
  Treatment = "Treatment",
  TreatmentList = "TreatmentList",
  TreatmentPlan = "TreatmentPlan",
}

export type Notification = PresentationItem &
  TreatmentPlanNode & {
    __typename?: "Notification";
    alternativeNames: Array<AlternativeNameEntry>;
    confirmation?: Maybe<Confirmation>;
    contentDocs: Array<ContentDoc>;
    contentMedia: Array<ContentMedia>;
    enabledParameter?: Maybe<Parameter>;
    eventDelta: Scalars["Duration"];
    groupNotificationTemplate?: Maybe<NotificationTemplate>;
    id: Scalars["UUID"];
    name?: Maybe<Scalars["String"]>;
    nodeHash: Scalars["Hash"];
    nodeProvenance: NodeProvenance;
    nodeType: NodeType;
    notificationPreference?: Maybe<NotificationPreference>;
    notificationTemplate: NotificationTemplate;
    occurrenceSpecifier: OccurrenceSpecifier;
    previewImageUrl?: Maybe<Scalars["URL"]>;
    question?: Maybe<Question>;
    referenceInfo: NodeReferenceInfo;
    snoozeableDuration?: Maybe<Scalars["Duration"]>;
    survey?: Maybe<Survey>;
    task?: Maybe<Task>;
    visible?: Maybe<Scalars["Boolean"]>;
  };

export type NotificationAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type NotificationContent = TreatmentPlanNode & {
  __typename?: "NotificationContent";
  alternativeNames: Array<AlternativeNameEntry>;
  content: Scalars["String"];
  contentKind: NotificationContentKind;
  id: Scalars["UUID"];
  language: Scalars["LanguageTag"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  notificationTemplate: NotificationTemplate;
  previewImageUrl?: Maybe<Scalars["URL"]>;
  referenceInfo: NodeReferenceInfo;
  referencesMedia: Array<ContentMedia>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type NotificationContentAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum NotificationContentKind {
  EMAIL_BODY_HTML = "EMAIL_BODY_HTML",
  EMAIL_BODY_PLAIN_TEXT = "EMAIL_BODY_PLAIN_TEXT",
  EMAIL_SUBJECT = "EMAIL_SUBJECT",
  SMS_BODY = "SMS_BODY",
}

export type NotificationPreference = TreatmentPlanNode & {
  __typename?: "NotificationPreference";
  alternativeNames: Array<AlternativeNameEntry>;
  category: NotificationPreferenceCategory;
  defaultEnabled: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  editableByPatient: Scalars["Boolean"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type NotificationPreferenceAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum NotificationPreferenceCategory {
  APPOINTMENTS = "APPOINTMENTS",
  CLINICAL_TRIAL = "CLINICAL_TRIAL",
  DIET_AND_EXERCISE = "DIET_AND_EXERCISE",
  MEDICATIONS = "MEDICATIONS",
  OTHER = "OTHER",
  SUPPORT = "SUPPORT",
}

export enum NotificationResendRelation {
  NOTIFICATION = "NOTIFICATION",
  SURVEY = "SURVEY",
  TASK = "TASK",
}

export type NotificationResponse = {
  __typename?: "NotificationResponse";
  alternativeNames: Array<AlternativeNameEntry>;
  id: Scalars["UUID"];
  language: Scalars["LanguageTag"];
  matchKind: MatchKind;
  matchRegex: Scalars["String"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type NotificationResponseAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type NotificationTemplate = TreatmentPlanNode & {
  __typename?: "NotificationTemplate";
  alternativeNames: Array<AlternativeNameEntry>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  notificationContents: Array<NotificationContent>;
  parsingTable?: Maybe<ParsingTable>;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type NotificationTemplateAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum OccurrenceSpecifier {
  EACH = "EACH",
  FIRST = "FIRST",
  LAST = "LAST",
}

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type Organization = {
  __typename?: "Organization";
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export enum PdfOrientation {
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
}

export enum PdfSize {
  A4 = "A4",
  US_LETTER = "US_LETTER",
}

export type Parameter = TreatmentPlanNode & {
  __typename?: "Parameter";
  alternativeNames: Array<AlternativeNameEntry>;
  defaultValue?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  editableBy: ParameterEditableBy;
  id: Scalars["UUID"];
  label: Scalars["String"];
  multiselect: Scalars["Boolean"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  required: Scalars["Boolean"];
  type: ParameterType;
  visible?: Maybe<Scalars["Boolean"]>;
  visibleFor: ParameterVisibleFor;
};

export type ParameterAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum ParameterEditableBy {
  NO_ONE = "NO_ONE",
  PATIENT_AND_PROVIDER = "PATIENT_AND_PROVIDER",
  PATIENT_ONLY = "PATIENT_ONLY",
  PROVIDER_ONLY = "PROVIDER_ONLY",
}

export type ParameterMapping = TreatmentPlanNode & {
  __typename?: "ParameterMapping";
  alternativeNames: Array<AlternativeNameEntry>;
  fromParameter: Parameter;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  offsetDuration?: Maybe<Scalars["Duration"]>;
  offsetPeriod?: Maybe<Scalars["Period"]>;
  referenceInfo: NodeReferenceInfo;
  toParameter: Parameter;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ParameterMappingAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum ParameterScopeSelector {
  CALENDAR_EVENT = "CALENDAR_EVENT",
  CALENDAR_EVENT_OCCURRENCE = "CALENDAR_EVENT_OCCURRENCE",
  CLOSEST_PHASE = "CLOSEST_PHASE",
  GLOBAL = "GLOBAL",
}

export enum ParameterType {
  BOOLEAN = "BOOLEAN",
  COUNT = "COUNT",
  DAY_OF_WEEK = "DAY_OF_WEEK",
  LOCAL_DATE = "LOCAL_DATE",
  LOCAL_DATE_TIME = "LOCAL_DATE_TIME",
  LOCAL_TIME = "LOCAL_TIME",
  TEXT = "TEXT",
}

export enum ParameterVisibleFor {
  NO_ONE = "NO_ONE",
  PATIENT_AND_PROVIDER = "PATIENT_AND_PROVIDER",
  PATIENT_ONLY = "PATIENT_ONLY",
  PROVIDER_ONLY = "PROVIDER_ONLY",
}

export type ParsingTable = {
  __typename?: "ParsingTable";
  alternativeNames: Array<AlternativeNameEntry>;
  entries: Array<ParsingTableEntry>;
  id: Scalars["UUID"];
  measure: Measure;
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ParsingTableAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type ParsingTableEntry = {
  __typename?: "ParsingTableEntry";
  id: Scalars["UUID"];
  notificationResponse: NotificationResponse;
  value?: Maybe<Scalars["String"]>;
};

export type PathElement = {
  __typename?: "PathElement";
  endIndex?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  startIndex?: Maybe<Scalars["Int"]>;
};

export type PathElementInput = {
  endIndex?: InputMaybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  startIndex?: InputMaybe<Scalars["Int"]>;
};

export type Patient = {
  __typename?: "Patient";
  cohorts: Array<PatientCohort>;
  courses: Array<Course>;
  demographics?: Maybe<PatientDemographics>;
  demographicsDeleted?: Maybe<Scalars["DateTime"]>;
  enrollments?: Maybe<Array<PatientEnrollment>>;
  id?: Maybe<Scalars["UUID"]>;
  lastUpdated: Scalars["DateTime"];
  moduleVisits?: Maybe<PatientModuleVisits>;
  providers?: Maybe<PatientProviders>;
};

export type PatientAuditDelta = {
  __typename?: "PatientAuditDelta";
  field: Scalars["String"];
  newValue?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
};

export type PatientCohort = {
  __typename?: "PatientCohort";
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type PatientConnection = {
  __typename?: "PatientConnection";
  edges?: Maybe<Array<PatientEdge>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientDemographics = {
  __typename?: "PatientDemographics";
  administrativeSex?: Maybe<AdministrativeSex>;
  dateOfBirth: Scalars["Date"];
  firstName: Scalars["String"];
  id: Scalars["UUID"];
  language?: Maybe<Scalars["LanguageTag"]>;
  lastName: Scalars["String"];
  patientIdentifiers: Array<PatientIdentifierValue>;
  patientType: PatientType;
  person?: Maybe<Person>;
  phoneNumber?: Maybe<Scalars["String"]>;
  race?: Maybe<Race>;
  sexAssignedAtBirth?: Maybe<SexAssignedAtBirth>;
  timeZone: Scalars["TimeZone"];
};

export type PatientDemographicsInput = {
  administrativeSex?: InputMaybe<AdministrativeSex>;
  dateOfBirth: Scalars["Date"];
  firstName: Scalars["String"];
  language: Scalars["LanguageTag"];
  lastName: Scalars["String"];
  patientIdentifiers?: InputMaybe<Array<PatientIdentifierInput>>;
  patientType: PatientType;
  phoneNumber: Scalars["String"];
  race?: InputMaybe<Race>;
  sexAssignedAtBirth?: InputMaybe<SexAssignedAtBirth>;
  timeZone?: InputMaybe<Scalars["TimeZone"]>;
};

export type PatientEdge = {
  __typename?: "PatientEdge";
  highlights?: Maybe<Array<PatientHighlight>>;
  node: Patient;
  score?: Maybe<Scalars["Float"]>;
};

export type PatientEnrollment = {
  __typename?: "PatientEnrollment";
  createdAt: Scalars["DateTime"];
  externalId: Scalars["String"];
  id: Scalars["UUID"];
  type: EnrollmentType;
  url: Scalars["String"];
};

export type PatientFilterSuggestion = {
  __typename?: "PatientFilterSuggestion";
  debugQualityInfo?: Maybe<Scalars["String"]>;
  field: FilterField;
  operator: SearchOperator;
  score: Scalars["Float"];
  subject?: Maybe<Scalars["UUID"]>;
  subjectName: Scalars["String"];
  subjectType: AnythingType;
  text: Scalars["String"];
  value: Scalars["String"];
  valueType: ValueType;
};

export type PatientHighlight = {
  __typename?: "PatientHighlight";
  details: Scalars["String"];
  field: QueryField;
  html: Scalars["String"];
};

export type PatientIdentifier = {
  __typename?: "PatientIdentifier";
  contentMedia: Array<ContentMedia>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  required: Scalars["Boolean"];
  uniquePerIdentifierContext: Scalars["Boolean"];
};

export type PatientIdentifierInput = {
  patientIdentifierId: Scalars["UUID"];
  value: Scalars["String"];
};

export type PatientIdentifierValue = {
  __typename?: "PatientIdentifierValue";
  patientIdentifier: PatientIdentifier;
  value?: Maybe<Scalars["String"]>;
};

export type PatientModuleVisit = {
  __typename?: "PatientModuleVisit";
  course: Course;
  id: Scalars["UUID"];
  module: Module;
  timestamp: Scalars["DateTime"];
};

export type PatientModuleVisits = {
  __typename?: "PatientModuleVisits";
  count: Scalars["Int"];
  firstVisit: Scalars["DateTime"];
  latestVisit: Scalars["DateTime"];
  visits: Array<PatientModuleVisit>;
};

export type PatientProviders = {
  __typename?: "PatientProviders";
  courseProviderLocations: Array<CourseProviderLocation>;
};

export type PatientSearchFilterInput = {
  field: FilterField;
  operator: SearchOperator;
  subject?: InputMaybe<Scalars["UUID"]>;
  subjectType: AnythingType;
  value?: InputMaybe<Scalars["String"]>;
};

export type PatientSearchSort = {
  field: PatientSortField;
  order?: InputMaybe<OrderDirection>;
};

export enum PatientSortField {
  DEMOGRAPHICS_DATEOFBIRTH = "DEMOGRAPHICS_DATEOFBIRTH",
  DEMOGRAPHICS_FIRSTNAME = "DEMOGRAPHICS_FIRSTNAME",
  DEMOGRAPHICS_LASTNAME = "DEMOGRAPHICS_LASTNAME",
  DEMOGRAPHICS_PATIENTIDENTIFIER = "DEMOGRAPHICS_PATIENTIDENTIFIER",
  DEMOGRAPHICS_PATIENTTYPE = "DEMOGRAPHICS_PATIENTTYPE",
  LASTUPDATED = "LASTUPDATED",
  MODULEVISITS_FIRSTVISIT = "MODULEVISITS_FIRSTVISIT",
  MODULEVISITS_LATESTVISIT = "MODULEVISITS_LATESTVISIT",
  TREATMENTSTARTDATE = "TREATMENTSTARTDATE",
}

export type PatientSuggestResult = {
  __typename?: "PatientSuggestResult";
  filters?: Maybe<Array<PatientFilterSuggestion>>;
};

export enum PatientType {
  AUTOMATED_TESTING = "AUTOMATED_TESTING",
  CAREGIVER = "CAREGIVER",
  CAREGIVER_DEMONSTRATION = "CAREGIVER_DEMONSTRATION",
  EXPERIMENT_PARTICIPANT = "EXPERIMENT_PARTICIPANT",
  MANUAL_TESTING = "MANUAL_TESTING",
  PARTNER_DEMONSTRATION = "PARTNER_DEMONSTRATION",
  PATIENT = "PATIENT",
  PATIENT_DEMONSTRATION = "PATIENT_DEMONSTRATION",
  PROVIDER_DEMONSTRATION = "PROVIDER_DEMONSTRATION",
}

export type Person = {
  __typename?: "Person";
  emailAddresses?: Maybe<Array<Scalars["String"]>>;
  firstName: Scalars["String"];
  id: Scalars["UUID"];
  language?: Maybe<Scalars["LanguageTag"]>;
  lastName: Scalars["String"];
  phoneNumber: Scalars["String"];
  postalAddresses?: Maybe<Array<Address>>;
  timeZone: Scalars["TimeZone"];
};

export type PersonInput = {
  emailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  firstName: Scalars["String"];
  language: Scalars["LanguageTag"];
  lastName: Scalars["String"];
  phoneNumber: Scalars["String"];
  postalAddresses?: InputMaybe<Array<AddressInput>>;
  timeZone: Scalars["TimeZone"];
};

export type Phase = TreatmentPlanNode & {
  __typename?: "Phase";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDocs: Array<ContentDoc>;
  defaultDuration: Scalars["Period"];
  displayIndexParameter?: Maybe<Parameter>;
  expandToContainAllEvents?: Maybe<Scalars["Boolean"]>;
  id: Scalars["UUID"];
  layers: Array<Layer>;
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  parameters?: Maybe<Array<Parameter>>;
  referenceInfo: NodeReferenceInfo;
  repeatCountParameter?: Maybe<Parameter>;
  startDateParameter: Parameter;
  totalCountParameter?: Maybe<Parameter>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type PhaseAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type PlanItem = TreatmentPlanNode & {
  __typename?: "PlanItem";
  alternativeNames: Array<AlternativeNameEntry>;
  calendarEventTemplates: Array<CalendarEventTemplate>;
  description?: Maybe<Scalars["String"]>;
  flows: Array<Flow>;
  id: Scalars["UUID"];
  measure?: Maybe<Measure>;
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  target: IPlanItemTarget;
  treatment?: Maybe<Treatment>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type PlanItemAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type PlanItemOccurrence = {
  __typename?: "PlanItemOccurrence";
  flowOccurrences: Array<FlowOccurrence>;
  occurrenceCoordinate: Scalars["String"];
  planItemId: Scalars["UUID"];
};

export enum PlanView {
  MONTH = "MONTH",
  TIMELINE = "TIMELINE",
  WEEK = "WEEK",
}

export type Practice = {
  __typename?: "Practice";
  id: Scalars["UUID"];
  name: Scalars["String"];
  organization?: Maybe<Organization>;
};

export type PresentationItem = {
  id: Scalars["UUID"];
  name?: Maybe<Scalars["String"]>;
  previewImageUrl?: Maybe<Scalars["URL"]>;
};

export type PreviewTemplatizedMessageResult = {
  __typename?: "PreviewTemplatizedMessageResult";
  content: Scalars["String"];
};

export type PreviousTreatment = {
  details?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  patientId: Scalars["UUID"];
  treatmentDate?: InputMaybe<Scalars["Date"]>;
  treatmentId: Scalars["UUID"];
};

export enum ProjectionDirection {
  NATURAL = "NATURAL",
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
}

export type Provider = {
  __typename?: "Provider";
  canEnroll?: Maybe<Scalars["Boolean"]>;
  canTreat?: Maybe<Scalars["Boolean"]>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  /** @deprecated Use firstName / lastName */
  id: Scalars["UUID"];
  lastName: Scalars["String"];
  locations: Array<ProviderLocation>;
  name: Array<Scalars["String"]>;
  salutation: Scalars["String"];
};

export type ProviderAck = {
  __typename?: "ProviderAck";
  date?: Maybe<Scalars["DateTime"]>;
  provider?: Maybe<Provider>;
};

export type ProviderLocation = {
  __typename?: "ProviderLocation";
  active: Scalars["Boolean"];
  externalId: Scalars["String"];
  /** @deprecated Use id */
  id: Scalars["UUID"];
  language?: Maybe<AvailableLanguage>;
  patientIdentifiers: Array<PatientIdentifier>;
  patientLanguages?: Maybe<Array<AvailableLanguage>>;
  practice: Practice;
  provider: Provider;
};

export type Query = {
  __typename?: "Query";
  activeTreatmentPlanOccurrences: Array<TreatmentPlanOccurrence>;
  allAvailableLanguages: Array<AvailableLanguage>;
  anythings?: Maybe<AnythingConnection>;
  auditLog: Array<AuditLogRecord>;
  /** @deprecated Use availableDiagnosesById */
  availableDiagnoses: Array<Diagnosis>;
  availableDiagnosesById: Array<Diagnosis>;
  /** @deprecated Use availablePreviousTreatmentsById */
  availablePreviousTreatments: Array<Treatment>;
  availablePreviousTreatmentsById: Array<Treatment>;
  availableTreatmentPlans: Array<TreatmentPlan>;
  content: ContentDoc;
  contentDocs: Array<ContentDoc>;
  crmCompany?: Maybe<CrmCompany>;
  crmPerson?: Maybe<CrmPerson>;
  /** @deprecated Use defaultDiagnosisById */
  defaultDiagnosis?: Maybe<Diagnosis>;
  defaultDiagnosisById?: Maybe<Diagnosis>;
  diagnoses: Array<Diagnosis>;
  diagnosis: Diagnosis;
  diagnosisBySlug: Diagnosis;
  getCsvMappings: Array<Scalars["String"]>;
  getNodes: Array<TreatmentPlanNode>;
  hasMatchRule: Scalars["Boolean"];
  listAvailableSurveys: Array<SurveyMonkeySurvey>;
  matchRules: Array<MatchRule>;
  measureOccurrences: Array<MeasureOccurrence>;
  notificationTemplates: Array<NotificationTemplate>;
  organization: Organization;
  patient: PatientDemographics;
  patientIdentifiers: Array<PatientIdentifier>;
  patientLanguages: Array<AvailableLanguage>;
  patients: PatientConnection;
  ping?: Maybe<Scalars["String"]>;
  previewTemplatizedMessage?: Maybe<PreviewTemplatizedMessageResult>;
  provider: Provider;
  /** @deprecated Use providerLocationById */
  providerLocation?: Maybe<ProviderLocation>;
  providerLocationById?: Maybe<ProviderLocation>;
  providerMatches?: Maybe<MatchResults>;
  renderCalendar: Calendar;
  renderCalendarPDF: CalendarPdf;
  scenarios: Array<Scenario>;
  staticCohorts: Array<StaticCohort>;
  suggest?: Maybe<PatientSuggestResult>;
  treatingProviderLocations: Array<ProviderLocation>;
  treatmentPlan: TreatmentPlan;
  treatmentPlanBySlug: TreatmentPlan;
  treatmentPlans: Array<TreatmentPlan>;
  whoami?: Maybe<UserInfo>;
  whois: Array<User>;
};

export type QueryActiveTreatmentPlanOccurrencesArgs = {
  courseId: Scalars["UUID"];
};

export type QueryAnythingsArgs = {
  alternativeNames?: InputMaybe<Array<AlternativeName>>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  prefixQuery?: InputMaybe<Scalars["Boolean"]>;
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Array<AnythingSearchSort>>;
  types?: InputMaybe<Array<AnythingType>>;
  visible?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryAuditLogArgs = {
  filter: AuditLogFilter;
};

export type QueryAvailableDiagnosesArgs = {
  orderingProviderLocation?: InputMaybe<Scalars["ExternalId"]>;
};

export type QueryAvailableDiagnosesByIdArgs = {
  orderingProviderLocation?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAvailablePreviousTreatmentsArgs = {
  diagnosisId: Scalars["UUID"];
  orderingProviderLocation: Scalars["ExternalId"];
};

export type QueryAvailablePreviousTreatmentsByIdArgs = {
  diagnosisId: Scalars["UUID"];
  orderingProviderLocation: Scalars["UUID"];
};

export type QueryAvailableTreatmentPlansArgs = {
  diagnosisId: Scalars["UUID"];
};

export type QueryContentArgs = {
  contentDocId: Scalars["UUID"];
  language?: InputMaybe<Scalars["LanguageTag"]>;
};

export type QueryContentDocsArgs = {
  contentDocIds: Array<Scalars["UUID"]>;
  recursive?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCrmCompanyArgs = {
  copperId: Scalars["String"];
};

export type QueryCrmPersonArgs = {
  copperId: Scalars["String"];
};

export type QueryDefaultDiagnosisArgs = {
  orderingProviderLocation?: InputMaybe<Scalars["ExternalId"]>;
};

export type QueryDefaultDiagnosisByIdArgs = {
  orderingProviderLocation?: InputMaybe<Scalars["UUID"]>;
};

export type QueryDiagnosisArgs = {
  diagnosisId: Scalars["UUID"];
};

export type QueryDiagnosisBySlugArgs = {
  diagnosisSlug: Scalars["String"];
};

export type QueryGetNodesArgs = {
  recursive?: InputMaybe<Scalars["Boolean"]>;
  uuids: Array<Scalars["UUID"]>;
};

export type QueryHasMatchRuleArgs = {
  diagnosisId: Scalars["UUID"];
  providerLocationId: Scalars["UUID"];
  treatmentPlanId: Scalars["UUID"];
};

export type QueryMatchRulesArgs = {
  providerId: Scalars["UUID"];
};

export type QueryMeasureOccurrencesArgs = {
  courseId: Scalars["UUID"];
  from?: InputMaybe<Scalars["Date"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type QueryOrganizationArgs = {
  organizationId: Scalars["UUID"];
};

export type QueryPatientArgs = {
  id: Scalars["UUID"];
};

export type QueryPatientLanguagesArgs = {
  providerLocation: Scalars["UUID"];
};

export type QueryPatientsArgs = {
  cohort?: InputMaybe<Scalars["UUID"]>;
  filters?: InputMaybe<Array<PatientSearchFilterInput>>;
  first?: InputMaybe<Scalars["Int"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Array<PatientSearchSort>>;
};

export type QueryPreviewTemplatizedMessageArgs = {
  notificationTemplateId: Scalars["UUID"];
  patientId: Scalars["UUID"];
};

export type QueryProviderArgs = {
  providerId: Scalars["UUID"];
};

export type QueryProviderLocationArgs = {
  externalId: Scalars["ExternalId"];
};

export type QueryProviderLocationByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryProviderMatchesArgs = {
  courseId: Scalars["UUID"];
};

export type QueryRenderCalendarArgs = {
  forPatient?: Scalars["Boolean"];
  format?: CalendarSerializationFormat;
  request: CalendarInput;
  requestor?: Requestor;
};

export type QueryRenderCalendarPdfArgs = {
  correlationId: Scalars["UUID"];
  pdfRequest?: InputMaybe<CalendarPdfRequest>;
  request: CalendarInput;
};

export type QueryStaticCohortsArgs = {
  organizationId: Scalars["UUID"];
};

export type QuerySuggestArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryTreatmentPlanArgs = {
  treatmentPlanId: Scalars["UUID"];
};

export type QueryTreatmentPlanBySlugArgs = {
  treatmentPlanSlug: Scalars["String"];
};

export type QueryWhoisArgs = {
  userIds: Array<Scalars["UUID"]>;
};

export enum QueryField {
  DEMOGRAPHICS_FIRSTNAME = "DEMOGRAPHICS_FIRSTNAME",
  DEMOGRAPHICS_LASTNAME = "DEMOGRAPHICS_LASTNAME",
  DEMOGRAPHICS_PATIENTIDENTIFIER = "DEMOGRAPHICS_PATIENTIDENTIFIER",
}

export type Question = TreatmentPlanNode & {
  __typename?: "Question";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDocs: Array<ContentDoc>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  notificationTemplate?: Maybe<NotificationTemplate>;
  prototypeMeasures: Array<Measure>;
  referenceInfo: NodeReferenceInfo;
  rootMeasure: Measure;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type QuestionAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum Race {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  ASIAN = "ASIAN",
  BLACK_OR_AFRICAN_AMERICAN = "BLACK_OR_AFRICAN_AMERICAN",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER",
  OTHER_RACE = "OTHER_RACE",
  WHITE = "WHITE",
}

export enum RecurrenceRelation {
  DURING = "DURING",
  SAME_DAYS = "SAME_DAYS",
}

export enum RelativeToOccurrence {
  EACH = "EACH",
  FIRST = "FIRST",
  LAST = "LAST",
}

export enum Requestor {
  PATIENT = "PATIENT",
  PROVIDER = "PROVIDER",
  SYSTEM = "SYSTEM",
}

export enum RescheduleStrategy {
  NEXT_BUSINESS_DAY = "NEXT_BUSINESS_DAY",
  PROJECT_PAST_RECURRENCE = "PROJECT_PAST_RECURRENCE",
  SKIP = "SKIP",
}

export type Resource = TreatmentPlanNode & {
  __typename?: "Resource";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDocs: Array<ContentDoc>;
  description: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  url?: Maybe<Scalars["URL"]>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ResourceAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type ResourceCategory = TreatmentPlanNode & {
  __typename?: "ResourceCategory";
  alternativeNames: Array<AlternativeNameEntry>;
  categoryWeight: Scalars["Float"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ResourceCategoryAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type ResourceReference = TreatmentPlanNode & {
  __typename?: "ResourceReference";
  alternativeNames: Array<AlternativeNameEntry>;
  globallyVisible: Scalars["Boolean"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  resource: Resource;
  resourceCategory: ResourceCategory;
  resourceWeight: Scalars["Float"];
  visible?: Maybe<Scalars["Boolean"]>;
};

export type ResourceReferenceAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type Scenario = {
  __typename?: "Scenario";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  variables?: Maybe<Array<ScenarioVariable>>;
};

export type ScenarioRunInput = {
  correlationId?: InputMaybe<Scalars["UUID"]>;
  id: Scalars["String"];
  variableValues?: InputMaybe<Array<ScenarioVariableInput>>;
};

export type ScenarioRunOutput = {
  __typename?: "ScenarioRunOutput";
  events?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  variableValues: Array<ScenarioVariableOutput>;
};

export type ScenarioVariable = {
  __typename?: "ScenarioVariable";
  description: Scalars["String"];
  name: Scalars["String"];
  required: Scalars["Boolean"];
  type: ScenarioVariableType;
};

export type ScenarioVariableInput = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ScenarioVariableOutput = {
  __typename?: "ScenarioVariableOutput";
  name: Scalars["String"];
  value: Scalars["String"];
};

export enum ScenarioVariableType {
  DOB = "DOB",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  MRN = "MRN",
  PHONE_NUMBER = "PHONE_NUMBER",
  STRING = "STRING",
  UUID = "UUID",
}

export enum SearchOperator {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
}

export enum SexAssignedAtBirth {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export type StaticCohort = {
  __typename?: "StaticCohort";
  id: Scalars["UUID"];
  name: Scalars["String"];
  organization: Organization;
};

export type Survey = PresentationItem &
  TreatmentPlanNode & {
    __typename?: "Survey";
    alternativeNames: Array<AlternativeNameEntry>;
    collectorId?: Maybe<Scalars["String"]>;
    contentDocs: Array<ContentDoc>;
    duration?: Maybe<Scalars["Duration"]>;
    eventDelta: Scalars["Duration"];
    id: Scalars["UUID"];
    name?: Maybe<Scalars["String"]>;
    nodeHash: Scalars["Hash"];
    nodeProvenance: NodeProvenance;
    nodeType: NodeType;
    occurrenceSpecifier: OccurrenceSpecifier;
    previewImageUrl?: Maybe<Scalars["URL"]>;
    referenceInfo: NodeReferenceInfo;
    surveyId: Scalars["String"];
    visible?: Maybe<Scalars["Boolean"]>;
  };

export type SurveyAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type SurveyMonkeySurvey = {
  __typename?: "SurveyMonkeySurvey";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["URL"]>;
};

export type SurveyOccurrence = {
  __typename?: "SurveyOccurrence";
  completedAt?: Maybe<Scalars["DateTime"]>;
  eventDate: Scalars["DateTime"];
  id: Scalars["UUID"];
  providerAck?: Maybe<ProviderAck>;
  providerAckDate?: Maybe<Scalars["DateTime"]>;
  survey: Survey;
};

export type Task = TreatmentPlanNode & {
  __typename?: "Task";
  alternativeNames: Array<AlternativeNameEntry>;
  category: TaskCategory;
  description?: Maybe<Scalars["String"]>;
  eventDelta: Scalars["Duration"];
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  occurrenceSpecifier: OccurrenceSpecifier;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type TaskAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum TaskCategory {
  INTERNAL = "INTERNAL",
  MEDICATION = "MEDICATION",
  ONBOARDING = "ONBOARDING",
}

export type TaskOccurrence = {
  __typename?: "TaskOccurrence";
  allDay?: Maybe<Scalars["Boolean"]>;
  eventDate: Scalars["DateTime"];
  id: Scalars["UUID"];
  status: TaskStatus;
  statusChangedAt?: Maybe<Scalars["DateTime"]>;
  task: Task;
};

export enum TaskStatus {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
}

export type Treatment = TreatmentPlanNode & {
  __typename?: "Treatment";
  abbreviation?: Maybe<Scalars["String"]>;
  alternativeNames: Array<AlternativeNameEntry>;
  brandName?: Maybe<Scalars["String"]>;
  contentDocs: Array<ContentDoc>;
  description?: Maybe<Scalars["String"]>;
  detailsField?: Maybe<TreatmentFieldOption>;
  genericName?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  subTreatments: Array<Treatment>;
  treatmentDateField?: Maybe<TreatmentFieldOption>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type TreatmentAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export enum TreatmentFieldOption {
  NOT_PRESENT = "NOT_PRESENT",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export type TreatmentList = TreatmentPlanNode & {
  __typename?: "TreatmentList";
  alternativeNames: Array<AlternativeNameEntry>;
  id: Scalars["UUID"];
  items?: Maybe<Array<PlanItem>>;
  key: Scalars["String"];
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type TreatmentListAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type TreatmentPlan = TreatmentPlanNode & {
  __typename?: "TreatmentPlan";
  alternativeNames: Array<AlternativeNameEntry>;
  contentDocs: Array<ContentDoc>;
  contentMedia: Array<ContentMedia>;
  description?: Maybe<Scalars["String"]>;
  excludedModules: Array<Module>;
  id: Scalars["UUID"];
  includedModules: Array<Module>;
  initialTasks: Array<Task>;
  initialView?: Maybe<PlanView>;
  invitationTemplate?: Maybe<NotificationTemplate>;
  layers: Array<Layer>;
  media: Array<ContentMedia>;
  name: Scalars["String"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  notificationResponseFlow?: Maybe<Flow>;
  onboardingFlow?: Maybe<Flow>;
  patientIdentifiers: Array<PatientIdentifier>;
  patientNameOverride?: Maybe<Scalars["String"]>;
  planItems: Array<PlanItem>;
  protocolName?: Maybe<Scalars["String"]>;
  referenceInfo: NodeReferenceInfo;
  resourceReferences: Array<ResourceReference>;
  rootLayer?: Maybe<Layer>;
  slug?: Maybe<Scalars["String"]>;
  /** @deprecated Renamed contentMedia */
  startDateParameter?: Maybe<Parameter>;
  treatmentLists: Array<TreatmentList>;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type TreatmentPlanAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type TreatmentPlanInput = {
  id: Scalars["UUID"];
  matchOccurrenceIdsToAccept?: InputMaybe<Array<Scalars["UUID"]>>;
  parameterValues?: InputMaybe<Array<TreatmentPlanParameterValueInput>>;
  patientIdentifiers?: InputMaybe<Array<PatientIdentifierInput>>;
  treatmentPlanId?: InputMaybe<Scalars["UUID"]>;
  treatmentStartDate?: InputMaybe<Scalars["Date"]>;
};

export type TreatmentPlanNode = {
  alternativeNames: Array<AlternativeNameEntry>;
  id: Scalars["UUID"];
  nodeHash: Scalars["Hash"];
  nodeProvenance: NodeProvenance;
  nodeType: NodeType;
  referenceInfo: NodeReferenceInfo;
  visible?: Maybe<Scalars["Boolean"]>;
};

export type TreatmentPlanNodeAlternativeNamesArgs = {
  ofType?: InputMaybe<Array<AlternativeName>>;
};

export type TreatmentPlanOccurrence = {
  __typename?: "TreatmentPlanOccurrence";
  course: Course;
  id: Scalars["UUID"];
  parameterValues: Array<Maybe<TreatmentPlanParameterValue>>;
  patientIdentifiers: Array<PatientIdentifierValue>;
  status: TreatmentPlanOccurrenceStatus;
  surveys: Array<SurveyOccurrence>;
  tasks: Array<TaskOccurrence>;
  treatmentPlan: TreatmentPlan;
  treatmentStartDate?: Maybe<Scalars["Date"]>;
};

export enum TreatmentPlanOccurrenceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type TreatmentPlanParameterValue = {
  __typename?: "TreatmentPlanParameterValue";
  createdAt: Scalars["OffsetDateTime"];
  id: Scalars["UUID"];
  parameter: Parameter;
  parameterId: Scalars["UUID"];
  scope?: Maybe<CalendarEventScope>;
  transient: Scalars["Boolean"];
  updatedAt?: Maybe<Scalars["OffsetDateTime"]>;
  updatedByName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type TreatmentPlanParameterValueInput = {
  id: Scalars["UUID"];
  parameterId: Scalars["UUID"];
  scope?: InputMaybe<CalendarEventScopeInput>;
  value?: InputMaybe<Scalars["String"]>;
};

export enum TreatmentStatus {
  ACTIVE = "ACTIVE",
  ALL = "ALL",
  FUTURE = "FUTURE",
  PATIENT_DELETED = "PATIENT_DELETED",
  PREVIOUS = "PREVIOUS",
  SUGGESTED = "SUGGESTED",
}

export enum UntilOccurrence {
  FIRST = "FIRST",
  LAST = "LAST",
}

export type User = {
  __typename?: "User";
  authEvents: AuthEventResponse;
  id: Scalars["UUID"];
  name: Scalars["String"];
  shortName: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  type: UserType;
  username: Scalars["String"];
};

export type UserAuthEventsArgs = {
  page?: InputMaybe<Scalars["String"]>;
};

export type UserInfo = {
  __typename?: "UserInfo";
  canEnroll: Scalars["Boolean"];
  canSeePhi: Scalars["Boolean"];
  isAuthenticated?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  provider?: Maybe<Provider>;
  shouldNotSeePromoContent: Scalars["Boolean"];
  user?: Maybe<User>;
  username?: Maybe<Scalars["String"]>;
};

export enum UserType {
  ADMIN = "ADMIN",
  PROVIDER = "PROVIDER",
}

export enum ValueType {
  BOOLEAN = "BOOLEAN",
}

export enum VisibleTo {
  ALL = "ALL",
  NONE = "NONE",
  PATIENT = "PATIENT",
  PROVIDER = "PROVIDER",
}

export type NavioCalendarUiCalendarFragment = {
  __typename: "Calendar";
  calendarEventTemplates: Array<{
    __typename?: "CalendarEventTemplate";
    id: any;
    name: string;
    description?: string | null;
    category?: CalendarEventCategory | null;
    color?: any | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      id: any;
      contentJson?: any | null;
      contentHtml?: string | null;
      context: string;
      useContentJson: boolean;
      docContext: { __typename: "ContentDocContext"; id: any };
      referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        id: any;
        language: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      id: any;
      language: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
    dependentEvents: Array<{ __typename?: "CalendarEventTemplate"; id: any }>;
  }>;
  events: Array<{
    __typename?: "CalendarEvent";
    id: string;
    calendarEventTemplate?: {
      __typename?: "CalendarEventTemplate";
      id: any;
    } | null;
    occurrences: Array<{
      __typename?: "CalendarEventOccurrence";
      id: string;
      description?: string | null;
      name: string;
      timeDescriptionOverride?: string | null;
      editableParameterValues: Array<{
        __typename?: "EditableParameterValue";
        editableParameter: {
          __typename?: "EditableParameter";
          id: any;
          scopeSelector: ParameterScopeSelector;
          andFollowing: boolean;
          parameter: {
            __typename?: "Parameter";
            id: any;
            label: string;
            type: ParameterType;
            required: boolean;
            multiselect: boolean;
            description?: string | null;
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
          };
          contentDocs: Array<{
            __typename: "ContentDoc";
            id: any;
            contentJson?: any | null;
            contentHtml?: string | null;
            context: string;
            useContentJson: boolean;
            docContext: { __typename: "ContentDocContext"; id: any };
            referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              id: any;
              language: any;
              context?: ContentMediaContext | null;
              mimeType: string;
              url?: any | null;
            }>;
          }>;
        };
        value: {
          __typename?: "TreatmentPlanParameterValue";
          id: any;
          transient: boolean;
          value?: string | null;
          parameter: {
            __typename?: "Parameter";
            id: any;
            label: string;
            type: ParameterType;
            required: boolean;
            multiselect: boolean;
            description?: string | null;
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
          };
          scope?: { __typename?: "CalendarEventScope"; string: string } | null;
        };
      }>;
      scope: { __typename?: "CalendarEventScope"; string: string };
      timing: {
        __typename?: "CalendarEventTiming";
        start: any;
        end: any;
        allDay: boolean;
      };
    }>;
  }>;
  parameters: Array<{
    __typename?: "TreatmentPlanParameterValue";
    id: any;
    transient: boolean;
    value?: string | null;
    parameter: {
      __typename?: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    };
    scope?: { __typename?: "CalendarEventScope"; string: string } | null;
  }>;
};

export type NavioCalendarUiCalendarEventFragment = {
  __typename?: "CalendarEvent";
  id: string;
  calendarEventTemplate?: {
    __typename?: "CalendarEventTemplate";
    id: any;
  } | null;
  occurrences: Array<{
    __typename?: "CalendarEventOccurrence";
    id: string;
    description?: string | null;
    name: string;
    timeDescriptionOverride?: string | null;
    editableParameterValues: Array<{
      __typename?: "EditableParameterValue";
      editableParameter: {
        __typename?: "EditableParameter";
        id: any;
        scopeSelector: ParameterScopeSelector;
        andFollowing: boolean;
        parameter: {
          __typename?: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        contentDocs: Array<{
          __typename: "ContentDoc";
          id: any;
          contentJson?: any | null;
          contentHtml?: string | null;
          context: string;
          useContentJson: boolean;
          docContext: { __typename: "ContentDocContext"; id: any };
          referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            id: any;
            language: any;
            context?: ContentMediaContext | null;
            mimeType: string;
            url?: any | null;
          }>;
        }>;
      };
      value: {
        __typename?: "TreatmentPlanParameterValue";
        id: any;
        transient: boolean;
        value?: string | null;
        parameter: {
          __typename?: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        scope?: { __typename?: "CalendarEventScope"; string: string } | null;
      };
    }>;
    scope: { __typename?: "CalendarEventScope"; string: string };
    timing: {
      __typename?: "CalendarEventTiming";
      start: any;
      end: any;
      allDay: boolean;
    };
  }>;
};

export type NavioCalendarUiCalendarEventOccurrenceFragment = {
  __typename?: "CalendarEventOccurrence";
  id: string;
  description?: string | null;
  name: string;
  timeDescriptionOverride?: string | null;
  editableParameterValues: Array<{
    __typename?: "EditableParameterValue";
    editableParameter: {
      __typename?: "EditableParameter";
      id: any;
      scopeSelector: ParameterScopeSelector;
      andFollowing: boolean;
      parameter: {
        __typename?: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      contentDocs: Array<{
        __typename: "ContentDoc";
        id: any;
        contentJson?: any | null;
        contentHtml?: string | null;
        context: string;
        useContentJson: boolean;
        docContext: { __typename: "ContentDocContext"; id: any };
        referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          id: any;
          language: any;
          context?: ContentMediaContext | null;
          mimeType: string;
          url?: any | null;
        }>;
      }>;
    };
    value: {
      __typename?: "TreatmentPlanParameterValue";
      id: any;
      transient: boolean;
      value?: string | null;
      parameter: {
        __typename?: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      scope?: { __typename?: "CalendarEventScope"; string: string } | null;
    };
  }>;
  scope: { __typename?: "CalendarEventScope"; string: string };
  timing: {
    __typename?: "CalendarEventTiming";
    start: any;
    end: any;
    allDay: boolean;
  };
};

export type NavioCalendarUiCalendarEventScopeFragment = {
  __typename?: "CalendarEventScope";
  string: string;
};

export type NavioCalendarUiCalendarEventTemplateFragment = {
  __typename?: "CalendarEventTemplate";
  id: any;
  name: string;
  description?: string | null;
  category?: CalendarEventCategory | null;
  color?: any | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    id: any;
    contentJson?: any | null;
    contentHtml?: string | null;
    context: string;
    useContentJson: boolean;
    docContext: { __typename: "ContentDocContext"; id: any };
    referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      id: any;
      language: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    id: any;
    language: any;
    context?: ContentMediaContext | null;
    mimeType: string;
    url?: any | null;
  }>;
  dependentEvents: Array<{ __typename?: "CalendarEventTemplate"; id: any }>;
};

export type NavioCalendarUiContentDocFragment = {
  __typename: "ContentDoc";
  id: any;
  contentJson?: any | null;
  contentHtml?: string | null;
  context: string;
  useContentJson: boolean;
  docContext: { __typename: "ContentDocContext"; id: any };
  referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
  referencesMedia: Array<{
    __typename: "ContentMedia";
    id: any;
    language: any;
    context?: ContentMediaContext | null;
    mimeType: string;
    url?: any | null;
  }>;
};

export type NavioCalendarUiContentDocContextFragment = {
  __typename: "ContentDocContext";
  id: any;
};

export type NavioCalendarUiContentMediaFragment = {
  __typename: "ContentMedia";
  id: any;
  language: any;
  context?: ContentMediaContext | null;
  mimeType: string;
  url?: any | null;
};

export type NavioCalendarUiEditableParameterFragment = {
  __typename?: "EditableParameter";
  id: any;
  scopeSelector: ParameterScopeSelector;
  andFollowing: boolean;
  parameter: {
    __typename?: "Parameter";
    id: any;
    label: string;
    type: ParameterType;
    required: boolean;
    multiselect: boolean;
    description?: string | null;
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
  };
  contentDocs: Array<{
    __typename: "ContentDoc";
    id: any;
    contentJson?: any | null;
    contentHtml?: string | null;
    context: string;
    useContentJson: boolean;
    docContext: { __typename: "ContentDocContext"; id: any };
    referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      id: any;
      language: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
  }>;
};

export type NavioCalendarUiEditableParameterValueFragment = {
  __typename?: "EditableParameterValue";
  editableParameter: {
    __typename?: "EditableParameter";
    id: any;
    scopeSelector: ParameterScopeSelector;
    andFollowing: boolean;
    parameter: {
      __typename?: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    };
    contentDocs: Array<{
      __typename: "ContentDoc";
      id: any;
      contentJson?: any | null;
      contentHtml?: string | null;
      context: string;
      useContentJson: boolean;
      docContext: { __typename: "ContentDocContext"; id: any };
      referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        id: any;
        language: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
    }>;
  };
  value: {
    __typename?: "TreatmentPlanParameterValue";
    id: any;
    transient: boolean;
    value?: string | null;
    parameter: {
      __typename?: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    };
    scope?: { __typename?: "CalendarEventScope"; string: string } | null;
  };
};

export type NavioCalendarUiParameterFragment = {
  __typename?: "Parameter";
  id: any;
  label: string;
  type: ParameterType;
  required: boolean;
  multiselect: boolean;
  description?: string | null;
  defaultValue?: string | null;
  editableBy: ParameterEditableBy;
};

export type NavioCalendarUiTreatmentPlanParameterValueFragment = {
  __typename?: "TreatmentPlanParameterValue";
  id: any;
  transient: boolean;
  value?: string | null;
  parameter: {
    __typename?: "Parameter";
    id: any;
    label: string;
    type: ParameterType;
    required: boolean;
    multiselect: boolean;
    description?: string | null;
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
  };
  scope?: { __typename?: "CalendarEventScope"; string: string } | null;
};

export type NavioCalendarUiEditEventParametersMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  courseId: Scalars["UUID"];
  parameterValues:
    | Array<CalendarEventParameterValueInput>
    | CalendarEventParameterValueInput;
  from?: InputMaybe<Scalars["OffsetDateTime"]>;
  to?: InputMaybe<Scalars["OffsetDateTime"]>;
  calendarSerializationFormat?: InputMaybe<CalendarSerializationFormat>;
  timezone: Scalars["TimeZone"];
}>;

export type NavioCalendarUiEditEventParametersMutation = {
  __typename?: "Mutation";
  editEventParameters: {
    __typename: "Course";
    id: any;
    calendar: {
      __typename: "Calendar";
      calendarEventTemplates: Array<{
        __typename?: "CalendarEventTemplate";
        id: any;
        name: string;
        description?: string | null;
        category?: CalendarEventCategory | null;
        color?: any | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          id: any;
          contentJson?: any | null;
          contentHtml?: string | null;
          context: string;
          useContentJson: boolean;
          docContext: { __typename: "ContentDocContext"; id: any };
          referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            id: any;
            language: any;
            context?: ContentMediaContext | null;
            mimeType: string;
            url?: any | null;
          }>;
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          id: any;
          language: any;
          context?: ContentMediaContext | null;
          mimeType: string;
          url?: any | null;
        }>;
        dependentEvents: Array<{
          __typename?: "CalendarEventTemplate";
          id: any;
        }>;
      }>;
      events: Array<{
        __typename?: "CalendarEvent";
        id: string;
        calendarEventTemplate?: {
          __typename?: "CalendarEventTemplate";
          id: any;
        } | null;
        occurrences: Array<{
          __typename?: "CalendarEventOccurrence";
          id: string;
          description?: string | null;
          name: string;
          timeDescriptionOverride?: string | null;
          editableParameterValues: Array<{
            __typename?: "EditableParameterValue";
            editableParameter: {
              __typename?: "EditableParameter";
              id: any;
              scopeSelector: ParameterScopeSelector;
              andFollowing: boolean;
              parameter: {
                __typename?: "Parameter";
                id: any;
                label: string;
                type: ParameterType;
                required: boolean;
                multiselect: boolean;
                description?: string | null;
                defaultValue?: string | null;
                editableBy: ParameterEditableBy;
              };
              contentDocs: Array<{
                __typename: "ContentDoc";
                id: any;
                contentJson?: any | null;
                contentHtml?: string | null;
                context: string;
                useContentJson: boolean;
                docContext: { __typename: "ContentDocContext"; id: any };
                referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  id: any;
                  language: any;
                  context?: ContentMediaContext | null;
                  mimeType: string;
                  url?: any | null;
                }>;
              }>;
            };
            value: {
              __typename?: "TreatmentPlanParameterValue";
              id: any;
              transient: boolean;
              value?: string | null;
              parameter: {
                __typename?: "Parameter";
                id: any;
                label: string;
                type: ParameterType;
                required: boolean;
                multiselect: boolean;
                description?: string | null;
                defaultValue?: string | null;
                editableBy: ParameterEditableBy;
              };
              scope?: {
                __typename?: "CalendarEventScope";
                string: string;
              } | null;
            };
          }>;
          scope: { __typename?: "CalendarEventScope"; string: string };
          timing: {
            __typename?: "CalendarEventTiming";
            start: any;
            end: any;
            allDay: boolean;
          };
        }>;
      }>;
      parameters: Array<{
        __typename?: "TreatmentPlanParameterValue";
        id: any;
        transient: boolean;
        value?: string | null;
        parameter: {
          __typename?: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        scope?: { __typename?: "CalendarEventScope"; string: string } | null;
      }>;
    };
    activeTreatmentPlanOccurrences: Array<{
      __typename: "TreatmentPlanOccurrence";
      id: any;
      treatmentPlan: { __typename: "TreatmentPlan"; id: any };
      parameterValues: Array<{
        __typename?: "TreatmentPlanParameterValue";
        id: any;
        transient: boolean;
        value?: string | null;
        parameter: {
          __typename?: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        scope?: { __typename?: "CalendarEventScope"; string: string } | null;
      } | null>;
    }>;
  };
};

export type NavioCalendarUiContentDocQueryVariables = Exact<{
  contentDocId: Scalars["UUID"];
}>;

export type NavioCalendarUiContentDocQuery = {
  __typename?: "Query";
  content: {
    __typename: "ContentDoc";
    id: any;
    contentJson?: any | null;
    contentHtml?: string | null;
    context: string;
    useContentJson: boolean;
    docContext: { __typename: "ContentDocContext"; id: any };
    referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      id: any;
      language: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
  };
};

export type NavioCalendarUiRenderCalendarQueryVariables = Exact<{
  request: CalendarInput;
  from?: InputMaybe<Scalars["OffsetDateTime"]>;
  to?: InputMaybe<Scalars["OffsetDateTime"]>;
  format?: InputMaybe<CalendarSerializationFormat>;
}>;

export type NavioCalendarUiRenderCalendarQuery = {
  __typename?: "Query";
  renderCalendar: {
    __typename: "Calendar";
    calendarEventTemplates: Array<{
      __typename?: "CalendarEventTemplate";
      id: any;
      name: string;
      description?: string | null;
      category?: CalendarEventCategory | null;
      color?: any | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        id: any;
        contentJson?: any | null;
        contentHtml?: string | null;
        context: string;
        useContentJson: boolean;
        docContext: { __typename: "ContentDocContext"; id: any };
        referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          id: any;
          language: any;
          context?: ContentMediaContext | null;
          mimeType: string;
          url?: any | null;
        }>;
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        id: any;
        language: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
      dependentEvents: Array<{ __typename?: "CalendarEventTemplate"; id: any }>;
    }>;
    events: Array<{
      __typename?: "CalendarEvent";
      id: string;
      calendarEventTemplate?: {
        __typename?: "CalendarEventTemplate";
        id: any;
      } | null;
      occurrences: Array<{
        __typename?: "CalendarEventOccurrence";
        id: string;
        description?: string | null;
        name: string;
        timeDescriptionOverride?: string | null;
        editableParameterValues: Array<{
          __typename?: "EditableParameterValue";
          editableParameter: {
            __typename?: "EditableParameter";
            id: any;
            scopeSelector: ParameterScopeSelector;
            andFollowing: boolean;
            parameter: {
              __typename?: "Parameter";
              id: any;
              label: string;
              type: ParameterType;
              required: boolean;
              multiselect: boolean;
              description?: string | null;
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
            };
            contentDocs: Array<{
              __typename: "ContentDoc";
              id: any;
              contentJson?: any | null;
              contentHtml?: string | null;
              context: string;
              useContentJson: boolean;
              docContext: { __typename: "ContentDocContext"; id: any };
              referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                id: any;
                language: any;
                context?: ContentMediaContext | null;
                mimeType: string;
                url?: any | null;
              }>;
            }>;
          };
          value: {
            __typename?: "TreatmentPlanParameterValue";
            id: any;
            transient: boolean;
            value?: string | null;
            parameter: {
              __typename?: "Parameter";
              id: any;
              label: string;
              type: ParameterType;
              required: boolean;
              multiselect: boolean;
              description?: string | null;
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
            };
            scope?: {
              __typename?: "CalendarEventScope";
              string: string;
            } | null;
          };
        }>;
        scope: { __typename?: "CalendarEventScope"; string: string };
        timing: {
          __typename?: "CalendarEventTiming";
          start: any;
          end: any;
          allDay: boolean;
        };
      }>;
    }>;
    parameters: Array<{
      __typename?: "TreatmentPlanParameterValue";
      id: any;
      transient: boolean;
      value?: string | null;
      parameter: {
        __typename?: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      scope?: { __typename?: "CalendarEventScope"; string: string } | null;
    }>;
  };
};

export type AddressFragment = {
  __typename?: "Address";
  id: any;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  postalCode: string;
  country: any;
};

export type AlternativeNameEntryFragment = {
  __typename: "AlternativeNameEntry";
  key: AlternativeName;
  value: string;
};

type AnyTreatmentPlanNode_Brand_Fragment = {
  __typename: "Brand";
  id: any;
  visible?: boolean | null;
  name: string;
  domainPrefix?: string | null;
  emailFromName?: string | null;
  emailFromAddress?: string | null;
  smsSenderId?: any | null;
  designTokens?: any | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_CalendarEventTemplate_Fragment = {
  __typename: "CalendarEventTemplate";
  id: any;
  visible?: boolean | null;
  blocking: boolean;
  category?: CalendarEventCategory | null;
  color?: any | null;
  exceptHolidays: boolean;
  icon?: string | null;
  name: string;
  patientNameOverride?: string | null;
  rescheduleStrategy?: RescheduleStrategy | null;
  timeDescriptionOverride?: string | null;
  visibleTo?: VisibleTo | null;
  calendarEventTemplateDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  confirmations: Array<{
    __typename: "Confirmation";
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    id: any;
    visible?: boolean | null;
    confirmationDuration: any;
    confirmationName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameters: Array<{ __typename: "Parameter"; id: any }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  duration?: {
    __typename?: "CalendarEventDuration";
    allDay?: boolean | null;
    length?: any | null;
  } | null;
  editableParameters: Array<{
    __typename: "EditableParameter";
    andFollowing: boolean;
    name: string;
    scopeSelector: ParameterScopeSelector;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  notifications: Array<{
    __typename: "Notification";
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    snoozeableDuration?: any | null;
    id: any;
    visible?: boolean | null;
    notificationName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    confirmation?: { __typename: "Confirmation"; id: any } | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    groupNotificationTemplate?: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationPreference?: {
      __typename: "NotificationPreference";
      defaultEnabled: boolean;
      editableByPatient: boolean;
      id: any;
      visible?: boolean | null;
      notificationPreferenceCategory: NotificationPreferenceCategory;
      notificationPreferenceDescription?: string | null;
      notificationPreferenceName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationTemplate: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    survey?: { __typename: "Survey"; id: any } | null;
    task?: { __typename: "Task"; id: any } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  recurrenceStrategy?: {
    __typename?: "CalendarRecurrenceStrategy";
    recurrenceFrequency?: Frequency | null;
    recurrenceInterval?: number | null;
    repeatCount?: number | null;
    repeatUntilEventOccurrence?: UntilOccurrence | null;
    inheritReschedules?: boolean | null;
    repeatOnAllAllowedDays?: boolean | null;
    countUntil?: CountUntil | null;
    repeatUntilDelta?: any | null;
    recurrenceRelation?: RecurrenceRelation | null;
    repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
    repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
    repeatUntilEvent?: { __typename: "CalendarEventTemplate"; id: any } | null;
  } | null;
  start?: {
    __typename?: "CalendarEventStart";
    absoluteTime?: any | null;
    relativeToOccurrence?: RelativeToOccurrence | null;
    projectionDirection?: ProjectionDirection | null;
    allowedDays?: Array<any> | null;
    offsetDuration?: any | null;
    offsetPeriod?: any | null;
    dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
    timeParameter?: { __typename: "Parameter"; id: any } | null;
    relativeToEvent?: { __typename: "CalendarEventTemplate"; id: any } | null;
    allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
  } | null;
  surveys: Array<{
    __typename: "Survey";
    collectorId?: string | null;
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    surveyId: string;
    id: any;
    visible?: boolean | null;
    surveyDuration?: any | null;
    surveyName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  tasks: Array<{
    __typename: "Task";
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    id: any;
    visible?: boolean | null;
    taskCategory: TaskCategory;
    taskDescription?: string | null;
    taskName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  flows: Array<{
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Confirmation_Fragment = {
  __typename: "Confirmation";
  id: any;
  visible?: boolean | null;
  eventDelta: any;
  occurrenceSpecifier: OccurrenceSpecifier;
  confirmationDuration: any;
  confirmationName?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameters: Array<{ __typename: "Parameter"; id: any }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_ContentDoc_Fragment = {
  __typename: "ContentDoc";
  id: any;
  visible?: boolean | null;
  useContentJson: boolean;
  language: any;
  slug?: string | null;
  url?: any | null;
  previewImageUrl?: any | null;
  contentDocTitle?: string | null;
  contentDocExternalId?: any | null;
  contentDocContext: string;
  docContext: {
    __typename: "ContentDocContext";
    name: string;
    slug?: string | null;
    description?: string | null;
    exampleImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    breakpointBase?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointSmall?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointMedium?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointLarge?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointExtraLarge?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
  referencesMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referencesQuestions: Array<{ __typename: "Question"; id: any }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_ContentDocContext_Fragment = {
  __typename: "ContentDocContext";
  id: any;
  visible?: boolean | null;
  name: string;
  slug?: string | null;
  description?: string | null;
  exampleImageUrl?: any | null;
  breakpointBase?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointSmall?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointMedium?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointLarge?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointExtraLarge?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_ContentMedia_Fragment = {
  __typename: "ContentMedia";
  id: any;
  visible?: boolean | null;
  title: string;
  language: any;
  mimeType: string;
  url?: any | null;
  contentMediaContext?: ContentMediaContext | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Diagnosis_Fragment = {
  __typename: "Diagnosis";
  id: any;
  visible?: boolean | null;
  name: string;
  diagnosisDescription?: string | null;
  diagnosisSlug?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  resourceReferences: Array<{
    __typename: "ResourceReference";
    globallyVisible: boolean;
    name: string;
    resourceWeight: number;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    resource: {
      __typename: "Resource";
      name: string;
      id: any;
      visible?: boolean | null;
      resourceDescription: string;
      resourceUrl?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    resourceCategory: {
      __typename: "ResourceCategory";
      categoryWeight: number;
      name: string;
      id: any;
      visible?: boolean | null;
      resourceCategoryDescription: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_EditableParameter_Fragment = {
  __typename: "EditableParameter";
  id: any;
  visible?: boolean | null;
  andFollowing: boolean;
  name: string;
  scopeSelector: ParameterScopeSelector;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Flow_Fragment = {
  __typename: "Flow";
  id: any;
  visible?: boolean | null;
  name: string;
  occurrenceSpecifier: OccurrenceSpecifier;
  eventDelta: any;
  restartAutomatically: boolean;
  flowDuration?: any | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  steps: Array<{
    __typename: "FlowStep";
    name: string;
    uxData?: any | null;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    nextStep?: { __typename?: "FlowStep"; id: any } | null;
    notification?: {
      __typename: "Notification";
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      snoozeableDuration?: any | null;
      id: any;
      visible?: boolean | null;
      notificationName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmation?: { __typename: "Confirmation"; id: any } | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      groupNotificationTemplate?: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationPreference?: {
        __typename: "NotificationPreference";
        defaultEnabled: boolean;
        editableByPatient: boolean;
        id: any;
        visible?: boolean | null;
        notificationPreferenceCategory: NotificationPreferenceCategory;
        notificationPreferenceDescription?: string | null;
        notificationPreferenceName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationTemplate: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      survey?: { __typename: "Survey"; id: any } | null;
      task?: { __typename: "Task"; id: any } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    contentDoc?: {
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  task?: {
    __typename: "Task";
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    id: any;
    visible?: boolean | null;
    taskCategory: TaskCategory;
    taskDescription?: string | null;
    taskName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_FlowStep_Fragment = {
  __typename: "FlowStep";
  id: any;
  visible?: boolean | null;
  name: string;
  uxData?: any | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  nextStep?: { __typename?: "FlowStep"; id: any } | null;
  notification?: {
    __typename: "Notification";
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    snoozeableDuration?: any | null;
    id: any;
    visible?: boolean | null;
    notificationName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    confirmation?: { __typename: "Confirmation"; id: any } | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    groupNotificationTemplate?: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationPreference?: {
      __typename: "NotificationPreference";
      defaultEnabled: boolean;
      editableByPatient: boolean;
      id: any;
      visible?: boolean | null;
      notificationPreferenceCategory: NotificationPreferenceCategory;
      notificationPreferenceDescription?: string | null;
      notificationPreferenceName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationTemplate: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    survey?: { __typename: "Survey"; id: any } | null;
    task?: { __typename: "Task"; id: any } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  contentDoc?: {
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Layer_Fragment = {
  __typename: "Layer";
  id: any;
  visible?: boolean | null;
  name: string;
  layerDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  enabledParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  layerGroups: Array<{
    __typename: "LayerGroup";
    name: string;
    required: boolean;
    multiple: boolean;
    id: any;
    visible?: boolean | null;
    layerGroupDescription?: string | null;
    layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameters: Array<{
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameterMappings: Array<{
    __typename: "ParameterMapping";
    name: string;
    offsetDuration?: any | null;
    offsetPeriod?: any | null;
    id: any;
    visible?: boolean | null;
    fromParameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    toParameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  phases: Array<{
    __typename: "Phase";
    name: string;
    defaultDuration: any;
    id: any;
    visible?: boolean | null;
    phaseLayers: Array<{ __typename: "Layer"; id: any }>;
    phaseStartDateParameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    repeatCountParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    totalCountParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    displayIndexParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    phaseParameters?: Array<{
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }> | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  planItems: Array<{
    __typename: "PlanItem";
    name: string;
    id: any;
    visible?: boolean | null;
    measure?: {
      __typename: "Measure";
      name: string;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      measureDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    treatment?: {
      __typename: "Treatment";
      name: string;
      genericName?: string | null;
      brandName?: string | null;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    calendarEventTemplates: Array<{
      __typename: "CalendarEventTemplate";
      blocking: boolean;
      category?: CalendarEventCategory | null;
      color?: any | null;
      exceptHolidays: boolean;
      icon?: string | null;
      name: string;
      patientNameOverride?: string | null;
      rescheduleStrategy?: RescheduleStrategy | null;
      timeDescriptionOverride?: string | null;
      visibleTo?: VisibleTo | null;
      id: any;
      visible?: boolean | null;
      calendarEventTemplateDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmations: Array<{
        __typename: "Confirmation";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        id: any;
        visible?: boolean | null;
        confirmationDuration: any;
        confirmationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{ __typename: "Parameter"; id: any }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      duration?: {
        __typename?: "CalendarEventDuration";
        allDay?: boolean | null;
        length?: any | null;
      } | null;
      editableParameters: Array<{
        __typename: "EditableParameter";
        andFollowing: boolean;
        name: string;
        scopeSelector: ParameterScopeSelector;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      notifications: Array<{
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      recurrenceStrategy?: {
        __typename?: "CalendarRecurrenceStrategy";
        recurrenceFrequency?: Frequency | null;
        recurrenceInterval?: number | null;
        repeatCount?: number | null;
        repeatUntilEventOccurrence?: UntilOccurrence | null;
        inheritReschedules?: boolean | null;
        repeatOnAllAllowedDays?: boolean | null;
        countUntil?: CountUntil | null;
        repeatUntilDelta?: any | null;
        recurrenceRelation?: RecurrenceRelation | null;
        repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
      } | null;
      start?: {
        __typename?: "CalendarEventStart";
        absoluteTime?: any | null;
        relativeToOccurrence?: RelativeToOccurrence | null;
        projectionDirection?: ProjectionDirection | null;
        allowedDays?: Array<any> | null;
        offsetDuration?: any | null;
        offsetPeriod?: any | null;
        dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
        timeParameter?: { __typename: "Parameter"; id: any } | null;
        relativeToEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
        allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
      } | null;
      surveys: Array<{
        __typename: "Survey";
        collectorId?: string | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        surveyId: string;
        id: any;
        visible?: boolean | null;
        surveyDuration?: any | null;
        surveyName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      tasks: Array<{
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    flows: Array<{
      __typename: "Flow";
      name: string;
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      restartAutomatically: boolean;
      id: any;
      visible?: boolean | null;
      flowDuration?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      steps: Array<{
        __typename: "FlowStep";
        name: string;
        uxData?: any | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      task?: {
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_LayerGroup_Fragment = {
  __typename: "LayerGroup";
  id: any;
  visible?: boolean | null;
  name: string;
  required: boolean;
  multiple: boolean;
  layerGroupDescription?: string | null;
  layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Measure_Fragment = {
  __typename: "Measure";
  id: any;
  visible?: boolean | null;
  name: string;
  abbreviation?: string | null;
  measureDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Module_Fragment = {
  __typename: "Module";
  id: any;
  visible?: boolean | null;
  name: string;
  shortName: string;
  ordering: number;
  jumbo: boolean;
  moduleDescription: string;
  moduleSlug: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDoc?: {
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Notification_Fragment = {
  __typename: "Notification";
  id: any;
  visible?: boolean | null;
  eventDelta: any;
  occurrenceSpecifier: OccurrenceSpecifier;
  snoozeableDuration?: any | null;
  notificationName?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  confirmation?: { __typename: "Confirmation"; id: any } | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  groupNotificationTemplate?: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  notificationPreference?: {
    __typename: "NotificationPreference";
    defaultEnabled: boolean;
    editableByPatient: boolean;
    id: any;
    visible?: boolean | null;
    notificationPreferenceCategory: NotificationPreferenceCategory;
    notificationPreferenceDescription?: string | null;
    notificationPreferenceName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  notificationTemplate: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  survey?: { __typename: "Survey"; id: any } | null;
  task?: { __typename: "Task"; id: any } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_NotificationContent_Fragment = {
  __typename: "NotificationContent";
  id: any;
  visible?: boolean | null;
  contentKind: NotificationContentKind;
  content: string;
  notificationContentName: string;
  notificationContentLanguage: any;
  notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
  notificationContentReferencesMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_NotificationPreference_Fragment = {
  __typename: "NotificationPreference";
  id: any;
  visible?: boolean | null;
  defaultEnabled: boolean;
  editableByPatient: boolean;
  notificationPreferenceCategory: NotificationPreferenceCategory;
  notificationPreferenceDescription?: string | null;
  notificationPreferenceName: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_NotificationTemplate_Fragment = {
  __typename: "NotificationTemplate";
  id: any;
  visible?: boolean | null;
  notificationTemplateName: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  notificationContents: Array<{
    __typename: "NotificationContent";
    contentKind: NotificationContentKind;
    content: string;
    id: any;
    visible?: boolean | null;
    notificationContentName: string;
    notificationContentLanguage: any;
    notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
    notificationContentReferencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Parameter_Fragment = {
  __typename: "Parameter";
  id: any;
  visible?: boolean | null;
  defaultValue?: string | null;
  editableBy: ParameterEditableBy;
  label: string;
  multiselect: boolean;
  required: boolean;
  type: ParameterType;
  visibleFor: ParameterVisibleFor;
  parameterDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_ParameterMapping_Fragment = {
  __typename: "ParameterMapping";
  id: any;
  visible?: boolean | null;
  name: string;
  offsetDuration?: any | null;
  offsetPeriod?: any | null;
  fromParameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  toParameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Phase_Fragment = {
  __typename: "Phase";
  id: any;
  visible?: boolean | null;
  name: string;
  defaultDuration: any;
  phaseLayers: Array<{ __typename: "Layer"; id: any }>;
  phaseStartDateParameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  repeatCountParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  totalCountParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  displayIndexParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  phaseParameters?: Array<{
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }> | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_PlanItem_Fragment = {
  __typename: "PlanItem";
  id: any;
  visible?: boolean | null;
  name: string;
  measure?: {
    __typename: "Measure";
    name: string;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    measureDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  treatment?: {
    __typename: "Treatment";
    name: string;
    genericName?: string | null;
    brandName?: string | null;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  calendarEventTemplates: Array<{
    __typename: "CalendarEventTemplate";
    blocking: boolean;
    category?: CalendarEventCategory | null;
    color?: any | null;
    exceptHolidays: boolean;
    icon?: string | null;
    name: string;
    patientNameOverride?: string | null;
    rescheduleStrategy?: RescheduleStrategy | null;
    timeDescriptionOverride?: string | null;
    visibleTo?: VisibleTo | null;
    id: any;
    visible?: boolean | null;
    calendarEventTemplateDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    confirmations: Array<{
      __typename: "Confirmation";
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      id: any;
      visible?: boolean | null;
      confirmationDuration: any;
      confirmationName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      parameters: Array<{ __typename: "Parameter"; id: any }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    duration?: {
      __typename?: "CalendarEventDuration";
      allDay?: boolean | null;
      length?: any | null;
    } | null;
    editableParameters: Array<{
      __typename: "EditableParameter";
      andFollowing: boolean;
      name: string;
      scopeSelector: ParameterScopeSelector;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      parameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    notifications: Array<{
      __typename: "Notification";
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      snoozeableDuration?: any | null;
      id: any;
      visible?: boolean | null;
      notificationName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmation?: { __typename: "Confirmation"; id: any } | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      groupNotificationTemplate?: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationPreference?: {
        __typename: "NotificationPreference";
        defaultEnabled: boolean;
        editableByPatient: boolean;
        id: any;
        visible?: boolean | null;
        notificationPreferenceCategory: NotificationPreferenceCategory;
        notificationPreferenceDescription?: string | null;
        notificationPreferenceName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationTemplate: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      survey?: { __typename: "Survey"; id: any } | null;
      task?: { __typename: "Task"; id: any } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    recurrenceStrategy?: {
      __typename?: "CalendarRecurrenceStrategy";
      recurrenceFrequency?: Frequency | null;
      recurrenceInterval?: number | null;
      repeatCount?: number | null;
      repeatUntilEventOccurrence?: UntilOccurrence | null;
      inheritReschedules?: boolean | null;
      repeatOnAllAllowedDays?: boolean | null;
      countUntil?: CountUntil | null;
      repeatUntilDelta?: any | null;
      recurrenceRelation?: RecurrenceRelation | null;
      repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
      repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
      repeatUntilEvent?: {
        __typename: "CalendarEventTemplate";
        id: any;
      } | null;
    } | null;
    start?: {
      __typename?: "CalendarEventStart";
      absoluteTime?: any | null;
      relativeToOccurrence?: RelativeToOccurrence | null;
      projectionDirection?: ProjectionDirection | null;
      allowedDays?: Array<any> | null;
      offsetDuration?: any | null;
      offsetPeriod?: any | null;
      dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
      timeParameter?: { __typename: "Parameter"; id: any } | null;
      relativeToEvent?: { __typename: "CalendarEventTemplate"; id: any } | null;
      allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
    } | null;
    surveys: Array<{
      __typename: "Survey";
      collectorId?: string | null;
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      surveyId: string;
      id: any;
      visible?: boolean | null;
      surveyDuration?: any | null;
      surveyName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    tasks: Array<{
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    flows: Array<{
      __typename: "Flow";
      name: string;
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      restartAutomatically: boolean;
      id: any;
      visible?: boolean | null;
      flowDuration?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      steps: Array<{
        __typename: "FlowStep";
        name: string;
        uxData?: any | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      task?: {
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  flows: Array<{
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Question_Fragment = {
  __typename: "Question";
  id: any;
  visible?: boolean | null;
  name: string;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  questionNotificationTemplate?: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  rootMeasure: {
    __typename: "Measure";
    name: string;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    measureDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  prototypeMeasures: Array<{
    __typename: "Measure";
    name: string;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    measureDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Resource_Fragment = {
  __typename: "Resource";
  id: any;
  visible?: boolean | null;
  name: string;
  resourceDescription: string;
  resourceUrl?: any | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_ResourceCategory_Fragment = {
  __typename: "ResourceCategory";
  id: any;
  visible?: boolean | null;
  categoryWeight: number;
  name: string;
  resourceCategoryDescription: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_ResourceReference_Fragment = {
  __typename: "ResourceReference";
  id: any;
  visible?: boolean | null;
  globallyVisible: boolean;
  name: string;
  resourceWeight: number;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  resource: {
    __typename: "Resource";
    name: string;
    id: any;
    visible?: boolean | null;
    resourceDescription: string;
    resourceUrl?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  resourceCategory: {
    __typename: "ResourceCategory";
    categoryWeight: number;
    name: string;
    id: any;
    visible?: boolean | null;
    resourceCategoryDescription: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Survey_Fragment = {
  __typename: "Survey";
  id: any;
  visible?: boolean | null;
  collectorId?: string | null;
  eventDelta: any;
  occurrenceSpecifier: OccurrenceSpecifier;
  surveyId: string;
  surveyDuration?: any | null;
  surveyName?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Task_Fragment = {
  __typename: "Task";
  id: any;
  visible?: boolean | null;
  occurrenceSpecifier: OccurrenceSpecifier;
  eventDelta: any;
  taskCategory: TaskCategory;
  taskDescription?: string | null;
  taskName: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_Treatment_Fragment = {
  __typename: "Treatment";
  id: any;
  visible?: boolean | null;
  name: string;
  genericName?: string | null;
  brandName?: string | null;
  abbreviation?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_TreatmentList_Fragment = {
  __typename: "TreatmentList";
  id: any;
  visible?: boolean | null;
  name: string;
  key: string;
  items?: Array<{
    __typename: "PlanItem";
    name: string;
    id: any;
    visible?: boolean | null;
    measure?: {
      __typename: "Measure";
      name: string;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      measureDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    treatment?: {
      __typename: "Treatment";
      name: string;
      genericName?: string | null;
      brandName?: string | null;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    calendarEventTemplates: Array<{
      __typename: "CalendarEventTemplate";
      blocking: boolean;
      category?: CalendarEventCategory | null;
      color?: any | null;
      exceptHolidays: boolean;
      icon?: string | null;
      name: string;
      patientNameOverride?: string | null;
      rescheduleStrategy?: RescheduleStrategy | null;
      timeDescriptionOverride?: string | null;
      visibleTo?: VisibleTo | null;
      id: any;
      visible?: boolean | null;
      calendarEventTemplateDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmations: Array<{
        __typename: "Confirmation";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        id: any;
        visible?: boolean | null;
        confirmationDuration: any;
        confirmationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{ __typename: "Parameter"; id: any }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      duration?: {
        __typename?: "CalendarEventDuration";
        allDay?: boolean | null;
        length?: any | null;
      } | null;
      editableParameters: Array<{
        __typename: "EditableParameter";
        andFollowing: boolean;
        name: string;
        scopeSelector: ParameterScopeSelector;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      notifications: Array<{
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      recurrenceStrategy?: {
        __typename?: "CalendarRecurrenceStrategy";
        recurrenceFrequency?: Frequency | null;
        recurrenceInterval?: number | null;
        repeatCount?: number | null;
        repeatUntilEventOccurrence?: UntilOccurrence | null;
        inheritReschedules?: boolean | null;
        repeatOnAllAllowedDays?: boolean | null;
        countUntil?: CountUntil | null;
        repeatUntilDelta?: any | null;
        recurrenceRelation?: RecurrenceRelation | null;
        repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
      } | null;
      start?: {
        __typename?: "CalendarEventStart";
        absoluteTime?: any | null;
        relativeToOccurrence?: RelativeToOccurrence | null;
        projectionDirection?: ProjectionDirection | null;
        allowedDays?: Array<any> | null;
        offsetDuration?: any | null;
        offsetPeriod?: any | null;
        dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
        timeParameter?: { __typename: "Parameter"; id: any } | null;
        relativeToEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
        allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
      } | null;
      surveys: Array<{
        __typename: "Survey";
        collectorId?: string | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        surveyId: string;
        id: any;
        visible?: boolean | null;
        surveyDuration?: any | null;
        surveyName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      tasks: Array<{
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    flows: Array<{
      __typename: "Flow";
      name: string;
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      restartAutomatically: boolean;
      id: any;
      visible?: boolean | null;
      flowDuration?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      steps: Array<{
        __typename: "FlowStep";
        name: string;
        uxData?: any | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      task?: {
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }> | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type AnyTreatmentPlanNode_TreatmentPlan_Fragment = {
  __typename: "TreatmentPlan";
  id: any;
  visible?: boolean | null;
  name: string;
  description?: string | null;
  protocolName?: string | null;
  patientNameOverride?: string | null;
  initialView?: PlanView | null;
  rootLayer?: { __typename: "Layer"; id: any } | null;
  initialTasks: Array<{
    __typename: "Task";
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    id: any;
    visible?: boolean | null;
    taskCategory: TaskCategory;
    taskDescription?: string | null;
    taskName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  invitationTemplate?: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  startDateParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  excludedModules: Array<{
    __typename: "Module";
    name: string;
    shortName: string;
    ordering: number;
    jumbo: boolean;
    id: any;
    visible?: boolean | null;
    moduleDescription: string;
    moduleSlug: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDoc?: {
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  includedModules: Array<{
    __typename: "Module";
    name: string;
    shortName: string;
    ordering: number;
    jumbo: boolean;
    id: any;
    visible?: boolean | null;
    moduleDescription: string;
    moduleSlug: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDoc?: {
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  layers: Array<{
    __typename: "Layer";
    name: string;
    id: any;
    visible?: boolean | null;
    layerDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    enabledParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    layerGroups: Array<{
      __typename: "LayerGroup";
      name: string;
      required: boolean;
      multiple: boolean;
      id: any;
      visible?: boolean | null;
      layerGroupDescription?: string | null;
      layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameters: Array<{
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameterMappings: Array<{
      __typename: "ParameterMapping";
      name: string;
      offsetDuration?: any | null;
      offsetPeriod?: any | null;
      id: any;
      visible?: boolean | null;
      fromParameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      toParameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    phases: Array<{
      __typename: "Phase";
      name: string;
      defaultDuration: any;
      id: any;
      visible?: boolean | null;
      phaseLayers: Array<{ __typename: "Layer"; id: any }>;
      phaseStartDateParameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      repeatCountParameter?: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      totalCountParameter?: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      displayIndexParameter?: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      phaseParameters?: Array<{
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }> | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    planItems: Array<{
      __typename: "PlanItem";
      name: string;
      id: any;
      visible?: boolean | null;
      measure?: {
        __typename: "Measure";
        name: string;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        measureDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      treatment?: {
        __typename: "Treatment";
        name: string;
        genericName?: string | null;
        brandName?: string | null;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      calendarEventTemplates: Array<{
        __typename: "CalendarEventTemplate";
        blocking: boolean;
        category?: CalendarEventCategory | null;
        color?: any | null;
        exceptHolidays: boolean;
        icon?: string | null;
        name: string;
        patientNameOverride?: string | null;
        rescheduleStrategy?: RescheduleStrategy | null;
        timeDescriptionOverride?: string | null;
        visibleTo?: VisibleTo | null;
        id: any;
        visible?: boolean | null;
        calendarEventTemplateDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmations: Array<{
          __typename: "Confirmation";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          id: any;
          visible?: boolean | null;
          confirmationDuration: any;
          confirmationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{ __typename: "Parameter"; id: any }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        duration?: {
          __typename?: "CalendarEventDuration";
          allDay?: boolean | null;
          length?: any | null;
        } | null;
        editableParameters: Array<{
          __typename: "EditableParameter";
          andFollowing: boolean;
          name: string;
          scopeSelector: ParameterScopeSelector;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        notifications: Array<{
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        recurrenceStrategy?: {
          __typename?: "CalendarRecurrenceStrategy";
          recurrenceFrequency?: Frequency | null;
          recurrenceInterval?: number | null;
          repeatCount?: number | null;
          repeatUntilEventOccurrence?: UntilOccurrence | null;
          inheritReschedules?: boolean | null;
          repeatOnAllAllowedDays?: boolean | null;
          countUntil?: CountUntil | null;
          repeatUntilDelta?: any | null;
          recurrenceRelation?: RecurrenceRelation | null;
          repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
        } | null;
        start?: {
          __typename?: "CalendarEventStart";
          absoluteTime?: any | null;
          relativeToOccurrence?: RelativeToOccurrence | null;
          projectionDirection?: ProjectionDirection | null;
          allowedDays?: Array<any> | null;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
          timeParameter?: { __typename: "Parameter"; id: any } | null;
          relativeToEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
          allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
        } | null;
        surveys: Array<{
          __typename: "Survey";
          collectorId?: string | null;
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          surveyId: string;
          id: any;
          visible?: boolean | null;
          surveyDuration?: any | null;
          surveyName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        tasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  resourceReferences: Array<{
    __typename: "ResourceReference";
    globallyVisible: boolean;
    name: string;
    resourceWeight: number;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    resource: {
      __typename: "Resource";
      name: string;
      id: any;
      visible?: boolean | null;
      resourceDescription: string;
      resourceUrl?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    resourceCategory: {
      __typename: "ResourceCategory";
      categoryWeight: number;
      name: string;
      id: any;
      visible?: boolean | null;
      resourceCategoryDescription: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  treatmentLists: Array<{
    __typename: "TreatmentList";
    name: string;
    key: string;
    id: any;
    visible?: boolean | null;
    items?: Array<{
      __typename: "PlanItem";
      name: string;
      id: any;
      visible?: boolean | null;
      measure?: {
        __typename: "Measure";
        name: string;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        measureDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      treatment?: {
        __typename: "Treatment";
        name: string;
        genericName?: string | null;
        brandName?: string | null;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      calendarEventTemplates: Array<{
        __typename: "CalendarEventTemplate";
        blocking: boolean;
        category?: CalendarEventCategory | null;
        color?: any | null;
        exceptHolidays: boolean;
        icon?: string | null;
        name: string;
        patientNameOverride?: string | null;
        rescheduleStrategy?: RescheduleStrategy | null;
        timeDescriptionOverride?: string | null;
        visibleTo?: VisibleTo | null;
        id: any;
        visible?: boolean | null;
        calendarEventTemplateDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmations: Array<{
          __typename: "Confirmation";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          id: any;
          visible?: boolean | null;
          confirmationDuration: any;
          confirmationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{ __typename: "Parameter"; id: any }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        duration?: {
          __typename?: "CalendarEventDuration";
          allDay?: boolean | null;
          length?: any | null;
        } | null;
        editableParameters: Array<{
          __typename: "EditableParameter";
          andFollowing: boolean;
          name: string;
          scopeSelector: ParameterScopeSelector;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        notifications: Array<{
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        recurrenceStrategy?: {
          __typename?: "CalendarRecurrenceStrategy";
          recurrenceFrequency?: Frequency | null;
          recurrenceInterval?: number | null;
          repeatCount?: number | null;
          repeatUntilEventOccurrence?: UntilOccurrence | null;
          inheritReschedules?: boolean | null;
          repeatOnAllAllowedDays?: boolean | null;
          countUntil?: CountUntil | null;
          repeatUntilDelta?: any | null;
          recurrenceRelation?: RecurrenceRelation | null;
          repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
        } | null;
        start?: {
          __typename?: "CalendarEventStart";
          absoluteTime?: any | null;
          relativeToOccurrence?: RelativeToOccurrence | null;
          projectionDirection?: ProjectionDirection | null;
          allowedDays?: Array<any> | null;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
          timeParameter?: { __typename: "Parameter"; id: any } | null;
          relativeToEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
          allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
        } | null;
        surveys: Array<{
          __typename: "Survey";
          collectorId?: string | null;
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          surveyId: string;
          id: any;
          visible?: boolean | null;
          surveyDuration?: any | null;
          surveyName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        tasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }> | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  onboardingFlow?: {
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  notificationResponseFlow?: {
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type AnyTreatmentPlanNodeFragment =
  | AnyTreatmentPlanNode_Brand_Fragment
  | AnyTreatmentPlanNode_CalendarEventTemplate_Fragment
  | AnyTreatmentPlanNode_Confirmation_Fragment
  | AnyTreatmentPlanNode_ContentDoc_Fragment
  | AnyTreatmentPlanNode_ContentDocContext_Fragment
  | AnyTreatmentPlanNode_ContentMedia_Fragment
  | AnyTreatmentPlanNode_Diagnosis_Fragment
  | AnyTreatmentPlanNode_EditableParameter_Fragment
  | AnyTreatmentPlanNode_Flow_Fragment
  | AnyTreatmentPlanNode_FlowStep_Fragment
  | AnyTreatmentPlanNode_Layer_Fragment
  | AnyTreatmentPlanNode_LayerGroup_Fragment
  | AnyTreatmentPlanNode_Measure_Fragment
  | AnyTreatmentPlanNode_Module_Fragment
  | AnyTreatmentPlanNode_Notification_Fragment
  | AnyTreatmentPlanNode_NotificationContent_Fragment
  | AnyTreatmentPlanNode_NotificationPreference_Fragment
  | AnyTreatmentPlanNode_NotificationTemplate_Fragment
  | AnyTreatmentPlanNode_Parameter_Fragment
  | AnyTreatmentPlanNode_ParameterMapping_Fragment
  | AnyTreatmentPlanNode_Phase_Fragment
  | AnyTreatmentPlanNode_PlanItem_Fragment
  | AnyTreatmentPlanNode_Question_Fragment
  | AnyTreatmentPlanNode_Resource_Fragment
  | AnyTreatmentPlanNode_ResourceCategory_Fragment
  | AnyTreatmentPlanNode_ResourceReference_Fragment
  | AnyTreatmentPlanNode_Survey_Fragment
  | AnyTreatmentPlanNode_Task_Fragment
  | AnyTreatmentPlanNode_Treatment_Fragment
  | AnyTreatmentPlanNode_TreatmentList_Fragment
  | AnyTreatmentPlanNode_TreatmentPlan_Fragment;

export type AnythingFragment = {
  __typename: "Anything";
  active: boolean;
  id: any;
  lastUpdated: any;
  name: string;
  type: AnythingType;
  visible: boolean;
  alternativeNames: Array<{
    __typename?: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
};

export type AuditLogRecordFragment = {
  __typename: "AuditLogRecord";
  revision: number;
  action: AuditLogAction;
  createdBy?: any | null;
  createdByName: string;
  createdAt: any;
  delta: Array<{
    __typename: "PatientAuditDelta";
    field: string;
    oldValue?: string | null;
    newValue?: string | null;
  }>;
};

export type AvailableLanguageFragment = {
  __typename?: "AvailableLanguage";
  name: string;
  tag: any;
};

export type BrandNodeFragment = {
  __typename: "Brand";
  id: any;
  name: string;
  domainPrefix?: string | null;
  emailFromName?: string | null;
  emailFromAddress?: string | null;
  smsSenderId?: any | null;
  designTokens?: any | null;
  visible?: boolean | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type CalendarFragment = {
  __typename?: "Calendar";
  timezone?: any | null;
  calendarEventTemplates: Array<{
    __typename?: "CalendarEventTemplate";
    id: any;
    name: string;
    description?: string | null;
    category?: CalendarEventCategory | null;
    color?: any | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      id: any;
      contentJson?: any | null;
      contentHtml?: string | null;
      context: string;
      useContentJson: boolean;
      docContext: { __typename: "ContentDocContext"; id: any };
      referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        id: any;
        language: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      id: any;
      language: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
    dependentEvents: Array<{ __typename?: "CalendarEventTemplate"; id: any }>;
  }>;
  events: Array<{
    __typename?: "CalendarEvent";
    id: string;
    calendarEventTemplate?: {
      __typename: "CalendarEventTemplate";
      id: any;
    } | null;
    occurrences: Array<{
      __typename?: "CalendarEventOccurrence";
      id: string;
      description?: string | null;
      name: string;
      timeDescriptionOverride?: string | null;
      editableParameterValues: Array<{
        __typename?: "EditableParameterValue";
        editableParameter: {
          __typename?: "EditableParameter";
          id: any;
          scopeSelector: ParameterScopeSelector;
          andFollowing: boolean;
          parameter: {
            __typename?: "Parameter";
            id: any;
            label: string;
            type: ParameterType;
            required: boolean;
            multiselect: boolean;
            description?: string | null;
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
          };
          contentDocs: Array<{
            __typename: "ContentDoc";
            id: any;
            contentJson?: any | null;
            contentHtml?: string | null;
            context: string;
            useContentJson: boolean;
            docContext: { __typename: "ContentDocContext"; id: any };
            referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              id: any;
              language: any;
              context?: ContentMediaContext | null;
              mimeType: string;
              url?: any | null;
            }>;
          }>;
        };
        value: {
          __typename?: "TreatmentPlanParameterValue";
          id: any;
          transient: boolean;
          value?: string | null;
          parameter: {
            __typename?: "Parameter";
            id: any;
            label: string;
            type: ParameterType;
            required: boolean;
            multiselect: boolean;
            description?: string | null;
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
          };
          scope?: { __typename?: "CalendarEventScope"; string: string } | null;
        };
      }>;
      scope: { __typename?: "CalendarEventScope"; string: string };
      timing: {
        __typename?: "CalendarEventTiming";
        start: any;
        end: any;
        allDay: boolean;
      };
    }>;
  }>;
  parameters: Array<{
    __typename: "TreatmentPlanParameterValue";
    id: any;
    transient: boolean;
    value?: string | null;
    parameter: {
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    };
    scope?: { __typename?: "CalendarEventScope"; string: string } | null;
  }>;
};

export type CalendarEventFragment = {
  __typename?: "CalendarEvent";
  id: string;
  calendarEventTemplate?: {
    __typename: "CalendarEventTemplate";
    id: any;
  } | null;
  occurrences: Array<{
    __typename?: "CalendarEventOccurrence";
    id: string;
    description?: string | null;
    name: string;
    timeDescriptionOverride?: string | null;
    editableParameterValues: Array<{
      __typename?: "EditableParameterValue";
      editableParameter: {
        __typename?: "EditableParameter";
        id: any;
        scopeSelector: ParameterScopeSelector;
        andFollowing: boolean;
        parameter: {
          __typename?: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        contentDocs: Array<{
          __typename: "ContentDoc";
          id: any;
          contentJson?: any | null;
          contentHtml?: string | null;
          context: string;
          useContentJson: boolean;
          docContext: { __typename: "ContentDocContext"; id: any };
          referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            id: any;
            language: any;
            context?: ContentMediaContext | null;
            mimeType: string;
            url?: any | null;
          }>;
        }>;
      };
      value: {
        __typename?: "TreatmentPlanParameterValue";
        id: any;
        transient: boolean;
        value?: string | null;
        parameter: {
          __typename?: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        scope?: { __typename?: "CalendarEventScope"; string: string } | null;
      };
    }>;
    scope: { __typename?: "CalendarEventScope"; string: string };
    timing: {
      __typename?: "CalendarEventTiming";
      start: any;
      end: any;
      allDay: boolean;
    };
  }>;
};

export type CalendarEventScopeFragment = {
  __typename?: "CalendarEventScope";
  string: string;
};

export type CalendarEventTemplateNodeFragment = {
  __typename: "CalendarEventTemplate";
  blocking: boolean;
  category?: CalendarEventCategory | null;
  color?: any | null;
  exceptHolidays: boolean;
  icon?: string | null;
  name: string;
  patientNameOverride?: string | null;
  rescheduleStrategy?: RescheduleStrategy | null;
  timeDescriptionOverride?: string | null;
  visibleTo?: VisibleTo | null;
  id: any;
  visible?: boolean | null;
  calendarEventTemplateDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  confirmations: Array<{
    __typename: "Confirmation";
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    id: any;
    visible?: boolean | null;
    confirmationDuration: any;
    confirmationName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameters: Array<{ __typename: "Parameter"; id: any }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  duration?: {
    __typename?: "CalendarEventDuration";
    allDay?: boolean | null;
    length?: any | null;
  } | null;
  editableParameters: Array<{
    __typename: "EditableParameter";
    andFollowing: boolean;
    name: string;
    scopeSelector: ParameterScopeSelector;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  notifications: Array<{
    __typename: "Notification";
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    snoozeableDuration?: any | null;
    id: any;
    visible?: boolean | null;
    notificationName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    confirmation?: { __typename: "Confirmation"; id: any } | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    groupNotificationTemplate?: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationPreference?: {
      __typename: "NotificationPreference";
      defaultEnabled: boolean;
      editableByPatient: boolean;
      id: any;
      visible?: boolean | null;
      notificationPreferenceCategory: NotificationPreferenceCategory;
      notificationPreferenceDescription?: string | null;
      notificationPreferenceName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationTemplate: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    survey?: { __typename: "Survey"; id: any } | null;
    task?: { __typename: "Task"; id: any } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  recurrenceStrategy?: {
    __typename?: "CalendarRecurrenceStrategy";
    recurrenceFrequency?: Frequency | null;
    recurrenceInterval?: number | null;
    repeatCount?: number | null;
    repeatUntilEventOccurrence?: UntilOccurrence | null;
    inheritReschedules?: boolean | null;
    repeatOnAllAllowedDays?: boolean | null;
    countUntil?: CountUntil | null;
    repeatUntilDelta?: any | null;
    recurrenceRelation?: RecurrenceRelation | null;
    repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
    repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
    repeatUntilEvent?: { __typename: "CalendarEventTemplate"; id: any } | null;
  } | null;
  start?: {
    __typename?: "CalendarEventStart";
    absoluteTime?: any | null;
    relativeToOccurrence?: RelativeToOccurrence | null;
    projectionDirection?: ProjectionDirection | null;
    allowedDays?: Array<any> | null;
    offsetDuration?: any | null;
    offsetPeriod?: any | null;
    dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
    timeParameter?: { __typename: "Parameter"; id: any } | null;
    relativeToEvent?: { __typename: "CalendarEventTemplate"; id: any } | null;
    allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
  } | null;
  surveys: Array<{
    __typename: "Survey";
    collectorId?: string | null;
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    surveyId: string;
    id: any;
    visible?: boolean | null;
    surveyDuration?: any | null;
    surveyName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  tasks: Array<{
    __typename: "Task";
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    id: any;
    visible?: boolean | null;
    taskCategory: TaskCategory;
    taskDescription?: string | null;
    taskName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  flows: Array<{
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ConfirmationNodeFragment = {
  __typename: "Confirmation";
  eventDelta: any;
  occurrenceSpecifier: OccurrenceSpecifier;
  id: any;
  visible?: boolean | null;
  confirmationDuration: any;
  confirmationName?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameters: Array<{ __typename: "Parameter"; id: any }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ContentDocFragment = {
  __typename: "ContentDoc";
  contentHtml?: string | null;
  context: string;
  id: any;
};

export type ContentDocContainerSizesFragment = {
  __typename?: "ContentDocContainerSizes";
  minWidthPx?: number | null;
  widthPx?: number | null;
  maxWidthPx?: number | null;
};

export type ContentDocContextNodeFragment = {
  __typename: "ContentDocContext";
  name: string;
  slug?: string | null;
  description?: string | null;
  exampleImageUrl?: any | null;
  id: any;
  visible?: boolean | null;
  breakpointBase?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointSmall?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointMedium?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointLarge?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  breakpointExtraLarge?: {
    __typename?: "ContentDocContainerSizes";
    minWidthPx?: number | null;
    widthPx?: number | null;
    maxWidthPx?: number | null;
  } | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ContentDocNodeFragment = {
  __typename: "ContentDoc";
  useContentJson: boolean;
  language: any;
  slug?: string | null;
  url?: any | null;
  previewImageUrl?: any | null;
  id: any;
  visible?: boolean | null;
  contentDocTitle?: string | null;
  contentDocExternalId?: any | null;
  contentDocContext: string;
  docContext: {
    __typename: "ContentDocContext";
    name: string;
    slug?: string | null;
    description?: string | null;
    exampleImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    breakpointBase?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointSmall?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointMedium?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointLarge?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    breakpointExtraLarge?: {
      __typename?: "ContentDocContainerSizes";
      minWidthPx?: number | null;
      widthPx?: number | null;
      maxWidthPx?: number | null;
    } | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
  referencesMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referencesQuestions: Array<{ __typename: "Question"; id: any }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ContentMediaNodeFragment = {
  __typename: "ContentMedia";
  title: string;
  language: any;
  mimeType: string;
  url?: any | null;
  id: any;
  visible?: boolean | null;
  contentMediaContext?: ContentMediaContext | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type CourseFragment = { __typename: "Course"; id: any };

export type CourseWithTreatmentPlanOccurrencesFragment = {
  __typename: "Course";
  id: any;
  treatmentPlanOccurrences: Array<{
    __typename: "TreatmentPlanOccurrence";
    id: any;
    status: TreatmentPlanOccurrenceStatus;
    treatmentStartDate?: any | null;
    treatmentPlan: { __typename: "TreatmentPlan"; id: any; name: string };
    surveys: Array<{
      __typename: "SurveyOccurrence";
      id: any;
      eventDate: any;
      completedAt?: any | null;
      providerAckDate?: any | null;
      survey: { __typename?: "Survey"; id: any; name?: string | null };
    }>;
    tasks: Array<{
      __typename?: "TaskOccurrence";
      id: any;
      eventDate: any;
      allDay?: boolean | null;
      status: TaskStatus;
      statusChangedAt?: any | null;
      task: { __typename?: "Task"; id: any; name: string };
    }>;
  }>;
};

export type CrmForeignSyncLogFragment = {
  __typename?: "CrmForeignSyncLog";
  id: any;
  field?: string | null;
  message: string;
};

export type CrmPersonFragment = {
  __typename?: "CrmPerson";
  id: string;
  provider?: {
    __typename?: "Provider";
    id: any;
    name: Array<string>;
    locations: Array<{
      __typename?: "ProviderLocation";
      id: any;
      active: boolean;
      practice: {
        __typename?: "Practice";
        id: any;
        name: string;
        organization?: {
          __typename?: "Organization";
          id: any;
          name: string;
        } | null;
      };
      patientIdentifiers: Array<{
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      }>;
    }>;
  } | null;
  logs: Array<{
    __typename?: "CrmForeignSyncLog";
    id: any;
    field?: string | null;
    message: string;
  }>;
};

export type CsvFieldMappingFragment = {
  __typename?: "CsvFieldMapping";
  mappedField?: string | null;
  mappedValue?: string | null;
  error?: string | null;
};

export type CsvMappingPreviewFragment = {
  __typename?: "CsvMappingPreview";
  mappingError?: string | null;
  rowMappings: Array<{
    __typename?: "CsvRowMapping";
    rowNumber?: number | null;
    mappedFields: Array<{
      __typename?: "CsvFieldMapping";
      mappedField?: string | null;
      mappedValue?: string | null;
      error?: string | null;
    }>;
    importResult?: {
      __typename?: "CsvRowMappingOutcome";
      error?: string | null;
      patientStatus?: CsvRowPatientStatus | null;
    } | null;
  }>;
};

export type CsvRowMappingFragment = {
  __typename?: "CsvRowMapping";
  rowNumber?: number | null;
  mappedFields: Array<{
    __typename?: "CsvFieldMapping";
    mappedField?: string | null;
    mappedValue?: string | null;
    error?: string | null;
  }>;
  importResult?: {
    __typename?: "CsvRowMappingOutcome";
    error?: string | null;
    patientStatus?: CsvRowPatientStatus | null;
  } | null;
};

export type DiagnosisFragment = {
  __typename: "Diagnosis";
  id: any;
  name: string;
};

export type DiagnosisNodeFragment = {
  __typename: "Diagnosis";
  name: string;
  id: any;
  visible?: boolean | null;
  diagnosisDescription?: string | null;
  diagnosisSlug?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  resourceReferences: Array<{
    __typename: "ResourceReference";
    globallyVisible: boolean;
    name: string;
    resourceWeight: number;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    resource: {
      __typename: "Resource";
      name: string;
      id: any;
      visible?: boolean | null;
      resourceDescription: string;
      resourceUrl?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    resourceCategory: {
      __typename: "ResourceCategory";
      categoryWeight: number;
      name: string;
      id: any;
      visible?: boolean | null;
      resourceCategoryDescription: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type DiagnosisOccurrenceFragment = {
  __typename: "DiagnosisOccurrence";
  id: any;
  patientId: any;
  dateOfDiagnosis?: any | null;
  courseId: any;
  diagnosis: { __typename: "Diagnosis"; id: any; name: string };
};

export type EditableParameterNodeFragment = {
  __typename: "EditableParameter";
  andFollowing: boolean;
  name: string;
  scopeSelector: ParameterScopeSelector;
  id: any;
  visible?: boolean | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type EnrollmentFragment = {
  __typename?: "Enrollment";
  enrollmentId: any;
  enrollmentType: EnrollmentType;
  patientFirstName: string;
  patientLastName: string;
  courseId: any;
};

export type FlowNodeFragment = {
  __typename: "Flow";
  name: string;
  occurrenceSpecifier: OccurrenceSpecifier;
  eventDelta: any;
  restartAutomatically: boolean;
  id: any;
  visible?: boolean | null;
  flowDuration?: any | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  steps: Array<{
    __typename: "FlowStep";
    name: string;
    uxData?: any | null;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    nextStep?: { __typename?: "FlowStep"; id: any } | null;
    notification?: {
      __typename: "Notification";
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      snoozeableDuration?: any | null;
      id: any;
      visible?: boolean | null;
      notificationName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmation?: { __typename: "Confirmation"; id: any } | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      groupNotificationTemplate?: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationPreference?: {
        __typename: "NotificationPreference";
        defaultEnabled: boolean;
        editableByPatient: boolean;
        id: any;
        visible?: boolean | null;
        notificationPreferenceCategory: NotificationPreferenceCategory;
        notificationPreferenceDescription?: string | null;
        notificationPreferenceName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationTemplate: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      survey?: { __typename: "Survey"; id: any } | null;
      task?: { __typename: "Task"; id: any } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    contentDoc?: {
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  task?: {
    __typename: "Task";
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    id: any;
    visible?: boolean | null;
    taskCategory: TaskCategory;
    taskDescription?: string | null;
    taskName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type FlowStepNodeFragment = {
  __typename: "FlowStep";
  name: string;
  uxData?: any | null;
  id: any;
  visible?: boolean | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  nextStep?: { __typename?: "FlowStep"; id: any } | null;
  notification?: {
    __typename: "Notification";
    eventDelta: any;
    occurrenceSpecifier: OccurrenceSpecifier;
    snoozeableDuration?: any | null;
    id: any;
    visible?: boolean | null;
    notificationName?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    confirmation?: { __typename: "Confirmation"; id: any } | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    groupNotificationTemplate?: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationPreference?: {
      __typename: "NotificationPreference";
      defaultEnabled: boolean;
      editableByPatient: boolean;
      id: any;
      visible?: boolean | null;
      notificationPreferenceCategory: NotificationPreferenceCategory;
      notificationPreferenceDescription?: string | null;
      notificationPreferenceName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    notificationTemplate: {
      __typename: "NotificationTemplate";
      id: any;
      visible?: boolean | null;
      notificationTemplateName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      notificationContents: Array<{
        __typename: "NotificationContent";
        contentKind: NotificationContentKind;
        content: string;
        id: any;
        visible?: boolean | null;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    survey?: { __typename: "Survey"; id: any } | null;
    task?: { __typename: "Task"; id: any } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  contentDoc?: {
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type LayerFragment = {
  __typename: "Layer";
  id: any;
  name: string;
  description?: string | null;
  parameters: Array<{
    __typename: "Parameter";
    id: any;
    label: string;
    type: ParameterType;
    required: boolean;
    multiselect: boolean;
    description?: string | null;
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
  }>;
  layerGroups: Array<{
    __typename: "LayerGroup";
    id: any;
    name: string;
    description?: string | null;
    required: boolean;
    multiple: boolean;
    layers: Array<{ __typename?: "Layer"; id: any }>;
  }>;
  enabledParameter?: {
    __typename: "Parameter";
    id: any;
    label: string;
    type: ParameterType;
    required: boolean;
    multiselect: boolean;
    description?: string | null;
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
  } | null;
};

export type LayerGroupFragment = {
  __typename: "LayerGroup";
  id: any;
  name: string;
  description?: string | null;
  required: boolean;
  multiple: boolean;
  layers: Array<{ __typename?: "Layer"; id: any }>;
};

export type LayerGroupNodeFragment = {
  __typename: "LayerGroup";
  name: string;
  required: boolean;
  multiple: boolean;
  id: any;
  visible?: boolean | null;
  layerGroupDescription?: string | null;
  layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type LayerNodeFragment = {
  __typename: "Layer";
  name: string;
  id: any;
  visible?: boolean | null;
  layerDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  enabledParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  layerGroups: Array<{
    __typename: "LayerGroup";
    name: string;
    required: boolean;
    multiple: boolean;
    id: any;
    visible?: boolean | null;
    layerGroupDescription?: string | null;
    layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameters: Array<{
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  parameterMappings: Array<{
    __typename: "ParameterMapping";
    name: string;
    offsetDuration?: any | null;
    offsetPeriod?: any | null;
    id: any;
    visible?: boolean | null;
    fromParameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    toParameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  phases: Array<{
    __typename: "Phase";
    name: string;
    defaultDuration: any;
    id: any;
    visible?: boolean | null;
    phaseLayers: Array<{ __typename: "Layer"; id: any }>;
    phaseStartDateParameter: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    repeatCountParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    totalCountParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    displayIndexParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    phaseParameters?: Array<{
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }> | null;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  planItems: Array<{
    __typename: "PlanItem";
    name: string;
    id: any;
    visible?: boolean | null;
    measure?: {
      __typename: "Measure";
      name: string;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      measureDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    treatment?: {
      __typename: "Treatment";
      name: string;
      genericName?: string | null;
      brandName?: string | null;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    calendarEventTemplates: Array<{
      __typename: "CalendarEventTemplate";
      blocking: boolean;
      category?: CalendarEventCategory | null;
      color?: any | null;
      exceptHolidays: boolean;
      icon?: string | null;
      name: string;
      patientNameOverride?: string | null;
      rescheduleStrategy?: RescheduleStrategy | null;
      timeDescriptionOverride?: string | null;
      visibleTo?: VisibleTo | null;
      id: any;
      visible?: boolean | null;
      calendarEventTemplateDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmations: Array<{
        __typename: "Confirmation";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        id: any;
        visible?: boolean | null;
        confirmationDuration: any;
        confirmationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{ __typename: "Parameter"; id: any }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      duration?: {
        __typename?: "CalendarEventDuration";
        allDay?: boolean | null;
        length?: any | null;
      } | null;
      editableParameters: Array<{
        __typename: "EditableParameter";
        andFollowing: boolean;
        name: string;
        scopeSelector: ParameterScopeSelector;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      notifications: Array<{
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      recurrenceStrategy?: {
        __typename?: "CalendarRecurrenceStrategy";
        recurrenceFrequency?: Frequency | null;
        recurrenceInterval?: number | null;
        repeatCount?: number | null;
        repeatUntilEventOccurrence?: UntilOccurrence | null;
        inheritReschedules?: boolean | null;
        repeatOnAllAllowedDays?: boolean | null;
        countUntil?: CountUntil | null;
        repeatUntilDelta?: any | null;
        recurrenceRelation?: RecurrenceRelation | null;
        repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
      } | null;
      start?: {
        __typename?: "CalendarEventStart";
        absoluteTime?: any | null;
        relativeToOccurrence?: RelativeToOccurrence | null;
        projectionDirection?: ProjectionDirection | null;
        allowedDays?: Array<any> | null;
        offsetDuration?: any | null;
        offsetPeriod?: any | null;
        dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
        timeParameter?: { __typename: "Parameter"; id: any } | null;
        relativeToEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
        allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
      } | null;
      surveys: Array<{
        __typename: "Survey";
        collectorId?: string | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        surveyId: string;
        id: any;
        visible?: boolean | null;
        surveyDuration?: any | null;
        surveyName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      tasks: Array<{
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    flows: Array<{
      __typename: "Flow";
      name: string;
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      restartAutomatically: boolean;
      id: any;
      visible?: boolean | null;
      flowDuration?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      steps: Array<{
        __typename: "FlowStep";
        name: string;
        uxData?: any | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      task?: {
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type MatchOccurrenceFragment = {
  __typename: "MatchOccurrence";
  id: any;
  courseId: any;
  treatmentPlanId: any;
  name: string;
  description?: string | null;
  score: number;
};

export type MatchResultsFragment = {
  __typename?: "MatchResults";
  courseId: any;
  matches: Array<{
    __typename: "MatchOccurrence";
    id: any;
    courseId: any;
    treatmentPlanId: any;
    name: string;
    description?: string | null;
    score: number;
  }>;
};

export type MatchRuleFragment = {
  __typename?: "MatchRule";
  id: any;
  providerLocation: { __typename?: "ProviderLocation"; id: any };
  diagnosis: { __typename?: "Diagnosis"; id: any };
  treatmentPlan: { __typename?: "TreatmentPlan"; id: any };
};

export type MeasureNodeFragment = {
  __typename: "Measure";
  name: string;
  abbreviation?: string | null;
  id: any;
  visible?: boolean | null;
  measureDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ModuleNodeFragment = {
  __typename: "Module";
  name: string;
  shortName: string;
  ordering: number;
  jumbo: boolean;
  id: any;
  visible?: boolean | null;
  moduleDescription: string;
  moduleSlug: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDoc?: {
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type NodeReferenceInfoFragment = {
  __typename: "NodeReferenceInfo";
  id: any;
  containers: Array<{
    __typename?: "NodeReferenceContainer";
    id: any;
    nodeType: NodeType;
    name: string;
  }>;
};

export type NotificationContentNodeFragment = {
  __typename: "NotificationContent";
  contentKind: NotificationContentKind;
  content: string;
  id: any;
  visible?: boolean | null;
  notificationContentName: string;
  notificationContentLanguage: any;
  notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
  notificationContentReferencesMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type NotificationNodeFragment = {
  __typename: "Notification";
  eventDelta: any;
  occurrenceSpecifier: OccurrenceSpecifier;
  snoozeableDuration?: any | null;
  id: any;
  visible?: boolean | null;
  notificationName?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  confirmation?: { __typename: "Confirmation"; id: any } | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  groupNotificationTemplate?: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  notificationPreference?: {
    __typename: "NotificationPreference";
    defaultEnabled: boolean;
    editableByPatient: boolean;
    id: any;
    visible?: boolean | null;
    notificationPreferenceCategory: NotificationPreferenceCategory;
    notificationPreferenceDescription?: string | null;
    notificationPreferenceName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  notificationTemplate: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  survey?: { __typename: "Survey"; id: any } | null;
  task?: { __typename: "Task"; id: any } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type NotificationPreferenceNodeFragment = {
  __typename: "NotificationPreference";
  defaultEnabled: boolean;
  editableByPatient: boolean;
  id: any;
  visible?: boolean | null;
  notificationPreferenceCategory: NotificationPreferenceCategory;
  notificationPreferenceDescription?: string | null;
  notificationPreferenceName: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type NotificationTemplateFragment = {
  __typename: "NotificationTemplate";
  id: any;
  name: string;
  notificationContents: Array<{
    __typename?: "NotificationContent";
    id: any;
    contentKind: NotificationContentKind;
    content: string;
  }>;
};

export type NotificationTemplateNodeFragment = {
  __typename: "NotificationTemplate";
  id: any;
  visible?: boolean | null;
  notificationTemplateName: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  notificationContents: Array<{
    __typename: "NotificationContent";
    contentKind: NotificationContentKind;
    content: string;
    id: any;
    visible?: boolean | null;
    notificationContentName: string;
    notificationContentLanguage: any;
    notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
    notificationContentReferencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ParameterFragment = {
  __typename: "Parameter";
  id: any;
  label: string;
  type: ParameterType;
  required: boolean;
  multiselect: boolean;
  description?: string | null;
  defaultValue?: string | null;
  editableBy: ParameterEditableBy;
};

export type ParameterMappingNodeFragment = {
  __typename: "ParameterMapping";
  name: string;
  offsetDuration?: any | null;
  offsetPeriod?: any | null;
  id: any;
  visible?: boolean | null;
  fromParameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  toParameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ParameterNodeFragment = {
  __typename: "Parameter";
  defaultValue?: string | null;
  editableBy: ParameterEditableBy;
  label: string;
  multiselect: boolean;
  required: boolean;
  type: ParameterType;
  visibleFor: ParameterVisibleFor;
  id: any;
  visible?: boolean | null;
  parameterDescription?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type PatientFragment = {
  __typename: "Patient";
  id?: any | null;
  demographicsDeleted?: any | null;
  demographics?: {
    __typename: "PatientDemographics";
    id: any;
    dateOfBirth: any;
    sexAssignedAtBirth?: SexAssignedAtBirth | null;
    firstName: string;
    lastName: string;
    patientType: PatientType;
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        id: any;
        name: string;
      };
    }>;
    person?: {
      __typename?: "Person";
      id: any;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      emailAddresses?: Array<string> | null;
      language?: any | null;
      timeZone: any;
      postalAddresses?: Array<{
        __typename?: "Address";
        id: any;
        street1: string;
        street2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: any;
      }> | null;
    } | null;
  } | null;
  courses: Array<{
    __typename: "Course";
    status: TreatmentStatus;
    id: any;
    diagnoses: Array<{ __typename: "Diagnosis"; id: any; name: string }>;
    treatmentPlanOccurrences: Array<{
      __typename: "TreatmentPlanOccurrence";
      id: any;
      status: TreatmentPlanOccurrenceStatus;
      treatmentStartDate?: any | null;
      treatmentPlan: { __typename: "TreatmentPlan"; id: any; name: string };
      surveys: Array<{
        __typename: "SurveyOccurrence";
        id: any;
        eventDate: any;
        completedAt?: any | null;
        providerAckDate?: any | null;
        survey: { __typename?: "Survey"; id: any; name?: string | null };
      }>;
      tasks: Array<{
        __typename?: "TaskOccurrence";
        id: any;
        eventDate: any;
        allDay?: boolean | null;
        status: TaskStatus;
        statusChangedAt?: any | null;
        task: { __typename?: "Task"; id: any; name: string };
      }>;
    }>;
  }>;
};

export type PatientAuditDeltaFragment = {
  __typename: "PatientAuditDelta";
  field: string;
  oldValue?: string | null;
  newValue?: string | null;
};

export type PatientCohortFragment = {
  __typename: "PatientCohort";
  id: any;
  name: string;
};

export type PatientCohortsFragment = {
  __typename: "Patient";
  cohorts: Array<{ __typename: "PatientCohort"; id: any; name: string }>;
};

export type PatientDemographicsFragment = {
  __typename: "PatientDemographics";
  id: any;
  dateOfBirth: any;
  sexAssignedAtBirth?: SexAssignedAtBirth | null;
  firstName: string;
  lastName: string;
  patientType: PatientType;
  patientIdentifiers: Array<{
    __typename?: "PatientIdentifierValue";
    value?: string | null;
    patientIdentifier: {
      __typename: "PatientIdentifier";
      id: any;
      name: string;
    };
  }>;
  person?: {
    __typename?: "Person";
    id: any;
    lastName: string;
    firstName: string;
    phoneNumber: string;
    emailAddresses?: Array<string> | null;
    language?: any | null;
    timeZone: any;
    postalAddresses?: Array<{
      __typename?: "Address";
      id: any;
      street1: string;
      street2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: any;
    }> | null;
  } | null;
};

export type PatientEdgeFragment = {
  __typename?: "PatientEdge";
  score?: number | null;
  highlights?: Array<{
    __typename?: "PatientHighlight";
    field: QueryField;
    html: string;
    details: string;
  }> | null;
  node: {
    __typename: "Patient";
    lastUpdated: any;
    id?: any | null;
    demographicsDeleted?: any | null;
    cohorts: Array<{ __typename: "PatientCohort"; id: any; name: string }>;
    enrollments?: Array<{
      __typename: "PatientEnrollment";
      id: any;
      type: EnrollmentType;
      externalId: string;
      createdAt: any;
    }> | null;
    providers?: {
      __typename?: "PatientProviders";
      courseProviderLocations: Array<{
        __typename?: "CourseProviderLocation";
        courseProviderType: CourseProviderType;
        providerLocation: {
          __typename?: "ProviderLocation";
          id: any;
          active: boolean;
          provider: { __typename?: "Provider"; id: any; fullName: string };
          practice: {
            __typename?: "Practice";
            id: any;
            name: string;
            organization?: {
              __typename?: "Organization";
              id: any;
              name: string;
            } | null;
          };
        };
      }>;
    } | null;
    moduleVisits?: {
      __typename?: "PatientModuleVisits";
      count: number;
      latestVisit: any;
    } | null;
    demographics?: {
      __typename: "PatientDemographics";
      id: any;
      dateOfBirth: any;
      sexAssignedAtBirth?: SexAssignedAtBirth | null;
      firstName: string;
      lastName: string;
      patientType: PatientType;
      patientIdentifiers: Array<{
        __typename?: "PatientIdentifierValue";
        value?: string | null;
        patientIdentifier: {
          __typename: "PatientIdentifier";
          id: any;
          name: string;
        };
      }>;
      person?: {
        __typename?: "Person";
        id: any;
        lastName: string;
        firstName: string;
        phoneNumber: string;
        emailAddresses?: Array<string> | null;
        language?: any | null;
        timeZone: any;
        postalAddresses?: Array<{
          __typename?: "Address";
          id: any;
          street1: string;
          street2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: any;
        }> | null;
      } | null;
    } | null;
    courses: Array<{
      __typename: "Course";
      status: TreatmentStatus;
      id: any;
      diagnoses: Array<{ __typename: "Diagnosis"; id: any; name: string }>;
      treatmentPlanOccurrences: Array<{
        __typename: "TreatmentPlanOccurrence";
        id: any;
        status: TreatmentPlanOccurrenceStatus;
        treatmentStartDate?: any | null;
        treatmentPlan: { __typename: "TreatmentPlan"; id: any; name: string };
        surveys: Array<{
          __typename: "SurveyOccurrence";
          id: any;
          eventDate: any;
          completedAt?: any | null;
          providerAckDate?: any | null;
          survey: { __typename?: "Survey"; id: any; name?: string | null };
        }>;
        tasks: Array<{
          __typename?: "TaskOccurrence";
          id: any;
          eventDate: any;
          allDay?: boolean | null;
          status: TaskStatus;
          statusChangedAt?: any | null;
          task: { __typename?: "Task"; id: any; name: string };
        }>;
      }>;
    }>;
  };
};

export type PatientEnrollmentFragment = {
  __typename: "PatientEnrollment";
  id: any;
  type: EnrollmentType;
  externalId: string;
  createdAt: any;
};

export type PatientEnrollmentsFragment = {
  __typename: "Patient";
  enrollments?: Array<{
    __typename: "PatientEnrollment";
    id: any;
    type: EnrollmentType;
    externalId: string;
    createdAt: any;
  }> | null;
};

export type PatientFilterSuggestionFragment = {
  __typename?: "PatientFilterSuggestion";
  text: string;
  score: number;
  field: FilterField;
  subjectType: AnythingType;
  subject?: any | null;
  subjectName: string;
  operator: SearchOperator;
  valueType: ValueType;
  value: string;
};

export type PatientHighlightFragment = {
  __typename?: "PatientHighlight";
  field: QueryField;
  html: string;
  details: string;
};

export type PatientIdentifierFragment = {
  __typename: "PatientIdentifier";
  id: any;
  name: string;
};

export type PatientProvidersFragment = {
  __typename: "PatientProviders";
  courseProviderLocations: Array<{
    __typename: "CourseProviderLocation";
    courseProviderType: CourseProviderType;
    providerLocation: {
      __typename: "ProviderLocation";
      id: any;
      active: boolean;
      provider: { __typename?: "Provider"; id: any; fullName: string };
      practice: {
        __typename: "Practice";
        id: any;
        name: string;
        organization?: {
          __typename: "Organization";
          id: any;
          name: string;
        } | null;
      };
    };
  }>;
};

export type PatientSuggestResultFragment = {
  __typename?: "PatientSuggestResult";
  filters?: Array<{
    __typename?: "PatientFilterSuggestion";
    text: string;
    score: number;
    field: FilterField;
    subjectType: AnythingType;
    subject?: any | null;
    subjectName: string;
    operator: SearchOperator;
    valueType: ValueType;
    value: string;
  }> | null;
};

export type PatientWithCoursesAndProvidersFragment = {
  __typename?: "Patient";
  courses: Array<{
    __typename: "Course";
    id: any;
    status: TreatmentStatus;
    diagnoses: Array<{ __typename: "Diagnosis"; id: any; name: string }>;
    treatmentPlanOccurrences: Array<{
      __typename: "TreatmentPlanOccurrence";
      id: any;
      status: TreatmentPlanOccurrenceStatus;
      treatmentStartDate?: any | null;
      treatmentPlan: { __typename: "TreatmentPlan"; id: any; name: string };
    }>;
  }>;
  providers?: {
    __typename: "PatientProviders";
    courseProviderLocations: Array<{
      __typename: "CourseProviderLocation";
      courseProviderType: CourseProviderType;
      providerLocation: {
        __typename: "ProviderLocation";
        id: any;
        active: boolean;
        provider: { __typename?: "Provider"; id: any; fullName: string };
        practice: {
          __typename: "Practice";
          id: any;
          name: string;
          organization?: {
            __typename: "Organization";
            id: any;
            name: string;
          } | null;
        };
      };
    }>;
  } | null;
};

export type PersonFragment = {
  __typename?: "Person";
  id: any;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  emailAddresses?: Array<string> | null;
  language?: any | null;
  timeZone: any;
  postalAddresses?: Array<{
    __typename?: "Address";
    id: any;
    street1: string;
    street2?: string | null;
    city: string;
    state: string;
    postalCode: string;
    country: any;
  }> | null;
};

export type PhaseNodeFragment = {
  __typename: "Phase";
  name: string;
  defaultDuration: any;
  id: any;
  visible?: boolean | null;
  phaseLayers: Array<{ __typename: "Layer"; id: any }>;
  phaseStartDateParameter: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  repeatCountParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  totalCountParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  displayIndexParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  phaseParameters?: Array<{
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }> | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type PlanItemNodeFragment = {
  __typename: "PlanItem";
  name: string;
  id: any;
  visible?: boolean | null;
  measure?: {
    __typename: "Measure";
    name: string;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    measureDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  treatment?: {
    __typename: "Treatment";
    name: string;
    genericName?: string | null;
    brandName?: string | null;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  calendarEventTemplates: Array<{
    __typename: "CalendarEventTemplate";
    blocking: boolean;
    category?: CalendarEventCategory | null;
    color?: any | null;
    exceptHolidays: boolean;
    icon?: string | null;
    name: string;
    patientNameOverride?: string | null;
    rescheduleStrategy?: RescheduleStrategy | null;
    timeDescriptionOverride?: string | null;
    visibleTo?: VisibleTo | null;
    id: any;
    visible?: boolean | null;
    calendarEventTemplateDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    confirmations: Array<{
      __typename: "Confirmation";
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      id: any;
      visible?: boolean | null;
      confirmationDuration: any;
      confirmationName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      parameters: Array<{ __typename: "Parameter"; id: any }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    duration?: {
      __typename?: "CalendarEventDuration";
      allDay?: boolean | null;
      length?: any | null;
    } | null;
    editableParameters: Array<{
      __typename: "EditableParameter";
      andFollowing: boolean;
      name: string;
      scopeSelector: ParameterScopeSelector;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      parameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    notifications: Array<{
      __typename: "Notification";
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      snoozeableDuration?: any | null;
      id: any;
      visible?: boolean | null;
      notificationName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmation?: { __typename: "Confirmation"; id: any } | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      groupNotificationTemplate?: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationPreference?: {
        __typename: "NotificationPreference";
        defaultEnabled: boolean;
        editableByPatient: boolean;
        id: any;
        visible?: boolean | null;
        notificationPreferenceCategory: NotificationPreferenceCategory;
        notificationPreferenceDescription?: string | null;
        notificationPreferenceName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      notificationTemplate: {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      survey?: { __typename: "Survey"; id: any } | null;
      task?: { __typename: "Task"; id: any } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    recurrenceStrategy?: {
      __typename?: "CalendarRecurrenceStrategy";
      recurrenceFrequency?: Frequency | null;
      recurrenceInterval?: number | null;
      repeatCount?: number | null;
      repeatUntilEventOccurrence?: UntilOccurrence | null;
      inheritReschedules?: boolean | null;
      repeatOnAllAllowedDays?: boolean | null;
      countUntil?: CountUntil | null;
      repeatUntilDelta?: any | null;
      recurrenceRelation?: RecurrenceRelation | null;
      repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
      repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
      repeatUntilEvent?: {
        __typename: "CalendarEventTemplate";
        id: any;
      } | null;
    } | null;
    start?: {
      __typename?: "CalendarEventStart";
      absoluteTime?: any | null;
      relativeToOccurrence?: RelativeToOccurrence | null;
      projectionDirection?: ProjectionDirection | null;
      allowedDays?: Array<any> | null;
      offsetDuration?: any | null;
      offsetPeriod?: any | null;
      dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
      timeParameter?: { __typename: "Parameter"; id: any } | null;
      relativeToEvent?: { __typename: "CalendarEventTemplate"; id: any } | null;
      allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
    } | null;
    surveys: Array<{
      __typename: "Survey";
      collectorId?: string | null;
      eventDelta: any;
      occurrenceSpecifier: OccurrenceSpecifier;
      surveyId: string;
      id: any;
      visible?: boolean | null;
      surveyDuration?: any | null;
      surveyName?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    tasks: Array<{
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    flows: Array<{
      __typename: "Flow";
      name: string;
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      restartAutomatically: boolean;
      id: any;
      visible?: boolean | null;
      flowDuration?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      steps: Array<{
        __typename: "FlowStep";
        name: string;
        uxData?: any | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      task?: {
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  flows: Array<{
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ProviderFragment = {
  __typename?: "Provider";
  id: any;
  name: Array<string>;
  locations: Array<{
    __typename?: "ProviderLocation";
    id: any;
    active: boolean;
    practice: {
      __typename?: "Practice";
      id: any;
      name: string;
      organization?: {
        __typename?: "Organization";
        id: any;
        name: string;
      } | null;
    };
    patientIdentifiers: Array<{
      __typename: "PatientIdentifier";
      description?: string | null;
      required: boolean;
      uniquePerIdentifierContext: boolean;
      id: any;
      name: string;
    }>;
  }>;
};

export type ProviderLocationFragment = {
  __typename?: "ProviderLocation";
  id: any;
  active: boolean;
  provider: {
    __typename?: "Provider";
    id: any;
    name: Array<string>;
    locations: Array<{
      __typename?: "ProviderLocation";
      id: any;
      active: boolean;
      practice: {
        __typename?: "Practice";
        id: any;
        name: string;
        organization?: {
          __typename?: "Organization";
          id: any;
          name: string;
        } | null;
      };
      patientIdentifiers: Array<{
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      }>;
    }>;
  };
  practice: {
    __typename?: "Practice";
    id: any;
    name: string;
    organization?: {
      __typename?: "Organization";
      id: any;
      name: string;
    } | null;
  };
  patientLanguages?: Array<{
    __typename?: "AvailableLanguage";
    name: string;
    tag: any;
  }> | null;
};

export type QuestionNodeFragment = {
  __typename: "Question";
  name: string;
  id: any;
  visible?: boolean | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  questionNotificationTemplate?: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  rootMeasure: {
    __typename: "Measure";
    name: string;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    measureDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  prototypeMeasures: Array<{
    __typename: "Measure";
    name: string;
    abbreviation?: string | null;
    id: any;
    visible?: boolean | null;
    measureDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ResourceCategoryNodeFragment = {
  __typename: "ResourceCategory";
  categoryWeight: number;
  name: string;
  id: any;
  visible?: boolean | null;
  resourceCategoryDescription: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ResourceNodeFragment = {
  __typename: "Resource";
  name: string;
  id: any;
  visible?: boolean | null;
  resourceDescription: string;
  resourceUrl?: any | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ResourceReferenceNodeFragment = {
  __typename: "ResourceReference";
  globallyVisible: boolean;
  name: string;
  resourceWeight: number;
  id: any;
  visible?: boolean | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  resource: {
    __typename: "Resource";
    name: string;
    id: any;
    visible?: boolean | null;
    resourceDescription: string;
    resourceUrl?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDocs: Array<{
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  resourceCategory: {
    __typename: "ResourceCategory";
    categoryWeight: number;
    name: string;
    id: any;
    visible?: boolean | null;
    resourceCategoryDescription: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  };
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type ScenarioFragment = {
  __typename: "Scenario";
  id: string;
  name: string;
  description: string;
  variables?: Array<{
    __typename: "ScenarioVariable";
    name: string;
    description: string;
    type: ScenarioVariableType;
    required: boolean;
  }> | null;
};

export type StaticCohortFragment = {
  __typename: "StaticCohort";
  id: any;
  name: string;
};

export type SurveyNodeFragment = {
  __typename: "Survey";
  collectorId?: string | null;
  eventDelta: any;
  occurrenceSpecifier: OccurrenceSpecifier;
  surveyId: string;
  id: any;
  visible?: boolean | null;
  surveyDuration?: any | null;
  surveyName?: string | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type SurveyOccurrenceFragment = {
  __typename: "SurveyOccurrence";
  id: any;
  eventDate: any;
  completedAt?: any | null;
  providerAckDate?: any | null;
  survey: { __typename?: "Survey"; id: any; name?: string | null };
};

export type TaskNodeFragment = {
  __typename: "Task";
  occurrenceSpecifier: OccurrenceSpecifier;
  eventDelta: any;
  id: any;
  visible?: boolean | null;
  taskCategory: TaskCategory;
  taskDescription?: string | null;
  taskName: string;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type TreatmentFragment = {
  __typename: "Treatment";
  id: any;
  name: string;
  genericName?: string | null;
  brandName?: string | null;
  treatmentDateField?: TreatmentFieldOption | null;
  detailsField?: TreatmentFieldOption | null;
};

export type TreatmentListNodeFragment = {
  __typename: "TreatmentList";
  name: string;
  key: string;
  id: any;
  visible?: boolean | null;
  items?: Array<{
    __typename: "PlanItem";
    name: string;
    id: any;
    visible?: boolean | null;
    measure?: {
      __typename: "Measure";
      name: string;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      measureDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    treatment?: {
      __typename: "Treatment";
      name: string;
      genericName?: string | null;
      brandName?: string | null;
      abbreviation?: string | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    calendarEventTemplates: Array<{
      __typename: "CalendarEventTemplate";
      blocking: boolean;
      category?: CalendarEventCategory | null;
      color?: any | null;
      exceptHolidays: boolean;
      icon?: string | null;
      name: string;
      patientNameOverride?: string | null;
      rescheduleStrategy?: RescheduleStrategy | null;
      timeDescriptionOverride?: string | null;
      visibleTo?: VisibleTo | null;
      id: any;
      visible?: boolean | null;
      calendarEventTemplateDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      confirmations: Array<{
        __typename: "Confirmation";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        id: any;
        visible?: boolean | null;
        confirmationDuration: any;
        confirmationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{ __typename: "Parameter"; id: any }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      duration?: {
        __typename?: "CalendarEventDuration";
        allDay?: boolean | null;
        length?: any | null;
      } | null;
      editableParameters: Array<{
        __typename: "EditableParameter";
        andFollowing: boolean;
        name: string;
        scopeSelector: ParameterScopeSelector;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      notifications: Array<{
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      recurrenceStrategy?: {
        __typename?: "CalendarRecurrenceStrategy";
        recurrenceFrequency?: Frequency | null;
        recurrenceInterval?: number | null;
        repeatCount?: number | null;
        repeatUntilEventOccurrence?: UntilOccurrence | null;
        inheritReschedules?: boolean | null;
        repeatOnAllAllowedDays?: boolean | null;
        countUntil?: CountUntil | null;
        repeatUntilDelta?: any | null;
        recurrenceRelation?: RecurrenceRelation | null;
        repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
        repeatUntilEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
      } | null;
      start?: {
        __typename?: "CalendarEventStart";
        absoluteTime?: any | null;
        relativeToOccurrence?: RelativeToOccurrence | null;
        projectionDirection?: ProjectionDirection | null;
        allowedDays?: Array<any> | null;
        offsetDuration?: any | null;
        offsetPeriod?: any | null;
        dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
        timeParameter?: { __typename: "Parameter"; id: any } | null;
        relativeToEvent?: {
          __typename: "CalendarEventTemplate";
          id: any;
        } | null;
        allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
      } | null;
      surveys: Array<{
        __typename: "Survey";
        collectorId?: string | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        surveyId: string;
        id: any;
        visible?: boolean | null;
        surveyDuration?: any | null;
        surveyName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      tasks: Array<{
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    flows: Array<{
      __typename: "Flow";
      name: string;
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      restartAutomatically: boolean;
      id: any;
      visible?: boolean | null;
      flowDuration?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      steps: Array<{
        __typename: "FlowStep";
        name: string;
        uxData?: any | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      task?: {
        __typename: "Task";
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        id: any;
        visible?: boolean | null;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }> | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type TreatmentNodeFragment = {
  __typename: "Treatment";
  name: string;
  genericName?: string | null;
  brandName?: string | null;
  abbreviation?: string | null;
  id: any;
  visible?: boolean | null;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type TreatmentPlanFragment = {
  __typename: "TreatmentPlan";
  id: any;
  name: string;
  description?: string | null;
  protocolName?: string | null;
  initialView?: PlanView | null;
  media: Array<{
    __typename: "ContentMedia";
    id: any;
    context?: ContentMediaContext | null;
    mimeType: string;
    url?: any | null;
  }>;
  onboardingFlow?: {
    __typename?: "Flow";
    id: any;
    steps: Array<{
      __typename?: "FlowStep";
      id: any;
      presentation:
        | { __typename?: "ContentDoc"; id: any }
        | {
            __typename?: "Notification";
            id: any;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              name: string;
              notificationContents: Array<{
                __typename?: "NotificationContent";
                id: any;
                contentKind: NotificationContentKind;
                content: string;
              }>;
            };
          }
        | { __typename?: "Survey"; id: any };
    }>;
  } | null;
  patientIdentifiers: Array<{
    __typename: "PatientIdentifier";
    description?: string | null;
    required: boolean;
    uniquePerIdentifierContext: boolean;
    id: any;
    name: string;
  }>;
};

export type TreatmentPlanNodeFragment = {
  __typename: "TreatmentPlan";
  name: string;
  description?: string | null;
  protocolName?: string | null;
  patientNameOverride?: string | null;
  initialView?: PlanView | null;
  id: any;
  visible?: boolean | null;
  rootLayer?: { __typename: "Layer"; id: any } | null;
  initialTasks: Array<{
    __typename: "Task";
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    id: any;
    visible?: boolean | null;
    taskCategory: TaskCategory;
    taskDescription?: string | null;
    taskName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  invitationTemplate?: {
    __typename: "NotificationTemplate";
    id: any;
    visible?: boolean | null;
    notificationTemplateName: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    notificationContents: Array<{
      __typename: "NotificationContent";
      contentKind: NotificationContentKind;
      content: string;
      id: any;
      visible?: boolean | null;
      notificationContentName: string;
      notificationContentLanguage: any;
      notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
      notificationContentReferencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  startDateParameter?: {
    __typename: "Parameter";
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
    label: string;
    multiselect: boolean;
    required: boolean;
    type: ParameterType;
    visibleFor: ParameterVisibleFor;
    id: any;
    visible?: boolean | null;
    parameterDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  contentDocs: Array<{
    __typename: "ContentDoc";
    useContentJson: boolean;
    language: any;
    slug?: string | null;
    url?: any | null;
    previewImageUrl?: any | null;
    id: any;
    visible?: boolean | null;
    contentDocTitle?: string | null;
    contentDocExternalId?: any | null;
    contentDocContext: string;
    docContext: {
      __typename: "ContentDocContext";
      name: string;
      slug?: string | null;
      description?: string | null;
      exampleImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      breakpointBase?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointSmall?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointMedium?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      breakpointExtraLarge?: {
        __typename?: "ContentDocContainerSizes";
        minWidthPx?: number | null;
        widthPx?: number | null;
        maxWidthPx?: number | null;
      } | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
    referencesMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referencesQuestions: Array<{ __typename: "Question"; id: any }>;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  contentMedia: Array<{
    __typename: "ContentMedia";
    title: string;
    language: any;
    mimeType: string;
    url?: any | null;
    id: any;
    visible?: boolean | null;
    contentMediaContext?: ContentMediaContext | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  excludedModules: Array<{
    __typename: "Module";
    name: string;
    shortName: string;
    ordering: number;
    jumbo: boolean;
    id: any;
    visible?: boolean | null;
    moduleDescription: string;
    moduleSlug: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDoc?: {
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  includedModules: Array<{
    __typename: "Module";
    name: string;
    shortName: string;
    ordering: number;
    jumbo: boolean;
    id: any;
    visible?: boolean | null;
    moduleDescription: string;
    moduleSlug: string;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    contentDoc?: {
      __typename: "ContentDoc";
      useContentJson: boolean;
      language: any;
      slug?: string | null;
      url?: any | null;
      previewImageUrl?: any | null;
      id: any;
      visible?: boolean | null;
      contentDocTitle?: string | null;
      contentDocExternalId?: any | null;
      contentDocContext: string;
      docContext: {
        __typename: "ContentDocContext";
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
      referencesMedia: Array<{
        __typename: "ContentMedia";
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        id: any;
        visible?: boolean | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referencesQuestions: Array<{ __typename: "Question"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    contentMedia: Array<{
      __typename: "ContentMedia";
      title: string;
      language: any;
      mimeType: string;
      url?: any | null;
      id: any;
      visible?: boolean | null;
      contentMediaContext?: ContentMediaContext | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  layers: Array<{
    __typename: "Layer";
    name: string;
    id: any;
    visible?: boolean | null;
    layerDescription?: string | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    enabledParameter?: {
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    layerGroups: Array<{
      __typename: "LayerGroup";
      name: string;
      required: boolean;
      multiple: boolean;
      id: any;
      visible?: boolean | null;
      layerGroupDescription?: string | null;
      layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameters: Array<{
      __typename: "Parameter";
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
      label: string;
      multiselect: boolean;
      required: boolean;
      type: ParameterType;
      visibleFor: ParameterVisibleFor;
      id: any;
      visible?: boolean | null;
      parameterDescription?: string | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    parameterMappings: Array<{
      __typename: "ParameterMapping";
      name: string;
      offsetDuration?: any | null;
      offsetPeriod?: any | null;
      id: any;
      visible?: boolean | null;
      fromParameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      toParameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    phases: Array<{
      __typename: "Phase";
      name: string;
      defaultDuration: any;
      id: any;
      visible?: boolean | null;
      phaseLayers: Array<{ __typename: "Layer"; id: any }>;
      phaseStartDateParameter: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      };
      repeatCountParameter?: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      totalCountParameter?: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      displayIndexParameter?: {
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      phaseParameters?: Array<{
        __typename: "Parameter";
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        id: any;
        visible?: boolean | null;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }> | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    planItems: Array<{
      __typename: "PlanItem";
      name: string;
      id: any;
      visible?: boolean | null;
      measure?: {
        __typename: "Measure";
        name: string;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        measureDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      treatment?: {
        __typename: "Treatment";
        name: string;
        genericName?: string | null;
        brandName?: string | null;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      calendarEventTemplates: Array<{
        __typename: "CalendarEventTemplate";
        blocking: boolean;
        category?: CalendarEventCategory | null;
        color?: any | null;
        exceptHolidays: boolean;
        icon?: string | null;
        name: string;
        patientNameOverride?: string | null;
        rescheduleStrategy?: RescheduleStrategy | null;
        timeDescriptionOverride?: string | null;
        visibleTo?: VisibleTo | null;
        id: any;
        visible?: boolean | null;
        calendarEventTemplateDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmations: Array<{
          __typename: "Confirmation";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          id: any;
          visible?: boolean | null;
          confirmationDuration: any;
          confirmationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{ __typename: "Parameter"; id: any }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        duration?: {
          __typename?: "CalendarEventDuration";
          allDay?: boolean | null;
          length?: any | null;
        } | null;
        editableParameters: Array<{
          __typename: "EditableParameter";
          andFollowing: boolean;
          name: string;
          scopeSelector: ParameterScopeSelector;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        notifications: Array<{
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        recurrenceStrategy?: {
          __typename?: "CalendarRecurrenceStrategy";
          recurrenceFrequency?: Frequency | null;
          recurrenceInterval?: number | null;
          repeatCount?: number | null;
          repeatUntilEventOccurrence?: UntilOccurrence | null;
          inheritReschedules?: boolean | null;
          repeatOnAllAllowedDays?: boolean | null;
          countUntil?: CountUntil | null;
          repeatUntilDelta?: any | null;
          recurrenceRelation?: RecurrenceRelation | null;
          repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
        } | null;
        start?: {
          __typename?: "CalendarEventStart";
          absoluteTime?: any | null;
          relativeToOccurrence?: RelativeToOccurrence | null;
          projectionDirection?: ProjectionDirection | null;
          allowedDays?: Array<any> | null;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
          timeParameter?: { __typename: "Parameter"; id: any } | null;
          relativeToEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
          allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
        } | null;
        surveys: Array<{
          __typename: "Survey";
          collectorId?: string | null;
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          surveyId: string;
          id: any;
          visible?: boolean | null;
          surveyDuration?: any | null;
          surveyName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        tasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  resourceReferences: Array<{
    __typename: "ResourceReference";
    globallyVisible: boolean;
    name: string;
    resourceWeight: number;
    id: any;
    visible?: boolean | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    resource: {
      __typename: "Resource";
      name: string;
      id: any;
      visible?: boolean | null;
      resourceDescription: string;
      resourceUrl?: any | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      contentDocs: Array<{
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    resourceCategory: {
      __typename: "ResourceCategory";
      categoryWeight: number;
      name: string;
      id: any;
      visible?: boolean | null;
      resourceCategoryDescription: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    };
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  treatmentLists: Array<{
    __typename: "TreatmentList";
    name: string;
    key: string;
    id: any;
    visible?: boolean | null;
    items?: Array<{
      __typename: "PlanItem";
      name: string;
      id: any;
      visible?: boolean | null;
      measure?: {
        __typename: "Measure";
        name: string;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        measureDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      treatment?: {
        __typename: "Treatment";
        name: string;
        genericName?: string | null;
        brandName?: string | null;
        abbreviation?: string | null;
        id: any;
        visible?: boolean | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      calendarEventTemplates: Array<{
        __typename: "CalendarEventTemplate";
        blocking: boolean;
        category?: CalendarEventCategory | null;
        color?: any | null;
        exceptHolidays: boolean;
        icon?: string | null;
        name: string;
        patientNameOverride?: string | null;
        rescheduleStrategy?: RescheduleStrategy | null;
        timeDescriptionOverride?: string | null;
        visibleTo?: VisibleTo | null;
        id: any;
        visible?: boolean | null;
        calendarEventTemplateDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmations: Array<{
          __typename: "Confirmation";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          id: any;
          visible?: boolean | null;
          confirmationDuration: any;
          confirmationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{ __typename: "Parameter"; id: any }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        duration?: {
          __typename?: "CalendarEventDuration";
          allDay?: boolean | null;
          length?: any | null;
        } | null;
        editableParameters: Array<{
          __typename: "EditableParameter";
          andFollowing: boolean;
          name: string;
          scopeSelector: ParameterScopeSelector;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        notifications: Array<{
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        recurrenceStrategy?: {
          __typename?: "CalendarRecurrenceStrategy";
          recurrenceFrequency?: Frequency | null;
          recurrenceInterval?: number | null;
          repeatCount?: number | null;
          repeatUntilEventOccurrence?: UntilOccurrence | null;
          inheritReschedules?: boolean | null;
          repeatOnAllAllowedDays?: boolean | null;
          countUntil?: CountUntil | null;
          repeatUntilDelta?: any | null;
          recurrenceRelation?: RecurrenceRelation | null;
          repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
        } | null;
        start?: {
          __typename?: "CalendarEventStart";
          absoluteTime?: any | null;
          relativeToOccurrence?: RelativeToOccurrence | null;
          projectionDirection?: ProjectionDirection | null;
          allowedDays?: Array<any> | null;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
          timeParameter?: { __typename: "Parameter"; id: any } | null;
          relativeToEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
          allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
        } | null;
        surveys: Array<{
          __typename: "Survey";
          collectorId?: string | null;
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          surveyId: string;
          id: any;
          visible?: boolean | null;
          surveyDuration?: any | null;
          surveyName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        tasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      flows: Array<{
        __typename: "Flow";
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        id: any;
        visible?: boolean | null;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }>;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }> | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  }>;
  alternativeNames: Array<{
    __typename: "AlternativeNameEntry";
    key: AlternativeName;
    value: string;
  }>;
  onboardingFlow?: {
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  notificationResponseFlow?: {
    __typename: "Flow";
    name: string;
    occurrenceSpecifier: OccurrenceSpecifier;
    eventDelta: any;
    restartAutomatically: boolean;
    id: any;
    visible?: boolean | null;
    flowDuration?: any | null;
    alternativeNames: Array<{
      __typename: "AlternativeNameEntry";
      key: AlternativeName;
      value: string;
    }>;
    steps: Array<{
      __typename: "FlowStep";
      name: string;
      uxData?: any | null;
      id: any;
      visible?: boolean | null;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      nextStep?: { __typename?: "FlowStep"; id: any } | null;
      notification?: {
        __typename: "Notification";
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        id: any;
        visible?: boolean | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      contentDoc?: {
        __typename: "ContentDoc";
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        id: any;
        visible?: boolean | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      } | null;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    }>;
    task?: {
      __typename: "Task";
      occurrenceSpecifier: OccurrenceSpecifier;
      eventDelta: any;
      id: any;
      visible?: boolean | null;
      taskCategory: TaskCategory;
      taskDescription?: string | null;
      taskName: string;
      alternativeNames: Array<{
        __typename: "AlternativeNameEntry";
        key: AlternativeName;
        value: string;
      }>;
      referenceInfo: {
        __typename: "NodeReferenceInfo";
        id: any;
        containers: Array<{
          __typename?: "NodeReferenceContainer";
          id: any;
          nodeType: NodeType;
          name: string;
        }>;
      };
    } | null;
    referenceInfo: {
      __typename: "NodeReferenceInfo";
      id: any;
      containers: Array<{
        __typename?: "NodeReferenceContainer";
        id: any;
        nodeType: NodeType;
        name: string;
      }>;
    };
  } | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Brand_Fragment = {
  __typename: "Brand";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_CalendarEventTemplate_Fragment = {
  __typename: "CalendarEventTemplate";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Confirmation_Fragment = {
  __typename: "Confirmation";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_ContentDoc_Fragment = {
  __typename: "ContentDoc";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_ContentDocContext_Fragment = {
  __typename: "ContentDocContext";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_ContentMedia_Fragment = {
  __typename: "ContentMedia";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Diagnosis_Fragment = {
  __typename: "Diagnosis";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_EditableParameter_Fragment = {
  __typename: "EditableParameter";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Flow_Fragment = {
  __typename: "Flow";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_FlowStep_Fragment = {
  __typename: "FlowStep";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Layer_Fragment = {
  __typename: "Layer";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_LayerGroup_Fragment = {
  __typename: "LayerGroup";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Measure_Fragment = {
  __typename: "Measure";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Module_Fragment = {
  __typename: "Module";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Notification_Fragment = {
  __typename: "Notification";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_NotificationContent_Fragment = {
  __typename: "NotificationContent";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_NotificationPreference_Fragment = {
  __typename: "NotificationPreference";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_NotificationTemplate_Fragment = {
  __typename: "NotificationTemplate";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Parameter_Fragment = {
  __typename: "Parameter";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_ParameterMapping_Fragment = {
  __typename: "ParameterMapping";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Phase_Fragment = {
  __typename: "Phase";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_PlanItem_Fragment = {
  __typename: "PlanItem";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Question_Fragment = {
  __typename: "Question";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Resource_Fragment = {
  __typename: "Resource";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_ResourceCategory_Fragment = {
  __typename: "ResourceCategory";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_ResourceReference_Fragment = {
  __typename: "ResourceReference";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Survey_Fragment = {
  __typename: "Survey";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Task_Fragment = {
  __typename: "Task";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_Treatment_Fragment = {
  __typename: "Treatment";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_TreatmentList_Fragment = {
  __typename: "TreatmentList";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

type TreatmentPlanNodeInterface_TreatmentPlan_Fragment = {
  __typename: "TreatmentPlan";
  id: any;
  visible?: boolean | null;
  referenceInfo: {
    __typename: "NodeReferenceInfo";
    id: any;
    containers: Array<{
      __typename?: "NodeReferenceContainer";
      id: any;
      nodeType: NodeType;
      name: string;
    }>;
  };
};

export type TreatmentPlanNodeInterfaceFragment =
  | TreatmentPlanNodeInterface_Brand_Fragment
  | TreatmentPlanNodeInterface_CalendarEventTemplate_Fragment
  | TreatmentPlanNodeInterface_Confirmation_Fragment
  | TreatmentPlanNodeInterface_ContentDoc_Fragment
  | TreatmentPlanNodeInterface_ContentDocContext_Fragment
  | TreatmentPlanNodeInterface_ContentMedia_Fragment
  | TreatmentPlanNodeInterface_Diagnosis_Fragment
  | TreatmentPlanNodeInterface_EditableParameter_Fragment
  | TreatmentPlanNodeInterface_Flow_Fragment
  | TreatmentPlanNodeInterface_FlowStep_Fragment
  | TreatmentPlanNodeInterface_Layer_Fragment
  | TreatmentPlanNodeInterface_LayerGroup_Fragment
  | TreatmentPlanNodeInterface_Measure_Fragment
  | TreatmentPlanNodeInterface_Module_Fragment
  | TreatmentPlanNodeInterface_Notification_Fragment
  | TreatmentPlanNodeInterface_NotificationContent_Fragment
  | TreatmentPlanNodeInterface_NotificationPreference_Fragment
  | TreatmentPlanNodeInterface_NotificationTemplate_Fragment
  | TreatmentPlanNodeInterface_Parameter_Fragment
  | TreatmentPlanNodeInterface_ParameterMapping_Fragment
  | TreatmentPlanNodeInterface_Phase_Fragment
  | TreatmentPlanNodeInterface_PlanItem_Fragment
  | TreatmentPlanNodeInterface_Question_Fragment
  | TreatmentPlanNodeInterface_Resource_Fragment
  | TreatmentPlanNodeInterface_ResourceCategory_Fragment
  | TreatmentPlanNodeInterface_ResourceReference_Fragment
  | TreatmentPlanNodeInterface_Survey_Fragment
  | TreatmentPlanNodeInterface_Task_Fragment
  | TreatmentPlanNodeInterface_Treatment_Fragment
  | TreatmentPlanNodeInterface_TreatmentList_Fragment
  | TreatmentPlanNodeInterface_TreatmentPlan_Fragment;

export type TreatmentPlanOccurrenceFragment = {
  __typename: "TreatmentPlanOccurrence";
  id: any;
  treatmentStartDate?: any | null;
  course: { __typename: "Course"; id: any };
  parameterValues: Array<{
    __typename: "TreatmentPlanParameterValue";
    id: any;
    transient: boolean;
    value?: string | null;
    parameter: {
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    };
    scope?: { __typename?: "CalendarEventScope"; string: string } | null;
  } | null>;
  treatmentPlan: {
    __typename: "TreatmentPlan";
    id: any;
    name: string;
    description?: string | null;
    protocolName?: string | null;
    initialView?: PlanView | null;
    media: Array<{
      __typename: "ContentMedia";
      id: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
    onboardingFlow?: {
      __typename?: "Flow";
      id: any;
      steps: Array<{
        __typename?: "FlowStep";
        id: any;
        presentation:
          | { __typename?: "ContentDoc"; id: any }
          | {
              __typename?: "Notification";
              id: any;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                name: string;
                notificationContents: Array<{
                  __typename?: "NotificationContent";
                  id: any;
                  contentKind: NotificationContentKind;
                  content: string;
                }>;
              };
            }
          | { __typename?: "Survey"; id: any };
      }>;
    } | null;
    patientIdentifiers: Array<{
      __typename: "PatientIdentifier";
      description?: string | null;
      required: boolean;
      uniquePerIdentifierContext: boolean;
      id: any;
      name: string;
    }>;
  };
  patientIdentifiers: Array<{
    __typename?: "PatientIdentifierValue";
    value?: string | null;
    patientIdentifier: {
      __typename: "PatientIdentifier";
      description?: string | null;
      required: boolean;
      uniquePerIdentifierContext: boolean;
      id: any;
      name: string;
    };
  }>;
};

export type TreatmentPlanParameterValueFragment = {
  __typename: "TreatmentPlanParameterValue";
  id: any;
  transient: boolean;
  value?: string | null;
  parameter: {
    __typename: "Parameter";
    id: any;
    label: string;
    type: ParameterType;
    required: boolean;
    multiselect: boolean;
    description?: string | null;
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
  };
  scope?: { __typename?: "CalendarEventScope"; string: string } | null;
};

export type TreatmentPlanSettingsFragment = {
  __typename: "TreatmentPlan";
  rootLayer?: {
    __typename: "Layer";
    id: any;
    name: string;
    description?: string | null;
    parameters: Array<{
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    }>;
    layerGroups: Array<{
      __typename: "LayerGroup";
      id: any;
      name: string;
      description?: string | null;
      required: boolean;
      multiple: boolean;
      layers: Array<{ __typename?: "Layer"; id: any }>;
    }>;
    enabledParameter?: {
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    } | null;
  } | null;
  layers: Array<{
    __typename: "Layer";
    id: any;
    name: string;
    description?: string | null;
    parameters: Array<{
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    }>;
    layerGroups: Array<{
      __typename: "LayerGroup";
      id: any;
      name: string;
      description?: string | null;
      required: boolean;
      multiple: boolean;
      layers: Array<{ __typename?: "Layer"; id: any }>;
    }>;
    enabledParameter?: {
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    } | null;
  }>;
  startDateParameter?: {
    __typename: "Parameter";
    id: any;
    label: string;
    type: ParameterType;
    required: boolean;
    multiselect: boolean;
    description?: string | null;
    defaultValue?: string | null;
    editableBy: ParameterEditableBy;
  } | null;
  invitationTemplate?: { __typename?: "NotificationTemplate"; id: any } | null;
};

export type UserInfoFragment = {
  __typename: "UserInfo";
  isAuthenticated?: boolean | null;
  name?: string | null;
  username?: string | null;
  canEnroll: boolean;
  canSeePhi: boolean;
  shouldNotSeePromoContent: boolean;
};

export type UserInfoProviderFragment = {
  __typename?: "UserInfo";
  provider?: {
    __typename: "Provider";
    id: any;
    name: Array<string>;
    locations: Array<{
      __typename: "ProviderLocation";
      id: any;
      practice: {
        __typename: "Practice";
        id: any;
        name: string;
        organization?: {
          __typename: "Organization";
          id: any;
          name: string;
        } | null;
      };
    }>;
  } | null;
};

export type AcknowledgeOverdueSurveyMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
  patientId: Scalars["UUID"];
  courseId: Scalars["UUID"];
  acknowledge: Scalars["Boolean"];
}>;

export type AcknowledgeOverdueSurveyMutation = {
  __typename?: "Mutation";
  acknowledgeOverdueSurvey: {
    __typename: "PatientDemographics";
    id: any;
    dateOfBirth: any;
    sexAssignedAtBirth?: SexAssignedAtBirth | null;
    firstName: string;
    lastName: string;
    patientType: PatientType;
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        id: any;
        name: string;
      };
    }>;
    person?: {
      __typename?: "Person";
      id: any;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      emailAddresses?: Array<string> | null;
      language?: any | null;
      timeZone: any;
      postalAddresses?: Array<{
        __typename?: "Address";
        id: any;
        street1: string;
        street2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: any;
      }> | null;
    } | null;
  };
};

export type AddPatientIdentifierMutationVariables = Exact<{
  providerLocationId: Scalars["UUID"];
  patientIdentifierId: Scalars["UUID"];
  correlationId: Scalars["UUID"];
}>;

export type AddPatientIdentifierMutation = {
  __typename?: "Mutation";
  addPatientIdentifier: {
    __typename?: "ProviderLocation";
    id: any;
    active: boolean;
    provider: {
      __typename?: "Provider";
      id: any;
      name: Array<string>;
      locations: Array<{
        __typename?: "ProviderLocation";
        id: any;
        active: boolean;
        practice: {
          __typename?: "Practice";
          id: any;
          name: string;
          organization?: {
            __typename?: "Organization";
            id: any;
            name: string;
          } | null;
        };
        patientIdentifiers: Array<{
          __typename: "PatientIdentifier";
          description?: string | null;
          required: boolean;
          uniquePerIdentifierContext: boolean;
          id: any;
          name: string;
        }>;
      }>;
    };
    practice: {
      __typename?: "Practice";
      id: any;
      name: string;
      organization?: {
        __typename?: "Organization";
        id: any;
        name: string;
      } | null;
    };
    patientLanguages?: Array<{
      __typename?: "AvailableLanguage";
      name: string;
      tag: any;
    }> | null;
  };
};

export type AddPatientsToStaticCohortMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  patientIds: Array<Scalars["UUID"]> | Scalars["UUID"];
  staticCohortId: Scalars["UUID"];
}>;

export type AddPatientsToStaticCohortMutation = {
  __typename?: "Mutation";
  addPatientsToStaticCohort: {
    __typename: "StaticCohort";
    id: any;
    name: string;
  };
};

export type CreateAndEnrollPatientMutationVariables = Exact<{
  request: CreateAndEnrollPatient;
}>;

export type CreateAndEnrollPatientMutation = {
  __typename?: "Mutation";
  createAndEnrollPatient: {
    __typename?: "CreateAndEnrollOutput";
    patient: {
      __typename: "PatientDemographics";
      id: any;
      dateOfBirth: any;
      sexAssignedAtBirth?: SexAssignedAtBirth | null;
      firstName: string;
      lastName: string;
      patientType: PatientType;
      patientIdentifiers: Array<{
        __typename?: "PatientIdentifierValue";
        value?: string | null;
        patientIdentifier: {
          __typename: "PatientIdentifier";
          id: any;
          name: string;
        };
      }>;
      person?: {
        __typename?: "Person";
        id: any;
        lastName: string;
        firstName: string;
        phoneNumber: string;
        emailAddresses?: Array<string> | null;
        language?: any | null;
        timeZone: any;
        postalAddresses?: Array<{
          __typename?: "Address";
          id: any;
          street1: string;
          street2?: string | null;
          city: string;
          state: string;
          postalCode: string;
          country: any;
        }> | null;
      } | null;
    };
    diagnosis?: {
      __typename: "DiagnosisOccurrence";
      id: any;
      patientId: any;
      dateOfDiagnosis?: any | null;
      courseId: any;
      diagnosis: { __typename: "Diagnosis"; id: any; name: string };
    } | null;
    matching?: {
      __typename?: "MatchResults";
      courseId: any;
      matches: Array<{
        __typename: "MatchOccurrence";
        id: any;
        courseId: any;
        treatmentPlanId: any;
        name: string;
        description?: string | null;
        score: number;
      }>;
    } | null;
    enrollment?: {
      __typename?: "Enrollment";
      enrollmentId: any;
      enrollmentType: EnrollmentType;
      patientFirstName: string;
      patientLastName: string;
      courseId: any;
    } | null;
    plan?: {
      __typename: "TreatmentPlanOccurrence";
      id: any;
      treatmentStartDate?: any | null;
      course: { __typename: "Course"; id: any };
      parameterValues: Array<{
        __typename: "TreatmentPlanParameterValue";
        id: any;
        transient: boolean;
        value?: string | null;
        parameter: {
          __typename: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        };
        scope?: { __typename?: "CalendarEventScope"; string: string } | null;
      } | null>;
      treatmentPlan: {
        __typename: "TreatmentPlan";
        id: any;
        name: string;
        description?: string | null;
        protocolName?: string | null;
        initialView?: PlanView | null;
        media: Array<{
          __typename: "ContentMedia";
          id: any;
          context?: ContentMediaContext | null;
          mimeType: string;
          url?: any | null;
        }>;
        onboardingFlow?: {
          __typename?: "Flow";
          id: any;
          steps: Array<{
            __typename?: "FlowStep";
            id: any;
            presentation:
              | { __typename?: "ContentDoc"; id: any }
              | {
                  __typename?: "Notification";
                  id: any;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    name: string;
                    notificationContents: Array<{
                      __typename?: "NotificationContent";
                      id: any;
                      contentKind: NotificationContentKind;
                      content: string;
                    }>;
                  };
                }
              | { __typename?: "Survey"; id: any };
          }>;
        } | null;
        patientIdentifiers: Array<{
          __typename: "PatientIdentifier";
          description?: string | null;
          required: boolean;
          uniquePerIdentifierContext: boolean;
          id: any;
          name: string;
        }>;
      };
      patientIdentifiers: Array<{
        __typename?: "PatientIdentifierValue";
        value?: string | null;
        patientIdentifier: {
          __typename: "PatientIdentifier";
          description?: string | null;
          required: boolean;
          uniquePerIdentifierContext: boolean;
          id: any;
          name: string;
        };
      }>;
    } | null;
  };
};

export type CreateMatchRuleMutationVariables = Exact<{
  matchRule: CreateMatchRule;
}>;

export type CreateMatchRuleMutation = {
  __typename?: "Mutation";
  createMatchRule: {
    __typename?: "MatchRule";
    id: any;
    providerLocation: { __typename?: "ProviderLocation"; id: any };
    diagnosis: { __typename?: "Diagnosis"; id: any };
    treatmentPlan: { __typename?: "TreatmentPlan"; id: any };
  };
};

export type CreateStaticPatientCohortMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  name: Scalars["String"];
  organizationId: Scalars["UUID"];
}>;

export type CreateStaticPatientCohortMutation = {
  __typename?: "Mutation";
  createStaticPatientCohort: {
    __typename: "StaticCohort";
    id: any;
    name: string;
  };
};

export type DeletePatientDemographicsMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  patientId: Scalars["UUID"];
}>;

export type DeletePatientDemographicsMutation = {
  __typename?: "Mutation";
  deletePatientDemographics: any;
};

export type DisableMatchRuleMutationVariables = Exact<{
  matchRuleId: Scalars["UUID"];
  correlationId?: InputMaybe<Scalars["UUID"]>;
}>;

export type DisableMatchRuleMutation = {
  __typename?: "Mutation";
  disableMatchRule: any;
};

export type EditNodesMutationVariables = Exact<{
  correlationId?: InputMaybe<Scalars["UUID"]>;
  deltas: Array<NodeEditDelta> | NodeEditDelta;
}>;

export type EditNodesMutation = {
  __typename?: "Mutation";
  editNodes: Array<
    | {
        __typename: "Brand";
        id: any;
        visible?: boolean | null;
        name: string;
        domainPrefix?: string | null;
        emailFromName?: string | null;
        emailFromAddress?: string | null;
        smsSenderId?: any | null;
        designTokens?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "CalendarEventTemplate";
        id: any;
        visible?: boolean | null;
        blocking: boolean;
        category?: CalendarEventCategory | null;
        color?: any | null;
        exceptHolidays: boolean;
        icon?: string | null;
        name: string;
        patientNameOverride?: string | null;
        rescheduleStrategy?: RescheduleStrategy | null;
        timeDescriptionOverride?: string | null;
        visibleTo?: VisibleTo | null;
        calendarEventTemplateDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmations: Array<{
          __typename: "Confirmation";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          id: any;
          visible?: boolean | null;
          confirmationDuration: any;
          confirmationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{ __typename: "Parameter"; id: any }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        duration?: {
          __typename?: "CalendarEventDuration";
          allDay?: boolean | null;
          length?: any | null;
        } | null;
        editableParameters: Array<{
          __typename: "EditableParameter";
          andFollowing: boolean;
          name: string;
          scopeSelector: ParameterScopeSelector;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        notifications: Array<{
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        recurrenceStrategy?: {
          __typename?: "CalendarRecurrenceStrategy";
          recurrenceFrequency?: Frequency | null;
          recurrenceInterval?: number | null;
          repeatCount?: number | null;
          repeatUntilEventOccurrence?: UntilOccurrence | null;
          inheritReschedules?: boolean | null;
          repeatOnAllAllowedDays?: boolean | null;
          countUntil?: CountUntil | null;
          repeatUntilDelta?: any | null;
          recurrenceRelation?: RecurrenceRelation | null;
          repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
        } | null;
        start?: {
          __typename?: "CalendarEventStart";
          absoluteTime?: any | null;
          relativeToOccurrence?: RelativeToOccurrence | null;
          projectionDirection?: ProjectionDirection | null;
          allowedDays?: Array<any> | null;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
          timeParameter?: { __typename: "Parameter"; id: any } | null;
          relativeToEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
          allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
        } | null;
        surveys: Array<{
          __typename: "Survey";
          collectorId?: string | null;
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          surveyId: string;
          id: any;
          visible?: boolean | null;
          surveyDuration?: any | null;
          surveyName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        tasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Confirmation";
        id: any;
        visible?: boolean | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        confirmationDuration: any;
        confirmationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{ __typename: "Parameter"; id: any }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ContentDoc";
        id: any;
        visible?: boolean | null;
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ContentDocContext";
        id: any;
        visible?: boolean | null;
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ContentMedia";
        id: any;
        visible?: boolean | null;
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Diagnosis";
        id: any;
        visible?: boolean | null;
        name: string;
        diagnosisDescription?: string | null;
        diagnosisSlug?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        resourceReferences: Array<{
          __typename: "ResourceReference";
          globallyVisible: boolean;
          name: string;
          resourceWeight: number;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          resource: {
            __typename: "Resource";
            name: string;
            id: any;
            visible?: boolean | null;
            resourceDescription: string;
            resourceUrl?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          resourceCategory: {
            __typename: "ResourceCategory";
            categoryWeight: number;
            name: string;
            id: any;
            visible?: boolean | null;
            resourceCategoryDescription: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "EditableParameter";
        id: any;
        visible?: boolean | null;
        andFollowing: boolean;
        name: string;
        scopeSelector: ParameterScopeSelector;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Flow";
        id: any;
        visible?: boolean | null;
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "FlowStep";
        id: any;
        visible?: boolean | null;
        name: string;
        uxData?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Layer";
        id: any;
        visible?: boolean | null;
        name: string;
        layerDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        enabledParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        layerGroups: Array<{
          __typename: "LayerGroup";
          name: string;
          required: boolean;
          multiple: boolean;
          id: any;
          visible?: boolean | null;
          layerGroupDescription?: string | null;
          layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameterMappings: Array<{
          __typename: "ParameterMapping";
          name: string;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          id: any;
          visible?: boolean | null;
          fromParameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          toParameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        phases: Array<{
          __typename: "Phase";
          name: string;
          defaultDuration: any;
          id: any;
          visible?: boolean | null;
          phaseLayers: Array<{ __typename: "Layer"; id: any }>;
          phaseStartDateParameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          repeatCountParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          totalCountParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          displayIndexParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          phaseParameters?: Array<{
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }> | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        planItems: Array<{
          __typename: "PlanItem";
          name: string;
          id: any;
          visible?: boolean | null;
          measure?: {
            __typename: "Measure";
            name: string;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            measureDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          treatment?: {
            __typename: "Treatment";
            name: string;
            genericName?: string | null;
            brandName?: string | null;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          calendarEventTemplates: Array<{
            __typename: "CalendarEventTemplate";
            blocking: boolean;
            category?: CalendarEventCategory | null;
            color?: any | null;
            exceptHolidays: boolean;
            icon?: string | null;
            name: string;
            patientNameOverride?: string | null;
            rescheduleStrategy?: RescheduleStrategy | null;
            timeDescriptionOverride?: string | null;
            visibleTo?: VisibleTo | null;
            id: any;
            visible?: boolean | null;
            calendarEventTemplateDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmations: Array<{
              __typename: "Confirmation";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              id: any;
              visible?: boolean | null;
              confirmationDuration: any;
              confirmationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameters: Array<{ __typename: "Parameter"; id: any }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            duration?: {
              __typename?: "CalendarEventDuration";
              allDay?: boolean | null;
              length?: any | null;
            } | null;
            editableParameters: Array<{
              __typename: "EditableParameter";
              andFollowing: boolean;
              name: string;
              scopeSelector: ParameterScopeSelector;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameter: {
                __typename: "Parameter";
                defaultValue?: string | null;
                editableBy: ParameterEditableBy;
                label: string;
                multiselect: boolean;
                required: boolean;
                type: ParameterType;
                visibleFor: ParameterVisibleFor;
                id: any;
                visible?: boolean | null;
                parameterDescription?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            notifications: Array<{
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            recurrenceStrategy?: {
              __typename?: "CalendarRecurrenceStrategy";
              recurrenceFrequency?: Frequency | null;
              recurrenceInterval?: number | null;
              repeatCount?: number | null;
              repeatUntilEventOccurrence?: UntilOccurrence | null;
              inheritReschedules?: boolean | null;
              repeatOnAllAllowedDays?: boolean | null;
              countUntil?: CountUntil | null;
              repeatUntilDelta?: any | null;
              recurrenceRelation?: RecurrenceRelation | null;
              repeatCountParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
            } | null;
            start?: {
              __typename?: "CalendarEventStart";
              absoluteTime?: any | null;
              relativeToOccurrence?: RelativeToOccurrence | null;
              projectionDirection?: ProjectionDirection | null;
              allowedDays?: Array<any> | null;
              offsetDuration?: any | null;
              offsetPeriod?: any | null;
              dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
              timeParameter?: { __typename: "Parameter"; id: any } | null;
              relativeToEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
              allowedDaysParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
            } | null;
            surveys: Array<{
              __typename: "Survey";
              collectorId?: string | null;
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              surveyId: string;
              id: any;
              visible?: boolean | null;
              surveyDuration?: any | null;
              surveyName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            tasks: Array<{
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          flows: Array<{
            __typename: "Flow";
            name: string;
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            restartAutomatically: boolean;
            id: any;
            visible?: boolean | null;
            flowDuration?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            steps: Array<{
              __typename: "FlowStep";
              name: string;
              uxData?: any | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              nextStep?: { __typename?: "FlowStep"; id: any } | null;
              notification?: {
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              contentDoc?: {
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            task?: {
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "LayerGroup";
        id: any;
        visible?: boolean | null;
        name: string;
        required: boolean;
        multiple: boolean;
        layerGroupDescription?: string | null;
        layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Measure";
        id: any;
        visible?: boolean | null;
        name: string;
        abbreviation?: string | null;
        measureDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Module";
        id: any;
        visible?: boolean | null;
        name: string;
        shortName: string;
        ordering: number;
        jumbo: boolean;
        moduleDescription: string;
        moduleSlug: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Notification";
        id: any;
        visible?: boolean | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "NotificationContent";
        id: any;
        visible?: boolean | null;
        contentKind: NotificationContentKind;
        content: string;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "NotificationPreference";
        id: any;
        visible?: boolean | null;
        defaultEnabled: boolean;
        editableByPatient: boolean;
        notificationPreferenceCategory: NotificationPreferenceCategory;
        notificationPreferenceDescription?: string | null;
        notificationPreferenceName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Parameter";
        id: any;
        visible?: boolean | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ParameterMapping";
        id: any;
        visible?: boolean | null;
        name: string;
        offsetDuration?: any | null;
        offsetPeriod?: any | null;
        fromParameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        toParameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Phase";
        id: any;
        visible?: boolean | null;
        name: string;
        defaultDuration: any;
        phaseLayers: Array<{ __typename: "Layer"; id: any }>;
        phaseStartDateParameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        repeatCountParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        totalCountParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        displayIndexParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        phaseParameters?: Array<{
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }> | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "PlanItem";
        id: any;
        visible?: boolean | null;
        name: string;
        measure?: {
          __typename: "Measure";
          name: string;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          measureDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        treatment?: {
          __typename: "Treatment";
          name: string;
          genericName?: string | null;
          brandName?: string | null;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        calendarEventTemplates: Array<{
          __typename: "CalendarEventTemplate";
          blocking: boolean;
          category?: CalendarEventCategory | null;
          color?: any | null;
          exceptHolidays: boolean;
          icon?: string | null;
          name: string;
          patientNameOverride?: string | null;
          rescheduleStrategy?: RescheduleStrategy | null;
          timeDescriptionOverride?: string | null;
          visibleTo?: VisibleTo | null;
          id: any;
          visible?: boolean | null;
          calendarEventTemplateDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmations: Array<{
            __typename: "Confirmation";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            id: any;
            visible?: boolean | null;
            confirmationDuration: any;
            confirmationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            parameters: Array<{ __typename: "Parameter"; id: any }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          duration?: {
            __typename?: "CalendarEventDuration";
            allDay?: boolean | null;
            length?: any | null;
          } | null;
          editableParameters: Array<{
            __typename: "EditableParameter";
            andFollowing: boolean;
            name: string;
            scopeSelector: ParameterScopeSelector;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            parameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          notifications: Array<{
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          recurrenceStrategy?: {
            __typename?: "CalendarRecurrenceStrategy";
            recurrenceFrequency?: Frequency | null;
            recurrenceInterval?: number | null;
            repeatCount?: number | null;
            repeatUntilEventOccurrence?: UntilOccurrence | null;
            inheritReschedules?: boolean | null;
            repeatOnAllAllowedDays?: boolean | null;
            countUntil?: CountUntil | null;
            repeatUntilDelta?: any | null;
            recurrenceRelation?: RecurrenceRelation | null;
            repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
            repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
            repeatUntilEvent?: {
              __typename: "CalendarEventTemplate";
              id: any;
            } | null;
          } | null;
          start?: {
            __typename?: "CalendarEventStart";
            absoluteTime?: any | null;
            relativeToOccurrence?: RelativeToOccurrence | null;
            projectionDirection?: ProjectionDirection | null;
            allowedDays?: Array<any> | null;
            offsetDuration?: any | null;
            offsetPeriod?: any | null;
            dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
            timeParameter?: { __typename: "Parameter"; id: any } | null;
            relativeToEvent?: {
              __typename: "CalendarEventTemplate";
              id: any;
            } | null;
            allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
          } | null;
          surveys: Array<{
            __typename: "Survey";
            collectorId?: string | null;
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            surveyId: string;
            id: any;
            visible?: boolean | null;
            surveyDuration?: any | null;
            surveyName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          tasks: Array<{
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          flows: Array<{
            __typename: "Flow";
            name: string;
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            restartAutomatically: boolean;
            id: any;
            visible?: boolean | null;
            flowDuration?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            steps: Array<{
              __typename: "FlowStep";
              name: string;
              uxData?: any | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              nextStep?: { __typename?: "FlowStep"; id: any } | null;
              notification?: {
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              contentDoc?: {
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            task?: {
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Question";
        id: any;
        visible?: boolean | null;
        name: string;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        questionNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        rootMeasure: {
          __typename: "Measure";
          name: string;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          measureDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        prototypeMeasures: Array<{
          __typename: "Measure";
          name: string;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          measureDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Resource";
        id: any;
        visible?: boolean | null;
        name: string;
        resourceDescription: string;
        resourceUrl?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ResourceCategory";
        id: any;
        visible?: boolean | null;
        categoryWeight: number;
        name: string;
        resourceCategoryDescription: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ResourceReference";
        id: any;
        visible?: boolean | null;
        globallyVisible: boolean;
        name: string;
        resourceWeight: number;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        resource: {
          __typename: "Resource";
          name: string;
          id: any;
          visible?: boolean | null;
          resourceDescription: string;
          resourceUrl?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        resourceCategory: {
          __typename: "ResourceCategory";
          categoryWeight: number;
          name: string;
          id: any;
          visible?: boolean | null;
          resourceCategoryDescription: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Survey";
        id: any;
        visible?: boolean | null;
        collectorId?: string | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        surveyId: string;
        surveyDuration?: any | null;
        surveyName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Task";
        id: any;
        visible?: boolean | null;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Treatment";
        id: any;
        visible?: boolean | null;
        name: string;
        genericName?: string | null;
        brandName?: string | null;
        abbreviation?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "TreatmentList";
        id: any;
        visible?: boolean | null;
        name: string;
        key: string;
        items?: Array<{
          __typename: "PlanItem";
          name: string;
          id: any;
          visible?: boolean | null;
          measure?: {
            __typename: "Measure";
            name: string;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            measureDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          treatment?: {
            __typename: "Treatment";
            name: string;
            genericName?: string | null;
            brandName?: string | null;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          calendarEventTemplates: Array<{
            __typename: "CalendarEventTemplate";
            blocking: boolean;
            category?: CalendarEventCategory | null;
            color?: any | null;
            exceptHolidays: boolean;
            icon?: string | null;
            name: string;
            patientNameOverride?: string | null;
            rescheduleStrategy?: RescheduleStrategy | null;
            timeDescriptionOverride?: string | null;
            visibleTo?: VisibleTo | null;
            id: any;
            visible?: boolean | null;
            calendarEventTemplateDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmations: Array<{
              __typename: "Confirmation";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              id: any;
              visible?: boolean | null;
              confirmationDuration: any;
              confirmationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameters: Array<{ __typename: "Parameter"; id: any }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            duration?: {
              __typename?: "CalendarEventDuration";
              allDay?: boolean | null;
              length?: any | null;
            } | null;
            editableParameters: Array<{
              __typename: "EditableParameter";
              andFollowing: boolean;
              name: string;
              scopeSelector: ParameterScopeSelector;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameter: {
                __typename: "Parameter";
                defaultValue?: string | null;
                editableBy: ParameterEditableBy;
                label: string;
                multiselect: boolean;
                required: boolean;
                type: ParameterType;
                visibleFor: ParameterVisibleFor;
                id: any;
                visible?: boolean | null;
                parameterDescription?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            notifications: Array<{
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            recurrenceStrategy?: {
              __typename?: "CalendarRecurrenceStrategy";
              recurrenceFrequency?: Frequency | null;
              recurrenceInterval?: number | null;
              repeatCount?: number | null;
              repeatUntilEventOccurrence?: UntilOccurrence | null;
              inheritReschedules?: boolean | null;
              repeatOnAllAllowedDays?: boolean | null;
              countUntil?: CountUntil | null;
              repeatUntilDelta?: any | null;
              recurrenceRelation?: RecurrenceRelation | null;
              repeatCountParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
            } | null;
            start?: {
              __typename?: "CalendarEventStart";
              absoluteTime?: any | null;
              relativeToOccurrence?: RelativeToOccurrence | null;
              projectionDirection?: ProjectionDirection | null;
              allowedDays?: Array<any> | null;
              offsetDuration?: any | null;
              offsetPeriod?: any | null;
              dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
              timeParameter?: { __typename: "Parameter"; id: any } | null;
              relativeToEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
              allowedDaysParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
            } | null;
            surveys: Array<{
              __typename: "Survey";
              collectorId?: string | null;
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              surveyId: string;
              id: any;
              visible?: boolean | null;
              surveyDuration?: any | null;
              surveyName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            tasks: Array<{
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          flows: Array<{
            __typename: "Flow";
            name: string;
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            restartAutomatically: boolean;
            id: any;
            visible?: boolean | null;
            flowDuration?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            steps: Array<{
              __typename: "FlowStep";
              name: string;
              uxData?: any | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              nextStep?: { __typename?: "FlowStep"; id: any } | null;
              notification?: {
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              contentDoc?: {
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            task?: {
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }> | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "TreatmentPlan";
        id: any;
        visible?: boolean | null;
        name: string;
        description?: string | null;
        protocolName?: string | null;
        patientNameOverride?: string | null;
        initialView?: PlanView | null;
        rootLayer?: { __typename: "Layer"; id: any } | null;
        initialTasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        invitationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        startDateParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        excludedModules: Array<{
          __typename: "Module";
          name: string;
          shortName: string;
          ordering: number;
          jumbo: boolean;
          id: any;
          visible?: boolean | null;
          moduleDescription: string;
          moduleSlug: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        includedModules: Array<{
          __typename: "Module";
          name: string;
          shortName: string;
          ordering: number;
          jumbo: boolean;
          id: any;
          visible?: boolean | null;
          moduleDescription: string;
          moduleSlug: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        layers: Array<{
          __typename: "Layer";
          name: string;
          id: any;
          visible?: boolean | null;
          layerDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          enabledParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          layerGroups: Array<{
            __typename: "LayerGroup";
            name: string;
            required: boolean;
            multiple: boolean;
            id: any;
            visible?: boolean | null;
            layerGroupDescription?: string | null;
            layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameterMappings: Array<{
            __typename: "ParameterMapping";
            name: string;
            offsetDuration?: any | null;
            offsetPeriod?: any | null;
            id: any;
            visible?: boolean | null;
            fromParameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            toParameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          phases: Array<{
            __typename: "Phase";
            name: string;
            defaultDuration: any;
            id: any;
            visible?: boolean | null;
            phaseLayers: Array<{ __typename: "Layer"; id: any }>;
            phaseStartDateParameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            repeatCountParameter?: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            totalCountParameter?: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            displayIndexParameter?: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            phaseParameters?: Array<{
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }> | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          planItems: Array<{
            __typename: "PlanItem";
            name: string;
            id: any;
            visible?: boolean | null;
            measure?: {
              __typename: "Measure";
              name: string;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              measureDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            treatment?: {
              __typename: "Treatment";
              name: string;
              genericName?: string | null;
              brandName?: string | null;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            calendarEventTemplates: Array<{
              __typename: "CalendarEventTemplate";
              blocking: boolean;
              category?: CalendarEventCategory | null;
              color?: any | null;
              exceptHolidays: boolean;
              icon?: string | null;
              name: string;
              patientNameOverride?: string | null;
              rescheduleStrategy?: RescheduleStrategy | null;
              timeDescriptionOverride?: string | null;
              visibleTo?: VisibleTo | null;
              id: any;
              visible?: boolean | null;
              calendarEventTemplateDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmations: Array<{
                __typename: "Confirmation";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                id: any;
                visible?: boolean | null;
                confirmationDuration: any;
                confirmationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameters: Array<{ __typename: "Parameter"; id: any }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              duration?: {
                __typename?: "CalendarEventDuration";
                allDay?: boolean | null;
                length?: any | null;
              } | null;
              editableParameters: Array<{
                __typename: "EditableParameter";
                andFollowing: boolean;
                name: string;
                scopeSelector: ParameterScopeSelector;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameter: {
                  __typename: "Parameter";
                  defaultValue?: string | null;
                  editableBy: ParameterEditableBy;
                  label: string;
                  multiselect: boolean;
                  required: boolean;
                  type: ParameterType;
                  visibleFor: ParameterVisibleFor;
                  id: any;
                  visible?: boolean | null;
                  parameterDescription?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              notifications: Array<{
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              recurrenceStrategy?: {
                __typename?: "CalendarRecurrenceStrategy";
                recurrenceFrequency?: Frequency | null;
                recurrenceInterval?: number | null;
                repeatCount?: number | null;
                repeatUntilEventOccurrence?: UntilOccurrence | null;
                inheritReschedules?: boolean | null;
                repeatOnAllAllowedDays?: boolean | null;
                countUntil?: CountUntil | null;
                repeatUntilDelta?: any | null;
                recurrenceRelation?: RecurrenceRelation | null;
                repeatCountParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
              } | null;
              start?: {
                __typename?: "CalendarEventStart";
                absoluteTime?: any | null;
                relativeToOccurrence?: RelativeToOccurrence | null;
                projectionDirection?: ProjectionDirection | null;
                allowedDays?: Array<any> | null;
                offsetDuration?: any | null;
                offsetPeriod?: any | null;
                dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
                timeParameter?: { __typename: "Parameter"; id: any } | null;
                relativeToEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
                allowedDaysParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
              } | null;
              surveys: Array<{
                __typename: "Survey";
                collectorId?: string | null;
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                surveyId: string;
                id: any;
                visible?: boolean | null;
                surveyDuration?: any | null;
                surveyName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              tasks: Array<{
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              flows: Array<{
                __typename: "Flow";
                name: string;
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                restartAutomatically: boolean;
                id: any;
                visible?: boolean | null;
                flowDuration?: any | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                steps: Array<{
                  __typename: "FlowStep";
                  name: string;
                  uxData?: any | null;
                  id: any;
                  visible?: boolean | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  nextStep?: { __typename?: "FlowStep"; id: any } | null;
                  notification?: {
                    __typename: "Notification";
                    eventDelta: any;
                    occurrenceSpecifier: OccurrenceSpecifier;
                    snoozeableDuration?: any | null;
                    id: any;
                    visible?: boolean | null;
                    notificationName?: string | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    confirmation?: {
                      __typename: "Confirmation";
                      id: any;
                    } | null;
                    contentDocs: Array<{
                      __typename: "ContentDoc";
                      useContentJson: boolean;
                      language: any;
                      slug?: string | null;
                      url?: any | null;
                      previewImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentDocTitle?: string | null;
                      contentDocExternalId?: any | null;
                      contentDocContext: string;
                      docContext: {
                        __typename: "ContentDocContext";
                        name: string;
                        slug?: string | null;
                        description?: string | null;
                        exampleImageUrl?: any | null;
                        id: any;
                        visible?: boolean | null;
                        breakpointBase?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointSmall?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointMedium?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointExtraLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      };
                      referencesDocs: Array<{
                        __typename: "ContentDoc";
                        id: any;
                      }>;
                      referencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referencesQuestions: Array<{
                        __typename: "Question";
                        id: any;
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    contentMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    groupNotificationTemplate?: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationPreference?: {
                      __typename: "NotificationPreference";
                      defaultEnabled: boolean;
                      editableByPatient: boolean;
                      id: any;
                      visible?: boolean | null;
                      notificationPreferenceCategory: NotificationPreferenceCategory;
                      notificationPreferenceDescription?: string | null;
                      notificationPreferenceName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationTemplate: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    survey?: { __typename: "Survey"; id: any } | null;
                    task?: { __typename: "Task"; id: any } | null;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  contentDoc?: {
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                task?: {
                  __typename: "Task";
                  occurrenceSpecifier: OccurrenceSpecifier;
                  eventDelta: any;
                  id: any;
                  visible?: boolean | null;
                  taskCategory: TaskCategory;
                  taskDescription?: string | null;
                  taskName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        resourceReferences: Array<{
          __typename: "ResourceReference";
          globallyVisible: boolean;
          name: string;
          resourceWeight: number;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          resource: {
            __typename: "Resource";
            name: string;
            id: any;
            visible?: boolean | null;
            resourceDescription: string;
            resourceUrl?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          resourceCategory: {
            __typename: "ResourceCategory";
            categoryWeight: number;
            name: string;
            id: any;
            visible?: boolean | null;
            resourceCategoryDescription: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        treatmentLists: Array<{
          __typename: "TreatmentList";
          name: string;
          key: string;
          id: any;
          visible?: boolean | null;
          items?: Array<{
            __typename: "PlanItem";
            name: string;
            id: any;
            visible?: boolean | null;
            measure?: {
              __typename: "Measure";
              name: string;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              measureDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            treatment?: {
              __typename: "Treatment";
              name: string;
              genericName?: string | null;
              brandName?: string | null;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            calendarEventTemplates: Array<{
              __typename: "CalendarEventTemplate";
              blocking: boolean;
              category?: CalendarEventCategory | null;
              color?: any | null;
              exceptHolidays: boolean;
              icon?: string | null;
              name: string;
              patientNameOverride?: string | null;
              rescheduleStrategy?: RescheduleStrategy | null;
              timeDescriptionOverride?: string | null;
              visibleTo?: VisibleTo | null;
              id: any;
              visible?: boolean | null;
              calendarEventTemplateDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmations: Array<{
                __typename: "Confirmation";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                id: any;
                visible?: boolean | null;
                confirmationDuration: any;
                confirmationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameters: Array<{ __typename: "Parameter"; id: any }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              duration?: {
                __typename?: "CalendarEventDuration";
                allDay?: boolean | null;
                length?: any | null;
              } | null;
              editableParameters: Array<{
                __typename: "EditableParameter";
                andFollowing: boolean;
                name: string;
                scopeSelector: ParameterScopeSelector;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameter: {
                  __typename: "Parameter";
                  defaultValue?: string | null;
                  editableBy: ParameterEditableBy;
                  label: string;
                  multiselect: boolean;
                  required: boolean;
                  type: ParameterType;
                  visibleFor: ParameterVisibleFor;
                  id: any;
                  visible?: boolean | null;
                  parameterDescription?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              notifications: Array<{
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              recurrenceStrategy?: {
                __typename?: "CalendarRecurrenceStrategy";
                recurrenceFrequency?: Frequency | null;
                recurrenceInterval?: number | null;
                repeatCount?: number | null;
                repeatUntilEventOccurrence?: UntilOccurrence | null;
                inheritReschedules?: boolean | null;
                repeatOnAllAllowedDays?: boolean | null;
                countUntil?: CountUntil | null;
                repeatUntilDelta?: any | null;
                recurrenceRelation?: RecurrenceRelation | null;
                repeatCountParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
              } | null;
              start?: {
                __typename?: "CalendarEventStart";
                absoluteTime?: any | null;
                relativeToOccurrence?: RelativeToOccurrence | null;
                projectionDirection?: ProjectionDirection | null;
                allowedDays?: Array<any> | null;
                offsetDuration?: any | null;
                offsetPeriod?: any | null;
                dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
                timeParameter?: { __typename: "Parameter"; id: any } | null;
                relativeToEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
                allowedDaysParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
              } | null;
              surveys: Array<{
                __typename: "Survey";
                collectorId?: string | null;
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                surveyId: string;
                id: any;
                visible?: boolean | null;
                surveyDuration?: any | null;
                surveyName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              tasks: Array<{
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              flows: Array<{
                __typename: "Flow";
                name: string;
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                restartAutomatically: boolean;
                id: any;
                visible?: boolean | null;
                flowDuration?: any | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                steps: Array<{
                  __typename: "FlowStep";
                  name: string;
                  uxData?: any | null;
                  id: any;
                  visible?: boolean | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  nextStep?: { __typename?: "FlowStep"; id: any } | null;
                  notification?: {
                    __typename: "Notification";
                    eventDelta: any;
                    occurrenceSpecifier: OccurrenceSpecifier;
                    snoozeableDuration?: any | null;
                    id: any;
                    visible?: boolean | null;
                    notificationName?: string | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    confirmation?: {
                      __typename: "Confirmation";
                      id: any;
                    } | null;
                    contentDocs: Array<{
                      __typename: "ContentDoc";
                      useContentJson: boolean;
                      language: any;
                      slug?: string | null;
                      url?: any | null;
                      previewImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentDocTitle?: string | null;
                      contentDocExternalId?: any | null;
                      contentDocContext: string;
                      docContext: {
                        __typename: "ContentDocContext";
                        name: string;
                        slug?: string | null;
                        description?: string | null;
                        exampleImageUrl?: any | null;
                        id: any;
                        visible?: boolean | null;
                        breakpointBase?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointSmall?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointMedium?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointExtraLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      };
                      referencesDocs: Array<{
                        __typename: "ContentDoc";
                        id: any;
                      }>;
                      referencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referencesQuestions: Array<{
                        __typename: "Question";
                        id: any;
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    contentMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    groupNotificationTemplate?: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationPreference?: {
                      __typename: "NotificationPreference";
                      defaultEnabled: boolean;
                      editableByPatient: boolean;
                      id: any;
                      visible?: boolean | null;
                      notificationPreferenceCategory: NotificationPreferenceCategory;
                      notificationPreferenceDescription?: string | null;
                      notificationPreferenceName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationTemplate: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    survey?: { __typename: "Survey"; id: any } | null;
                    task?: { __typename: "Task"; id: any } | null;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  contentDoc?: {
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                task?: {
                  __typename: "Task";
                  occurrenceSpecifier: OccurrenceSpecifier;
                  eventDelta: any;
                  id: any;
                  visible?: boolean | null;
                  taskCategory: TaskCategory;
                  taskDescription?: string | null;
                  taskName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }> | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        onboardingFlow?: {
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationResponseFlow?: {
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
  >;
};

export type ImportPatientCsvMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  upload: Scalars["Upload"];
  mapping?: InputMaybe<Scalars["String"]>;
  importRows?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type ImportPatientCsvMutation = {
  __typename?: "Mutation";
  importPatientCsv: Array<{
    __typename?: "CreateAndEnrollOutput";
    patient: { __typename?: "PatientDemographics"; id: any };
  }>;
};

export type PreviewCsvImportMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  upload: Scalars["Upload"];
  mapping?: InputMaybe<Scalars["String"]>;
}>;

export type PreviewCsvImportMutation = {
  __typename?: "Mutation";
  previewCsvImport?: {
    __typename?: "CsvMappingPreview";
    mappingError?: string | null;
    rowMappings: Array<{
      __typename?: "CsvRowMapping";
      rowNumber?: number | null;
      mappedFields: Array<{
        __typename?: "CsvFieldMapping";
        mappedField?: string | null;
        mappedValue?: string | null;
        error?: string | null;
      }>;
      importResult?: {
        __typename?: "CsvRowMappingOutcome";
        error?: string | null;
        patientStatus?: CsvRowPatientStatus | null;
      } | null;
    }>;
  } | null;
};

export type RemovePatientIdentifierMutationVariables = Exact<{
  providerLocationId: Scalars["UUID"];
  patientIdentifierId: Scalars["UUID"];
  correlationId: Scalars["UUID"];
}>;

export type RemovePatientIdentifierMutation = {
  __typename?: "Mutation";
  removePatientIdentifier: {
    __typename?: "ProviderLocation";
    id: any;
    active: boolean;
    provider: {
      __typename?: "Provider";
      id: any;
      name: Array<string>;
      locations: Array<{
        __typename?: "ProviderLocation";
        id: any;
        active: boolean;
        practice: {
          __typename?: "Practice";
          id: any;
          name: string;
          organization?: {
            __typename?: "Organization";
            id: any;
            name: string;
          } | null;
        };
        patientIdentifiers: Array<{
          __typename: "PatientIdentifier";
          description?: string | null;
          required: boolean;
          uniquePerIdentifierContext: boolean;
          id: any;
          name: string;
        }>;
      }>;
    };
    practice: {
      __typename?: "Practice";
      id: any;
      name: string;
      organization?: {
        __typename?: "Organization";
        id: any;
        name: string;
      } | null;
    };
    patientLanguages?: Array<{
      __typename?: "AvailableLanguage";
      name: string;
      tag: any;
    }> | null;
  };
};

export type RemovePatientsFromStaticCohortMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  patientIds: Array<Scalars["UUID"]> | Scalars["UUID"];
  staticCohortId: Scalars["UUID"];
}>;

export type RemovePatientsFromStaticCohortMutation = {
  __typename?: "Mutation";
  removePatientsFromStaticCohort: {
    __typename: "StaticCohort";
    id: any;
    name: string;
  };
};

export type RemoveTreatmentPlanOccurrenceMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  treatmentPlanOccurrenceId: Scalars["UUID"];
}>;

export type RemoveTreatmentPlanOccurrenceMutation = {
  __typename?: "Mutation";
  removeTreatmentPlanOccurrence: { __typename: "Course"; id: any };
};

export type ResendNotificationForOccurrenceMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
  type: NotificationResendRelation;
  patientId: Scalars["UUID"];
  courseId: Scalars["UUID"];
}>;

export type ResendNotificationForOccurrenceMutation = {
  __typename?: "Mutation";
  resendNotificationForOccurrence: {
    __typename: "PatientDemographics";
    id: any;
    dateOfBirth: any;
    sexAssignedAtBirth?: SexAssignedAtBirth | null;
    firstName: string;
    lastName: string;
    patientType: PatientType;
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        id: any;
        name: string;
      };
    }>;
    person?: {
      __typename?: "Person";
      id: any;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      emailAddresses?: Array<string> | null;
      language?: any | null;
      timeZone: any;
      postalAddresses?: Array<{
        __typename?: "Address";
        id: any;
        street1: string;
        street2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: any;
      }> | null;
    } | null;
  };
};

export type RetriggerEnrollmentSmsMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
}>;

export type RetriggerEnrollmentSmsMutation = {
  __typename?: "Mutation";
  retriggerEnrollmentSms: {
    __typename: "PatientDemographics";
    id: any;
    dateOfBirth: any;
    sexAssignedAtBirth?: SexAssignedAtBirth | null;
    firstName: string;
    lastName: string;
    patientType: PatientType;
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        id: any;
        name: string;
      };
    }>;
    person?: {
      __typename?: "Person";
      id: any;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      emailAddresses?: Array<string> | null;
      language?: any | null;
      timeZone: any;
      postalAddresses?: Array<{
        __typename?: "Address";
        id: any;
        street1: string;
        street2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: any;
      }> | null;
    } | null;
  };
};

export type RunScenarioMutationVariables = Exact<{
  scenario: ScenarioRunInput;
}>;

export type RunScenarioMutation = {
  __typename?: "Mutation";
  runScenario: {
    __typename: "ScenarioRunOutput";
    id: string;
    events?: string | null;
    variableValues: Array<{
      __typename: "ScenarioVariableOutput";
      name: string;
      value: string;
    }>;
  };
};

export type SendTemplatizedMessageMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  notificationTemplateId: Scalars["UUID"];
  patientIds: Array<Scalars["UUID"]> | Scalars["UUID"];
}>;

export type SendTemplatizedMessageMutation = {
  __typename?: "Mutation";
  sendTemplatizedMessage: Array<{
    __typename?: "PatientDemographics";
    id: any;
  }>;
};

export type UpdateParameterValuesMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  treatmentPlanOccurrenceId: Scalars["UUID"];
  parameterValues:
    | Array<TreatmentPlanParameterValueInput>
    | TreatmentPlanParameterValueInput;
}>;

export type UpdateParameterValuesMutation = {
  __typename?: "Mutation";
  updateParameterValues: {
    __typename: "TreatmentPlanOccurrence";
    id: any;
    treatmentStartDate?: any | null;
    course: { __typename: "Course"; id: any };
    parameterValues: Array<{
      __typename: "TreatmentPlanParameterValue";
      id: any;
      transient: boolean;
      value?: string | null;
      parameter: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      scope?: { __typename?: "CalendarEventScope"; string: string } | null;
    } | null>;
    treatmentPlan: {
      __typename: "TreatmentPlan";
      id: any;
      name: string;
      description?: string | null;
      protocolName?: string | null;
      initialView?: PlanView | null;
      media: Array<{
        __typename: "ContentMedia";
        id: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
      onboardingFlow?: {
        __typename?: "Flow";
        id: any;
        steps: Array<{
          __typename?: "FlowStep";
          id: any;
          presentation:
            | { __typename?: "ContentDoc"; id: any }
            | {
                __typename?: "Notification";
                id: any;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  name: string;
                  notificationContents: Array<{
                    __typename?: "NotificationContent";
                    id: any;
                    contentKind: NotificationContentKind;
                    content: string;
                  }>;
                };
              }
            | { __typename?: "Survey"; id: any };
        }>;
      } | null;
      patientIdentifiers: Array<{
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      }>;
    };
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      };
    }>;
  };
};

export type UpdatePatientDemographicsMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
  demographics: PatientDemographicsInput;
  treatingProviderLocationId?: InputMaybe<Scalars["UUID"]>;
}>;

export type UpdatePatientDemographicsMutation = {
  __typename?: "Mutation";
  updatePatientDemographics: {
    __typename: "PatientDemographics";
    id: any;
    dateOfBirth: any;
    sexAssignedAtBirth?: SexAssignedAtBirth | null;
    firstName: string;
    lastName: string;
    patientType: PatientType;
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        id: any;
        name: string;
      };
    }>;
    person?: {
      __typename?: "Person";
      id: any;
      lastName: string;
      firstName: string;
      phoneNumber: string;
      emailAddresses?: Array<string> | null;
      language?: any | null;
      timeZone: any;
      postalAddresses?: Array<{
        __typename?: "Address";
        id: any;
        street1: string;
        street2?: string | null;
        city: string;
        state: string;
        postalCode: string;
        country: any;
      }> | null;
    } | null;
  };
};

export type UpdatePersonMutationVariables = Exact<{
  correlationId: Scalars["UUID"];
  id: Scalars["UUID"];
  person: PersonInput;
}>;

export type UpdatePersonMutation = {
  __typename?: "Mutation";
  updatePerson?: {
    __typename?: "Person";
    id: any;
    lastName: string;
    firstName: string;
    phoneNumber: string;
    emailAddresses?: Array<string> | null;
    language?: any | null;
    timeZone: any;
    postalAddresses?: Array<{
      __typename?: "Address";
      id: any;
      street1: string;
      street2?: string | null;
      city: string;
      state: string;
      postalCode: string;
      country: any;
    }> | null;
  } | null;
};

export type ActiveTreatmentPlanOccurrencesQueryVariables = Exact<{
  courseId: Scalars["UUID"];
}>;

export type ActiveTreatmentPlanOccurrencesQuery = {
  __typename?: "Query";
  activeTreatmentPlanOccurrences: Array<{
    __typename: "TreatmentPlanOccurrence";
    id: any;
    treatmentStartDate?: any | null;
    treatmentPlan: {
      __typename: "TreatmentPlan";
      id: any;
      name: string;
      description?: string | null;
      protocolName?: string | null;
      initialView?: PlanView | null;
      rootLayer?: {
        __typename: "Layer";
        id: any;
        name: string;
        description?: string | null;
        parameters: Array<{
          __typename: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        }>;
        layerGroups: Array<{
          __typename: "LayerGroup";
          id: any;
          name: string;
          description?: string | null;
          required: boolean;
          multiple: boolean;
          layers: Array<{ __typename?: "Layer"; id: any }>;
        }>;
        enabledParameter?: {
          __typename: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        } | null;
      } | null;
      layers: Array<{
        __typename: "Layer";
        id: any;
        name: string;
        description?: string | null;
        parameters: Array<{
          __typename: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        }>;
        layerGroups: Array<{
          __typename: "LayerGroup";
          id: any;
          name: string;
          description?: string | null;
          required: boolean;
          multiple: boolean;
          layers: Array<{ __typename?: "Layer"; id: any }>;
        }>;
        enabledParameter?: {
          __typename: "Parameter";
          id: any;
          label: string;
          type: ParameterType;
          required: boolean;
          multiselect: boolean;
          description?: string | null;
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
        } | null;
      }>;
      startDateParameter?: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      } | null;
      invitationTemplate?: {
        __typename?: "NotificationTemplate";
        id: any;
      } | null;
      media: Array<{
        __typename: "ContentMedia";
        id: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
      onboardingFlow?: {
        __typename?: "Flow";
        id: any;
        steps: Array<{
          __typename?: "FlowStep";
          id: any;
          presentation:
            | { __typename?: "ContentDoc"; id: any }
            | {
                __typename?: "Notification";
                id: any;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  name: string;
                  notificationContents: Array<{
                    __typename?: "NotificationContent";
                    id: any;
                    contentKind: NotificationContentKind;
                    content: string;
                  }>;
                };
              }
            | { __typename?: "Survey"; id: any };
        }>;
      } | null;
      patientIdentifiers: Array<{
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      }>;
    };
    course: { __typename: "Course"; id: any };
    parameterValues: Array<{
      __typename: "TreatmentPlanParameterValue";
      id: any;
      transient: boolean;
      value?: string | null;
      parameter: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      scope?: { __typename?: "CalendarEventScope"; string: string } | null;
    } | null>;
    patientIdentifiers: Array<{
      __typename?: "PatientIdentifierValue";
      value?: string | null;
      patientIdentifier: {
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      };
    }>;
  }>;
};

export type AllAvailableLanguagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllAvailableLanguagesQuery = {
  __typename?: "Query";
  allAvailableLanguages: Array<{
    __typename?: "AvailableLanguage";
    name: string;
    tag: any;
  }>;
};

export type AuditLogQueryVariables = Exact<{
  filter: AuditLogFilter;
}>;

export type AuditLogQuery = {
  __typename?: "Query";
  auditLog: Array<{
    __typename: "AuditLogRecord";
    revision: number;
    action: AuditLogAction;
    createdBy?: any | null;
    createdByName: string;
    createdAt: any;
    delta: Array<{
      __typename: "PatientAuditDelta";
      field: string;
      oldValue?: string | null;
      newValue?: string | null;
    }>;
  }>;
};

export type AvailableDiagnosesQueryVariables = Exact<{
  orderingProviderLocation?: InputMaybe<Scalars["UUID"]>;
}>;

export type AvailableDiagnosesQuery = {
  __typename?: "Query";
  availableDiagnosesById: Array<{
    __typename: "Diagnosis";
    id: any;
    name: string;
  }>;
  defaultDiagnosisById?: {
    __typename: "Diagnosis";
    id: any;
    name: string;
  } | null;
};

export type AvailableTreatmentPlansQueryVariables = Exact<{
  diagnosisId: Scalars["UUID"];
  withSettings?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AvailableTreatmentPlansQuery = {
  __typename?: "Query";
  availableTreatmentPlans: Array<{
    __typename: "TreatmentPlan";
    id: any;
    name: string;
    description?: string | null;
    protocolName?: string | null;
    initialView?: PlanView | null;
    media: Array<{
      __typename: "ContentMedia";
      id: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
    onboardingFlow?: {
      __typename?: "Flow";
      id: any;
      steps: Array<{
        __typename?: "FlowStep";
        id: any;
        presentation:
          | { __typename?: "ContentDoc"; id: any }
          | {
              __typename?: "Notification";
              id: any;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                name: string;
                notificationContents: Array<{
                  __typename?: "NotificationContent";
                  id: any;
                  contentKind: NotificationContentKind;
                  content: string;
                }>;
              };
            }
          | { __typename?: "Survey"; id: any };
      }>;
    } | null;
    patientIdentifiers: Array<{
      __typename: "PatientIdentifier";
      description?: string | null;
      required: boolean;
      uniquePerIdentifierContext: boolean;
      id: any;
      name: string;
    }>;
    rootLayer?: {
      __typename: "Layer";
      id: any;
      name: string;
      description?: string | null;
      parameters: Array<{
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      }>;
      layerGroups: Array<{
        __typename: "LayerGroup";
        id: any;
        name: string;
        description?: string | null;
        required: boolean;
        multiple: boolean;
        layers: Array<{ __typename?: "Layer"; id: any }>;
      }>;
      enabledParameter?: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      } | null;
    } | null;
    layers: Array<{
      __typename: "Layer";
      id: any;
      name: string;
      description?: string | null;
      parameters: Array<{
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      }>;
      layerGroups: Array<{
        __typename: "LayerGroup";
        id: any;
        name: string;
        description?: string | null;
        required: boolean;
        multiple: boolean;
        layers: Array<{ __typename?: "Layer"; id: any }>;
      }>;
      enabledParameter?: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      } | null;
    }>;
    startDateParameter?: {
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    } | null;
    invitationTemplate?: {
      __typename?: "NotificationTemplate";
      id: any;
    } | null;
  }>;
};

export type CalendarBlobQueryVariables = Exact<{
  request: CalendarInput;
  format?: InputMaybe<CalendarSerializationFormat>;
}>;

export type CalendarBlobQuery = {
  __typename?: "Query";
  renderCalendar: { __typename: "Calendar"; blob: string };
};

export type CrmPersonQueryVariables = Exact<{
  copperId: Scalars["String"];
}>;

export type CrmPersonQuery = {
  __typename?: "Query";
  crmPerson?: {
    __typename?: "CrmPerson";
    id: string;
    provider?: {
      __typename?: "Provider";
      id: any;
      name: Array<string>;
      locations: Array<{
        __typename?: "ProviderLocation";
        id: any;
        active: boolean;
        practice: {
          __typename?: "Practice";
          id: any;
          name: string;
          organization?: {
            __typename?: "Organization";
            id: any;
            name: string;
          } | null;
        };
        patientIdentifiers: Array<{
          __typename: "PatientIdentifier";
          description?: string | null;
          required: boolean;
          uniquePerIdentifierContext: boolean;
          id: any;
          name: string;
        }>;
      }>;
    } | null;
    logs: Array<{
      __typename?: "CrmForeignSyncLog";
      id: any;
      field?: string | null;
      message: string;
    }>;
  } | null;
};

export type GetCsvMappingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCsvMappingsQuery = {
  __typename?: "Query";
  getCsvMappings: Array<string>;
};

export type GetNodesQueryVariables = Exact<{
  uuids: Array<Scalars["UUID"]> | Scalars["UUID"];
  recursive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetNodesQuery = {
  __typename?: "Query";
  getNodes: Array<
    | {
        __typename: "Brand";
        id: any;
        visible?: boolean | null;
        name: string;
        domainPrefix?: string | null;
        emailFromName?: string | null;
        emailFromAddress?: string | null;
        smsSenderId?: any | null;
        designTokens?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "CalendarEventTemplate";
        id: any;
        visible?: boolean | null;
        blocking: boolean;
        category?: CalendarEventCategory | null;
        color?: any | null;
        exceptHolidays: boolean;
        icon?: string | null;
        name: string;
        patientNameOverride?: string | null;
        rescheduleStrategy?: RescheduleStrategy | null;
        timeDescriptionOverride?: string | null;
        visibleTo?: VisibleTo | null;
        calendarEventTemplateDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmations: Array<{
          __typename: "Confirmation";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          id: any;
          visible?: boolean | null;
          confirmationDuration: any;
          confirmationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{ __typename: "Parameter"; id: any }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        duration?: {
          __typename?: "CalendarEventDuration";
          allDay?: boolean | null;
          length?: any | null;
        } | null;
        editableParameters: Array<{
          __typename: "EditableParameter";
          andFollowing: boolean;
          name: string;
          scopeSelector: ParameterScopeSelector;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        notifications: Array<{
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        recurrenceStrategy?: {
          __typename?: "CalendarRecurrenceStrategy";
          recurrenceFrequency?: Frequency | null;
          recurrenceInterval?: number | null;
          repeatCount?: number | null;
          repeatUntilEventOccurrence?: UntilOccurrence | null;
          inheritReschedules?: boolean | null;
          repeatOnAllAllowedDays?: boolean | null;
          countUntil?: CountUntil | null;
          repeatUntilDelta?: any | null;
          recurrenceRelation?: RecurrenceRelation | null;
          repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
          repeatUntilEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
        } | null;
        start?: {
          __typename?: "CalendarEventStart";
          absoluteTime?: any | null;
          relativeToOccurrence?: RelativeToOccurrence | null;
          projectionDirection?: ProjectionDirection | null;
          allowedDays?: Array<any> | null;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
          timeParameter?: { __typename: "Parameter"; id: any } | null;
          relativeToEvent?: {
            __typename: "CalendarEventTemplate";
            id: any;
          } | null;
          allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
        } | null;
        surveys: Array<{
          __typename: "Survey";
          collectorId?: string | null;
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          surveyId: string;
          id: any;
          visible?: boolean | null;
          surveyDuration?: any | null;
          surveyName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        tasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Confirmation";
        id: any;
        visible?: boolean | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        confirmationDuration: any;
        confirmationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{ __typename: "Parameter"; id: any }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ContentDoc";
        id: any;
        visible?: boolean | null;
        useContentJson: boolean;
        language: any;
        slug?: string | null;
        url?: any | null;
        previewImageUrl?: any | null;
        contentDocTitle?: string | null;
        contentDocExternalId?: any | null;
        contentDocContext: string;
        docContext: {
          __typename: "ContentDocContext";
          name: string;
          slug?: string | null;
          description?: string | null;
          exampleImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          breakpointBase?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointSmall?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointMedium?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          breakpointExtraLarge?: {
            __typename?: "ContentDocContainerSizes";
            minWidthPx?: number | null;
            widthPx?: number | null;
            maxWidthPx?: number | null;
          } | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referencesQuestions: Array<{ __typename: "Question"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ContentDocContext";
        id: any;
        visible?: boolean | null;
        name: string;
        slug?: string | null;
        description?: string | null;
        exampleImageUrl?: any | null;
        breakpointBase?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointSmall?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointMedium?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        breakpointExtraLarge?: {
          __typename?: "ContentDocContainerSizes";
          minWidthPx?: number | null;
          widthPx?: number | null;
          maxWidthPx?: number | null;
        } | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ContentMedia";
        id: any;
        visible?: boolean | null;
        title: string;
        language: any;
        mimeType: string;
        url?: any | null;
        contentMediaContext?: ContentMediaContext | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Diagnosis";
        id: any;
        visible?: boolean | null;
        name: string;
        diagnosisDescription?: string | null;
        diagnosisSlug?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        resourceReferences: Array<{
          __typename: "ResourceReference";
          globallyVisible: boolean;
          name: string;
          resourceWeight: number;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          resource: {
            __typename: "Resource";
            name: string;
            id: any;
            visible?: boolean | null;
            resourceDescription: string;
            resourceUrl?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          resourceCategory: {
            __typename: "ResourceCategory";
            categoryWeight: number;
            name: string;
            id: any;
            visible?: boolean | null;
            resourceCategoryDescription: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "EditableParameter";
        id: any;
        visible?: boolean | null;
        andFollowing: boolean;
        name: string;
        scopeSelector: ParameterScopeSelector;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Flow";
        id: any;
        visible?: boolean | null;
        name: string;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        restartAutomatically: boolean;
        flowDuration?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        steps: Array<{
          __typename: "FlowStep";
          name: string;
          uxData?: any | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          nextStep?: { __typename?: "FlowStep"; id: any } | null;
          notification?: {
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        task?: {
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "FlowStep";
        id: any;
        visible?: boolean | null;
        name: string;
        uxData?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        nextStep?: { __typename?: "FlowStep"; id: any } | null;
        notification?: {
          __typename: "Notification";
          eventDelta: any;
          occurrenceSpecifier: OccurrenceSpecifier;
          snoozeableDuration?: any | null;
          id: any;
          visible?: boolean | null;
          notificationName?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmation?: { __typename: "Confirmation"; id: any } | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          groupNotificationTemplate?: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationPreference?: {
            __typename: "NotificationPreference";
            defaultEnabled: boolean;
            editableByPatient: boolean;
            id: any;
            visible?: boolean | null;
            notificationPreferenceCategory: NotificationPreferenceCategory;
            notificationPreferenceDescription?: string | null;
            notificationPreferenceName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          notificationTemplate: {
            __typename: "NotificationTemplate";
            id: any;
            visible?: boolean | null;
            notificationTemplateName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            notificationContents: Array<{
              __typename: "NotificationContent";
              contentKind: NotificationContentKind;
              content: string;
              id: any;
              visible?: boolean | null;
              notificationContentName: string;
              notificationContentLanguage: any;
              notificationTemplate: {
                __typename?: "NotificationTemplate";
                id: any;
              };
              notificationContentReferencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          survey?: { __typename: "Survey"; id: any } | null;
          task?: { __typename: "Task"; id: any } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Layer";
        id: any;
        visible?: boolean | null;
        name: string;
        layerDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        enabledParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        layerGroups: Array<{
          __typename: "LayerGroup";
          name: string;
          required: boolean;
          multiple: boolean;
          id: any;
          visible?: boolean | null;
          layerGroupDescription?: string | null;
          layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameters: Array<{
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        parameterMappings: Array<{
          __typename: "ParameterMapping";
          name: string;
          offsetDuration?: any | null;
          offsetPeriod?: any | null;
          id: any;
          visible?: boolean | null;
          fromParameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          toParameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        phases: Array<{
          __typename: "Phase";
          name: string;
          defaultDuration: any;
          id: any;
          visible?: boolean | null;
          phaseLayers: Array<{ __typename: "Layer"; id: any }>;
          phaseStartDateParameter: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          repeatCountParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          totalCountParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          displayIndexParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          phaseParameters?: Array<{
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }> | null;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        planItems: Array<{
          __typename: "PlanItem";
          name: string;
          id: any;
          visible?: boolean | null;
          measure?: {
            __typename: "Measure";
            name: string;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            measureDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          treatment?: {
            __typename: "Treatment";
            name: string;
            genericName?: string | null;
            brandName?: string | null;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          calendarEventTemplates: Array<{
            __typename: "CalendarEventTemplate";
            blocking: boolean;
            category?: CalendarEventCategory | null;
            color?: any | null;
            exceptHolidays: boolean;
            icon?: string | null;
            name: string;
            patientNameOverride?: string | null;
            rescheduleStrategy?: RescheduleStrategy | null;
            timeDescriptionOverride?: string | null;
            visibleTo?: VisibleTo | null;
            id: any;
            visible?: boolean | null;
            calendarEventTemplateDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmations: Array<{
              __typename: "Confirmation";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              id: any;
              visible?: boolean | null;
              confirmationDuration: any;
              confirmationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameters: Array<{ __typename: "Parameter"; id: any }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            duration?: {
              __typename?: "CalendarEventDuration";
              allDay?: boolean | null;
              length?: any | null;
            } | null;
            editableParameters: Array<{
              __typename: "EditableParameter";
              andFollowing: boolean;
              name: string;
              scopeSelector: ParameterScopeSelector;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameter: {
                __typename: "Parameter";
                defaultValue?: string | null;
                editableBy: ParameterEditableBy;
                label: string;
                multiselect: boolean;
                required: boolean;
                type: ParameterType;
                visibleFor: ParameterVisibleFor;
                id: any;
                visible?: boolean | null;
                parameterDescription?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            notifications: Array<{
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            recurrenceStrategy?: {
              __typename?: "CalendarRecurrenceStrategy";
              recurrenceFrequency?: Frequency | null;
              recurrenceInterval?: number | null;
              repeatCount?: number | null;
              repeatUntilEventOccurrence?: UntilOccurrence | null;
              inheritReschedules?: boolean | null;
              repeatOnAllAllowedDays?: boolean | null;
              countUntil?: CountUntil | null;
              repeatUntilDelta?: any | null;
              recurrenceRelation?: RecurrenceRelation | null;
              repeatCountParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
            } | null;
            start?: {
              __typename?: "CalendarEventStart";
              absoluteTime?: any | null;
              relativeToOccurrence?: RelativeToOccurrence | null;
              projectionDirection?: ProjectionDirection | null;
              allowedDays?: Array<any> | null;
              offsetDuration?: any | null;
              offsetPeriod?: any | null;
              dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
              timeParameter?: { __typename: "Parameter"; id: any } | null;
              relativeToEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
              allowedDaysParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
            } | null;
            surveys: Array<{
              __typename: "Survey";
              collectorId?: string | null;
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              surveyId: string;
              id: any;
              visible?: boolean | null;
              surveyDuration?: any | null;
              surveyName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            tasks: Array<{
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          flows: Array<{
            __typename: "Flow";
            name: string;
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            restartAutomatically: boolean;
            id: any;
            visible?: boolean | null;
            flowDuration?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            steps: Array<{
              __typename: "FlowStep";
              name: string;
              uxData?: any | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              nextStep?: { __typename?: "FlowStep"; id: any } | null;
              notification?: {
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              contentDoc?: {
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            task?: {
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "LayerGroup";
        id: any;
        visible?: boolean | null;
        name: string;
        required: boolean;
        multiple: boolean;
        layerGroupDescription?: string | null;
        layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Measure";
        id: any;
        visible?: boolean | null;
        name: string;
        abbreviation?: string | null;
        measureDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Module";
        id: any;
        visible?: boolean | null;
        name: string;
        shortName: string;
        ordering: number;
        jumbo: boolean;
        moduleDescription: string;
        moduleSlug: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDoc?: {
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Notification";
        id: any;
        visible?: boolean | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        snoozeableDuration?: any | null;
        notificationName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        confirmation?: { __typename: "Confirmation"; id: any } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        groupNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationPreference?: {
          __typename: "NotificationPreference";
          defaultEnabled: boolean;
          editableByPatient: boolean;
          id: any;
          visible?: boolean | null;
          notificationPreferenceCategory: NotificationPreferenceCategory;
          notificationPreferenceDescription?: string | null;
          notificationPreferenceName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationTemplate: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        survey?: { __typename: "Survey"; id: any } | null;
        task?: { __typename: "Task"; id: any } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "NotificationContent";
        id: any;
        visible?: boolean | null;
        contentKind: NotificationContentKind;
        content: string;
        notificationContentName: string;
        notificationContentLanguage: any;
        notificationTemplate: { __typename?: "NotificationTemplate"; id: any };
        notificationContentReferencesMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "NotificationPreference";
        id: any;
        visible?: boolean | null;
        defaultEnabled: boolean;
        editableByPatient: boolean;
        notificationPreferenceCategory: NotificationPreferenceCategory;
        notificationPreferenceDescription?: string | null;
        notificationPreferenceName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "NotificationTemplate";
        id: any;
        visible?: boolean | null;
        notificationTemplateName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        notificationContents: Array<{
          __typename: "NotificationContent";
          contentKind: NotificationContentKind;
          content: string;
          id: any;
          visible?: boolean | null;
          notificationContentName: string;
          notificationContentLanguage: any;
          notificationTemplate: {
            __typename?: "NotificationTemplate";
            id: any;
          };
          notificationContentReferencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Parameter";
        id: any;
        visible?: boolean | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
        label: string;
        multiselect: boolean;
        required: boolean;
        type: ParameterType;
        visibleFor: ParameterVisibleFor;
        parameterDescription?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ParameterMapping";
        id: any;
        visible?: boolean | null;
        name: string;
        offsetDuration?: any | null;
        offsetPeriod?: any | null;
        fromParameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        toParameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Phase";
        id: any;
        visible?: boolean | null;
        name: string;
        defaultDuration: any;
        phaseLayers: Array<{ __typename: "Layer"; id: any }>;
        phaseStartDateParameter: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        repeatCountParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        totalCountParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        displayIndexParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        phaseParameters?: Array<{
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }> | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "PlanItem";
        id: any;
        visible?: boolean | null;
        name: string;
        measure?: {
          __typename: "Measure";
          name: string;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          measureDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        treatment?: {
          __typename: "Treatment";
          name: string;
          genericName?: string | null;
          brandName?: string | null;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        calendarEventTemplates: Array<{
          __typename: "CalendarEventTemplate";
          blocking: boolean;
          category?: CalendarEventCategory | null;
          color?: any | null;
          exceptHolidays: boolean;
          icon?: string | null;
          name: string;
          patientNameOverride?: string | null;
          rescheduleStrategy?: RescheduleStrategy | null;
          timeDescriptionOverride?: string | null;
          visibleTo?: VisibleTo | null;
          id: any;
          visible?: boolean | null;
          calendarEventTemplateDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          confirmations: Array<{
            __typename: "Confirmation";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            id: any;
            visible?: boolean | null;
            confirmationDuration: any;
            confirmationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            parameters: Array<{ __typename: "Parameter"; id: any }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          duration?: {
            __typename?: "CalendarEventDuration";
            allDay?: boolean | null;
            length?: any | null;
          } | null;
          editableParameters: Array<{
            __typename: "EditableParameter";
            andFollowing: boolean;
            name: string;
            scopeSelector: ParameterScopeSelector;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            parameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          notifications: Array<{
            __typename: "Notification";
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            snoozeableDuration?: any | null;
            id: any;
            visible?: boolean | null;
            notificationName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmation?: { __typename: "Confirmation"; id: any } | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            groupNotificationTemplate?: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationPreference?: {
              __typename: "NotificationPreference";
              defaultEnabled: boolean;
              editableByPatient: boolean;
              id: any;
              visible?: boolean | null;
              notificationPreferenceCategory: NotificationPreferenceCategory;
              notificationPreferenceDescription?: string | null;
              notificationPreferenceName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            notificationTemplate: {
              __typename: "NotificationTemplate";
              id: any;
              visible?: boolean | null;
              notificationTemplateName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              notificationContents: Array<{
                __typename: "NotificationContent";
                contentKind: NotificationContentKind;
                content: string;
                id: any;
                visible?: boolean | null;
                notificationContentName: string;
                notificationContentLanguage: any;
                notificationTemplate: {
                  __typename?: "NotificationTemplate";
                  id: any;
                };
                notificationContentReferencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            survey?: { __typename: "Survey"; id: any } | null;
            task?: { __typename: "Task"; id: any } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          recurrenceStrategy?: {
            __typename?: "CalendarRecurrenceStrategy";
            recurrenceFrequency?: Frequency | null;
            recurrenceInterval?: number | null;
            repeatCount?: number | null;
            repeatUntilEventOccurrence?: UntilOccurrence | null;
            inheritReschedules?: boolean | null;
            repeatOnAllAllowedDays?: boolean | null;
            countUntil?: CountUntil | null;
            repeatUntilDelta?: any | null;
            recurrenceRelation?: RecurrenceRelation | null;
            repeatCountParameter?: { __typename: "Parameter"; id: any } | null;
            repeatUntilParameter?: { __typename: "Parameter"; id: any } | null;
            repeatUntilEvent?: {
              __typename: "CalendarEventTemplate";
              id: any;
            } | null;
          } | null;
          start?: {
            __typename?: "CalendarEventStart";
            absoluteTime?: any | null;
            relativeToOccurrence?: RelativeToOccurrence | null;
            projectionDirection?: ProjectionDirection | null;
            allowedDays?: Array<any> | null;
            offsetDuration?: any | null;
            offsetPeriod?: any | null;
            dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
            timeParameter?: { __typename: "Parameter"; id: any } | null;
            relativeToEvent?: {
              __typename: "CalendarEventTemplate";
              id: any;
            } | null;
            allowedDaysParameter?: { __typename: "Parameter"; id: any } | null;
          } | null;
          surveys: Array<{
            __typename: "Survey";
            collectorId?: string | null;
            eventDelta: any;
            occurrenceSpecifier: OccurrenceSpecifier;
            surveyId: string;
            id: any;
            visible?: boolean | null;
            surveyDuration?: any | null;
            surveyName?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          tasks: Array<{
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          flows: Array<{
            __typename: "Flow";
            name: string;
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            restartAutomatically: boolean;
            id: any;
            visible?: boolean | null;
            flowDuration?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            steps: Array<{
              __typename: "FlowStep";
              name: string;
              uxData?: any | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              nextStep?: { __typename?: "FlowStep"; id: any } | null;
              notification?: {
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              contentDoc?: {
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            task?: {
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        flows: Array<{
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Question";
        id: any;
        visible?: boolean | null;
        name: string;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        questionNotificationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        rootMeasure: {
          __typename: "Measure";
          name: string;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          measureDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        prototypeMeasures: Array<{
          __typename: "Measure";
          name: string;
          abbreviation?: string | null;
          id: any;
          visible?: boolean | null;
          measureDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Resource";
        id: any;
        visible?: boolean | null;
        name: string;
        resourceDescription: string;
        resourceUrl?: any | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ResourceCategory";
        id: any;
        visible?: boolean | null;
        categoryWeight: number;
        name: string;
        resourceCategoryDescription: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "ResourceReference";
        id: any;
        visible?: boolean | null;
        globallyVisible: boolean;
        name: string;
        resourceWeight: number;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        resource: {
          __typename: "Resource";
          name: string;
          id: any;
          visible?: boolean | null;
          resourceDescription: string;
          resourceUrl?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDocs: Array<{
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        resourceCategory: {
          __typename: "ResourceCategory";
          categoryWeight: number;
          name: string;
          id: any;
          visible?: boolean | null;
          resourceCategoryDescription: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        };
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Survey";
        id: any;
        visible?: boolean | null;
        collectorId?: string | null;
        eventDelta: any;
        occurrenceSpecifier: OccurrenceSpecifier;
        surveyId: string;
        surveyDuration?: any | null;
        surveyName?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Task";
        id: any;
        visible?: boolean | null;
        occurrenceSpecifier: OccurrenceSpecifier;
        eventDelta: any;
        taskCategory: TaskCategory;
        taskDescription?: string | null;
        taskName: string;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "Treatment";
        id: any;
        visible?: boolean | null;
        name: string;
        genericName?: string | null;
        brandName?: string | null;
        abbreviation?: string | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "TreatmentList";
        id: any;
        visible?: boolean | null;
        name: string;
        key: string;
        items?: Array<{
          __typename: "PlanItem";
          name: string;
          id: any;
          visible?: boolean | null;
          measure?: {
            __typename: "Measure";
            name: string;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            measureDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          treatment?: {
            __typename: "Treatment";
            name: string;
            genericName?: string | null;
            brandName?: string | null;
            abbreviation?: string | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          calendarEventTemplates: Array<{
            __typename: "CalendarEventTemplate";
            blocking: boolean;
            category?: CalendarEventCategory | null;
            color?: any | null;
            exceptHolidays: boolean;
            icon?: string | null;
            name: string;
            patientNameOverride?: string | null;
            rescheduleStrategy?: RescheduleStrategy | null;
            timeDescriptionOverride?: string | null;
            visibleTo?: VisibleTo | null;
            id: any;
            visible?: boolean | null;
            calendarEventTemplateDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            confirmations: Array<{
              __typename: "Confirmation";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              id: any;
              visible?: boolean | null;
              confirmationDuration: any;
              confirmationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameters: Array<{ __typename: "Parameter"; id: any }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            contentMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            duration?: {
              __typename?: "CalendarEventDuration";
              allDay?: boolean | null;
              length?: any | null;
            } | null;
            editableParameters: Array<{
              __typename: "EditableParameter";
              andFollowing: boolean;
              name: string;
              scopeSelector: ParameterScopeSelector;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              parameter: {
                __typename: "Parameter";
                defaultValue?: string | null;
                editableBy: ParameterEditableBy;
                label: string;
                multiselect: boolean;
                required: boolean;
                type: ParameterType;
                visibleFor: ParameterVisibleFor;
                id: any;
                visible?: boolean | null;
                parameterDescription?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            notifications: Array<{
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            recurrenceStrategy?: {
              __typename?: "CalendarRecurrenceStrategy";
              recurrenceFrequency?: Frequency | null;
              recurrenceInterval?: number | null;
              repeatCount?: number | null;
              repeatUntilEventOccurrence?: UntilOccurrence | null;
              inheritReschedules?: boolean | null;
              repeatOnAllAllowedDays?: boolean | null;
              countUntil?: CountUntil | null;
              repeatUntilDelta?: any | null;
              recurrenceRelation?: RecurrenceRelation | null;
              repeatCountParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
              repeatUntilEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
            } | null;
            start?: {
              __typename?: "CalendarEventStart";
              absoluteTime?: any | null;
              relativeToOccurrence?: RelativeToOccurrence | null;
              projectionDirection?: ProjectionDirection | null;
              allowedDays?: Array<any> | null;
              offsetDuration?: any | null;
              offsetPeriod?: any | null;
              dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
              timeParameter?: { __typename: "Parameter"; id: any } | null;
              relativeToEvent?: {
                __typename: "CalendarEventTemplate";
                id: any;
              } | null;
              allowedDaysParameter?: {
                __typename: "Parameter";
                id: any;
              } | null;
            } | null;
            surveys: Array<{
              __typename: "Survey";
              collectorId?: string | null;
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              surveyId: string;
              id: any;
              visible?: boolean | null;
              surveyDuration?: any | null;
              surveyName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            tasks: Array<{
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          flows: Array<{
            __typename: "Flow";
            name: string;
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            restartAutomatically: boolean;
            id: any;
            visible?: boolean | null;
            flowDuration?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            steps: Array<{
              __typename: "FlowStep";
              name: string;
              uxData?: any | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              nextStep?: { __typename?: "FlowStep"; id: any } | null;
              notification?: {
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              contentDoc?: {
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            task?: {
              __typename: "Task";
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              id: any;
              visible?: boolean | null;
              taskCategory: TaskCategory;
              taskDescription?: string | null;
              taskName: string;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }> | null;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
    | {
        __typename: "TreatmentPlan";
        id: any;
        visible?: boolean | null;
        name: string;
        description?: string | null;
        protocolName?: string | null;
        patientNameOverride?: string | null;
        initialView?: PlanView | null;
        rootLayer?: { __typename: "Layer"; id: any } | null;
        initialTasks: Array<{
          __typename: "Task";
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          id: any;
          visible?: boolean | null;
          taskCategory: TaskCategory;
          taskDescription?: string | null;
          taskName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        invitationTemplate?: {
          __typename: "NotificationTemplate";
          id: any;
          visible?: boolean | null;
          notificationTemplateName: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          notificationContents: Array<{
            __typename: "NotificationContent";
            contentKind: NotificationContentKind;
            content: string;
            id: any;
            visible?: boolean | null;
            notificationContentName: string;
            notificationContentLanguage: any;
            notificationTemplate: {
              __typename?: "NotificationTemplate";
              id: any;
            };
            notificationContentReferencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        startDateParameter?: {
          __typename: "Parameter";
          defaultValue?: string | null;
          editableBy: ParameterEditableBy;
          label: string;
          multiselect: boolean;
          required: boolean;
          type: ParameterType;
          visibleFor: ParameterVisibleFor;
          id: any;
          visible?: boolean | null;
          parameterDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        contentDocs: Array<{
          __typename: "ContentDoc";
          useContentJson: boolean;
          language: any;
          slug?: string | null;
          url?: any | null;
          previewImageUrl?: any | null;
          id: any;
          visible?: boolean | null;
          contentDocTitle?: string | null;
          contentDocExternalId?: any | null;
          contentDocContext: string;
          docContext: {
            __typename: "ContentDocContext";
            name: string;
            slug?: string | null;
            description?: string | null;
            exampleImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            breakpointBase?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointSmall?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointMedium?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            breakpointExtraLarge?: {
              __typename?: "ContentDocContainerSizes";
              minWidthPx?: number | null;
              widthPx?: number | null;
              maxWidthPx?: number | null;
            } | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
          referencesMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referencesQuestions: Array<{ __typename: "Question"; id: any }>;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        contentMedia: Array<{
          __typename: "ContentMedia";
          title: string;
          language: any;
          mimeType: string;
          url?: any | null;
          id: any;
          visible?: boolean | null;
          contentMediaContext?: ContentMediaContext | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        excludedModules: Array<{
          __typename: "Module";
          name: string;
          shortName: string;
          ordering: number;
          jumbo: boolean;
          id: any;
          visible?: boolean | null;
          moduleDescription: string;
          moduleSlug: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        includedModules: Array<{
          __typename: "Module";
          name: string;
          shortName: string;
          ordering: number;
          jumbo: boolean;
          id: any;
          visible?: boolean | null;
          moduleDescription: string;
          moduleSlug: string;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          contentDoc?: {
            __typename: "ContentDoc";
            useContentJson: boolean;
            language: any;
            slug?: string | null;
            url?: any | null;
            previewImageUrl?: any | null;
            id: any;
            visible?: boolean | null;
            contentDocTitle?: string | null;
            contentDocExternalId?: any | null;
            contentDocContext: string;
            docContext: {
              __typename: "ContentDocContext";
              name: string;
              slug?: string | null;
              description?: string | null;
              exampleImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              breakpointBase?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointSmall?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointMedium?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              breakpointExtraLarge?: {
                __typename?: "ContentDocContainerSizes";
                minWidthPx?: number | null;
                widthPx?: number | null;
                maxWidthPx?: number | null;
              } | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
            referencesMedia: Array<{
              __typename: "ContentMedia";
              title: string;
              language: any;
              mimeType: string;
              url?: any | null;
              id: any;
              visible?: boolean | null;
              contentMediaContext?: ContentMediaContext | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referencesQuestions: Array<{ __typename: "Question"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          contentMedia: Array<{
            __typename: "ContentMedia";
            title: string;
            language: any;
            mimeType: string;
            url?: any | null;
            id: any;
            visible?: boolean | null;
            contentMediaContext?: ContentMediaContext | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        layers: Array<{
          __typename: "Layer";
          name: string;
          id: any;
          visible?: boolean | null;
          layerDescription?: string | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          enabledParameter?: {
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          layerGroups: Array<{
            __typename: "LayerGroup";
            name: string;
            required: boolean;
            multiple: boolean;
            id: any;
            visible?: boolean | null;
            layerGroupDescription?: string | null;
            layerGroupLayers: Array<{ __typename: "Layer"; id: any }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameters: Array<{
            __typename: "Parameter";
            defaultValue?: string | null;
            editableBy: ParameterEditableBy;
            label: string;
            multiselect: boolean;
            required: boolean;
            type: ParameterType;
            visibleFor: ParameterVisibleFor;
            id: any;
            visible?: boolean | null;
            parameterDescription?: string | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          parameterMappings: Array<{
            __typename: "ParameterMapping";
            name: string;
            offsetDuration?: any | null;
            offsetPeriod?: any | null;
            id: any;
            visible?: boolean | null;
            fromParameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            toParameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          phases: Array<{
            __typename: "Phase";
            name: string;
            defaultDuration: any;
            id: any;
            visible?: boolean | null;
            phaseLayers: Array<{ __typename: "Layer"; id: any }>;
            phaseStartDateParameter: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            };
            repeatCountParameter?: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            totalCountParameter?: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            displayIndexParameter?: {
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            phaseParameters?: Array<{
              __typename: "Parameter";
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
              label: string;
              multiselect: boolean;
              required: boolean;
              type: ParameterType;
              visibleFor: ParameterVisibleFor;
              id: any;
              visible?: boolean | null;
              parameterDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }> | null;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          planItems: Array<{
            __typename: "PlanItem";
            name: string;
            id: any;
            visible?: boolean | null;
            measure?: {
              __typename: "Measure";
              name: string;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              measureDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            treatment?: {
              __typename: "Treatment";
              name: string;
              genericName?: string | null;
              brandName?: string | null;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            calendarEventTemplates: Array<{
              __typename: "CalendarEventTemplate";
              blocking: boolean;
              category?: CalendarEventCategory | null;
              color?: any | null;
              exceptHolidays: boolean;
              icon?: string | null;
              name: string;
              patientNameOverride?: string | null;
              rescheduleStrategy?: RescheduleStrategy | null;
              timeDescriptionOverride?: string | null;
              visibleTo?: VisibleTo | null;
              id: any;
              visible?: boolean | null;
              calendarEventTemplateDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmations: Array<{
                __typename: "Confirmation";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                id: any;
                visible?: boolean | null;
                confirmationDuration: any;
                confirmationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameters: Array<{ __typename: "Parameter"; id: any }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              duration?: {
                __typename?: "CalendarEventDuration";
                allDay?: boolean | null;
                length?: any | null;
              } | null;
              editableParameters: Array<{
                __typename: "EditableParameter";
                andFollowing: boolean;
                name: string;
                scopeSelector: ParameterScopeSelector;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameter: {
                  __typename: "Parameter";
                  defaultValue?: string | null;
                  editableBy: ParameterEditableBy;
                  label: string;
                  multiselect: boolean;
                  required: boolean;
                  type: ParameterType;
                  visibleFor: ParameterVisibleFor;
                  id: any;
                  visible?: boolean | null;
                  parameterDescription?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              notifications: Array<{
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              recurrenceStrategy?: {
                __typename?: "CalendarRecurrenceStrategy";
                recurrenceFrequency?: Frequency | null;
                recurrenceInterval?: number | null;
                repeatCount?: number | null;
                repeatUntilEventOccurrence?: UntilOccurrence | null;
                inheritReschedules?: boolean | null;
                repeatOnAllAllowedDays?: boolean | null;
                countUntil?: CountUntil | null;
                repeatUntilDelta?: any | null;
                recurrenceRelation?: RecurrenceRelation | null;
                repeatCountParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
              } | null;
              start?: {
                __typename?: "CalendarEventStart";
                absoluteTime?: any | null;
                relativeToOccurrence?: RelativeToOccurrence | null;
                projectionDirection?: ProjectionDirection | null;
                allowedDays?: Array<any> | null;
                offsetDuration?: any | null;
                offsetPeriod?: any | null;
                dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
                timeParameter?: { __typename: "Parameter"; id: any } | null;
                relativeToEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
                allowedDaysParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
              } | null;
              surveys: Array<{
                __typename: "Survey";
                collectorId?: string | null;
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                surveyId: string;
                id: any;
                visible?: boolean | null;
                surveyDuration?: any | null;
                surveyName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              tasks: Array<{
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              flows: Array<{
                __typename: "Flow";
                name: string;
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                restartAutomatically: boolean;
                id: any;
                visible?: boolean | null;
                flowDuration?: any | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                steps: Array<{
                  __typename: "FlowStep";
                  name: string;
                  uxData?: any | null;
                  id: any;
                  visible?: boolean | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  nextStep?: { __typename?: "FlowStep"; id: any } | null;
                  notification?: {
                    __typename: "Notification";
                    eventDelta: any;
                    occurrenceSpecifier: OccurrenceSpecifier;
                    snoozeableDuration?: any | null;
                    id: any;
                    visible?: boolean | null;
                    notificationName?: string | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    confirmation?: {
                      __typename: "Confirmation";
                      id: any;
                    } | null;
                    contentDocs: Array<{
                      __typename: "ContentDoc";
                      useContentJson: boolean;
                      language: any;
                      slug?: string | null;
                      url?: any | null;
                      previewImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentDocTitle?: string | null;
                      contentDocExternalId?: any | null;
                      contentDocContext: string;
                      docContext: {
                        __typename: "ContentDocContext";
                        name: string;
                        slug?: string | null;
                        description?: string | null;
                        exampleImageUrl?: any | null;
                        id: any;
                        visible?: boolean | null;
                        breakpointBase?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointSmall?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointMedium?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointExtraLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      };
                      referencesDocs: Array<{
                        __typename: "ContentDoc";
                        id: any;
                      }>;
                      referencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referencesQuestions: Array<{
                        __typename: "Question";
                        id: any;
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    contentMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    groupNotificationTemplate?: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationPreference?: {
                      __typename: "NotificationPreference";
                      defaultEnabled: boolean;
                      editableByPatient: boolean;
                      id: any;
                      visible?: boolean | null;
                      notificationPreferenceCategory: NotificationPreferenceCategory;
                      notificationPreferenceDescription?: string | null;
                      notificationPreferenceName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationTemplate: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    survey?: { __typename: "Survey"; id: any } | null;
                    task?: { __typename: "Task"; id: any } | null;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  contentDoc?: {
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                task?: {
                  __typename: "Task";
                  occurrenceSpecifier: OccurrenceSpecifier;
                  eventDelta: any;
                  id: any;
                  visible?: boolean | null;
                  taskCategory: TaskCategory;
                  taskDescription?: string | null;
                  taskName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        resourceReferences: Array<{
          __typename: "ResourceReference";
          globallyVisible: boolean;
          name: string;
          resourceWeight: number;
          id: any;
          visible?: boolean | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          resource: {
            __typename: "Resource";
            name: string;
            id: any;
            visible?: boolean | null;
            resourceDescription: string;
            resourceUrl?: any | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            contentDocs: Array<{
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          resourceCategory: {
            __typename: "ResourceCategory";
            categoryWeight: number;
            name: string;
            id: any;
            visible?: boolean | null;
            resourceCategoryDescription: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          };
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        treatmentLists: Array<{
          __typename: "TreatmentList";
          name: string;
          key: string;
          id: any;
          visible?: boolean | null;
          items?: Array<{
            __typename: "PlanItem";
            name: string;
            id: any;
            visible?: boolean | null;
            measure?: {
              __typename: "Measure";
              name: string;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              measureDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            treatment?: {
              __typename: "Treatment";
              name: string;
              genericName?: string | null;
              brandName?: string | null;
              abbreviation?: string | null;
              id: any;
              visible?: boolean | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            calendarEventTemplates: Array<{
              __typename: "CalendarEventTemplate";
              blocking: boolean;
              category?: CalendarEventCategory | null;
              color?: any | null;
              exceptHolidays: boolean;
              icon?: string | null;
              name: string;
              patientNameOverride?: string | null;
              rescheduleStrategy?: RescheduleStrategy | null;
              timeDescriptionOverride?: string | null;
              visibleTo?: VisibleTo | null;
              id: any;
              visible?: boolean | null;
              calendarEventTemplateDescription?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmations: Array<{
                __typename: "Confirmation";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                id: any;
                visible?: boolean | null;
                confirmationDuration: any;
                confirmationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameters: Array<{ __typename: "Parameter"; id: any }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              duration?: {
                __typename?: "CalendarEventDuration";
                allDay?: boolean | null;
                length?: any | null;
              } | null;
              editableParameters: Array<{
                __typename: "EditableParameter";
                andFollowing: boolean;
                name: string;
                scopeSelector: ParameterScopeSelector;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                parameter: {
                  __typename: "Parameter";
                  defaultValue?: string | null;
                  editableBy: ParameterEditableBy;
                  label: string;
                  multiselect: boolean;
                  required: boolean;
                  type: ParameterType;
                  visibleFor: ParameterVisibleFor;
                  id: any;
                  visible?: boolean | null;
                  parameterDescription?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              notifications: Array<{
                __typename: "Notification";
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                snoozeableDuration?: any | null;
                id: any;
                visible?: boolean | null;
                notificationName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                confirmation?: { __typename: "Confirmation"; id: any } | null;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                contentMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                groupNotificationTemplate?: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationPreference?: {
                  __typename: "NotificationPreference";
                  defaultEnabled: boolean;
                  editableByPatient: boolean;
                  id: any;
                  visible?: boolean | null;
                  notificationPreferenceCategory: NotificationPreferenceCategory;
                  notificationPreferenceDescription?: string | null;
                  notificationPreferenceName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                notificationTemplate: {
                  __typename: "NotificationTemplate";
                  id: any;
                  visible?: boolean | null;
                  notificationTemplateName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  notificationContents: Array<{
                    __typename: "NotificationContent";
                    contentKind: NotificationContentKind;
                    content: string;
                    id: any;
                    visible?: boolean | null;
                    notificationContentName: string;
                    notificationContentLanguage: any;
                    notificationTemplate: {
                      __typename?: "NotificationTemplate";
                      id: any;
                    };
                    notificationContentReferencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                survey?: { __typename: "Survey"; id: any } | null;
                task?: { __typename: "Task"; id: any } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              recurrenceStrategy?: {
                __typename?: "CalendarRecurrenceStrategy";
                recurrenceFrequency?: Frequency | null;
                recurrenceInterval?: number | null;
                repeatCount?: number | null;
                repeatUntilEventOccurrence?: UntilOccurrence | null;
                inheritReschedules?: boolean | null;
                repeatOnAllAllowedDays?: boolean | null;
                countUntil?: CountUntil | null;
                repeatUntilDelta?: any | null;
                recurrenceRelation?: RecurrenceRelation | null;
                repeatCountParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
                repeatUntilEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
              } | null;
              start?: {
                __typename?: "CalendarEventStart";
                absoluteTime?: any | null;
                relativeToOccurrence?: RelativeToOccurrence | null;
                projectionDirection?: ProjectionDirection | null;
                allowedDays?: Array<any> | null;
                offsetDuration?: any | null;
                offsetPeriod?: any | null;
                dateTimeParameter?: { __typename: "Parameter"; id: any } | null;
                timeParameter?: { __typename: "Parameter"; id: any } | null;
                relativeToEvent?: {
                  __typename: "CalendarEventTemplate";
                  id: any;
                } | null;
                allowedDaysParameter?: {
                  __typename: "Parameter";
                  id: any;
                } | null;
              } | null;
              surveys: Array<{
                __typename: "Survey";
                collectorId?: string | null;
                eventDelta: any;
                occurrenceSpecifier: OccurrenceSpecifier;
                surveyId: string;
                id: any;
                visible?: boolean | null;
                surveyDuration?: any | null;
                surveyName?: string | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                contentDocs: Array<{
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              tasks: Array<{
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              flows: Array<{
                __typename: "Flow";
                name: string;
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                restartAutomatically: boolean;
                id: any;
                visible?: boolean | null;
                flowDuration?: any | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                steps: Array<{
                  __typename: "FlowStep";
                  name: string;
                  uxData?: any | null;
                  id: any;
                  visible?: boolean | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  nextStep?: { __typename?: "FlowStep"; id: any } | null;
                  notification?: {
                    __typename: "Notification";
                    eventDelta: any;
                    occurrenceSpecifier: OccurrenceSpecifier;
                    snoozeableDuration?: any | null;
                    id: any;
                    visible?: boolean | null;
                    notificationName?: string | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    confirmation?: {
                      __typename: "Confirmation";
                      id: any;
                    } | null;
                    contentDocs: Array<{
                      __typename: "ContentDoc";
                      useContentJson: boolean;
                      language: any;
                      slug?: string | null;
                      url?: any | null;
                      previewImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentDocTitle?: string | null;
                      contentDocExternalId?: any | null;
                      contentDocContext: string;
                      docContext: {
                        __typename: "ContentDocContext";
                        name: string;
                        slug?: string | null;
                        description?: string | null;
                        exampleImageUrl?: any | null;
                        id: any;
                        visible?: boolean | null;
                        breakpointBase?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointSmall?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointMedium?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        breakpointExtraLarge?: {
                          __typename?: "ContentDocContainerSizes";
                          minWidthPx?: number | null;
                          widthPx?: number | null;
                          maxWidthPx?: number | null;
                        } | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      };
                      referencesDocs: Array<{
                        __typename: "ContentDoc";
                        id: any;
                      }>;
                      referencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referencesQuestions: Array<{
                        __typename: "Question";
                        id: any;
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    contentMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    groupNotificationTemplate?: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationPreference?: {
                      __typename: "NotificationPreference";
                      defaultEnabled: boolean;
                      editableByPatient: boolean;
                      id: any;
                      visible?: boolean | null;
                      notificationPreferenceCategory: NotificationPreferenceCategory;
                      notificationPreferenceDescription?: string | null;
                      notificationPreferenceName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    } | null;
                    notificationTemplate: {
                      __typename: "NotificationTemplate";
                      id: any;
                      visible?: boolean | null;
                      notificationTemplateName: string;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      notificationContents: Array<{
                        __typename: "NotificationContent";
                        contentKind: NotificationContentKind;
                        content: string;
                        id: any;
                        visible?: boolean | null;
                        notificationContentName: string;
                        notificationContentLanguage: any;
                        notificationTemplate: {
                          __typename?: "NotificationTemplate";
                          id: any;
                        };
                        notificationContentReferencesMedia: Array<{
                          __typename: "ContentMedia";
                          title: string;
                          language: any;
                          mimeType: string;
                          url?: any | null;
                          id: any;
                          visible?: boolean | null;
                          contentMediaContext?: ContentMediaContext | null;
                          alternativeNames: Array<{
                            __typename: "AlternativeNameEntry";
                            key: AlternativeName;
                            value: string;
                          }>;
                          referenceInfo: {
                            __typename: "NodeReferenceInfo";
                            id: any;
                            containers: Array<{
                              __typename?: "NodeReferenceContainer";
                              id: any;
                              nodeType: NodeType;
                              name: string;
                            }>;
                          };
                        }>;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    survey?: { __typename: "Survey"; id: any } | null;
                    task?: { __typename: "Task"; id: any } | null;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  contentDoc?: {
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                task?: {
                  __typename: "Task";
                  occurrenceSpecifier: OccurrenceSpecifier;
                  eventDelta: any;
                  id: any;
                  visible?: boolean | null;
                  taskCategory: TaskCategory;
                  taskDescription?: string | null;
                  taskName: string;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            flows: Array<{
              __typename: "Flow";
              name: string;
              occurrenceSpecifier: OccurrenceSpecifier;
              eventDelta: any;
              restartAutomatically: boolean;
              id: any;
              visible?: boolean | null;
              flowDuration?: any | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              steps: Array<{
                __typename: "FlowStep";
                name: string;
                uxData?: any | null;
                id: any;
                visible?: boolean | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                nextStep?: { __typename?: "FlowStep"; id: any } | null;
                notification?: {
                  __typename: "Notification";
                  eventDelta: any;
                  occurrenceSpecifier: OccurrenceSpecifier;
                  snoozeableDuration?: any | null;
                  id: any;
                  visible?: boolean | null;
                  notificationName?: string | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  confirmation?: { __typename: "Confirmation"; id: any } | null;
                  contentDocs: Array<{
                    __typename: "ContentDoc";
                    useContentJson: boolean;
                    language: any;
                    slug?: string | null;
                    url?: any | null;
                    previewImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentDocTitle?: string | null;
                    contentDocExternalId?: any | null;
                    contentDocContext: string;
                    docContext: {
                      __typename: "ContentDocContext";
                      name: string;
                      slug?: string | null;
                      description?: string | null;
                      exampleImageUrl?: any | null;
                      id: any;
                      visible?: boolean | null;
                      breakpointBase?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointSmall?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointMedium?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      breakpointExtraLarge?: {
                        __typename?: "ContentDocContainerSizes";
                        minWidthPx?: number | null;
                        widthPx?: number | null;
                        maxWidthPx?: number | null;
                      } | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    };
                    referencesDocs: Array<{
                      __typename: "ContentDoc";
                      id: any;
                    }>;
                    referencesMedia: Array<{
                      __typename: "ContentMedia";
                      title: string;
                      language: any;
                      mimeType: string;
                      url?: any | null;
                      id: any;
                      visible?: boolean | null;
                      contentMediaContext?: ContentMediaContext | null;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referencesQuestions: Array<{
                      __typename: "Question";
                      id: any;
                    }>;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  contentMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  groupNotificationTemplate?: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationPreference?: {
                    __typename: "NotificationPreference";
                    defaultEnabled: boolean;
                    editableByPatient: boolean;
                    id: any;
                    visible?: boolean | null;
                    notificationPreferenceCategory: NotificationPreferenceCategory;
                    notificationPreferenceDescription?: string | null;
                    notificationPreferenceName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  } | null;
                  notificationTemplate: {
                    __typename: "NotificationTemplate";
                    id: any;
                    visible?: boolean | null;
                    notificationTemplateName: string;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    notificationContents: Array<{
                      __typename: "NotificationContent";
                      contentKind: NotificationContentKind;
                      content: string;
                      id: any;
                      visible?: boolean | null;
                      notificationContentName: string;
                      notificationContentLanguage: any;
                      notificationTemplate: {
                        __typename?: "NotificationTemplate";
                        id: any;
                      };
                      notificationContentReferencesMedia: Array<{
                        __typename: "ContentMedia";
                        title: string;
                        language: any;
                        mimeType: string;
                        url?: any | null;
                        id: any;
                        visible?: boolean | null;
                        contentMediaContext?: ContentMediaContext | null;
                        alternativeNames: Array<{
                          __typename: "AlternativeNameEntry";
                          key: AlternativeName;
                          value: string;
                        }>;
                        referenceInfo: {
                          __typename: "NodeReferenceInfo";
                          id: any;
                          containers: Array<{
                            __typename?: "NodeReferenceContainer";
                            id: any;
                            nodeType: NodeType;
                            name: string;
                          }>;
                        };
                      }>;
                      alternativeNames: Array<{
                        __typename: "AlternativeNameEntry";
                        key: AlternativeName;
                        value: string;
                      }>;
                      referenceInfo: {
                        __typename: "NodeReferenceInfo";
                        id: any;
                        containers: Array<{
                          __typename?: "NodeReferenceContainer";
                          id: any;
                          nodeType: NodeType;
                          name: string;
                        }>;
                      };
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  survey?: { __typename: "Survey"; id: any } | null;
                  task?: { __typename: "Task"; id: any } | null;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                contentDoc?: {
                  __typename: "ContentDoc";
                  useContentJson: boolean;
                  language: any;
                  slug?: string | null;
                  url?: any | null;
                  previewImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentDocTitle?: string | null;
                  contentDocExternalId?: any | null;
                  contentDocContext: string;
                  docContext: {
                    __typename: "ContentDocContext";
                    name: string;
                    slug?: string | null;
                    description?: string | null;
                    exampleImageUrl?: any | null;
                    id: any;
                    visible?: boolean | null;
                    breakpointBase?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointSmall?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointMedium?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    breakpointExtraLarge?: {
                      __typename?: "ContentDocContainerSizes";
                      minWidthPx?: number | null;
                      widthPx?: number | null;
                      maxWidthPx?: number | null;
                    } | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  };
                  referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                  referencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  referencesQuestions: Array<{
                    __typename: "Question";
                    id: any;
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                } | null;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              task?: {
                __typename: "Task";
                occurrenceSpecifier: OccurrenceSpecifier;
                eventDelta: any;
                id: any;
                visible?: boolean | null;
                taskCategory: TaskCategory;
                taskDescription?: string | null;
                taskName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            }>;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }> | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        }>;
        alternativeNames: Array<{
          __typename: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
        onboardingFlow?: {
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        notificationResponseFlow?: {
          __typename: "Flow";
          name: string;
          occurrenceSpecifier: OccurrenceSpecifier;
          eventDelta: any;
          restartAutomatically: boolean;
          id: any;
          visible?: boolean | null;
          flowDuration?: any | null;
          alternativeNames: Array<{
            __typename: "AlternativeNameEntry";
            key: AlternativeName;
            value: string;
          }>;
          steps: Array<{
            __typename: "FlowStep";
            name: string;
            uxData?: any | null;
            id: any;
            visible?: boolean | null;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            nextStep?: { __typename?: "FlowStep"; id: any } | null;
            notification?: {
              __typename: "Notification";
              eventDelta: any;
              occurrenceSpecifier: OccurrenceSpecifier;
              snoozeableDuration?: any | null;
              id: any;
              visible?: boolean | null;
              notificationName?: string | null;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              confirmation?: { __typename: "Confirmation"; id: any } | null;
              contentDocs: Array<{
                __typename: "ContentDoc";
                useContentJson: boolean;
                language: any;
                slug?: string | null;
                url?: any | null;
                previewImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                contentDocTitle?: string | null;
                contentDocExternalId?: any | null;
                contentDocContext: string;
                docContext: {
                  __typename: "ContentDocContext";
                  name: string;
                  slug?: string | null;
                  description?: string | null;
                  exampleImageUrl?: any | null;
                  id: any;
                  visible?: boolean | null;
                  breakpointBase?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointSmall?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointMedium?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  breakpointExtraLarge?: {
                    __typename?: "ContentDocContainerSizes";
                    minWidthPx?: number | null;
                    widthPx?: number | null;
                    maxWidthPx?: number | null;
                  } | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                };
                referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
                referencesMedia: Array<{
                  __typename: "ContentMedia";
                  title: string;
                  language: any;
                  mimeType: string;
                  url?: any | null;
                  id: any;
                  visible?: boolean | null;
                  contentMediaContext?: ContentMediaContext | null;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referencesQuestions: Array<{ __typename: "Question"; id: any }>;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              contentMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              groupNotificationTemplate?: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationPreference?: {
                __typename: "NotificationPreference";
                defaultEnabled: boolean;
                editableByPatient: boolean;
                id: any;
                visible?: boolean | null;
                notificationPreferenceCategory: NotificationPreferenceCategory;
                notificationPreferenceDescription?: string | null;
                notificationPreferenceName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              } | null;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                visible?: boolean | null;
                notificationTemplateName: string;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                notificationContents: Array<{
                  __typename: "NotificationContent";
                  contentKind: NotificationContentKind;
                  content: string;
                  id: any;
                  visible?: boolean | null;
                  notificationContentName: string;
                  notificationContentLanguage: any;
                  notificationTemplate: {
                    __typename?: "NotificationTemplate";
                    id: any;
                  };
                  notificationContentReferencesMedia: Array<{
                    __typename: "ContentMedia";
                    title: string;
                    language: any;
                    mimeType: string;
                    url?: any | null;
                    id: any;
                    visible?: boolean | null;
                    contentMediaContext?: ContentMediaContext | null;
                    alternativeNames: Array<{
                      __typename: "AlternativeNameEntry";
                      key: AlternativeName;
                      value: string;
                    }>;
                    referenceInfo: {
                      __typename: "NodeReferenceInfo";
                      id: any;
                      containers: Array<{
                        __typename?: "NodeReferenceContainer";
                        id: any;
                        nodeType: NodeType;
                        name: string;
                      }>;
                    };
                  }>;
                  alternativeNames: Array<{
                    __typename: "AlternativeNameEntry";
                    key: AlternativeName;
                    value: string;
                  }>;
                  referenceInfo: {
                    __typename: "NodeReferenceInfo";
                    id: any;
                    containers: Array<{
                      __typename?: "NodeReferenceContainer";
                      id: any;
                      nodeType: NodeType;
                      name: string;
                    }>;
                  };
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              survey?: { __typename: "Survey"; id: any } | null;
              task?: { __typename: "Task"; id: any } | null;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            contentDoc?: {
              __typename: "ContentDoc";
              useContentJson: boolean;
              language: any;
              slug?: string | null;
              url?: any | null;
              previewImageUrl?: any | null;
              id: any;
              visible?: boolean | null;
              contentDocTitle?: string | null;
              contentDocExternalId?: any | null;
              contentDocContext: string;
              docContext: {
                __typename: "ContentDocContext";
                name: string;
                slug?: string | null;
                description?: string | null;
                exampleImageUrl?: any | null;
                id: any;
                visible?: boolean | null;
                breakpointBase?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointSmall?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointMedium?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                breakpointExtraLarge?: {
                  __typename?: "ContentDocContainerSizes";
                  minWidthPx?: number | null;
                  widthPx?: number | null;
                  maxWidthPx?: number | null;
                } | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              };
              referencesDocs: Array<{ __typename: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                title: string;
                language: any;
                mimeType: string;
                url?: any | null;
                id: any;
                visible?: boolean | null;
                contentMediaContext?: ContentMediaContext | null;
                alternativeNames: Array<{
                  __typename: "AlternativeNameEntry";
                  key: AlternativeName;
                  value: string;
                }>;
                referenceInfo: {
                  __typename: "NodeReferenceInfo";
                  id: any;
                  containers: Array<{
                    __typename?: "NodeReferenceContainer";
                    id: any;
                    nodeType: NodeType;
                    name: string;
                  }>;
                };
              }>;
              referencesQuestions: Array<{ __typename: "Question"; id: any }>;
              alternativeNames: Array<{
                __typename: "AlternativeNameEntry";
                key: AlternativeName;
                value: string;
              }>;
              referenceInfo: {
                __typename: "NodeReferenceInfo";
                id: any;
                containers: Array<{
                  __typename?: "NodeReferenceContainer";
                  id: any;
                  nodeType: NodeType;
                  name: string;
                }>;
              };
            } | null;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          }>;
          task?: {
            __typename: "Task";
            occurrenceSpecifier: OccurrenceSpecifier;
            eventDelta: any;
            id: any;
            visible?: boolean | null;
            taskCategory: TaskCategory;
            taskDescription?: string | null;
            taskName: string;
            alternativeNames: Array<{
              __typename: "AlternativeNameEntry";
              key: AlternativeName;
              value: string;
            }>;
            referenceInfo: {
              __typename: "NodeReferenceInfo";
              id: any;
              containers: Array<{
                __typename?: "NodeReferenceContainer";
                id: any;
                nodeType: NodeType;
                name: string;
              }>;
            };
          } | null;
          referenceInfo: {
            __typename: "NodeReferenceInfo";
            id: any;
            containers: Array<{
              __typename?: "NodeReferenceContainer";
              id: any;
              nodeType: NodeType;
              name: string;
            }>;
          };
        } | null;
        referenceInfo: {
          __typename: "NodeReferenceInfo";
          id: any;
          containers: Array<{
            __typename?: "NodeReferenceContainer";
            id: any;
            nodeType: NodeType;
            name: string;
          }>;
        };
      }
  >;
};

export type HasMatchRuleQueryVariables = Exact<{
  providerLocationId: Scalars["UUID"];
  diagnosisId: Scalars["UUID"];
  treatmentPlanId: Scalars["UUID"];
}>;

export type HasMatchRuleQuery = { __typename?: "Query"; hasMatchRule: boolean };

export type ListAvailableSurveysQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListAvailableSurveysQuery = {
  __typename?: "Query";
  listAvailableSurveys: Array<{
    __typename?: "SurveyMonkeySurvey";
    id?: string | null;
    name?: string | null;
    url?: any | null;
  }>;
};

export type NotificationTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationTemplatesQuery = {
  __typename?: "Query";
  notificationTemplates: Array<{
    __typename: "NotificationTemplate";
    id: any;
    name: string;
    notificationContents: Array<{
      __typename?: "NotificationContent";
      id: any;
      contentKind: NotificationContentKind;
      content: string;
    }>;
  }>;
};

export type PatientsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<
    Array<PatientSearchFilterInput> | PatientSearchFilterInput
  >;
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<Array<PatientSearchSort> | PatientSearchSort>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type PatientsQuery = {
  __typename?: "Query";
  patients: {
    __typename?: "PatientConnection";
    totalCount?: number | null;
    edges?: Array<{
      __typename?: "PatientEdge";
      score?: number | null;
      highlights?: Array<{
        __typename?: "PatientHighlight";
        field: QueryField;
        html: string;
        details: string;
      }> | null;
      node: {
        __typename: "Patient";
        lastUpdated: any;
        id?: any | null;
        demographicsDeleted?: any | null;
        cohorts: Array<{ __typename: "PatientCohort"; id: any; name: string }>;
        enrollments?: Array<{
          __typename: "PatientEnrollment";
          id: any;
          type: EnrollmentType;
          externalId: string;
          createdAt: any;
        }> | null;
        providers?: {
          __typename: "PatientProviders";
          courseProviderLocations: Array<{
            __typename: "CourseProviderLocation";
            courseProviderType: CourseProviderType;
            providerLocation: {
              __typename: "ProviderLocation";
              id: any;
              active: boolean;
              provider: { __typename?: "Provider"; id: any; fullName: string };
              practice: {
                __typename: "Practice";
                id: any;
                name: string;
                organization?: {
                  __typename: "Organization";
                  id: any;
                  name: string;
                } | null;
              };
            };
          }>;
        } | null;
        moduleVisits?: {
          __typename?: "PatientModuleVisits";
          count: number;
          latestVisit: any;
        } | null;
        demographics?: {
          __typename: "PatientDemographics";
          id: any;
          dateOfBirth: any;
          sexAssignedAtBirth?: SexAssignedAtBirth | null;
          firstName: string;
          lastName: string;
          patientType: PatientType;
          patientIdentifiers: Array<{
            __typename?: "PatientIdentifierValue";
            value?: string | null;
            patientIdentifier: {
              __typename: "PatientIdentifier";
              id: any;
              name: string;
            };
          }>;
          person?: {
            __typename?: "Person";
            id: any;
            lastName: string;
            firstName: string;
            phoneNumber: string;
            emailAddresses?: Array<string> | null;
            language?: any | null;
            timeZone: any;
            postalAddresses?: Array<{
              __typename?: "Address";
              id: any;
              street1: string;
              street2?: string | null;
              city: string;
              state: string;
              postalCode: string;
              country: any;
            }> | null;
          } | null;
        } | null;
        courses: Array<{
          __typename: "Course";
          status: TreatmentStatus;
          id: any;
          diagnoses: Array<{ __typename: "Diagnosis"; id: any; name: string }>;
          treatmentPlanOccurrences: Array<{
            __typename: "TreatmentPlanOccurrence";
            id: any;
            status: TreatmentPlanOccurrenceStatus;
            treatmentStartDate?: any | null;
            treatmentPlan: {
              __typename: "TreatmentPlan";
              id: any;
              name: string;
            };
            surveys: Array<{
              __typename: "SurveyOccurrence";
              id: any;
              eventDate: any;
              completedAt?: any | null;
              providerAckDate?: any | null;
              survey: { __typename?: "Survey"; id: any; name?: string | null };
            }>;
            tasks: Array<{
              __typename?: "TaskOccurrence";
              id: any;
              eventDate: any;
              allDay?: boolean | null;
              status: TaskStatus;
              statusChangedAt?: any | null;
              task: { __typename?: "Task"; id: any; name: string };
            }>;
          }>;
        }>;
      };
    }> | null;
  };
};

export type PreviewTemplatizedMessageQueryVariables = Exact<{
  notificationTemplateId: Scalars["UUID"];
  patientId: Scalars["UUID"];
}>;

export type PreviewTemplatizedMessageQuery = {
  __typename?: "Query";
  previewTemplatizedMessage?: {
    __typename?: "PreviewTemplatizedMessageResult";
    content: string;
  } | null;
};

export type ProviderMatchesQueryVariables = Exact<{
  courseId: Scalars["UUID"];
}>;

export type ProviderMatchesQuery = {
  __typename?: "Query";
  providerMatches?: {
    __typename?: "MatchResults";
    courseId: any;
    matches: Array<{
      __typename: "MatchOccurrence";
      id: any;
      courseId: any;
      description?: string | null;
      name: string;
      score: number;
      treatmentPlanId: any;
    }>;
  } | null;
};

export type ProviderSettingsQueryVariables = Exact<{
  providerId: Scalars["UUID"];
}>;

export type ProviderSettingsQuery = {
  __typename?: "Query";
  treatmentPlans: Array<{
    __typename: "TreatmentPlan";
    id: any;
    name: string;
    description?: string | null;
    protocolName?: string | null;
    initialView?: PlanView | null;
    media: Array<{
      __typename: "ContentMedia";
      id: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
    onboardingFlow?: {
      __typename?: "Flow";
      id: any;
      steps: Array<{
        __typename?: "FlowStep";
        id: any;
        presentation:
          | { __typename?: "ContentDoc"; id: any }
          | {
              __typename?: "Notification";
              id: any;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                name: string;
                notificationContents: Array<{
                  __typename?: "NotificationContent";
                  id: any;
                  contentKind: NotificationContentKind;
                  content: string;
                }>;
              };
            }
          | { __typename?: "Survey"; id: any };
      }>;
    } | null;
    patientIdentifiers: Array<{
      __typename: "PatientIdentifier";
      description?: string | null;
      required: boolean;
      uniquePerIdentifierContext: boolean;
      id: any;
      name: string;
    }>;
  }>;
  diagnoses: Array<{ __typename: "Diagnosis"; id: any; name: string }>;
  patientIdentifiers: Array<{
    __typename: "PatientIdentifier";
    description?: string | null;
    id: any;
    name: string;
  }>;
  provider: {
    __typename?: "Provider";
    id: any;
    name: Array<string>;
    locations: Array<{
      __typename?: "ProviderLocation";
      id: any;
      active: boolean;
      practice: {
        __typename?: "Practice";
        id: any;
        name: string;
        organization?: {
          __typename?: "Organization";
          id: any;
          name: string;
        } | null;
      };
      patientIdentifiers: Array<{
        __typename: "PatientIdentifier";
        description?: string | null;
        required: boolean;
        uniquePerIdentifierContext: boolean;
        id: any;
        name: string;
      }>;
    }>;
  };
  matchRules: Array<{
    __typename?: "MatchRule";
    id: any;
    providerLocation: { __typename?: "ProviderLocation"; id: any };
    diagnosis: { __typename?: "Diagnosis"; id: any };
    treatmentPlan: { __typename?: "TreatmentPlan"; id: any };
  }>;
};

export type RenderCalendarQueryVariables = Exact<{
  request: CalendarInput;
  from?: InputMaybe<Scalars["OffsetDateTime"]>;
  to?: InputMaybe<Scalars["OffsetDateTime"]>;
  format?: InputMaybe<CalendarSerializationFormat>;
}>;

export type RenderCalendarQuery = {
  __typename?: "Query";
  renderCalendar: {
    __typename?: "Calendar";
    timezone?: any | null;
    calendarEventTemplates: Array<{
      __typename?: "CalendarEventTemplate";
      id: any;
      name: string;
      description?: string | null;
      category?: CalendarEventCategory | null;
      color?: any | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        id: any;
        contentJson?: any | null;
        contentHtml?: string | null;
        context: string;
        useContentJson: boolean;
        docContext: { __typename: "ContentDocContext"; id: any };
        referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          id: any;
          language: any;
          context?: ContentMediaContext | null;
          mimeType: string;
          url?: any | null;
        }>;
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        id: any;
        language: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
      dependentEvents: Array<{ __typename?: "CalendarEventTemplate"; id: any }>;
    }>;
    events: Array<{
      __typename?: "CalendarEvent";
      id: string;
      calendarEventTemplate?: {
        __typename: "CalendarEventTemplate";
        id: any;
      } | null;
      occurrences: Array<{
        __typename?: "CalendarEventOccurrence";
        id: string;
        description?: string | null;
        name: string;
        timeDescriptionOverride?: string | null;
        editableParameterValues: Array<{
          __typename?: "EditableParameterValue";
          editableParameter: {
            __typename?: "EditableParameter";
            id: any;
            scopeSelector: ParameterScopeSelector;
            andFollowing: boolean;
            parameter: {
              __typename?: "Parameter";
              id: any;
              label: string;
              type: ParameterType;
              required: boolean;
              multiselect: boolean;
              description?: string | null;
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
            };
            contentDocs: Array<{
              __typename: "ContentDoc";
              id: any;
              contentJson?: any | null;
              contentHtml?: string | null;
              context: string;
              useContentJson: boolean;
              docContext: { __typename: "ContentDocContext"; id: any };
              referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                id: any;
                language: any;
                context?: ContentMediaContext | null;
                mimeType: string;
                url?: any | null;
              }>;
            }>;
          };
          value: {
            __typename?: "TreatmentPlanParameterValue";
            id: any;
            transient: boolean;
            value?: string | null;
            parameter: {
              __typename?: "Parameter";
              id: any;
              label: string;
              type: ParameterType;
              required: boolean;
              multiselect: boolean;
              description?: string | null;
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
            };
            scope?: {
              __typename?: "CalendarEventScope";
              string: string;
            } | null;
          };
        }>;
        scope: { __typename?: "CalendarEventScope"; string: string };
        timing: {
          __typename?: "CalendarEventTiming";
          start: any;
          end: any;
          allDay: boolean;
        };
      }>;
    }>;
    parameters: Array<{
      __typename: "TreatmentPlanParameterValue";
      id: any;
      transient: boolean;
      value?: string | null;
      parameter: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      scope?: { __typename?: "CalendarEventScope"; string: string } | null;
    }>;
  };
};

export type RenderCalendarPdfQueryVariables = Exact<{
  correlationId: Scalars["UUID"];
  request: CalendarInput;
  pdfRequest?: InputMaybe<CalendarPdfRequest>;
}>;

export type RenderCalendarPdfQuery = {
  __typename?: "Query";
  renderCalendarPDF: { __typename?: "CalendarPDF"; blob?: any | null };
};

export type RenderCalendarPlanningQueryVariables = Exact<{
  request: CalendarInput;
  from?: InputMaybe<Scalars["OffsetDateTime"]>;
  to?: InputMaybe<Scalars["OffsetDateTime"]>;
  format?: InputMaybe<CalendarSerializationFormat>;
}>;

export type RenderCalendarPlanningQuery = {
  __typename?: "Query";
  renderCalendar: {
    __typename?: "Calendar";
    timezone?: any | null;
    calendarEventTemplates: Array<{
      __typename?: "CalendarEventTemplate";
      id: any;
      name: string;
      description?: string | null;
      category?: CalendarEventCategory | null;
      color?: any | null;
      contentDocs: Array<{
        __typename: "ContentDoc";
        id: any;
        contentJson?: any | null;
        contentHtml?: string | null;
        context: string;
        useContentJson: boolean;
        docContext: { __typename: "ContentDocContext"; id: any };
        referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
        referencesMedia: Array<{
          __typename: "ContentMedia";
          id: any;
          language: any;
          context?: ContentMediaContext | null;
          mimeType: string;
          url?: any | null;
        }>;
      }>;
      contentMedia: Array<{
        __typename: "ContentMedia";
        id: any;
        language: any;
        context?: ContentMediaContext | null;
        mimeType: string;
        url?: any | null;
      }>;
      dependentEvents: Array<{ __typename?: "CalendarEventTemplate"; id: any }>;
    }>;
    events: Array<{
      __typename?: "CalendarEvent";
      id: string;
      calendarEventTemplate?: {
        __typename: "CalendarEventTemplate";
        id: any;
      } | null;
      occurrences: Array<{
        __typename?: "CalendarEventOccurrence";
        id: string;
        description?: string | null;
        name: string;
        timeDescriptionOverride?: string | null;
        editableParameterValues: Array<{
          __typename?: "EditableParameterValue";
          editableParameter: {
            __typename?: "EditableParameter";
            id: any;
            scopeSelector: ParameterScopeSelector;
            andFollowing: boolean;
            parameter: {
              __typename?: "Parameter";
              id: any;
              label: string;
              type: ParameterType;
              required: boolean;
              multiselect: boolean;
              description?: string | null;
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
            };
            contentDocs: Array<{
              __typename: "ContentDoc";
              id: any;
              contentJson?: any | null;
              contentHtml?: string | null;
              context: string;
              useContentJson: boolean;
              docContext: { __typename: "ContentDocContext"; id: any };
              referencesDocs: Array<{ __typename?: "ContentDoc"; id: any }>;
              referencesMedia: Array<{
                __typename: "ContentMedia";
                id: any;
                language: any;
                context?: ContentMediaContext | null;
                mimeType: string;
                url?: any | null;
              }>;
            }>;
          };
          value: {
            __typename?: "TreatmentPlanParameterValue";
            id: any;
            transient: boolean;
            value?: string | null;
            parameter: {
              __typename?: "Parameter";
              id: any;
              label: string;
              type: ParameterType;
              required: boolean;
              multiselect: boolean;
              description?: string | null;
              defaultValue?: string | null;
              editableBy: ParameterEditableBy;
            };
            scope?: {
              __typename?: "CalendarEventScope";
              string: string;
            } | null;
          };
        }>;
        scope: { __typename?: "CalendarEventScope"; string: string };
        timing: {
          __typename?: "CalendarEventTiming";
          start: any;
          end: any;
          allDay: boolean;
        };
      }>;
    }>;
    parameters: Array<{
      __typename: "TreatmentPlanParameterValue";
      id: any;
      transient: boolean;
      value?: string | null;
      parameter: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      };
      scope?: { __typename?: "CalendarEventScope"; string: string } | null;
    }>;
  };
};

export type ScenariosQueryVariables = Exact<{ [key: string]: never }>;

export type ScenariosQuery = {
  __typename?: "Query";
  scenarios: Array<{
    __typename: "Scenario";
    id: string;
    name: string;
    description: string;
    variables?: Array<{
      __typename: "ScenarioVariable";
      name: string;
      description: string;
      type: ScenarioVariableType;
      required: boolean;
    }> | null;
  }>;
};

export type StaticCohortsQueryVariables = Exact<{
  organizationId: Scalars["UUID"];
}>;

export type StaticCohortsQuery = {
  __typename?: "Query";
  staticCohorts: Array<{ __typename: "StaticCohort"; id: any; name: string }>;
};

export type SuggestQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
}>;

export type SuggestQuery = {
  __typename?: "Query";
  suggest?: {
    __typename?: "PatientSuggestResult";
    filters?: Array<{
      __typename?: "PatientFilterSuggestion";
      text: string;
      score: number;
      field: FilterField;
      subjectType: AnythingType;
      subject?: any | null;
      subjectName: string;
      operator: SearchOperator;
      valueType: ValueType;
      value: string;
    }> | null;
  } | null;
};

export type SuggestElementsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  types?: InputMaybe<Array<AnythingType> | AnythingType>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Array<AnythingSearchSort> | AnythingSearchSort>;
  prefixQuery?: InputMaybe<Scalars["Boolean"]>;
  alternativeNames?: InputMaybe<Array<AlternativeName> | AlternativeName>;
  visible?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SuggestElementsQuery = {
  __typename?: "Query";
  anythings?: {
    __typename?: "AnythingConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "AnythingEdge";
      score: number;
      node: {
        __typename: "Anything";
        active: boolean;
        id: any;
        lastUpdated: any;
        name: string;
        type: AnythingType;
        visible: boolean;
        alternativeNames: Array<{
          __typename?: "AlternativeNameEntry";
          key: AlternativeName;
          value: string;
        }>;
      };
    }>;
  } | null;
};

export type TreatingProviderLocationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TreatingProviderLocationsQuery = {
  __typename?: "Query";
  treatingProviderLocations: Array<{
    __typename?: "ProviderLocation";
    id: any;
    active: boolean;
    provider: {
      __typename?: "Provider";
      id: any;
      name: Array<string>;
      locations: Array<{
        __typename?: "ProviderLocation";
        id: any;
        active: boolean;
        practice: {
          __typename?: "Practice";
          id: any;
          name: string;
          organization?: {
            __typename?: "Organization";
            id: any;
            name: string;
          } | null;
        };
        patientIdentifiers: Array<{
          __typename: "PatientIdentifier";
          description?: string | null;
          required: boolean;
          uniquePerIdentifierContext: boolean;
          id: any;
          name: string;
        }>;
      }>;
    };
    practice: {
      __typename?: "Practice";
      id: any;
      name: string;
      organization?: {
        __typename?: "Organization";
        id: any;
        name: string;
      } | null;
    };
    patientLanguages?: Array<{
      __typename?: "AvailableLanguage";
      name: string;
      tag: any;
    }> | null;
  }>;
};

export type TreatmentPlanQueryVariables = Exact<{
  treatmentPlanId: Scalars["UUID"];
}>;

export type TreatmentPlanQuery = {
  __typename?: "Query";
  treatmentPlan: {
    __typename: "TreatmentPlan";
    id: any;
    name: string;
    description?: string | null;
    protocolName?: string | null;
    initialView?: PlanView | null;
    media: Array<{
      __typename: "ContentMedia";
      id: any;
      context?: ContentMediaContext | null;
      mimeType: string;
      url?: any | null;
    }>;
    onboardingFlow?: {
      __typename?: "Flow";
      id: any;
      steps: Array<{
        __typename?: "FlowStep";
        id: any;
        presentation:
          | { __typename?: "ContentDoc"; id: any }
          | {
              __typename?: "Notification";
              id: any;
              notificationTemplate: {
                __typename: "NotificationTemplate";
                id: any;
                name: string;
                notificationContents: Array<{
                  __typename?: "NotificationContent";
                  id: any;
                  contentKind: NotificationContentKind;
                  content: string;
                }>;
              };
            }
          | { __typename?: "Survey"; id: any };
      }>;
    } | null;
    patientIdentifiers: Array<{
      __typename: "PatientIdentifier";
      description?: string | null;
      required: boolean;
      uniquePerIdentifierContext: boolean;
      id: any;
      name: string;
    }>;
    rootLayer?: {
      __typename: "Layer";
      id: any;
      name: string;
      description?: string | null;
      parameters: Array<{
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      }>;
      layerGroups: Array<{
        __typename: "LayerGroup";
        id: any;
        name: string;
        description?: string | null;
        required: boolean;
        multiple: boolean;
        layers: Array<{ __typename?: "Layer"; id: any }>;
      }>;
      enabledParameter?: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      } | null;
    } | null;
    layers: Array<{
      __typename: "Layer";
      id: any;
      name: string;
      description?: string | null;
      parameters: Array<{
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      }>;
      layerGroups: Array<{
        __typename: "LayerGroup";
        id: any;
        name: string;
        description?: string | null;
        required: boolean;
        multiple: boolean;
        layers: Array<{ __typename?: "Layer"; id: any }>;
      }>;
      enabledParameter?: {
        __typename: "Parameter";
        id: any;
        label: string;
        type: ParameterType;
        required: boolean;
        multiselect: boolean;
        description?: string | null;
        defaultValue?: string | null;
        editableBy: ParameterEditableBy;
      } | null;
    }>;
    startDateParameter?: {
      __typename: "Parameter";
      id: any;
      label: string;
      type: ParameterType;
      required: boolean;
      multiselect: boolean;
      description?: string | null;
      defaultValue?: string | null;
      editableBy: ParameterEditableBy;
    } | null;
    invitationTemplate?: {
      __typename?: "NotificationTemplate";
      id: any;
    } | null;
  };
};

export type WhoamiQueryVariables = Exact<{
  withProvider?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WhoamiQuery = {
  __typename?: "Query";
  whoami?: {
    __typename: "UserInfo";
    isAuthenticated?: boolean | null;
    name?: string | null;
    username?: string | null;
    canEnroll: boolean;
    canSeePhi: boolean;
    shouldNotSeePromoContent: boolean;
    provider?: {
      __typename: "Provider";
      id: any;
      name: Array<string>;
      locations: Array<{
        __typename: "ProviderLocation";
        id: any;
        practice: {
          __typename: "Practice";
          id: any;
          name: string;
          organization?: {
            __typename: "Organization";
            id: any;
            name: string;
          } | null;
        };
      }>;
    } | null;
  } | null;
};

type ExistingNode_Brand_Fragment = { __typename?: "Brand"; id: any };

type ExistingNode_CalendarEventTemplate_Fragment = {
  __typename?: "CalendarEventTemplate";
  id: any;
};

type ExistingNode_Confirmation_Fragment = {
  __typename?: "Confirmation";
  id: any;
};

type ExistingNode_ContentDoc_Fragment = { __typename?: "ContentDoc"; id: any };

type ExistingNode_ContentDocContext_Fragment = {
  __typename?: "ContentDocContext";
  id: any;
};

type ExistingNode_ContentMedia_Fragment = {
  __typename?: "ContentMedia";
  id: any;
};

type ExistingNode_Diagnosis_Fragment = { __typename?: "Diagnosis"; id: any };

type ExistingNode_EditableParameter_Fragment = {
  __typename?: "EditableParameter";
  id: any;
};

type ExistingNode_Flow_Fragment = { __typename?: "Flow"; id: any };

type ExistingNode_FlowStep_Fragment = { __typename?: "FlowStep"; id: any };

type ExistingNode_Layer_Fragment = { __typename?: "Layer"; id: any };

type ExistingNode_LayerGroup_Fragment = { __typename?: "LayerGroup"; id: any };

type ExistingNode_Measure_Fragment = { __typename?: "Measure"; id: any };

type ExistingNode_Module_Fragment = { __typename?: "Module"; id: any };

type ExistingNode_Notification_Fragment = {
  __typename?: "Notification";
  id: any;
};

type ExistingNode_NotificationContent_Fragment = {
  __typename?: "NotificationContent";
  id: any;
};

type ExistingNode_NotificationPreference_Fragment = {
  __typename?: "NotificationPreference";
  id: any;
};

type ExistingNode_NotificationTemplate_Fragment = {
  __typename?: "NotificationTemplate";
  id: any;
};

type ExistingNode_Parameter_Fragment = { __typename?: "Parameter"; id: any };

type ExistingNode_ParameterMapping_Fragment = {
  __typename?: "ParameterMapping";
  id: any;
};

type ExistingNode_Phase_Fragment = { __typename?: "Phase"; id: any };

type ExistingNode_PlanItem_Fragment = { __typename?: "PlanItem"; id: any };

type ExistingNode_Question_Fragment = { __typename?: "Question"; id: any };

type ExistingNode_Resource_Fragment = { __typename?: "Resource"; id: any };

type ExistingNode_ResourceCategory_Fragment = {
  __typename?: "ResourceCategory";
  id: any;
};

type ExistingNode_ResourceReference_Fragment = {
  __typename?: "ResourceReference";
  id: any;
};

type ExistingNode_Survey_Fragment = { __typename?: "Survey"; id: any };

type ExistingNode_Task_Fragment = { __typename?: "Task"; id: any };

type ExistingNode_Treatment_Fragment = { __typename?: "Treatment"; id: any };

type ExistingNode_TreatmentList_Fragment = {
  __typename?: "TreatmentList";
  id: any;
};

type ExistingNode_TreatmentPlan_Fragment = {
  __typename?: "TreatmentPlan";
  id: any;
};

export type ExistingNodeFragment =
  | ExistingNode_Brand_Fragment
  | ExistingNode_CalendarEventTemplate_Fragment
  | ExistingNode_Confirmation_Fragment
  | ExistingNode_ContentDoc_Fragment
  | ExistingNode_ContentDocContext_Fragment
  | ExistingNode_ContentMedia_Fragment
  | ExistingNode_Diagnosis_Fragment
  | ExistingNode_EditableParameter_Fragment
  | ExistingNode_Flow_Fragment
  | ExistingNode_FlowStep_Fragment
  | ExistingNode_Layer_Fragment
  | ExistingNode_LayerGroup_Fragment
  | ExistingNode_Measure_Fragment
  | ExistingNode_Module_Fragment
  | ExistingNode_Notification_Fragment
  | ExistingNode_NotificationContent_Fragment
  | ExistingNode_NotificationPreference_Fragment
  | ExistingNode_NotificationTemplate_Fragment
  | ExistingNode_Parameter_Fragment
  | ExistingNode_ParameterMapping_Fragment
  | ExistingNode_Phase_Fragment
  | ExistingNode_PlanItem_Fragment
  | ExistingNode_Question_Fragment
  | ExistingNode_Resource_Fragment
  | ExistingNode_ResourceCategory_Fragment
  | ExistingNode_ResourceReference_Fragment
  | ExistingNode_Survey_Fragment
  | ExistingNode_Task_Fragment
  | ExistingNode_Treatment_Fragment
  | ExistingNode_TreatmentList_Fragment
  | ExistingNode_TreatmentPlan_Fragment;

export const NavioCalendarUiContentDocContextFragmentDoc = gql`
  fragment NavioCalendarUiContentDocContext on ContentDocContext {
    __typename
    id
  }
`;
export const NavioCalendarUiContentMediaFragmentDoc = gql`
  fragment NavioCalendarUiContentMedia on ContentMedia {
    __typename
    id
    language
    context
    mimeType
    url
  }
`;
export const NavioCalendarUiContentDocFragmentDoc = gql`
  fragment NavioCalendarUiContentDoc on ContentDoc {
    __typename
    id
    contentJson
    contentHtml
    context
    useContentJson
    docContext {
      ...NavioCalendarUiContentDocContext
    }
    referencesDocs {
      id
    }
    referencesMedia {
      ...NavioCalendarUiContentMedia
    }
  }
  ${NavioCalendarUiContentDocContextFragmentDoc}
  ${NavioCalendarUiContentMediaFragmentDoc}
`;
export const NavioCalendarUiCalendarEventTemplateFragmentDoc = gql`
  fragment NavioCalendarUiCalendarEventTemplate on CalendarEventTemplate {
    id
    name
    description
    category
    color
    contentDocs {
      ...NavioCalendarUiContentDoc
    }
    contentMedia {
      ...NavioCalendarUiContentMedia
    }
    dependentEvents {
      id
    }
  }
  ${NavioCalendarUiContentDocFragmentDoc}
  ${NavioCalendarUiContentMediaFragmentDoc}
`;
export const NavioCalendarUiParameterFragmentDoc = gql`
  fragment NavioCalendarUiParameter on Parameter {
    id
    label
    type
    required
    multiselect
    description
    defaultValue
    editableBy
  }
`;
export const NavioCalendarUiEditableParameterFragmentDoc = gql`
  fragment NavioCalendarUiEditableParameter on EditableParameter {
    id
    parameter {
      ...NavioCalendarUiParameter
    }
    scopeSelector
    andFollowing
    contentDocs {
      ...NavioCalendarUiContentDoc
    }
  }
  ${NavioCalendarUiParameterFragmentDoc}
  ${NavioCalendarUiContentDocFragmentDoc}
`;
export const NavioCalendarUiCalendarEventScopeFragmentDoc = gql`
  fragment NavioCalendarUiCalendarEventScope on CalendarEventScope {
    string
  }
`;
export const NavioCalendarUiTreatmentPlanParameterValueFragmentDoc = gql`
  fragment NavioCalendarUiTreatmentPlanParameterValue on TreatmentPlanParameterValue {
    id
    parameter {
      ...NavioCalendarUiParameter
    }
    scope {
      ...NavioCalendarUiCalendarEventScope
    }
    transient
    value
  }
  ${NavioCalendarUiParameterFragmentDoc}
  ${NavioCalendarUiCalendarEventScopeFragmentDoc}
`;
export const NavioCalendarUiEditableParameterValueFragmentDoc = gql`
  fragment NavioCalendarUiEditableParameterValue on EditableParameterValue {
    editableParameter {
      ...NavioCalendarUiEditableParameter
    }
    value {
      ...NavioCalendarUiTreatmentPlanParameterValue
    }
  }
  ${NavioCalendarUiEditableParameterFragmentDoc}
  ${NavioCalendarUiTreatmentPlanParameterValueFragmentDoc}
`;
export const NavioCalendarUiCalendarEventOccurrenceFragmentDoc = gql`
  fragment NavioCalendarUiCalendarEventOccurrence on CalendarEventOccurrence {
    id
    description
    editableParameterValues {
      ...NavioCalendarUiEditableParameterValue
    }
    name
    scope {
      ...NavioCalendarUiCalendarEventScope
    }
    timing {
      start
      end
      allDay
    }
    timeDescriptionOverride
  }
  ${NavioCalendarUiEditableParameterValueFragmentDoc}
  ${NavioCalendarUiCalendarEventScopeFragmentDoc}
`;
export const NavioCalendarUiCalendarEventFragmentDoc = gql`
  fragment NavioCalendarUiCalendarEvent on CalendarEvent {
    id
    calendarEventTemplate {
      id
    }
    occurrences {
      ...NavioCalendarUiCalendarEventOccurrence
    }
  }
  ${NavioCalendarUiCalendarEventOccurrenceFragmentDoc}
`;
export const NavioCalendarUiCalendarFragmentDoc = gql`
  fragment NavioCalendarUiCalendar on Calendar {
    __typename
    calendarEventTemplates {
      ...NavioCalendarUiCalendarEventTemplate
    }
    events(from: $from, to: $to) {
      ...NavioCalendarUiCalendarEvent
    }
    parameters {
      ...NavioCalendarUiTreatmentPlanParameterValue
    }
  }
  ${NavioCalendarUiCalendarEventTemplateFragmentDoc}
  ${NavioCalendarUiCalendarEventFragmentDoc}
  ${NavioCalendarUiTreatmentPlanParameterValueFragmentDoc}
`;
export const NodeReferenceInfoFragmentDoc = gql`
  fragment NodeReferenceInfo on NodeReferenceInfo {
    __typename
    id
    containers {
      id
      nodeType
      name
    }
  }
`;
export const TreatmentPlanNodeInterfaceFragmentDoc = gql`
  fragment TreatmentPlanNodeInterface on TreatmentPlanNode {
    __typename
    id
    visible
    referenceInfo {
      ...NodeReferenceInfo
    }
  }
  ${NodeReferenceInfoFragmentDoc}
`;
export const AlternativeNameEntryFragmentDoc = gql`
  fragment AlternativeNameEntry on AlternativeNameEntry {
    __typename
    key
    value
  }
`;
export const ContentMediaNodeFragmentDoc = gql`
  fragment ContentMediaNode on ContentMedia {
    ...TreatmentPlanNodeInterface
    title
    language
    contentMediaContext: context
    mimeType
    url
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const BrandNodeFragmentDoc = gql`
  fragment BrandNode on Brand {
    ...TreatmentPlanNodeInterface
    id
    name
    alternativeNames {
      ...AlternativeNameEntry
    }
    domainPrefix
    emailFromName
    emailFromAddress
    smsSenderId
    designTokens
    contentMedia {
      ...ContentMediaNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
`;
export const ContentDocContainerSizesFragmentDoc = gql`
  fragment ContentDocContainerSizes on ContentDocContainerSizes {
    minWidthPx
    widthPx
    maxWidthPx
  }
`;
export const ContentDocContextNodeFragmentDoc = gql`
  fragment ContentDocContextNode on ContentDocContext {
    ...TreatmentPlanNodeInterface
    name
    slug
    description
    exampleImageUrl
    breakpointBase {
      ...ContentDocContainerSizes
    }
    breakpointSmall {
      ...ContentDocContainerSizes
    }
    breakpointMedium {
      ...ContentDocContainerSizes
    }
    breakpointLarge {
      ...ContentDocContainerSizes
    }
    breakpointExtraLarge {
      ...ContentDocContainerSizes
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${ContentDocContainerSizesFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const ContentDocNodeFragmentDoc = gql`
  fragment ContentDocNode on ContentDoc {
    ...TreatmentPlanNodeInterface
    contentDocTitle: title
    contentDocExternalId: externalId
    contentDocContext: context
    useContentJson
    docContext {
      ...ContentDocContextNode
    }
    referencesDocs {
      id
      __typename
    }
    referencesMedia {
      ...ContentMediaNode
    }
    referencesQuestions {
      id
      __typename
    }
    language
    slug
    url
    alternativeNames {
      ...AlternativeNameEntry
    }
    previewImageUrl
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${ContentDocContextNodeFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const ConfirmationNodeFragmentDoc = gql`
  fragment ConfirmationNode on Confirmation {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    contentDocs {
      ...ContentDocNode
    }
    confirmationDuration: duration
    eventDelta
    confirmationName: name
    occurrenceSpecifier
    parameters {
      __typename
      id
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
`;
export const ParameterNodeFragmentDoc = gql`
  fragment ParameterNode on Parameter {
    ...TreatmentPlanNodeInterface
    defaultValue
    editableBy
    label
    multiselect
    parameterDescription: description
    required
    type
    visibleFor
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const EditableParameterNodeFragmentDoc = gql`
  fragment EditableParameterNode on EditableParameter {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    andFollowing
    contentDocs {
      ...ContentDocNode
    }
    name
    parameter {
      ...ParameterNode
    }
    scopeSelector
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ParameterNodeFragmentDoc}
`;
export const NotificationContentNodeFragmentDoc = gql`
  fragment NotificationContentNode on NotificationContent {
    ...TreatmentPlanNodeInterface
    notificationTemplate {
      id
    }
    notificationContentName: name
    notificationContentLanguage: language
    contentKind
    content
    notificationContentReferencesMedia: referencesMedia {
      ...ContentMediaNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const NotificationTemplateNodeFragmentDoc = gql`
  fragment NotificationTemplateNode on NotificationTemplate {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    notificationTemplateName: name
    notificationContents {
      ...NotificationContentNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${NotificationContentNodeFragmentDoc}
`;
export const NotificationPreferenceNodeFragmentDoc = gql`
  fragment NotificationPreferenceNode on NotificationPreference {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    defaultEnabled
    editableByPatient
    notificationPreferenceCategory: category
    notificationPreferenceDescription: description
    notificationPreferenceName: name
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const NotificationNodeFragmentDoc = gql`
  fragment NotificationNode on Notification {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    confirmation {
      __typename
      id
    }
    contentDocs {
      ...ContentDocNode
    }
    contentMedia {
      ...ContentMediaNode
    }
    eventDelta
    groupNotificationTemplate {
      ...NotificationTemplateNode
    }
    notificationName: name
    notificationPreference {
      ...NotificationPreferenceNode
    }
    notificationTemplate {
      ...NotificationTemplateNode
    }
    occurrenceSpecifier
    snoozeableDuration
    survey {
      __typename
      id
    }
    task {
      __typename
      id
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
  ${NotificationTemplateNodeFragmentDoc}
  ${NotificationPreferenceNodeFragmentDoc}
`;
export const SurveyNodeFragmentDoc = gql`
  fragment SurveyNode on Survey {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    collectorId
    eventDelta
    surveyDuration: duration
    surveyName: name
    occurrenceSpecifier
    surveyId
    contentDocs {
      ...ContentDocNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
`;
export const TaskNodeFragmentDoc = gql`
  fragment TaskNode on Task {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    occurrenceSpecifier
    eventDelta
    taskCategory: category
    taskDescription: description
    taskName: name
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const FlowStepNodeFragmentDoc = gql`
  fragment FlowStepNode on FlowStep {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    name
    nextStep {
      id
    }
    uxData
    notification {
      ...NotificationNode
    }
    contentDoc {
      ...ContentDocNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${NotificationNodeFragmentDoc}
  ${ContentDocNodeFragmentDoc}
`;
export const FlowNodeFragmentDoc = gql`
  fragment FlowNode on Flow {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    name
    occurrenceSpecifier
    eventDelta
    flowDuration: duration
    restartAutomatically
    steps {
      ...FlowStepNode
    }
    task {
      ...TaskNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${FlowStepNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
`;
export const CalendarEventTemplateNodeFragmentDoc = gql`
  fragment CalendarEventTemplateNode on CalendarEventTemplate {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    blocking
    calendarEventTemplateDescription: description
    category
    color
    confirmations {
      ...ConfirmationNode
    }
    contentDocs {
      ...ContentDocNode
    }
    contentMedia {
      ...ContentMediaNode
    }
    duration {
      allDay
      length
    }
    editableParameters {
      ...EditableParameterNode
    }
    exceptHolidays
    icon
    name
    patientNameOverride
    notifications {
      ...NotificationNode
    }
    recurrenceStrategy {
      recurrenceFrequency
      recurrenceInterval
      repeatCountParameter {
        __typename
        id
      }
      repeatCount
      repeatUntilParameter {
        __typename
        id
      }
      repeatUntilEvent {
        __typename
        id
      }
      repeatUntilEventOccurrence
      inheritReschedules
      repeatOnAllAllowedDays
      countUntil
      repeatUntilDelta
      recurrenceRelation
    }
    rescheduleStrategy
    start {
      dateTimeParameter {
        __typename
        id
      }
      timeParameter {
        __typename
        id
      }
      relativeToEvent {
        __typename
        id
      }
      absoluteTime
      relativeToOccurrence
      projectionDirection
      allowedDaysParameter {
        __typename
        id
      }
      allowedDays
      offsetDuration
      offsetPeriod
    }
    surveys {
      ...SurveyNode
    }
    tasks {
      ...TaskNode
    }
    flows {
      ...FlowNode
    }
    timeDescriptionOverride
    visibleTo
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ConfirmationNodeFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
  ${EditableParameterNodeFragmentDoc}
  ${NotificationNodeFragmentDoc}
  ${SurveyNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
  ${FlowNodeFragmentDoc}
`;
export const ResourceNodeFragmentDoc = gql`
  fragment ResourceNode on Resource {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    contentDocs {
      ...ContentDocNode
    }
    name
    resourceDescription: description
    resourceUrl: url
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
`;
export const ResourceCategoryNodeFragmentDoc = gql`
  fragment ResourceCategoryNode on ResourceCategory {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    categoryWeight
    name
    resourceCategoryDescription: description
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const ResourceReferenceNodeFragmentDoc = gql`
  fragment ResourceReferenceNode on ResourceReference {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    globallyVisible
    name
    resource {
      ...ResourceNode
    }
    resourceCategory {
      ...ResourceCategoryNode
    }
    resourceWeight
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ResourceNodeFragmentDoc}
  ${ResourceCategoryNodeFragmentDoc}
`;
export const DiagnosisNodeFragmentDoc = gql`
  fragment DiagnosisNode on Diagnosis {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    contentDocs {
      ...ContentDocNode
    }
    diagnosisDescription: description
    diagnosisSlug: slug
    name
    resourceReferences {
      ...ResourceReferenceNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ResourceReferenceNodeFragmentDoc}
`;
export const LayerGroupNodeFragmentDoc = gql`
  fragment LayerGroupNode on LayerGroup {
    ...TreatmentPlanNodeInterface
    name
    layerGroupDescription: description
    required
    multiple
    layerGroupLayers: layers {
      __typename
      id
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const ParameterMappingNodeFragmentDoc = gql`
  fragment ParameterMappingNode on ParameterMapping {
    ...TreatmentPlanNodeInterface
    name
    offsetDuration
    offsetPeriod
    fromParameter {
      ...ParameterNode
    }
    toParameter {
      ...ParameterNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${ParameterNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const PhaseNodeFragmentDoc = gql`
  fragment PhaseNode on Phase {
    ...TreatmentPlanNodeInterface
    name
    phaseLayers: layers {
      __typename
      id
    }
    phaseStartDateParameter: startDateParameter {
      ...ParameterNode
    }
    repeatCountParameter {
      ...ParameterNode
    }
    totalCountParameter {
      ...ParameterNode
    }
    displayIndexParameter {
      ...ParameterNode
    }
    defaultDuration
    phaseParameters: parameters {
      ...ParameterNode
    }
    contentDocs {
      ...ContentDocNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${ParameterNodeFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const MeasureNodeFragmentDoc = gql`
  fragment MeasureNode on Measure {
    ...TreatmentPlanNodeInterface
    name
    measureDescription: description
    abbreviation
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const TreatmentNodeFragmentDoc = gql`
  fragment TreatmentNode on Treatment {
    ...TreatmentPlanNodeInterface
    name
    genericName
    brandName
    abbreviation
    alternativeNames {
      ...AlternativeNameEntry
    }
    contentDocs {
      ...ContentDocNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
`;
export const PlanItemNodeFragmentDoc = gql`
  fragment PlanItemNode on PlanItem {
    name
    ...TreatmentPlanNodeInterface
    measure {
      ...MeasureNode
    }
    treatment {
      ...TreatmentNode
    }
    calendarEventTemplates {
      ...CalendarEventTemplateNode
    }
    flows {
      ...FlowNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${MeasureNodeFragmentDoc}
  ${TreatmentNodeFragmentDoc}
  ${CalendarEventTemplateNodeFragmentDoc}
  ${FlowNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const LayerNodeFragmentDoc = gql`
  fragment LayerNode on Layer {
    ...TreatmentPlanNodeInterface
    alternativeNames {
      ...AlternativeNameEntry
    }
    enabledParameter {
      ...ParameterNode
    }
    name
    layerDescription: description
    layerGroups {
      ...LayerGroupNode
    }
    parameters {
      ...ParameterNode
    }
    parameterMappings {
      ...ParameterMappingNode
    }
    phases {
      ...PhaseNode
    }
    planItems {
      ...PlanItemNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ParameterNodeFragmentDoc}
  ${LayerGroupNodeFragmentDoc}
  ${ParameterMappingNodeFragmentDoc}
  ${PhaseNodeFragmentDoc}
  ${PlanItemNodeFragmentDoc}
`;
export const ModuleNodeFragmentDoc = gql`
  fragment ModuleNode on Module {
    ...TreatmentPlanNodeInterface
    name
    shortName
    moduleDescription: description
    moduleSlug: slug
    ordering
    jumbo
    alternativeNames {
      ...AlternativeNameEntry
    }
    contentDoc {
      ...ContentDocNode
    }
    contentMedia {
      ...ContentMediaNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
`;
export const QuestionNodeFragmentDoc = gql`
  fragment QuestionNode on Question {
    ...TreatmentPlanNodeInterface
    contentDocs {
      ...ContentDocNode
    }
    name
    questionNotificationTemplate: notificationTemplate {
      ...NotificationTemplateNode
    }
    rootMeasure {
      ...MeasureNode
    }
    prototypeMeasures {
      ...MeasureNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${NotificationTemplateNodeFragmentDoc}
  ${MeasureNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const TreatmentListNodeFragmentDoc = gql`
  fragment TreatmentListNode on TreatmentList {
    ...TreatmentPlanNodeInterface
    name
    key
    items {
      ...PlanItemNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${PlanItemNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
`;
export const TreatmentPlanNodeFragmentDoc = gql`
  fragment TreatmentPlanNode on TreatmentPlan {
    ...TreatmentPlanNodeInterface
    name
    description
    protocolName
    patientNameOverride
    initialView
    rootLayer {
      __typename
      id
    }
    initialTasks {
      ...TaskNode
    }
    invitationTemplate {
      ...NotificationTemplateNode
    }
    startDateParameter {
      ...ParameterNode
    }
    contentDocs {
      ...ContentDocNode
    }
    contentMedia {
      ...ContentMediaNode
    }
    excludedModules {
      ...ModuleNode
    }
    includedModules {
      ...ModuleNode
    }
    layers {
      ...LayerNode
    }
    resourceReferences {
      ...ResourceReferenceNode
    }
    treatmentLists {
      ...TreatmentListNode
    }
    alternativeNames {
      ...AlternativeNameEntry
    }
    onboardingFlow {
      ...FlowNode
    }
    notificationResponseFlow {
      ...FlowNode
    }
  }
  ${TreatmentPlanNodeInterfaceFragmentDoc}
  ${TaskNodeFragmentDoc}
  ${NotificationTemplateNodeFragmentDoc}
  ${ParameterNodeFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
  ${ModuleNodeFragmentDoc}
  ${LayerNodeFragmentDoc}
  ${ResourceReferenceNodeFragmentDoc}
  ${TreatmentListNodeFragmentDoc}
  ${AlternativeNameEntryFragmentDoc}
  ${FlowNodeFragmentDoc}
`;
export const AnyTreatmentPlanNodeFragmentDoc = gql`
  fragment AnyTreatmentPlanNode on TreatmentPlanNode {
    __typename
    id
    visible
    ... on Brand {
      ...BrandNode
    }
    ... on CalendarEventTemplate {
      ...CalendarEventTemplateNode
    }
    ... on Confirmation {
      ...ConfirmationNode
    }
    ... on ContentDoc {
      ...ContentDocNode
    }
    ... on ContentDocContext {
      ...ContentDocContextNode
    }
    ... on ContentMedia {
      ...ContentMediaNode
    }
    ... on Diagnosis {
      ...DiagnosisNode
    }
    ... on EditableParameter {
      ...EditableParameterNode
    }
    ... on Flow {
      ...FlowNode
    }
    ... on FlowStep {
      ...FlowStepNode
    }
    ... on Layer {
      ...LayerNode
    }
    ... on LayerGroup {
      ...LayerGroupNode
    }
    ... on Measure {
      ...MeasureNode
    }
    ... on Module {
      ...ModuleNode
    }
    ... on Notification {
      ...NotificationNode
    }
    ... on NotificationPreference {
      ...NotificationPreferenceNode
    }
    ... on NotificationTemplate {
      ...NotificationTemplateNode
    }
    ... on NotificationContent {
      ...NotificationContentNode
    }
    ... on Parameter {
      ...ParameterNode
    }
    ... on ParameterMapping {
      ...ParameterMappingNode
    }
    ... on Phase {
      ...PhaseNode
    }
    ... on PlanItem {
      ...PlanItemNode
    }
    ... on Question {
      ...QuestionNode
    }
    ... on Resource {
      ...ResourceNode
    }
    ... on ResourceCategory {
      ...ResourceCategoryNode
    }
    ... on ResourceReference {
      ...ResourceReferenceNode
    }
    ... on Survey {
      ...SurveyNode
    }
    ... on Task {
      ...TaskNode
    }
    ... on Treatment {
      ...TreatmentNode
    }
    ... on TreatmentList {
      ...TreatmentListNode
    }
    ... on TreatmentPlan {
      ...TreatmentPlanNode
    }
  }
  ${BrandNodeFragmentDoc}
  ${CalendarEventTemplateNodeFragmentDoc}
  ${ConfirmationNodeFragmentDoc}
  ${ContentDocNodeFragmentDoc}
  ${ContentDocContextNodeFragmentDoc}
  ${ContentMediaNodeFragmentDoc}
  ${DiagnosisNodeFragmentDoc}
  ${EditableParameterNodeFragmentDoc}
  ${FlowNodeFragmentDoc}
  ${FlowStepNodeFragmentDoc}
  ${LayerNodeFragmentDoc}
  ${LayerGroupNodeFragmentDoc}
  ${MeasureNodeFragmentDoc}
  ${ModuleNodeFragmentDoc}
  ${NotificationNodeFragmentDoc}
  ${NotificationPreferenceNodeFragmentDoc}
  ${NotificationTemplateNodeFragmentDoc}
  ${NotificationContentNodeFragmentDoc}
  ${ParameterNodeFragmentDoc}
  ${ParameterMappingNodeFragmentDoc}
  ${PhaseNodeFragmentDoc}
  ${PlanItemNodeFragmentDoc}
  ${QuestionNodeFragmentDoc}
  ${ResourceNodeFragmentDoc}
  ${ResourceCategoryNodeFragmentDoc}
  ${ResourceReferenceNodeFragmentDoc}
  ${SurveyNodeFragmentDoc}
  ${TaskNodeFragmentDoc}
  ${TreatmentNodeFragmentDoc}
  ${TreatmentListNodeFragmentDoc}
  ${TreatmentPlanNodeFragmentDoc}
`;
export const AnythingFragmentDoc = gql`
  fragment Anything on Anything {
    __typename
    active
    alternativeNames {
      key
      value
    }
    id
    lastUpdated
    name
    type
    visible
  }
`;
export const PatientAuditDeltaFragmentDoc = gql`
  fragment PatientAuditDelta on PatientAuditDelta {
    __typename
    field
    oldValue
    newValue
  }
`;
export const AuditLogRecordFragmentDoc = gql`
  fragment AuditLogRecord on AuditLogRecord {
    __typename
    revision
    action
    createdBy
    createdByName
    createdAt
    delta {
      ...PatientAuditDelta
    }
  }
  ${PatientAuditDeltaFragmentDoc}
`;
export const CalendarEventFragmentDoc = gql`
  fragment CalendarEvent on CalendarEvent {
    id
    calendarEventTemplate {
      id
      __typename
    }
    occurrences {
      ...NavioCalendarUiCalendarEventOccurrence
    }
  }
  ${NavioCalendarUiCalendarEventOccurrenceFragmentDoc}
`;
export const ParameterFragmentDoc = gql`
  fragment Parameter on Parameter {
    __typename
    id
    label
    type
    required
    multiselect
    description
    defaultValue
    editableBy
  }
`;
export const CalendarEventScopeFragmentDoc = gql`
  fragment CalendarEventScope on CalendarEventScope {
    string
  }
`;
export const TreatmentPlanParameterValueFragmentDoc = gql`
  fragment TreatmentPlanParameterValue on TreatmentPlanParameterValue {
    __typename
    id
    parameter {
      ...Parameter
    }
    scope {
      ...CalendarEventScope
    }
    transient
    value
  }
  ${ParameterFragmentDoc}
  ${CalendarEventScopeFragmentDoc}
`;
export const CalendarFragmentDoc = gql`
  fragment Calendar on Calendar {
    calendarEventTemplates {
      ...NavioCalendarUiCalendarEventTemplate
    }
    events(from: $from, to: $to) {
      ...CalendarEvent
    }
    parameters {
      ...TreatmentPlanParameterValue
    }
    timezone
  }
  ${NavioCalendarUiCalendarEventTemplateFragmentDoc}
  ${CalendarEventFragmentDoc}
  ${TreatmentPlanParameterValueFragmentDoc}
`;
export const ContentDocFragmentDoc = gql`
  fragment ContentDoc on ContentDoc {
    __typename
    contentHtml
    context
    id
  }
`;
export const PatientIdentifierFragmentDoc = gql`
  fragment PatientIdentifier on PatientIdentifier {
    __typename
    id
    name
  }
`;
export const ProviderFragmentDoc = gql`
  fragment Provider on Provider {
    id
    name
    locations {
      id
      active
      practice {
        id
        name
        organization {
          id
          name
        }
      }
      patientIdentifiers {
        ...PatientIdentifier
        description
        required
        uniquePerIdentifierContext
      }
    }
  }
  ${PatientIdentifierFragmentDoc}
`;
export const CrmForeignSyncLogFragmentDoc = gql`
  fragment CrmForeignSyncLog on CrmForeignSyncLog {
    id
    field
    message
  }
`;
export const CrmPersonFragmentDoc = gql`
  fragment CrmPerson on CrmPerson {
    id
    provider {
      ...Provider
    }
    logs {
      ...CrmForeignSyncLog
    }
  }
  ${ProviderFragmentDoc}
  ${CrmForeignSyncLogFragmentDoc}
`;
export const CsvFieldMappingFragmentDoc = gql`
  fragment CsvFieldMapping on CsvFieldMapping {
    mappedField
    mappedValue
    error
  }
`;
export const CsvRowMappingFragmentDoc = gql`
  fragment CsvRowMapping on CsvRowMapping {
    rowNumber
    mappedFields {
      ...CsvFieldMapping
    }
    importResult {
      error
      patientStatus
    }
  }
  ${CsvFieldMappingFragmentDoc}
`;
export const CsvMappingPreviewFragmentDoc = gql`
  fragment CsvMappingPreview on CsvMappingPreview {
    mappingError
    rowMappings {
      ...CsvRowMapping
    }
  }
  ${CsvRowMappingFragmentDoc}
`;
export const DiagnosisFragmentDoc = gql`
  fragment Diagnosis on Diagnosis {
    __typename
    id
    name
  }
`;
export const DiagnosisOccurrenceFragmentDoc = gql`
  fragment DiagnosisOccurrence on DiagnosisOccurrence {
    __typename
    id
    patientId
    diagnosis {
      ...Diagnosis
    }
    dateOfDiagnosis
    courseId
  }
  ${DiagnosisFragmentDoc}
`;
export const EnrollmentFragmentDoc = gql`
  fragment Enrollment on Enrollment {
    enrollmentId
    enrollmentType
    patientFirstName
    patientLastName
    courseId
  }
`;
export const MatchOccurrenceFragmentDoc = gql`
  fragment MatchOccurrence on MatchOccurrence {
    __typename
    id
    courseId
    treatmentPlanId
    name
    description
    score
  }
`;
export const MatchResultsFragmentDoc = gql`
  fragment MatchResults on MatchResults {
    courseId
    matches {
      ...MatchOccurrence
    }
  }
  ${MatchOccurrenceFragmentDoc}
`;
export const MatchRuleFragmentDoc = gql`
  fragment MatchRule on MatchRule {
    id
    providerLocation {
      id
    }
    diagnosis {
      id
    }
    treatmentPlan {
      id
    }
  }
`;
export const PatientCohortFragmentDoc = gql`
  fragment PatientCohort on PatientCohort {
    __typename
    id
    name
  }
`;
export const PatientCohortsFragmentDoc = gql`
  fragment PatientCohorts on Patient {
    cohorts {
      ...PatientCohort
    }
    __typename
  }
  ${PatientCohortFragmentDoc}
`;
export const PatientHighlightFragmentDoc = gql`
  fragment PatientHighlight on PatientHighlight {
    field
    html
    details
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    id
    street1
    street2
    city
    state
    postalCode
    country
  }
`;
export const PersonFragmentDoc = gql`
  fragment Person on Person {
    id
    lastName
    firstName
    phoneNumber
    emailAddresses
    postalAddresses {
      ...Address
    }
    language
    timeZone
  }
  ${AddressFragmentDoc}
`;
export const PatientDemographicsFragmentDoc = gql`
  fragment PatientDemographics on PatientDemographics {
    __typename
    id
    dateOfBirth
    sexAssignedAtBirth
    firstName
    lastName
    patientIdentifiers {
      patientIdentifier {
        ...PatientIdentifier
      }
      value
    }
    patientType
    person {
      ...Person
    }
  }
  ${PatientIdentifierFragmentDoc}
  ${PersonFragmentDoc}
`;
export const SurveyOccurrenceFragmentDoc = gql`
  fragment SurveyOccurrence on SurveyOccurrence {
    __typename
    id
    survey {
      id
      name
    }
    eventDate
    completedAt
    providerAckDate
  }
`;
export const CourseWithTreatmentPlanOccurrencesFragmentDoc = gql`
  fragment CourseWithTreatmentPlanOccurrences on Course {
    __typename
    id
    treatmentPlanOccurrences {
      __typename
      id
      status
      treatmentStartDate
      treatmentPlan {
        __typename
        id
        name
      }
      surveys {
        ...SurveyOccurrence
      }
      tasks {
        id
        task {
          id
          name
        }
        eventDate
        allDay
        status
        statusChangedAt
      }
    }
  }
  ${SurveyOccurrenceFragmentDoc}
`;
export const PatientFragmentDoc = gql`
  fragment Patient on Patient {
    __typename
    id
    demographics {
      ...PatientDemographics
    }
    demographicsDeleted
    courses {
      ...CourseWithTreatmentPlanOccurrences
      diagnoses {
        __typename
        id
        name
      }
      status
    }
  }
  ${PatientDemographicsFragmentDoc}
  ${CourseWithTreatmentPlanOccurrencesFragmentDoc}
`;
export const PatientEnrollmentFragmentDoc = gql`
  fragment PatientEnrollment on PatientEnrollment {
    __typename
    id
    type
    externalId
    createdAt
  }
`;
export const PatientEdgeFragmentDoc = gql`
  fragment PatientEdge on PatientEdge {
    score
    highlights {
      ...PatientHighlight
    }
    node {
      ...Patient
      cohorts {
        ...PatientCohort
      }
      enrollments {
        ...PatientEnrollment
      }
      providers {
        courseProviderLocations {
          providerLocation {
            id
            active
            provider {
              id
              fullName
            }
            practice {
              id
              name
              organization {
                id
                name
              }
            }
          }
          courseProviderType
        }
      }
      lastUpdated
      moduleVisits {
        count
        latestVisit
      }
    }
  }
  ${PatientHighlightFragmentDoc}
  ${PatientFragmentDoc}
  ${PatientCohortFragmentDoc}
  ${PatientEnrollmentFragmentDoc}
`;
export const PatientEnrollmentsFragmentDoc = gql`
  fragment PatientEnrollments on Patient {
    enrollments {
      ...PatientEnrollment
    }
    __typename
  }
  ${PatientEnrollmentFragmentDoc}
`;
export const PatientFilterSuggestionFragmentDoc = gql`
  fragment PatientFilterSuggestion on PatientFilterSuggestion {
    text
    score
    field
    subjectType
    subject
    subjectName
    operator
    valueType
    value
  }
`;
export const PatientSuggestResultFragmentDoc = gql`
  fragment PatientSuggestResult on PatientSuggestResult {
    filters {
      ...PatientFilterSuggestion
    }
  }
  ${PatientFilterSuggestionFragmentDoc}
`;
export const PatientProvidersFragmentDoc = gql`
  fragment PatientProviders on PatientProviders {
    __typename
    courseProviderLocations {
      __typename
      providerLocation {
        __typename
        id
        active
        provider {
          id
          fullName
        }
        practice {
          __typename
          id
          name
          organization {
            __typename
            id
            name
          }
        }
      }
      courseProviderType
    }
  }
`;
export const PatientWithCoursesAndProvidersFragmentDoc = gql`
  fragment PatientWithCoursesAndProviders on Patient {
    courses {
      __typename
      id
      status
      diagnoses {
        __typename
        id
        name
      }
      treatmentPlanOccurrences {
        __typename
        id
        status
        treatmentStartDate
        treatmentPlan {
          __typename
          id
          name
        }
      }
    }
    providers {
      ...PatientProviders
    }
  }
  ${PatientProvidersFragmentDoc}
`;
export const AvailableLanguageFragmentDoc = gql`
  fragment AvailableLanguage on AvailableLanguage {
    name
    tag
  }
`;
export const ProviderLocationFragmentDoc = gql`
  fragment ProviderLocation on ProviderLocation {
    id
    active
    provider {
      ...Provider
    }
    practice {
      id
      name
      organization {
        id
        name
      }
    }
    patientLanguages {
      ...AvailableLanguage
    }
  }
  ${ProviderFragmentDoc}
  ${AvailableLanguageFragmentDoc}
`;
export const ScenarioFragmentDoc = gql`
  fragment Scenario on Scenario {
    __typename
    id
    name
    description
    variables {
      __typename
      name
      description
      type
      required
    }
  }
`;
export const StaticCohortFragmentDoc = gql`
  fragment StaticCohort on StaticCohort {
    __typename
    id
    name
  }
`;
export const TreatmentFragmentDoc = gql`
  fragment Treatment on Treatment {
    __typename
    id
    name
    genericName
    brandName
    treatmentDateField
    detailsField
  }
`;
export const CourseFragmentDoc = gql`
  fragment Course on Course {
    __typename
    id
  }
`;
export const NotificationTemplateFragmentDoc = gql`
  fragment NotificationTemplate on NotificationTemplate {
    __typename
    id
    name
    notificationContents {
      id
      contentKind
      content
    }
  }
`;
export const TreatmentPlanFragmentDoc = gql`
  fragment TreatmentPlan on TreatmentPlan {
    __typename
    id
    name
    description
    protocolName
    initialView
    media {
      __typename
      id
      context
      mimeType
      url
    }
    onboardingFlow {
      id
      steps {
        id
        presentation {
          id
          ... on Notification {
            id
            notificationTemplate {
              ...NotificationTemplate
            }
          }
        }
      }
    }
    patientIdentifiers {
      ...PatientIdentifier
      description
      required
      uniquePerIdentifierContext
    }
  }
  ${NotificationTemplateFragmentDoc}
  ${PatientIdentifierFragmentDoc}
`;
export const TreatmentPlanOccurrenceFragmentDoc = gql`
  fragment TreatmentPlanOccurrence on TreatmentPlanOccurrence {
    __typename
    id
    course {
      ...Course
    }
    parameterValues {
      ...TreatmentPlanParameterValue
    }
    treatmentPlan {
      ...TreatmentPlan
    }
    treatmentStartDate
    patientIdentifiers {
      patientIdentifier {
        ...PatientIdentifier
        description
        required
        uniquePerIdentifierContext
      }
      value
    }
  }
  ${CourseFragmentDoc}
  ${TreatmentPlanParameterValueFragmentDoc}
  ${TreatmentPlanFragmentDoc}
  ${PatientIdentifierFragmentDoc}
`;
export const LayerGroupFragmentDoc = gql`
  fragment LayerGroup on LayerGroup {
    __typename
    id
    name
    description
    required
    multiple
    layers {
      id
    }
  }
`;
export const LayerFragmentDoc = gql`
  fragment Layer on Layer {
    __typename
    id
    name
    description
    parameters {
      ...Parameter
    }
    layerGroups {
      ...LayerGroup
    }
    enabledParameter {
      ...Parameter
    }
  }
  ${ParameterFragmentDoc}
  ${LayerGroupFragmentDoc}
`;
export const TreatmentPlanSettingsFragmentDoc = gql`
  fragment TreatmentPlanSettings on TreatmentPlan {
    __typename
    rootLayer {
      ...Layer
    }
    layers {
      ...Layer
    }
    startDateParameter {
      ...Parameter
    }
    invitationTemplate {
      id
    }
  }
  ${LayerFragmentDoc}
  ${ParameterFragmentDoc}
`;
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on UserInfo {
    __typename
    isAuthenticated
    name
    username
    canEnroll
    canSeePhi
    shouldNotSeePromoContent
  }
`;
export const UserInfoProviderFragmentDoc = gql`
  fragment UserInfoProvider on UserInfo {
    provider {
      __typename
      id
      name
      locations {
        __typename
        id
        practice {
          __typename
          id
          name
          organization {
            __typename
            id
            name
          }
        }
      }
    }
  }
`;
export const ExistingNodeFragmentDoc = gql`
  fragment ExistingNode on TreatmentPlanNode {
    id
  }
`;
export const NavioCalendarUiEditEventParametersDocument = gql`
  mutation NavioCalendarUiEditEventParameters(
    $correlationId: UUID!
    $courseId: UUID!
    $parameterValues: [CalendarEventParameterValueInput!]!
    $from: OffsetDateTime
    $to: OffsetDateTime
    $calendarSerializationFormat: CalendarSerializationFormat = ICAL
    $timezone: TimeZone!
  ) {
    editEventParameters(
      correlationId: $correlationId
      courseId: $courseId
      parameterValues: $parameterValues
    ) {
      __typename
      id
      calendar(format: $calendarSerializationFormat, timezone: $timezone) {
        ...NavioCalendarUiCalendar
      }
      activeTreatmentPlanOccurrences {
        id
        __typename
        treatmentPlan {
          __typename
          id
        }
        parameterValues {
          ...NavioCalendarUiTreatmentPlanParameterValue
        }
      }
    }
  }
  ${NavioCalendarUiCalendarFragmentDoc}
  ${NavioCalendarUiTreatmentPlanParameterValueFragmentDoc}
`;
export type NavioCalendarUiEditEventParametersMutationFn =
  Apollo.MutationFunction<
    NavioCalendarUiEditEventParametersMutation,
    NavioCalendarUiEditEventParametersMutationVariables
  >;

/**
 * __useNavioCalendarUiEditEventParametersMutation__
 *
 * To run a mutation, you first call `useNavioCalendarUiEditEventParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNavioCalendarUiEditEventParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [navioCalendarUiEditEventParametersMutation, { data, loading, error }] = useNavioCalendarUiEditEventParametersMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      courseId: // value for 'courseId'
 *      parameterValues: // value for 'parameterValues'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      calendarSerializationFormat: // value for 'calendarSerializationFormat'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useNavioCalendarUiEditEventParametersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NavioCalendarUiEditEventParametersMutation,
    NavioCalendarUiEditEventParametersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NavioCalendarUiEditEventParametersMutation,
    NavioCalendarUiEditEventParametersMutationVariables
  >(NavioCalendarUiEditEventParametersDocument, options);
}
export type NavioCalendarUiEditEventParametersMutationHookResult = ReturnType<
  typeof useNavioCalendarUiEditEventParametersMutation
>;
export type NavioCalendarUiEditEventParametersMutationResult =
  Apollo.MutationResult<NavioCalendarUiEditEventParametersMutation>;
export type NavioCalendarUiEditEventParametersMutationOptions =
  Apollo.BaseMutationOptions<
    NavioCalendarUiEditEventParametersMutation,
    NavioCalendarUiEditEventParametersMutationVariables
  >;
export const NavioCalendarUiContentDocDocument = gql`
  query NavioCalendarUiContentDoc($contentDocId: UUID!) {
    content(contentDocId: $contentDocId) {
      ...NavioCalendarUiContentDoc
    }
  }
  ${NavioCalendarUiContentDocFragmentDoc}
`;

/**
 * __useNavioCalendarUiContentDocQuery__
 *
 * To run a query within a React component, call `useNavioCalendarUiContentDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavioCalendarUiContentDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavioCalendarUiContentDocQuery({
 *   variables: {
 *      contentDocId: // value for 'contentDocId'
 *   },
 * });
 */
export function useNavioCalendarUiContentDocQuery(
  baseOptions: Apollo.QueryHookOptions<
    NavioCalendarUiContentDocQuery,
    NavioCalendarUiContentDocQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NavioCalendarUiContentDocQuery,
    NavioCalendarUiContentDocQueryVariables
  >(NavioCalendarUiContentDocDocument, options);
}
export function useNavioCalendarUiContentDocLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavioCalendarUiContentDocQuery,
    NavioCalendarUiContentDocQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NavioCalendarUiContentDocQuery,
    NavioCalendarUiContentDocQueryVariables
  >(NavioCalendarUiContentDocDocument, options);
}
export type NavioCalendarUiContentDocQueryHookResult = ReturnType<
  typeof useNavioCalendarUiContentDocQuery
>;
export type NavioCalendarUiContentDocLazyQueryHookResult = ReturnType<
  typeof useNavioCalendarUiContentDocLazyQuery
>;
export type NavioCalendarUiContentDocQueryResult = Apollo.QueryResult<
  NavioCalendarUiContentDocQuery,
  NavioCalendarUiContentDocQueryVariables
>;
export const NavioCalendarUiRenderCalendarDocument = gql`
  query NavioCalendarUiRenderCalendar(
    $request: CalendarInput!
    $from: OffsetDateTime
    $to: OffsetDateTime
    $format: CalendarSerializationFormat = ICAL
  ) {
    renderCalendar(request: $request, format: $format) {
      ...NavioCalendarUiCalendar
    }
  }
  ${NavioCalendarUiCalendarFragmentDoc}
`;

/**
 * __useNavioCalendarUiRenderCalendarQuery__
 *
 * To run a query within a React component, call `useNavioCalendarUiRenderCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavioCalendarUiRenderCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavioCalendarUiRenderCalendarQuery({
 *   variables: {
 *      request: // value for 'request'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useNavioCalendarUiRenderCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    NavioCalendarUiRenderCalendarQuery,
    NavioCalendarUiRenderCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NavioCalendarUiRenderCalendarQuery,
    NavioCalendarUiRenderCalendarQueryVariables
  >(NavioCalendarUiRenderCalendarDocument, options);
}
export function useNavioCalendarUiRenderCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NavioCalendarUiRenderCalendarQuery,
    NavioCalendarUiRenderCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NavioCalendarUiRenderCalendarQuery,
    NavioCalendarUiRenderCalendarQueryVariables
  >(NavioCalendarUiRenderCalendarDocument, options);
}
export type NavioCalendarUiRenderCalendarQueryHookResult = ReturnType<
  typeof useNavioCalendarUiRenderCalendarQuery
>;
export type NavioCalendarUiRenderCalendarLazyQueryHookResult = ReturnType<
  typeof useNavioCalendarUiRenderCalendarLazyQuery
>;
export type NavioCalendarUiRenderCalendarQueryResult = Apollo.QueryResult<
  NavioCalendarUiRenderCalendarQuery,
  NavioCalendarUiRenderCalendarQueryVariables
>;
export const AcknowledgeOverdueSurveyDocument = gql`
  mutation acknowledgeOverdueSurvey(
    $correlationId: UUID!
    $id: UUID!
    $patientId: UUID!
    $courseId: UUID!
    $acknowledge: Boolean!
  ) {
    acknowledgeOverdueSurvey(
      correlationId: $correlationId
      id: $id
      patientId: $patientId
      courseId: $courseId
      acknowledge: $acknowledge
    ) {
      ...PatientDemographics
    }
  }
  ${PatientDemographicsFragmentDoc}
`;
export type AcknowledgeOverdueSurveyMutationFn = Apollo.MutationFunction<
  AcknowledgeOverdueSurveyMutation,
  AcknowledgeOverdueSurveyMutationVariables
>;

/**
 * __useAcknowledgeOverdueSurveyMutation__
 *
 * To run a mutation, you first call `useAcknowledgeOverdueSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeOverdueSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeOverdueSurveyMutation, { data, loading, error }] = useAcknowledgeOverdueSurveyMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      id: // value for 'id'
 *      patientId: // value for 'patientId'
 *      courseId: // value for 'courseId'
 *      acknowledge: // value for 'acknowledge'
 *   },
 * });
 */
export function useAcknowledgeOverdueSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcknowledgeOverdueSurveyMutation,
    AcknowledgeOverdueSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcknowledgeOverdueSurveyMutation,
    AcknowledgeOverdueSurveyMutationVariables
  >(AcknowledgeOverdueSurveyDocument, options);
}
export type AcknowledgeOverdueSurveyMutationHookResult = ReturnType<
  typeof useAcknowledgeOverdueSurveyMutation
>;
export type AcknowledgeOverdueSurveyMutationResult =
  Apollo.MutationResult<AcknowledgeOverdueSurveyMutation>;
export type AcknowledgeOverdueSurveyMutationOptions =
  Apollo.BaseMutationOptions<
    AcknowledgeOverdueSurveyMutation,
    AcknowledgeOverdueSurveyMutationVariables
  >;
export const AddPatientIdentifierDocument = gql`
  mutation AddPatientIdentifier(
    $providerLocationId: UUID!
    $patientIdentifierId: UUID!
    $correlationId: UUID!
  ) {
    addPatientIdentifier(
      providerLocationId: $providerLocationId
      patientIdentifierId: $patientIdentifierId
      correlationId: $correlationId
    ) {
      ...ProviderLocation
    }
  }
  ${ProviderLocationFragmentDoc}
`;
export type AddPatientIdentifierMutationFn = Apollo.MutationFunction<
  AddPatientIdentifierMutation,
  AddPatientIdentifierMutationVariables
>;

/**
 * __useAddPatientIdentifierMutation__
 *
 * To run a mutation, you first call `useAddPatientIdentifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientIdentifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientIdentifierMutation, { data, loading, error }] = useAddPatientIdentifierMutation({
 *   variables: {
 *      providerLocationId: // value for 'providerLocationId'
 *      patientIdentifierId: // value for 'patientIdentifierId'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useAddPatientIdentifierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPatientIdentifierMutation,
    AddPatientIdentifierMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPatientIdentifierMutation,
    AddPatientIdentifierMutationVariables
  >(AddPatientIdentifierDocument, options);
}
export type AddPatientIdentifierMutationHookResult = ReturnType<
  typeof useAddPatientIdentifierMutation
>;
export type AddPatientIdentifierMutationResult =
  Apollo.MutationResult<AddPatientIdentifierMutation>;
export type AddPatientIdentifierMutationOptions = Apollo.BaseMutationOptions<
  AddPatientIdentifierMutation,
  AddPatientIdentifierMutationVariables
>;
export const AddPatientsToStaticCohortDocument = gql`
  mutation AddPatientsToStaticCohort(
    $correlationId: UUID!
    $patientIds: [UUID!]!
    $staticCohortId: UUID!
  ) {
    addPatientsToStaticCohort(
      correlationId: $correlationId
      patientIds: $patientIds
      staticCohortId: $staticCohortId
    ) {
      ...StaticCohort
    }
  }
  ${StaticCohortFragmentDoc}
`;
export type AddPatientsToStaticCohortMutationFn = Apollo.MutationFunction<
  AddPatientsToStaticCohortMutation,
  AddPatientsToStaticCohortMutationVariables
>;

/**
 * __useAddPatientsToStaticCohortMutation__
 *
 * To run a mutation, you first call `useAddPatientsToStaticCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientsToStaticCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientsToStaticCohortMutation, { data, loading, error }] = useAddPatientsToStaticCohortMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      patientIds: // value for 'patientIds'
 *      staticCohortId: // value for 'staticCohortId'
 *   },
 * });
 */
export function useAddPatientsToStaticCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPatientsToStaticCohortMutation,
    AddPatientsToStaticCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPatientsToStaticCohortMutation,
    AddPatientsToStaticCohortMutationVariables
  >(AddPatientsToStaticCohortDocument, options);
}
export type AddPatientsToStaticCohortMutationHookResult = ReturnType<
  typeof useAddPatientsToStaticCohortMutation
>;
export type AddPatientsToStaticCohortMutationResult =
  Apollo.MutationResult<AddPatientsToStaticCohortMutation>;
export type AddPatientsToStaticCohortMutationOptions =
  Apollo.BaseMutationOptions<
    AddPatientsToStaticCohortMutation,
    AddPatientsToStaticCohortMutationVariables
  >;
export const CreateAndEnrollPatientDocument = gql`
  mutation CreateAndEnrollPatient($request: CreateAndEnrollPatient!) {
    createAndEnrollPatient(request: $request) {
      patient {
        ...PatientDemographics
      }
      diagnosis {
        ...DiagnosisOccurrence
      }
      matching {
        ...MatchResults
      }
      enrollment {
        ...Enrollment
      }
      plan {
        ...TreatmentPlanOccurrence
      }
    }
  }
  ${PatientDemographicsFragmentDoc}
  ${DiagnosisOccurrenceFragmentDoc}
  ${MatchResultsFragmentDoc}
  ${EnrollmentFragmentDoc}
  ${TreatmentPlanOccurrenceFragmentDoc}
`;
export type CreateAndEnrollPatientMutationFn = Apollo.MutationFunction<
  CreateAndEnrollPatientMutation,
  CreateAndEnrollPatientMutationVariables
>;

/**
 * __useCreateAndEnrollPatientMutation__
 *
 * To run a mutation, you first call `useCreateAndEnrollPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAndEnrollPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAndEnrollPatientMutation, { data, loading, error }] = useCreateAndEnrollPatientMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateAndEnrollPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAndEnrollPatientMutation,
    CreateAndEnrollPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAndEnrollPatientMutation,
    CreateAndEnrollPatientMutationVariables
  >(CreateAndEnrollPatientDocument, options);
}
export type CreateAndEnrollPatientMutationHookResult = ReturnType<
  typeof useCreateAndEnrollPatientMutation
>;
export type CreateAndEnrollPatientMutationResult =
  Apollo.MutationResult<CreateAndEnrollPatientMutation>;
export type CreateAndEnrollPatientMutationOptions = Apollo.BaseMutationOptions<
  CreateAndEnrollPatientMutation,
  CreateAndEnrollPatientMutationVariables
>;
export const CreateMatchRuleDocument = gql`
  mutation CreateMatchRule($matchRule: CreateMatchRule!) {
    createMatchRule(matchRule: $matchRule) {
      ...MatchRule
    }
  }
  ${MatchRuleFragmentDoc}
`;
export type CreateMatchRuleMutationFn = Apollo.MutationFunction<
  CreateMatchRuleMutation,
  CreateMatchRuleMutationVariables
>;

/**
 * __useCreateMatchRuleMutation__
 *
 * To run a mutation, you first call `useCreateMatchRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMatchRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMatchRuleMutation, { data, loading, error }] = useCreateMatchRuleMutation({
 *   variables: {
 *      matchRule: // value for 'matchRule'
 *   },
 * });
 */
export function useCreateMatchRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMatchRuleMutation,
    CreateMatchRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMatchRuleMutation,
    CreateMatchRuleMutationVariables
  >(CreateMatchRuleDocument, options);
}
export type CreateMatchRuleMutationHookResult = ReturnType<
  typeof useCreateMatchRuleMutation
>;
export type CreateMatchRuleMutationResult =
  Apollo.MutationResult<CreateMatchRuleMutation>;
export type CreateMatchRuleMutationOptions = Apollo.BaseMutationOptions<
  CreateMatchRuleMutation,
  CreateMatchRuleMutationVariables
>;
export const CreateStaticPatientCohortDocument = gql`
  mutation CreateStaticPatientCohort(
    $correlationId: UUID!
    $name: String!
    $organizationId: UUID!
  ) {
    createStaticPatientCohort(
      correlationId: $correlationId
      name: $name
      organizationId: $organizationId
    ) {
      ...StaticCohort
    }
  }
  ${StaticCohortFragmentDoc}
`;
export type CreateStaticPatientCohortMutationFn = Apollo.MutationFunction<
  CreateStaticPatientCohortMutation,
  CreateStaticPatientCohortMutationVariables
>;

/**
 * __useCreateStaticPatientCohortMutation__
 *
 * To run a mutation, you first call `useCreateStaticPatientCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaticPatientCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaticPatientCohortMutation, { data, loading, error }] = useCreateStaticPatientCohortMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateStaticPatientCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStaticPatientCohortMutation,
    CreateStaticPatientCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStaticPatientCohortMutation,
    CreateStaticPatientCohortMutationVariables
  >(CreateStaticPatientCohortDocument, options);
}
export type CreateStaticPatientCohortMutationHookResult = ReturnType<
  typeof useCreateStaticPatientCohortMutation
>;
export type CreateStaticPatientCohortMutationResult =
  Apollo.MutationResult<CreateStaticPatientCohortMutation>;
export type CreateStaticPatientCohortMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStaticPatientCohortMutation,
    CreateStaticPatientCohortMutationVariables
  >;
export const DeletePatientDemographicsDocument = gql`
  mutation DeletePatientDemographics($correlationId: UUID!, $patientId: UUID!) {
    deletePatientDemographics(
      correlationId: $correlationId
      patientId: $patientId
    )
  }
`;
export type DeletePatientDemographicsMutationFn = Apollo.MutationFunction<
  DeletePatientDemographicsMutation,
  DeletePatientDemographicsMutationVariables
>;

/**
 * __useDeletePatientDemographicsMutation__
 *
 * To run a mutation, you first call `useDeletePatientDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientDemographicsMutation, { data, loading, error }] = useDeletePatientDemographicsMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useDeletePatientDemographicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatientDemographicsMutation,
    DeletePatientDemographicsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePatientDemographicsMutation,
    DeletePatientDemographicsMutationVariables
  >(DeletePatientDemographicsDocument, options);
}
export type DeletePatientDemographicsMutationHookResult = ReturnType<
  typeof useDeletePatientDemographicsMutation
>;
export type DeletePatientDemographicsMutationResult =
  Apollo.MutationResult<DeletePatientDemographicsMutation>;
export type DeletePatientDemographicsMutationOptions =
  Apollo.BaseMutationOptions<
    DeletePatientDemographicsMutation,
    DeletePatientDemographicsMutationVariables
  >;
export const DisableMatchRuleDocument = gql`
  mutation DisableMatchRule($matchRuleId: UUID!, $correlationId: UUID) {
    disableMatchRule(matchRuleId: $matchRuleId, correlationId: $correlationId)
  }
`;
export type DisableMatchRuleMutationFn = Apollo.MutationFunction<
  DisableMatchRuleMutation,
  DisableMatchRuleMutationVariables
>;

/**
 * __useDisableMatchRuleMutation__
 *
 * To run a mutation, you first call `useDisableMatchRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableMatchRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableMatchRuleMutation, { data, loading, error }] = useDisableMatchRuleMutation({
 *   variables: {
 *      matchRuleId: // value for 'matchRuleId'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useDisableMatchRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableMatchRuleMutation,
    DisableMatchRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableMatchRuleMutation,
    DisableMatchRuleMutationVariables
  >(DisableMatchRuleDocument, options);
}
export type DisableMatchRuleMutationHookResult = ReturnType<
  typeof useDisableMatchRuleMutation
>;
export type DisableMatchRuleMutationResult =
  Apollo.MutationResult<DisableMatchRuleMutation>;
export type DisableMatchRuleMutationOptions = Apollo.BaseMutationOptions<
  DisableMatchRuleMutation,
  DisableMatchRuleMutationVariables
>;
export const EditNodesDocument = gql`
  mutation EditNodes($correlationId: UUID, $deltas: [NodeEditDelta!]!) {
    editNodes(correlationId: $correlationId, deltas: $deltas) {
      ...AnyTreatmentPlanNode
    }
  }
  ${AnyTreatmentPlanNodeFragmentDoc}
`;
export type EditNodesMutationFn = Apollo.MutationFunction<
  EditNodesMutation,
  EditNodesMutationVariables
>;

/**
 * __useEditNodesMutation__
 *
 * To run a mutation, you first call `useEditNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNodesMutation, { data, loading, error }] = useEditNodesMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      deltas: // value for 'deltas'
 *   },
 * });
 */
export function useEditNodesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditNodesMutation,
    EditNodesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditNodesMutation, EditNodesMutationVariables>(
    EditNodesDocument,
    options
  );
}
export type EditNodesMutationHookResult = ReturnType<
  typeof useEditNodesMutation
>;
export type EditNodesMutationResult = Apollo.MutationResult<EditNodesMutation>;
export type EditNodesMutationOptions = Apollo.BaseMutationOptions<
  EditNodesMutation,
  EditNodesMutationVariables
>;
export const ImportPatientCsvDocument = gql`
  mutation ImportPatientCsv(
    $correlationId: UUID!
    $upload: Upload!
    $mapping: String
    $importRows: [Int!]
  ) {
    importPatientCsv(
      correlationId: $correlationId
      upload: $upload
      mapping: $mapping
      importRows: $importRows
    ) {
      patient {
        id
      }
    }
  }
`;
export type ImportPatientCsvMutationFn = Apollo.MutationFunction<
  ImportPatientCsvMutation,
  ImportPatientCsvMutationVariables
>;

/**
 * __useImportPatientCsvMutation__
 *
 * To run a mutation, you first call `useImportPatientCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPatientCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPatientCsvMutation, { data, loading, error }] = useImportPatientCsvMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      upload: // value for 'upload'
 *      mapping: // value for 'mapping'
 *      importRows: // value for 'importRows'
 *   },
 * });
 */
export function useImportPatientCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportPatientCsvMutation,
    ImportPatientCsvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportPatientCsvMutation,
    ImportPatientCsvMutationVariables
  >(ImportPatientCsvDocument, options);
}
export type ImportPatientCsvMutationHookResult = ReturnType<
  typeof useImportPatientCsvMutation
>;
export type ImportPatientCsvMutationResult =
  Apollo.MutationResult<ImportPatientCsvMutation>;
export type ImportPatientCsvMutationOptions = Apollo.BaseMutationOptions<
  ImportPatientCsvMutation,
  ImportPatientCsvMutationVariables
>;
export const PreviewCsvImportDocument = gql`
  mutation PreviewCsvImport(
    $correlationId: UUID!
    $upload: Upload!
    $mapping: String
  ) {
    previewCsvImport(
      correlationId: $correlationId
      upload: $upload
      mapping: $mapping
    ) {
      ...CsvMappingPreview
    }
  }
  ${CsvMappingPreviewFragmentDoc}
`;
export type PreviewCsvImportMutationFn = Apollo.MutationFunction<
  PreviewCsvImportMutation,
  PreviewCsvImportMutationVariables
>;

/**
 * __usePreviewCsvImportMutation__
 *
 * To run a mutation, you first call `usePreviewCsvImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewCsvImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewCsvImportMutation, { data, loading, error }] = usePreviewCsvImportMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      upload: // value for 'upload'
 *      mapping: // value for 'mapping'
 *   },
 * });
 */
export function usePreviewCsvImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreviewCsvImportMutation,
    PreviewCsvImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreviewCsvImportMutation,
    PreviewCsvImportMutationVariables
  >(PreviewCsvImportDocument, options);
}
export type PreviewCsvImportMutationHookResult = ReturnType<
  typeof usePreviewCsvImportMutation
>;
export type PreviewCsvImportMutationResult =
  Apollo.MutationResult<PreviewCsvImportMutation>;
export type PreviewCsvImportMutationOptions = Apollo.BaseMutationOptions<
  PreviewCsvImportMutation,
  PreviewCsvImportMutationVariables
>;
export const RemovePatientIdentifierDocument = gql`
  mutation RemovePatientIdentifier(
    $providerLocationId: UUID!
    $patientIdentifierId: UUID!
    $correlationId: UUID!
  ) {
    removePatientIdentifier(
      providerLocationId: $providerLocationId
      patientIdentifierId: $patientIdentifierId
      correlationId: $correlationId
    ) {
      ...ProviderLocation
    }
  }
  ${ProviderLocationFragmentDoc}
`;
export type RemovePatientIdentifierMutationFn = Apollo.MutationFunction<
  RemovePatientIdentifierMutation,
  RemovePatientIdentifierMutationVariables
>;

/**
 * __useRemovePatientIdentifierMutation__
 *
 * To run a mutation, you first call `useRemovePatientIdentifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientIdentifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientIdentifierMutation, { data, loading, error }] = useRemovePatientIdentifierMutation({
 *   variables: {
 *      providerLocationId: // value for 'providerLocationId'
 *      patientIdentifierId: // value for 'patientIdentifierId'
 *      correlationId: // value for 'correlationId'
 *   },
 * });
 */
export function useRemovePatientIdentifierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePatientIdentifierMutation,
    RemovePatientIdentifierMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePatientIdentifierMutation,
    RemovePatientIdentifierMutationVariables
  >(RemovePatientIdentifierDocument, options);
}
export type RemovePatientIdentifierMutationHookResult = ReturnType<
  typeof useRemovePatientIdentifierMutation
>;
export type RemovePatientIdentifierMutationResult =
  Apollo.MutationResult<RemovePatientIdentifierMutation>;
export type RemovePatientIdentifierMutationOptions = Apollo.BaseMutationOptions<
  RemovePatientIdentifierMutation,
  RemovePatientIdentifierMutationVariables
>;
export const RemovePatientsFromStaticCohortDocument = gql`
  mutation RemovePatientsFromStaticCohort(
    $correlationId: UUID!
    $patientIds: [UUID!]!
    $staticCohortId: UUID!
  ) {
    removePatientsFromStaticCohort(
      correlationId: $correlationId
      patientIds: $patientIds
      staticCohortId: $staticCohortId
    ) {
      ...StaticCohort
    }
  }
  ${StaticCohortFragmentDoc}
`;
export type RemovePatientsFromStaticCohortMutationFn = Apollo.MutationFunction<
  RemovePatientsFromStaticCohortMutation,
  RemovePatientsFromStaticCohortMutationVariables
>;

/**
 * __useRemovePatientsFromStaticCohortMutation__
 *
 * To run a mutation, you first call `useRemovePatientsFromStaticCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientsFromStaticCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientsFromStaticCohortMutation, { data, loading, error }] = useRemovePatientsFromStaticCohortMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      patientIds: // value for 'patientIds'
 *      staticCohortId: // value for 'staticCohortId'
 *   },
 * });
 */
export function useRemovePatientsFromStaticCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePatientsFromStaticCohortMutation,
    RemovePatientsFromStaticCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemovePatientsFromStaticCohortMutation,
    RemovePatientsFromStaticCohortMutationVariables
  >(RemovePatientsFromStaticCohortDocument, options);
}
export type RemovePatientsFromStaticCohortMutationHookResult = ReturnType<
  typeof useRemovePatientsFromStaticCohortMutation
>;
export type RemovePatientsFromStaticCohortMutationResult =
  Apollo.MutationResult<RemovePatientsFromStaticCohortMutation>;
export type RemovePatientsFromStaticCohortMutationOptions =
  Apollo.BaseMutationOptions<
    RemovePatientsFromStaticCohortMutation,
    RemovePatientsFromStaticCohortMutationVariables
  >;
export const RemoveTreatmentPlanOccurrenceDocument = gql`
  mutation RemoveTreatmentPlanOccurrence(
    $correlationId: UUID!
    $treatmentPlanOccurrenceId: UUID!
  ) {
    removeTreatmentPlanOccurrence(
      correlationId: $correlationId
      treatmentPlanOccurrenceId: $treatmentPlanOccurrenceId
    ) {
      ...Course
    }
  }
  ${CourseFragmentDoc}
`;
export type RemoveTreatmentPlanOccurrenceMutationFn = Apollo.MutationFunction<
  RemoveTreatmentPlanOccurrenceMutation,
  RemoveTreatmentPlanOccurrenceMutationVariables
>;

/**
 * __useRemoveTreatmentPlanOccurrenceMutation__
 *
 * To run a mutation, you first call `useRemoveTreatmentPlanOccurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTreatmentPlanOccurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTreatmentPlanOccurrenceMutation, { data, loading, error }] = useRemoveTreatmentPlanOccurrenceMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      treatmentPlanOccurrenceId: // value for 'treatmentPlanOccurrenceId'
 *   },
 * });
 */
export function useRemoveTreatmentPlanOccurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTreatmentPlanOccurrenceMutation,
    RemoveTreatmentPlanOccurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveTreatmentPlanOccurrenceMutation,
    RemoveTreatmentPlanOccurrenceMutationVariables
  >(RemoveTreatmentPlanOccurrenceDocument, options);
}
export type RemoveTreatmentPlanOccurrenceMutationHookResult = ReturnType<
  typeof useRemoveTreatmentPlanOccurrenceMutation
>;
export type RemoveTreatmentPlanOccurrenceMutationResult =
  Apollo.MutationResult<RemoveTreatmentPlanOccurrenceMutation>;
export type RemoveTreatmentPlanOccurrenceMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveTreatmentPlanOccurrenceMutation,
    RemoveTreatmentPlanOccurrenceMutationVariables
  >;
export const ResendNotificationForOccurrenceDocument = gql`
  mutation resendNotificationForOccurrence(
    $correlationId: UUID!
    $id: UUID!
    $type: NotificationResendRelation!
    $patientId: UUID!
    $courseId: UUID!
  ) {
    resendNotificationForOccurrence(
      correlationId: $correlationId
      id: $id
      type: $type
      patientId: $patientId
      courseId: $courseId
    ) {
      ...PatientDemographics
    }
  }
  ${PatientDemographicsFragmentDoc}
`;
export type ResendNotificationForOccurrenceMutationFn = Apollo.MutationFunction<
  ResendNotificationForOccurrenceMutation,
  ResendNotificationForOccurrenceMutationVariables
>;

/**
 * __useResendNotificationForOccurrenceMutation__
 *
 * To run a mutation, you first call `useResendNotificationForOccurrenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendNotificationForOccurrenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendNotificationForOccurrenceMutation, { data, loading, error }] = useResendNotificationForOccurrenceMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      patientId: // value for 'patientId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useResendNotificationForOccurrenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendNotificationForOccurrenceMutation,
    ResendNotificationForOccurrenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendNotificationForOccurrenceMutation,
    ResendNotificationForOccurrenceMutationVariables
  >(ResendNotificationForOccurrenceDocument, options);
}
export type ResendNotificationForOccurrenceMutationHookResult = ReturnType<
  typeof useResendNotificationForOccurrenceMutation
>;
export type ResendNotificationForOccurrenceMutationResult =
  Apollo.MutationResult<ResendNotificationForOccurrenceMutation>;
export type ResendNotificationForOccurrenceMutationOptions =
  Apollo.BaseMutationOptions<
    ResendNotificationForOccurrenceMutation,
    ResendNotificationForOccurrenceMutationVariables
  >;
export const RetriggerEnrollmentSmsDocument = gql`
  mutation RetriggerEnrollmentSms($correlationId: UUID!, $id: UUID!) {
    retriggerEnrollmentSms(correlationId: $correlationId, id: $id) {
      ...PatientDemographics
    }
  }
  ${PatientDemographicsFragmentDoc}
`;
export type RetriggerEnrollmentSmsMutationFn = Apollo.MutationFunction<
  RetriggerEnrollmentSmsMutation,
  RetriggerEnrollmentSmsMutationVariables
>;

/**
 * __useRetriggerEnrollmentSmsMutation__
 *
 * To run a mutation, you first call `useRetriggerEnrollmentSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetriggerEnrollmentSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retriggerEnrollmentSmsMutation, { data, loading, error }] = useRetriggerEnrollmentSmsMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetriggerEnrollmentSmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetriggerEnrollmentSmsMutation,
    RetriggerEnrollmentSmsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetriggerEnrollmentSmsMutation,
    RetriggerEnrollmentSmsMutationVariables
  >(RetriggerEnrollmentSmsDocument, options);
}
export type RetriggerEnrollmentSmsMutationHookResult = ReturnType<
  typeof useRetriggerEnrollmentSmsMutation
>;
export type RetriggerEnrollmentSmsMutationResult =
  Apollo.MutationResult<RetriggerEnrollmentSmsMutation>;
export type RetriggerEnrollmentSmsMutationOptions = Apollo.BaseMutationOptions<
  RetriggerEnrollmentSmsMutation,
  RetriggerEnrollmentSmsMutationVariables
>;
export const RunScenarioDocument = gql`
  mutation RunScenario($scenario: ScenarioRunInput!) {
    runScenario(scenario: $scenario) {
      __typename
      id
      variableValues {
        __typename
        name
        value
      }
      events
    }
  }
`;
export type RunScenarioMutationFn = Apollo.MutationFunction<
  RunScenarioMutation,
  RunScenarioMutationVariables
>;

/**
 * __useRunScenarioMutation__
 *
 * To run a mutation, you first call `useRunScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runScenarioMutation, { data, loading, error }] = useRunScenarioMutation({
 *   variables: {
 *      scenario: // value for 'scenario'
 *   },
 * });
 */
export function useRunScenarioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunScenarioMutation,
    RunScenarioMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RunScenarioMutation, RunScenarioMutationVariables>(
    RunScenarioDocument,
    options
  );
}
export type RunScenarioMutationHookResult = ReturnType<
  typeof useRunScenarioMutation
>;
export type RunScenarioMutationResult =
  Apollo.MutationResult<RunScenarioMutation>;
export type RunScenarioMutationOptions = Apollo.BaseMutationOptions<
  RunScenarioMutation,
  RunScenarioMutationVariables
>;
export const SendTemplatizedMessageDocument = gql`
  mutation SendTemplatizedMessage(
    $correlationId: UUID!
    $notificationTemplateId: UUID!
    $patientIds: [UUID!]!
  ) {
    sendTemplatizedMessage(
      correlationId: $correlationId
      notificationTemplateId: $notificationTemplateId
      patientIds: $patientIds
    ) {
      id
    }
  }
`;
export type SendTemplatizedMessageMutationFn = Apollo.MutationFunction<
  SendTemplatizedMessageMutation,
  SendTemplatizedMessageMutationVariables
>;

/**
 * __useSendTemplatizedMessageMutation__
 *
 * To run a mutation, you first call `useSendTemplatizedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTemplatizedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTemplatizedMessageMutation, { data, loading, error }] = useSendTemplatizedMessageMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      notificationTemplateId: // value for 'notificationTemplateId'
 *      patientIds: // value for 'patientIds'
 *   },
 * });
 */
export function useSendTemplatizedMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTemplatizedMessageMutation,
    SendTemplatizedMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendTemplatizedMessageMutation,
    SendTemplatizedMessageMutationVariables
  >(SendTemplatizedMessageDocument, options);
}
export type SendTemplatizedMessageMutationHookResult = ReturnType<
  typeof useSendTemplatizedMessageMutation
>;
export type SendTemplatizedMessageMutationResult =
  Apollo.MutationResult<SendTemplatizedMessageMutation>;
export type SendTemplatizedMessageMutationOptions = Apollo.BaseMutationOptions<
  SendTemplatizedMessageMutation,
  SendTemplatizedMessageMutationVariables
>;
export const UpdateParameterValuesDocument = gql`
  mutation UpdateParameterValues(
    $correlationId: UUID!
    $treatmentPlanOccurrenceId: UUID!
    $parameterValues: [TreatmentPlanParameterValueInput!]!
  ) {
    updateParameterValues(
      correlationId: $correlationId
      treatmentPlanOccurrenceId: $treatmentPlanOccurrenceId
      parameterValues: $parameterValues
    ) {
      ...TreatmentPlanOccurrence
    }
  }
  ${TreatmentPlanOccurrenceFragmentDoc}
`;
export type UpdateParameterValuesMutationFn = Apollo.MutationFunction<
  UpdateParameterValuesMutation,
  UpdateParameterValuesMutationVariables
>;

/**
 * __useUpdateParameterValuesMutation__
 *
 * To run a mutation, you first call `useUpdateParameterValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParameterValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParameterValuesMutation, { data, loading, error }] = useUpdateParameterValuesMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      treatmentPlanOccurrenceId: // value for 'treatmentPlanOccurrenceId'
 *      parameterValues: // value for 'parameterValues'
 *   },
 * });
 */
export function useUpdateParameterValuesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParameterValuesMutation,
    UpdateParameterValuesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateParameterValuesMutation,
    UpdateParameterValuesMutationVariables
  >(UpdateParameterValuesDocument, options);
}
export type UpdateParameterValuesMutationHookResult = ReturnType<
  typeof useUpdateParameterValuesMutation
>;
export type UpdateParameterValuesMutationResult =
  Apollo.MutationResult<UpdateParameterValuesMutation>;
export type UpdateParameterValuesMutationOptions = Apollo.BaseMutationOptions<
  UpdateParameterValuesMutation,
  UpdateParameterValuesMutationVariables
>;
export const UpdatePatientDemographicsDocument = gql`
  mutation UpdatePatientDemographics(
    $correlationId: UUID!
    $id: UUID!
    $demographics: PatientDemographicsInput!
    $treatingProviderLocationId: UUID
  ) {
    updatePatientDemographics(
      correlationId: $correlationId
      id: $id
      demographics: $demographics
      treatingProviderLocationId: $treatingProviderLocationId
    ) {
      ...PatientDemographics
    }
  }
  ${PatientDemographicsFragmentDoc}
`;
export type UpdatePatientDemographicsMutationFn = Apollo.MutationFunction<
  UpdatePatientDemographicsMutation,
  UpdatePatientDemographicsMutationVariables
>;

/**
 * __useUpdatePatientDemographicsMutation__
 *
 * To run a mutation, you first call `useUpdatePatientDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientDemographicsMutation, { data, loading, error }] = useUpdatePatientDemographicsMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      id: // value for 'id'
 *      demographics: // value for 'demographics'
 *      treatingProviderLocationId: // value for 'treatingProviderLocationId'
 *   },
 * });
 */
export function useUpdatePatientDemographicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientDemographicsMutation,
    UpdatePatientDemographicsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientDemographicsMutation,
    UpdatePatientDemographicsMutationVariables
  >(UpdatePatientDemographicsDocument, options);
}
export type UpdatePatientDemographicsMutationHookResult = ReturnType<
  typeof useUpdatePatientDemographicsMutation
>;
export type UpdatePatientDemographicsMutationResult =
  Apollo.MutationResult<UpdatePatientDemographicsMutation>;
export type UpdatePatientDemographicsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePatientDemographicsMutation,
    UpdatePatientDemographicsMutationVariables
  >;
export const UpdatePersonDocument = gql`
  mutation UpdatePerson(
    $correlationId: UUID!
    $id: UUID!
    $person: PersonInput!
  ) {
    updatePerson(correlationId: $correlationId, id: $id, person: $person) {
      ...Person
    }
  }
  ${PersonFragmentDoc}
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      id: // value for 'id'
 *      person: // value for 'person'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >(UpdatePersonDocument, options);
}
export type UpdatePersonMutationHookResult = ReturnType<
  typeof useUpdatePersonMutation
>;
export type UpdatePersonMutationResult =
  Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const ActiveTreatmentPlanOccurrencesDocument = gql`
  query ActiveTreatmentPlanOccurrences($courseId: UUID!) {
    activeTreatmentPlanOccurrences(courseId: $courseId) {
      ...TreatmentPlanOccurrence
      treatmentPlan {
        ...TreatmentPlanSettings
      }
    }
  }
  ${TreatmentPlanOccurrenceFragmentDoc}
  ${TreatmentPlanSettingsFragmentDoc}
`;

/**
 * __useActiveTreatmentPlanOccurrencesQuery__
 *
 * To run a query within a React component, call `useActiveTreatmentPlanOccurrencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTreatmentPlanOccurrencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTreatmentPlanOccurrencesQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useActiveTreatmentPlanOccurrencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveTreatmentPlanOccurrencesQuery,
    ActiveTreatmentPlanOccurrencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveTreatmentPlanOccurrencesQuery,
    ActiveTreatmentPlanOccurrencesQueryVariables
  >(ActiveTreatmentPlanOccurrencesDocument, options);
}
export function useActiveTreatmentPlanOccurrencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveTreatmentPlanOccurrencesQuery,
    ActiveTreatmentPlanOccurrencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveTreatmentPlanOccurrencesQuery,
    ActiveTreatmentPlanOccurrencesQueryVariables
  >(ActiveTreatmentPlanOccurrencesDocument, options);
}
export type ActiveTreatmentPlanOccurrencesQueryHookResult = ReturnType<
  typeof useActiveTreatmentPlanOccurrencesQuery
>;
export type ActiveTreatmentPlanOccurrencesLazyQueryHookResult = ReturnType<
  typeof useActiveTreatmentPlanOccurrencesLazyQuery
>;
export type ActiveTreatmentPlanOccurrencesQueryResult = Apollo.QueryResult<
  ActiveTreatmentPlanOccurrencesQuery,
  ActiveTreatmentPlanOccurrencesQueryVariables
>;
export const AllAvailableLanguagesDocument = gql`
  query AllAvailableLanguages {
    allAvailableLanguages {
      ...AvailableLanguage
    }
  }
  ${AvailableLanguageFragmentDoc}
`;

/**
 * __useAllAvailableLanguagesQuery__
 *
 * To run a query within a React component, call `useAllAvailableLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAvailableLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAvailableLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAvailableLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllAvailableLanguagesQuery,
    AllAvailableLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllAvailableLanguagesQuery,
    AllAvailableLanguagesQueryVariables
  >(AllAvailableLanguagesDocument, options);
}
export function useAllAvailableLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllAvailableLanguagesQuery,
    AllAvailableLanguagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllAvailableLanguagesQuery,
    AllAvailableLanguagesQueryVariables
  >(AllAvailableLanguagesDocument, options);
}
export type AllAvailableLanguagesQueryHookResult = ReturnType<
  typeof useAllAvailableLanguagesQuery
>;
export type AllAvailableLanguagesLazyQueryHookResult = ReturnType<
  typeof useAllAvailableLanguagesLazyQuery
>;
export type AllAvailableLanguagesQueryResult = Apollo.QueryResult<
  AllAvailableLanguagesQuery,
  AllAvailableLanguagesQueryVariables
>;
export const AuditLogDocument = gql`
  query AuditLog($filter: AuditLogFilter!) {
    auditLog(filter: $filter) {
      ...AuditLogRecord
    }
  }
  ${AuditLogRecordFragmentDoc}
`;

/**
 * __useAuditLogQuery__
 *
 * To run a query within a React component, call `useAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAuditLogQuery(
  baseOptions: Apollo.QueryHookOptions<AuditLogQuery, AuditLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options
  );
}
export function useAuditLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditLogQuery,
    AuditLogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options
  );
}
export type AuditLogQueryHookResult = ReturnType<typeof useAuditLogQuery>;
export type AuditLogLazyQueryHookResult = ReturnType<
  typeof useAuditLogLazyQuery
>;
export type AuditLogQueryResult = Apollo.QueryResult<
  AuditLogQuery,
  AuditLogQueryVariables
>;
export const AvailableDiagnosesDocument = gql`
  query AvailableDiagnoses($orderingProviderLocation: UUID) {
    availableDiagnosesById(
      orderingProviderLocation: $orderingProviderLocation
    ) {
      ...Diagnosis
    }
    defaultDiagnosisById(orderingProviderLocation: $orderingProviderLocation) {
      ...Diagnosis
    }
  }
  ${DiagnosisFragmentDoc}
`;

/**
 * __useAvailableDiagnosesQuery__
 *
 * To run a query within a React component, call `useAvailableDiagnosesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDiagnosesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDiagnosesQuery({
 *   variables: {
 *      orderingProviderLocation: // value for 'orderingProviderLocation'
 *   },
 * });
 */
export function useAvailableDiagnosesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableDiagnosesQuery,
    AvailableDiagnosesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableDiagnosesQuery,
    AvailableDiagnosesQueryVariables
  >(AvailableDiagnosesDocument, options);
}
export function useAvailableDiagnosesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableDiagnosesQuery,
    AvailableDiagnosesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableDiagnosesQuery,
    AvailableDiagnosesQueryVariables
  >(AvailableDiagnosesDocument, options);
}
export type AvailableDiagnosesQueryHookResult = ReturnType<
  typeof useAvailableDiagnosesQuery
>;
export type AvailableDiagnosesLazyQueryHookResult = ReturnType<
  typeof useAvailableDiagnosesLazyQuery
>;
export type AvailableDiagnosesQueryResult = Apollo.QueryResult<
  AvailableDiagnosesQuery,
  AvailableDiagnosesQueryVariables
>;
export const AvailableTreatmentPlansDocument = gql`
  query AvailableTreatmentPlans(
    $diagnosisId: UUID!
    $withSettings: Boolean = false
  ) {
    availableTreatmentPlans(diagnosisId: $diagnosisId) {
      ...TreatmentPlan
      ...TreatmentPlanSettings @include(if: $withSettings)
    }
  }
  ${TreatmentPlanFragmentDoc}
  ${TreatmentPlanSettingsFragmentDoc}
`;

/**
 * __useAvailableTreatmentPlansQuery__
 *
 * To run a query within a React component, call `useAvailableTreatmentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTreatmentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTreatmentPlansQuery({
 *   variables: {
 *      diagnosisId: // value for 'diagnosisId'
 *      withSettings: // value for 'withSettings'
 *   },
 * });
 */
export function useAvailableTreatmentPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableTreatmentPlansQuery,
    AvailableTreatmentPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableTreatmentPlansQuery,
    AvailableTreatmentPlansQueryVariables
  >(AvailableTreatmentPlansDocument, options);
}
export function useAvailableTreatmentPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableTreatmentPlansQuery,
    AvailableTreatmentPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableTreatmentPlansQuery,
    AvailableTreatmentPlansQueryVariables
  >(AvailableTreatmentPlansDocument, options);
}
export type AvailableTreatmentPlansQueryHookResult = ReturnType<
  typeof useAvailableTreatmentPlansQuery
>;
export type AvailableTreatmentPlansLazyQueryHookResult = ReturnType<
  typeof useAvailableTreatmentPlansLazyQuery
>;
export type AvailableTreatmentPlansQueryResult = Apollo.QueryResult<
  AvailableTreatmentPlansQuery,
  AvailableTreatmentPlansQueryVariables
>;
export const CalendarBlobDocument = gql`
  query CalendarBlob(
    $request: CalendarInput!
    $format: CalendarSerializationFormat = ICAL
  ) {
    renderCalendar(request: $request, format: $format) {
      __typename
      blob
    }
  }
`;

/**
 * __useCalendarBlobQuery__
 *
 * To run a query within a React component, call `useCalendarBlobQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarBlobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarBlobQuery({
 *   variables: {
 *      request: // value for 'request'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useCalendarBlobQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalendarBlobQuery,
    CalendarBlobQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CalendarBlobQuery, CalendarBlobQueryVariables>(
    CalendarBlobDocument,
    options
  );
}
export function useCalendarBlobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalendarBlobQuery,
    CalendarBlobQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CalendarBlobQuery, CalendarBlobQueryVariables>(
    CalendarBlobDocument,
    options
  );
}
export type CalendarBlobQueryHookResult = ReturnType<
  typeof useCalendarBlobQuery
>;
export type CalendarBlobLazyQueryHookResult = ReturnType<
  typeof useCalendarBlobLazyQuery
>;
export type CalendarBlobQueryResult = Apollo.QueryResult<
  CalendarBlobQuery,
  CalendarBlobQueryVariables
>;
export const CrmPersonDocument = gql`
  query CrmPerson($copperId: String!) {
    crmPerson(copperId: $copperId) {
      ...CrmPerson
    }
  }
  ${CrmPersonFragmentDoc}
`;

/**
 * __useCrmPersonQuery__
 *
 * To run a query within a React component, call `useCrmPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmPersonQuery({
 *   variables: {
 *      copperId: // value for 'copperId'
 *   },
 * });
 */
export function useCrmPersonQuery(
  baseOptions: Apollo.QueryHookOptions<CrmPersonQuery, CrmPersonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CrmPersonQuery, CrmPersonQueryVariables>(
    CrmPersonDocument,
    options
  );
}
export function useCrmPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CrmPersonQuery,
    CrmPersonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CrmPersonQuery, CrmPersonQueryVariables>(
    CrmPersonDocument,
    options
  );
}
export type CrmPersonQueryHookResult = ReturnType<typeof useCrmPersonQuery>;
export type CrmPersonLazyQueryHookResult = ReturnType<
  typeof useCrmPersonLazyQuery
>;
export type CrmPersonQueryResult = Apollo.QueryResult<
  CrmPersonQuery,
  CrmPersonQueryVariables
>;
export const GetCsvMappingsDocument = gql`
  query GetCsvMappings {
    getCsvMappings
  }
`;

/**
 * __useGetCsvMappingsQuery__
 *
 * To run a query within a React component, call `useGetCsvMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCsvMappingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCsvMappingsQuery,
    GetCsvMappingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCsvMappingsQuery, GetCsvMappingsQueryVariables>(
    GetCsvMappingsDocument,
    options
  );
}
export function useGetCsvMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCsvMappingsQuery,
    GetCsvMappingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCsvMappingsQuery, GetCsvMappingsQueryVariables>(
    GetCsvMappingsDocument,
    options
  );
}
export type GetCsvMappingsQueryHookResult = ReturnType<
  typeof useGetCsvMappingsQuery
>;
export type GetCsvMappingsLazyQueryHookResult = ReturnType<
  typeof useGetCsvMappingsLazyQuery
>;
export type GetCsvMappingsQueryResult = Apollo.QueryResult<
  GetCsvMappingsQuery,
  GetCsvMappingsQueryVariables
>;
export const GetNodesDocument = gql`
  query GetNodes($uuids: [UUID!]!, $recursive: Boolean) {
    getNodes(uuids: $uuids, recursive: $recursive) {
      ...AnyTreatmentPlanNode
    }
  }
  ${AnyTreatmentPlanNodeFragmentDoc}
`;

/**
 * __useGetNodesQuery__
 *
 * To run a query within a React component, call `useGetNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodesQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      recursive: // value for 'recursive'
 *   },
 * });
 */
export function useGetNodesQuery(
  baseOptions: Apollo.QueryHookOptions<GetNodesQuery, GetNodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNodesQuery, GetNodesQueryVariables>(
    GetNodesDocument,
    options
  );
}
export function useGetNodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNodesQuery,
    GetNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNodesQuery, GetNodesQueryVariables>(
    GetNodesDocument,
    options
  );
}
export type GetNodesQueryHookResult = ReturnType<typeof useGetNodesQuery>;
export type GetNodesLazyQueryHookResult = ReturnType<
  typeof useGetNodesLazyQuery
>;
export type GetNodesQueryResult = Apollo.QueryResult<
  GetNodesQuery,
  GetNodesQueryVariables
>;
export const HasMatchRuleDocument = gql`
  query HasMatchRule(
    $providerLocationId: UUID!
    $diagnosisId: UUID!
    $treatmentPlanId: UUID!
  ) {
    hasMatchRule(
      providerLocationId: $providerLocationId
      diagnosisId: $diagnosisId
      treatmentPlanId: $treatmentPlanId
    )
  }
`;

/**
 * __useHasMatchRuleQuery__
 *
 * To run a query within a React component, call `useHasMatchRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasMatchRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasMatchRuleQuery({
 *   variables: {
 *      providerLocationId: // value for 'providerLocationId'
 *      diagnosisId: // value for 'diagnosisId'
 *      treatmentPlanId: // value for 'treatmentPlanId'
 *   },
 * });
 */
export function useHasMatchRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasMatchRuleQuery,
    HasMatchRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasMatchRuleQuery, HasMatchRuleQueryVariables>(
    HasMatchRuleDocument,
    options
  );
}
export function useHasMatchRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasMatchRuleQuery,
    HasMatchRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HasMatchRuleQuery, HasMatchRuleQueryVariables>(
    HasMatchRuleDocument,
    options
  );
}
export type HasMatchRuleQueryHookResult = ReturnType<
  typeof useHasMatchRuleQuery
>;
export type HasMatchRuleLazyQueryHookResult = ReturnType<
  typeof useHasMatchRuleLazyQuery
>;
export type HasMatchRuleQueryResult = Apollo.QueryResult<
  HasMatchRuleQuery,
  HasMatchRuleQueryVariables
>;
export const ListAvailableSurveysDocument = gql`
  query ListAvailableSurveys {
    listAvailableSurveys {
      id
      name
      url
    }
  }
`;

/**
 * __useListAvailableSurveysQuery__
 *
 * To run a query within a React component, call `useListAvailableSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAvailableSurveysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAvailableSurveysQuery,
    ListAvailableSurveysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAvailableSurveysQuery,
    ListAvailableSurveysQueryVariables
  >(ListAvailableSurveysDocument, options);
}
export function useListAvailableSurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAvailableSurveysQuery,
    ListAvailableSurveysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAvailableSurveysQuery,
    ListAvailableSurveysQueryVariables
  >(ListAvailableSurveysDocument, options);
}
export type ListAvailableSurveysQueryHookResult = ReturnType<
  typeof useListAvailableSurveysQuery
>;
export type ListAvailableSurveysLazyQueryHookResult = ReturnType<
  typeof useListAvailableSurveysLazyQuery
>;
export type ListAvailableSurveysQueryResult = Apollo.QueryResult<
  ListAvailableSurveysQuery,
  ListAvailableSurveysQueryVariables
>;
export const NotificationTemplatesDocument = gql`
  query NotificationTemplates {
    notificationTemplates {
      ...NotificationTemplate
    }
  }
  ${NotificationTemplateFragmentDoc}
`;

/**
 * __useNotificationTemplatesQuery__
 *
 * To run a query within a React component, call `useNotificationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationTemplatesQuery,
    NotificationTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationTemplatesQuery,
    NotificationTemplatesQueryVariables
  >(NotificationTemplatesDocument, options);
}
export function useNotificationTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationTemplatesQuery,
    NotificationTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationTemplatesQuery,
    NotificationTemplatesQueryVariables
  >(NotificationTemplatesDocument, options);
}
export type NotificationTemplatesQueryHookResult = ReturnType<
  typeof useNotificationTemplatesQuery
>;
export type NotificationTemplatesLazyQueryHookResult = ReturnType<
  typeof useNotificationTemplatesLazyQuery
>;
export type NotificationTemplatesQueryResult = Apollo.QueryResult<
  NotificationTemplatesQuery,
  NotificationTemplatesQueryVariables
>;
export const PatientsDocument = gql`
  query Patients(
    $query: String
    $filters: [PatientSearchFilterInput!]
    $includeDeleted: Boolean
    $sort: [PatientSearchSort!]
    $first: Int
    $offset: Int
  ) {
    patients(
      query: $query
      filters: $filters
      includeDeleted: $includeDeleted
      sort: $sort
      first: $first
      offset: $offset
    ) {
      totalCount
      edges {
        score
        highlights {
          ...PatientHighlight
        }
        node {
          ...Patient
          cohorts {
            ...PatientCohort
          }
          enrollments {
            ...PatientEnrollment
          }
          providers {
            ...PatientProviders
          }
          lastUpdated
          moduleVisits {
            count
            latestVisit
          }
        }
      }
    }
  }
  ${PatientHighlightFragmentDoc}
  ${PatientFragmentDoc}
  ${PatientCohortFragmentDoc}
  ${PatientEnrollmentFragmentDoc}
  ${PatientProvidersFragmentDoc}
`;

/**
 * __usePatientsQuery__
 *
 * To run a query within a React component, call `usePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      filters: // value for 'filters'
 *      includeDeleted: // value for 'includeDeleted'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<PatientsQuery, PatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options
  );
}
export function usePatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientsQuery,
    PatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientsQuery, PatientsQueryVariables>(
    PatientsDocument,
    options
  );
}
export type PatientsQueryHookResult = ReturnType<typeof usePatientsQuery>;
export type PatientsLazyQueryHookResult = ReturnType<
  typeof usePatientsLazyQuery
>;
export type PatientsQueryResult = Apollo.QueryResult<
  PatientsQuery,
  PatientsQueryVariables
>;
export const PreviewTemplatizedMessageDocument = gql`
  query PreviewTemplatizedMessage(
    $notificationTemplateId: UUID!
    $patientId: UUID!
  ) {
    previewTemplatizedMessage(
      notificationTemplateId: $notificationTemplateId
      patientId: $patientId
    ) {
      content
    }
  }
`;

/**
 * __usePreviewTemplatizedMessageQuery__
 *
 * To run a query within a React component, call `usePreviewTemplatizedMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewTemplatizedMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewTemplatizedMessageQuery({
 *   variables: {
 *      notificationTemplateId: // value for 'notificationTemplateId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePreviewTemplatizedMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreviewTemplatizedMessageQuery,
    PreviewTemplatizedMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PreviewTemplatizedMessageQuery,
    PreviewTemplatizedMessageQueryVariables
  >(PreviewTemplatizedMessageDocument, options);
}
export function usePreviewTemplatizedMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreviewTemplatizedMessageQuery,
    PreviewTemplatizedMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PreviewTemplatizedMessageQuery,
    PreviewTemplatizedMessageQueryVariables
  >(PreviewTemplatizedMessageDocument, options);
}
export type PreviewTemplatizedMessageQueryHookResult = ReturnType<
  typeof usePreviewTemplatizedMessageQuery
>;
export type PreviewTemplatizedMessageLazyQueryHookResult = ReturnType<
  typeof usePreviewTemplatizedMessageLazyQuery
>;
export type PreviewTemplatizedMessageQueryResult = Apollo.QueryResult<
  PreviewTemplatizedMessageQuery,
  PreviewTemplatizedMessageQueryVariables
>;
export const ProviderMatchesDocument = gql`
  query ProviderMatches($courseId: UUID!) {
    providerMatches(courseId: $courseId) {
      courseId
      matches {
        __typename
        id
        courseId
        description
        name
        score
        treatmentPlanId
      }
    }
  }
`;

/**
 * __useProviderMatchesQuery__
 *
 * To run a query within a React component, call `useProviderMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderMatchesQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useProviderMatchesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderMatchesQuery,
    ProviderMatchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderMatchesQuery, ProviderMatchesQueryVariables>(
    ProviderMatchesDocument,
    options
  );
}
export function useProviderMatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderMatchesQuery,
    ProviderMatchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderMatchesQuery,
    ProviderMatchesQueryVariables
  >(ProviderMatchesDocument, options);
}
export type ProviderMatchesQueryHookResult = ReturnType<
  typeof useProviderMatchesQuery
>;
export type ProviderMatchesLazyQueryHookResult = ReturnType<
  typeof useProviderMatchesLazyQuery
>;
export type ProviderMatchesQueryResult = Apollo.QueryResult<
  ProviderMatchesQuery,
  ProviderMatchesQueryVariables
>;
export const ProviderSettingsDocument = gql`
  query ProviderSettings($providerId: UUID!) {
    treatmentPlans {
      ...TreatmentPlan
    }
    diagnoses {
      ...Diagnosis
    }
    patientIdentifiers {
      ...PatientIdentifier
      description
    }
    provider(providerId: $providerId) {
      ...Provider
    }
    matchRules(providerId: $providerId) {
      ...MatchRule
    }
  }
  ${TreatmentPlanFragmentDoc}
  ${DiagnosisFragmentDoc}
  ${PatientIdentifierFragmentDoc}
  ${ProviderFragmentDoc}
  ${MatchRuleFragmentDoc}
`;

/**
 * __useProviderSettingsQuery__
 *
 * To run a query within a React component, call `useProviderSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderSettingsQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useProviderSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderSettingsQuery,
    ProviderSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderSettingsQuery, ProviderSettingsQueryVariables>(
    ProviderSettingsDocument,
    options
  );
}
export function useProviderSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderSettingsQuery,
    ProviderSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderSettingsQuery,
    ProviderSettingsQueryVariables
  >(ProviderSettingsDocument, options);
}
export type ProviderSettingsQueryHookResult = ReturnType<
  typeof useProviderSettingsQuery
>;
export type ProviderSettingsLazyQueryHookResult = ReturnType<
  typeof useProviderSettingsLazyQuery
>;
export type ProviderSettingsQueryResult = Apollo.QueryResult<
  ProviderSettingsQuery,
  ProviderSettingsQueryVariables
>;
export const RenderCalendarDocument = gql`
  query RenderCalendar(
    $request: CalendarInput!
    $from: OffsetDateTime
    $to: OffsetDateTime
    $format: CalendarSerializationFormat = ICAL
  ) {
    renderCalendar(request: $request, format: $format) {
      ...Calendar
    }
  }
  ${CalendarFragmentDoc}
`;

/**
 * __useRenderCalendarQuery__
 *
 * To run a query within a React component, call `useRenderCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderCalendarQuery({
 *   variables: {
 *      request: // value for 'request'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useRenderCalendarQuery(
  baseOptions: Apollo.QueryHookOptions<
    RenderCalendarQuery,
    RenderCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RenderCalendarQuery, RenderCalendarQueryVariables>(
    RenderCalendarDocument,
    options
  );
}
export function useRenderCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RenderCalendarQuery,
    RenderCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RenderCalendarQuery, RenderCalendarQueryVariables>(
    RenderCalendarDocument,
    options
  );
}
export type RenderCalendarQueryHookResult = ReturnType<
  typeof useRenderCalendarQuery
>;
export type RenderCalendarLazyQueryHookResult = ReturnType<
  typeof useRenderCalendarLazyQuery
>;
export type RenderCalendarQueryResult = Apollo.QueryResult<
  RenderCalendarQuery,
  RenderCalendarQueryVariables
>;
export const RenderCalendarPdfDocument = gql`
  query RenderCalendarPDF(
    $correlationId: UUID!
    $request: CalendarInput!
    $pdfRequest: CalendarPDFRequest
  ) {
    renderCalendarPDF(
      correlationId: $correlationId
      request: $request
      pdfRequest: $pdfRequest
    ) {
      blob
    }
  }
`;

/**
 * __useRenderCalendarPdfQuery__
 *
 * To run a query within a React component, call `useRenderCalendarPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderCalendarPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderCalendarPdfQuery({
 *   variables: {
 *      correlationId: // value for 'correlationId'
 *      request: // value for 'request'
 *      pdfRequest: // value for 'pdfRequest'
 *   },
 * });
 */
export function useRenderCalendarPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    RenderCalendarPdfQuery,
    RenderCalendarPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RenderCalendarPdfQuery,
    RenderCalendarPdfQueryVariables
  >(RenderCalendarPdfDocument, options);
}
export function useRenderCalendarPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RenderCalendarPdfQuery,
    RenderCalendarPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RenderCalendarPdfQuery,
    RenderCalendarPdfQueryVariables
  >(RenderCalendarPdfDocument, options);
}
export type RenderCalendarPdfQueryHookResult = ReturnType<
  typeof useRenderCalendarPdfQuery
>;
export type RenderCalendarPdfLazyQueryHookResult = ReturnType<
  typeof useRenderCalendarPdfLazyQuery
>;
export type RenderCalendarPdfQueryResult = Apollo.QueryResult<
  RenderCalendarPdfQuery,
  RenderCalendarPdfQueryVariables
>;
export const RenderCalendarPlanningDocument = gql`
  query RenderCalendarPlanning(
    $request: CalendarInput!
    $from: OffsetDateTime
    $to: OffsetDateTime
    $format: CalendarSerializationFormat = ICAL
  ) {
    renderCalendar(request: $request, format: $format) {
      ...Calendar
    }
  }
  ${CalendarFragmentDoc}
`;

/**
 * __useRenderCalendarPlanningQuery__
 *
 * To run a query within a React component, call `useRenderCalendarPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenderCalendarPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenderCalendarPlanningQuery({
 *   variables: {
 *      request: // value for 'request'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useRenderCalendarPlanningQuery(
  baseOptions: Apollo.QueryHookOptions<
    RenderCalendarPlanningQuery,
    RenderCalendarPlanningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RenderCalendarPlanningQuery,
    RenderCalendarPlanningQueryVariables
  >(RenderCalendarPlanningDocument, options);
}
export function useRenderCalendarPlanningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RenderCalendarPlanningQuery,
    RenderCalendarPlanningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RenderCalendarPlanningQuery,
    RenderCalendarPlanningQueryVariables
  >(RenderCalendarPlanningDocument, options);
}
export type RenderCalendarPlanningQueryHookResult = ReturnType<
  typeof useRenderCalendarPlanningQuery
>;
export type RenderCalendarPlanningLazyQueryHookResult = ReturnType<
  typeof useRenderCalendarPlanningLazyQuery
>;
export type RenderCalendarPlanningQueryResult = Apollo.QueryResult<
  RenderCalendarPlanningQuery,
  RenderCalendarPlanningQueryVariables
>;
export const ScenariosDocument = gql`
  query Scenarios {
    scenarios {
      ...Scenario
    }
  }
  ${ScenarioFragmentDoc}
`;

/**
 * __useScenariosQuery__
 *
 * To run a query within a React component, call `useScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenariosQuery({
 *   variables: {
 *   },
 * });
 */
export function useScenariosQuery(
  baseOptions?: Apollo.QueryHookOptions<ScenariosQuery, ScenariosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScenariosQuery, ScenariosQueryVariables>(
    ScenariosDocument,
    options
  );
}
export function useScenariosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScenariosQuery,
    ScenariosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScenariosQuery, ScenariosQueryVariables>(
    ScenariosDocument,
    options
  );
}
export type ScenariosQueryHookResult = ReturnType<typeof useScenariosQuery>;
export type ScenariosLazyQueryHookResult = ReturnType<
  typeof useScenariosLazyQuery
>;
export type ScenariosQueryResult = Apollo.QueryResult<
  ScenariosQuery,
  ScenariosQueryVariables
>;
export const StaticCohortsDocument = gql`
  query StaticCohorts($organizationId: UUID!) {
    staticCohorts(organizationId: $organizationId) {
      ...StaticCohort
    }
  }
  ${StaticCohortFragmentDoc}
`;

/**
 * __useStaticCohortsQuery__
 *
 * To run a query within a React component, call `useStaticCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticCohortsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStaticCohortsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StaticCohortsQuery,
    StaticCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaticCohortsQuery, StaticCohortsQueryVariables>(
    StaticCohortsDocument,
    options
  );
}
export function useStaticCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaticCohortsQuery,
    StaticCohortsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaticCohortsQuery, StaticCohortsQueryVariables>(
    StaticCohortsDocument,
    options
  );
}
export type StaticCohortsQueryHookResult = ReturnType<
  typeof useStaticCohortsQuery
>;
export type StaticCohortsLazyQueryHookResult = ReturnType<
  typeof useStaticCohortsLazyQuery
>;
export type StaticCohortsQueryResult = Apollo.QueryResult<
  StaticCohortsQuery,
  StaticCohortsQueryVariables
>;
export const SuggestDocument = gql`
  query Suggest($query: String) {
    suggest(query: $query) {
      ...PatientSuggestResult
    }
  }
  ${PatientSuggestResultFragmentDoc}
`;

/**
 * __useSuggestQuery__
 *
 * To run a query within a React component, call `useSuggestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSuggestQuery(
  baseOptions?: Apollo.QueryHookOptions<SuggestQuery, SuggestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestQuery, SuggestQueryVariables>(
    SuggestDocument,
    options
  );
}
export function useSuggestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuggestQuery, SuggestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestQuery, SuggestQueryVariables>(
    SuggestDocument,
    options
  );
}
export type SuggestQueryHookResult = ReturnType<typeof useSuggestQuery>;
export type SuggestLazyQueryHookResult = ReturnType<typeof useSuggestLazyQuery>;
export type SuggestQueryResult = Apollo.QueryResult<
  SuggestQuery,
  SuggestQueryVariables
>;
export const SuggestElementsDocument = gql`
  query SuggestElements(
    $query: String
    $types: [AnythingType!]
    $first: Int
    $offset: Int
    $sort: [AnythingSearchSort!]
    $prefixQuery: Boolean = true
    $alternativeNames: [AlternativeName!] = [
      GENERIC
      BRAND
      PROTOCOL
      ABBREVIATION
    ]
    $visible: Boolean = true
  ) {
    anythings(
      query: $query
      types: $types
      first: $first
      offset: $offset
      sort: $sort
      prefixQuery: $prefixQuery
      alternativeNames: $alternativeNames
      visible: $visible
    ) {
      edges {
        node {
          ...Anything
        }
        score
      }
      totalCount
    }
  }
  ${AnythingFragmentDoc}
`;

/**
 * __useSuggestElementsQuery__
 *
 * To run a query within a React component, call `useSuggestElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestElementsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      types: // value for 'types'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      prefixQuery: // value for 'prefixQuery'
 *      alternativeNames: // value for 'alternativeNames'
 *      visible: // value for 'visible'
 *   },
 * });
 */
export function useSuggestElementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuggestElementsQuery,
    SuggestElementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestElementsQuery, SuggestElementsQueryVariables>(
    SuggestElementsDocument,
    options
  );
}
export function useSuggestElementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestElementsQuery,
    SuggestElementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestElementsQuery,
    SuggestElementsQueryVariables
  >(SuggestElementsDocument, options);
}
export type SuggestElementsQueryHookResult = ReturnType<
  typeof useSuggestElementsQuery
>;
export type SuggestElementsLazyQueryHookResult = ReturnType<
  typeof useSuggestElementsLazyQuery
>;
export type SuggestElementsQueryResult = Apollo.QueryResult<
  SuggestElementsQuery,
  SuggestElementsQueryVariables
>;
export const TreatingProviderLocationsDocument = gql`
  query TreatingProviderLocations {
    treatingProviderLocations {
      ...ProviderLocation
    }
  }
  ${ProviderLocationFragmentDoc}
`;

/**
 * __useTreatingProviderLocationsQuery__
 *
 * To run a query within a React component, call `useTreatingProviderLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatingProviderLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatingProviderLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreatingProviderLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TreatingProviderLocationsQuery,
    TreatingProviderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TreatingProviderLocationsQuery,
    TreatingProviderLocationsQueryVariables
  >(TreatingProviderLocationsDocument, options);
}
export function useTreatingProviderLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatingProviderLocationsQuery,
    TreatingProviderLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TreatingProviderLocationsQuery,
    TreatingProviderLocationsQueryVariables
  >(TreatingProviderLocationsDocument, options);
}
export type TreatingProviderLocationsQueryHookResult = ReturnType<
  typeof useTreatingProviderLocationsQuery
>;
export type TreatingProviderLocationsLazyQueryHookResult = ReturnType<
  typeof useTreatingProviderLocationsLazyQuery
>;
export type TreatingProviderLocationsQueryResult = Apollo.QueryResult<
  TreatingProviderLocationsQuery,
  TreatingProviderLocationsQueryVariables
>;
export const TreatmentPlanDocument = gql`
  query TreatmentPlan($treatmentPlanId: UUID!) {
    treatmentPlan(treatmentPlanId: $treatmentPlanId) {
      ...TreatmentPlan
      ...TreatmentPlanSettings
    }
  }
  ${TreatmentPlanFragmentDoc}
  ${TreatmentPlanSettingsFragmentDoc}
`;

/**
 * __useTreatmentPlanQuery__
 *
 * To run a query within a React component, call `useTreatmentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentPlanQuery({
 *   variables: {
 *      treatmentPlanId: // value for 'treatmentPlanId'
 *   },
 * });
 */
export function useTreatmentPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    TreatmentPlanQuery,
    TreatmentPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentPlanQuery, TreatmentPlanQueryVariables>(
    TreatmentPlanDocument,
    options
  );
}
export function useTreatmentPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatmentPlanQuery,
    TreatmentPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentPlanQuery, TreatmentPlanQueryVariables>(
    TreatmentPlanDocument,
    options
  );
}
export type TreatmentPlanQueryHookResult = ReturnType<
  typeof useTreatmentPlanQuery
>;
export type TreatmentPlanLazyQueryHookResult = ReturnType<
  typeof useTreatmentPlanLazyQuery
>;
export type TreatmentPlanQueryResult = Apollo.QueryResult<
  TreatmentPlanQuery,
  TreatmentPlanQueryVariables
>;
export const WhoamiDocument = gql`
  query Whoami($withProvider: Boolean = false) {
    whoami {
      ...UserInfo
      ...UserInfoProvider @include(if: $withProvider)
    }
  }
  ${UserInfoFragmentDoc}
  ${UserInfoProviderFragmentDoc}
`;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *      withProvider: // value for 'withProvider'
 *   },
 * });
 */
export function useWhoamiQuery(
  baseOptions?: Apollo.QueryHookOptions<WhoamiQuery, WhoamiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WhoamiQuery, WhoamiQueryVariables>(
    WhoamiDocument,
    options
  );
}
export function useWhoamiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WhoamiQuery, WhoamiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WhoamiQuery, WhoamiQueryVariables>(
    WhoamiDocument,
    options
  );
}
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<
  WhoamiQuery,
  WhoamiQueryVariables
>;
