import { lazy } from "react";

export const ContentBuilder = lazy(
  () => import("./ContentBuilder/ContentBuilder")
);

export const TreatmentPlansRoute = lazy(() => import("./TreatmentPlansRoute"));

export const PatientsRoute = lazy(() => import("./PatientsRoute"));

export const Home = lazy(() => import("./Home"));

export const Library = lazy(() => import("./Library/Library"));

export const NotFound = lazy(() => import("./NotFound"));

export const Patients = lazy(() => import("./Patients/Patients"));

export const Patient = lazy(() => import("./Patient/Patient"));

export const PersonSettings = lazy(
  () => import("./PersonSettings/PersonSettings")
);

export const ProviderSettings = lazy(
  () => import("./ProviderSettings/ProviderSettings")
);

export const Scenarios = lazy(() => import("./Scenarios/Scenarios"));
