import React from "react";
import { Theme, Box, makeStyles } from "@material-ui/core";
import { Router } from "@reach/router";
import { SnackbarProvider } from "notistack";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";

import GlobalLoadingIndicator from "./GlobalLoadingIndicator";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import Analytics from "./Analytics";
import { CookieBanner } from "./CookieBanner";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: "auto",
    display: "flex",
    flexDirection: "column",
  },
  snackbar: {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.25, 2),
  },
  success: {
    background: theme.palette.success.light,
  },
  error: {
    background: theme.palette.error.main, // TODO: fix error.light in navio-ui
  },
  warning: {
    background: theme.palette.warning.light,
  },
  info: {
    background: theme.palette.info.light,
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
}));

const MainLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const styles = useStyles();

  return (
    <SnackbarProvider
      classes={{
        variantSuccess: clsx(styles.snackbar, styles.success),
        variantError: clsx(styles.snackbar, styles.error),
        variantWarning: clsx(styles.snackbar, styles.warning),
        variantInfo: clsx(styles.snackbar, styles.info),
      }}
      iconVariant={{
        success: <CheckCircleOutlineIcon className={styles.icon} />,
        error: <ErrorOutlineIcon className={styles.icon} />,
        warning: <ErrorOutlineIcon className={styles.icon} />,
        info: <ErrorOutlineIcon className={styles.icon} />,
      }}
    >
      <Box className={styles.root}>
        <GlobalLoadingIndicator />
        <Router>
          <AuthenticatedRoutes path="*" />
        </Router>
        <Router primary={false}>
          <Analytics path="*" />
        </Router>
      </Box>
      {(process.env.NODE_ENV !== "development" ||
        process.env.REACT_APP_ENV === "cypress") && <CookieBanner />}
    </SnackbarProvider>
  );
};

export default MainLayout;
