import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(5, 4, 1, 4),
  },
  content: {
    padding: theme.spacing(1, 4, 4, 4),
  },
}));

export const IdleDialog = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle className={styles.title}>
        <Typography variant="h5">
          {t("You'll be signed out shortly")}
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant="body2">
          {t(
            "It looks like you’ve been away a while. To protect patient information, you’ll be signed out soon. Any action on this page will keep you signed in."
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default IdleDialog;
