import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nResources } from "@naviothera/react-native-navio-ui";

import en_US from "./en.json";

const initi18n = () => {
  i18next.use(initReactI18next).init({
    resources: {
      en: {
        default: en_US,
        ...i18nResources["en"],
      },
    },
    defaultNS: "default",
    lng: "en",
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
};

export default initi18n;
