import React from "react";
import { RouteComponentProps } from "@reach/router";
import useAnalytics from "../hooks/useAnalytics";

interface IProps extends RouteComponentProps {
  children?: any;
}

export const Analytics = ({ location, path }: IProps) => {
  const { pathname } = location || {};
  useAnalytics(pathname);
  return <React.Fragment />;
};

export default Analytics;
