import Helmet from "react-helmet";
import IS_ADMIN from "../../helpers/isAdmin";

export const getPageTitle = (title?: string) =>
  `Navio | ${IS_ADMIN ? "Admin" : "Provider"}${title ? ` - ${title}` : ""}`;

export const PageTitle = ({ title }: { title?: string }) => (
  <Helmet>
    <title>{getPageTitle(title)}</title>
  </Helmet>
);

export default PageTitle;
